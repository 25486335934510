import React, { useState, useEffect, useLayoutEffect, useCallback } from 'react';

import { render } from 'react-dom';

import { useWindowSize } from '../../hooks/other/useWindowSize';
import { useTranslation } from 'react-i18next';

import View from './View';

import { Diamond } from '../Rootrilla/RootRilla';
import { Modal, ServiceCaption } from '../Element/Modal';

import { growAnimation } from '../../utils/animation';
import { closeOverlay } from '../Element/Modal';

import AirCraft from '../Rootrilla/Aircraft';

import { MIN_SCREEN_WIDTH_NAV_TOGGLE } from '../constants';

const servicesContent: IService[] = [
    {
        id: 0,
        titleLangKey: 'services.unfold.1.title',
        captionLangKey: 'services.unfold.1.caption'
    },
    {
        id: 1,
        titleLangKey: 'services.unfold.2.title',
        captionLangKey: 'services.unfold.2.caption',
    },
    {
        id: 2,
        titleLangKey: 'services.unfold.3.title',
        captionLangKey: 'services.unfold.3.caption'
    }
]

export const Services: React.FC<SectionProps> = (props) => {
    const { t } = useTranslation();
    const [captionText, setCaptionText] = useState('');

    useLayoutEffect(() => {
        const overlay = document.getElementsByClassName("overlay")[0] as HTMLDivElement;

        render(
            <Modal id="modalService">
                <ServiceCaption
                    text={captionText}
                />
            </Modal>,
            overlay
        );
        overlay.onclick = closeOverlay;

    }, [captionText]);

    useEffect(() => {
        let isAnimated = false;
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting && !isAnimated) {

                    if (!('animateSVG' in props) || props.animateSVG) {
                        const aircraftPaths = document.querySelectorAll('svg#aircraftServices path');
                        aircraftPaths.forEach(path => growAnimation(path as SVGPathElement, { growTime: 1.7 }));
                    }

                    const servicesContainer = document.getElementById('services');

                    servicesContainer?.classList.add('animate-services');

                    isAnimated = true;
                }
            })
        })
        observer.observe(document.getElementById('services')!);
    }, [props]);

    return (
        <View langKey="services" level={3} id="services">
            {/* animate false allows animating this svg once the user has the view on the screen */}
            <AirCraft direction="left" id="aircraftServices" variant animate={false} />
            <div className="c-services">
                {servicesContent.map(service => (
                    <Service
                        titleLangKey={service.titleLangKey}
                        captionLangKey={service.captionLangKey}
                        stateSetter={setCaptionText}
                        t={t}
                        key={'ser' + service.id}
                        id={'ser' + service.id}
                    />
                ))}
            </div>
        </View>
    );
}

const Service: React.FC<ServiceProps> = ({ titleLangKey, captionLangKey, t, stateSetter, id }) => {
    const screenSize = useWindowSize();

    const updateCaption = useCallback(() => {
        stateSetter(t(captionLangKey));
        const overlay = document.getElementsByClassName("overlay")[0] as HTMLDivElement;
        const modal = overlay.querySelector('.c-modal') as HTMLDivElement;

        overlay.style.opacity = "1";
        overlay.style.pointerEvents = "all";
        modal.style.transform = "scale(1)";

    }, [stateSetter, captionLangKey, t]);

    const toggleTitle = useCallback((e: React.MouseEvent) => {
        var show: boolean = false;

        const diamondDiv = e.currentTarget as HTMLDivElement;

        const container = diamondDiv.previousSibling as HTMLDivElement;
        const parentContainer = document.getElementById(id) as HTMLDivElement;

        const previousActive = document.querySelector('#services div.c-service.active') as HTMLDivElement;

        if (previousActive) {
            previousActive.querySelector('h3')!.style.opacity = '0';
            setTimeout(() => {
                previousActive.classList.remove('active');
            }, 200);
            if (previousActive === parentContainer) return;
        }

        if (screenSize && screenSize.width && screenSize.width <= MIN_SCREEN_WIDTH_NAV_TOGGLE) show = true;

        if (show) {
            container.style.transform = "scaleX(1)";
            setTimeout(() => container.querySelector('h3')!.style.opacity = '1', 300);
            return;
        }

        parentContainer.classList.add('active');

        setTimeout(() => container.querySelector('h3')!.style.opacity = '1', 300);

    }, [screenSize, id]);

    useLayoutEffect(() => {
        const isMobileScreen = window.innerWidth <= MIN_SCREEN_WIDTH_NAV_TOGGLE;
        if (!isMobileScreen) return;


    });

    return (
        <div className="c-service" id={id}>
            <div className="wrapper-title">
                <div>
                </div>
                <h3 className="typo-h3">{t(titleLangKey)}</h3>
            </div>
            <Diamond
                onClick={updateCaption}
                onMouseEnter={toggleTitle}
            >
            </Diamond>
        </div>
    )
}


export default React.memo(Services);