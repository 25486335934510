export const sleep = (ms: number): Promise<any> => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const openTab = (link: string) => window.open(link, '_blank');

export const redirectHome = () => window.location.replace('/');

export const redirectPaymentGateway = () => window.location.replace('/payments');

// allows get theme without subscribing to ThemeContext
// this aims to remove dependency of ThemeContext for components that just need it once. Through this, components won't need
// to re-render every time ThemeContext changes.
export const getThemeFromCSS = () => document.getElementsByClassName('App')[0].classList.contains("dark") ? "dark" : "light";