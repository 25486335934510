import React, { useCallback, useContext, useRef } from 'react';
import { storeTheme } from '../../utils/storage';

import Logo from '../Rootrilla/Logo';

import ThemeContext from './ThemeContext';

import { onThemeToggle as togglePwn2me } from '../Rootrilla/LogoPwn2me';
import { onThemeToggle as toggleNet2me } from '../Rootrilla/LogoNet2me';

import Tooltip from '../Element/Tooltip';

export const ThemeSwitcher: React.FC = () => {
    const { theme, setTheme } = useContext(ThemeContext);

    const toggleTheme = useCallback(() => {
        const newTheme = theme === "dark" ? "light" : "dark";

        // store them in localStorage
        storeTheme(newTheme);

        // updates App theme
        setTheme(newTheme);

        togglePwn2me(newTheme);
        toggleNet2me(newTheme);
        // toggleCTF(newTheme);

    }, [theme, setTheme]);

    return (
        <div
            onClick={toggleTheme}
            className="c-theme_switcher"
            id="themeSwitcher"
        >
            <div className="theme-wrapper">
                <div></div>
                <div></div>
                <div></div>
                <div></div>

                <Logo id="logoSwitcher" />
            </div>
        </div>
    )
}

export const DashboardThemeSwitcher: React.FC<DshbThemeSwitcherProps> = ({ classes }) => {
    const { theme, setTheme } = useContext(ThemeContext);
    const parentRef = useRef(null);

    const toggleTheme = useCallback(() => {
        const newTheme = theme === "dark" ? "light" : "dark";

        // store them in localStorage
        storeTheme(newTheme);

        // updates App theme
        setTheme(newTheme);
        console.log("new Theme: ", newTheme);

    }, [theme, setTheme]);

    console.log("dashboard theme switcher init theme: ", theme);

    return (
        <div
            className={classes ? classes : ""}
            onClick={toggleTheme}
            ref={parentRef}
        >
            <svg version="1.1" viewBox="0 0 486.9 486.9"
                xmlns="http://www.w3.org/2000/svg">
                <path d="m243.5 0c-134.2 0-243.4 109.2-243.4 243.4 0 134.2 109.2 243.5 243.4 243.5s243.4-109.2 243.4-243.5c0-134.2-109.2-243.4-243.4-243.4zm0 438c-0.237 0-0.479-0.033-0.716-0.033v-389c0.237 0 0.479-0.035 0.716-0.035 107.2 0 194.5 87.25 194.5 194.5 0 107.3-87.26 194.5-194.5 194.5z" />
            </svg>
            <Tooltip
                text="Toggle dashboard theme"
                target={parentRef}
                offset={80}
            />
        </div>
    );
}

export default ThemeSwitcher;