import React, { useEffect, memo } from 'react';

import View from './View';
import Pricing from './Pricing';
import { Paragraph } from '../Element/Paragraph';
import { Polygon } from '../Other/Polygon';
import { EdgeBars, DiagonalEdgeBar } from '../Rootrilla/RootRilla';

import { useTranslation } from 'react-i18next';
import DiagAircrafts from '../Rootrilla/DiagAircrafts';

import { growAnimation } from '../../utils/animation';

export const RTM: React.FC<SectionProps> = (props) => {
    const { t } = useTranslation();
    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    if (!('animateSVG' in props) || props.animateSVG) {
                        const aircraftPaths = document.querySelectorAll('#rtm svg path');
                        aircraftPaths.forEach(
                            path => growAnimation(
                                path as SVGPathElement,
                                { growTime: 1.5, delay: 0.2 }
                            )
                        );
                    }
                }
            });
        });
        observer.observe(document.getElementById('rtm')!);
    }, [props]);

    return (
        <>
            <View langKey="rtm" level={4} id="rtm">
                <Paragraph className="art--rtm" id="rtmCaption">
                    <Polygon
                        id="RTMPolLeft"
                        direction="left"
                        shape="polygon(0px 0px, 35px 0px, 35px 27%, 100% 70%, 100% 100%, 0px 100%)"
                        height="85vh"
                        width="40vh"
                    />

                    <Polygon
                        id="RTMPolRight"
                        direction="right"
                        shape="polygon(80% 0px, 80% 70%, 0% 100%, 100% 100%, 100% 0%)"
                        height="85vh"
                        width="200px"
                    />

                    <div className="wrapper-text">
                        {t('rtm.main.caption').split('\n').map((line, index) => <p key={"landP" + index}>{line}</p>)}
                        {t('rtm.main.resume').split('\n').map((line, index) => <p key={"landP" + index}>{line}</p>)}
                        <EdgeBars direction="right" />
                        <DiagonalEdgeBar
                            direction="left"
                            shape="polygon(2px 0px, 2px 40%, 100% 80%, 100% calc(80% - 3px), 4px calc(40% - 2px), 4px 0px)"
                            height="60vh"
                            width="70vh"
                        />
                    </div>
                </Paragraph>
                <DiagAircrafts id="aicraftDiag" />
            </View>
            <Pricing />
        </>
    );
}

export default memo(RTM);