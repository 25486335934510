import React, { useEffect, useState, useRef, memo } from 'react';

import { growAnimation } from '../../utils/animation';

export const AirCraft: React.FC<AirCraftProps> = ({ direction, ...props }) => {

    const aircraftRef = useRef(null);
    const [isAnimated, setIsAnimated] = useState(false);

    useEffect(() => {
        if (isAnimated || ('animate' in props && !props.animate)) return;

        const aircraft = aircraftRef.current! as SVGElement;
        if (!('animProps' in props)) {
            aircraft.childNodes.forEach(node => growAnimation(node as SVGPathElement))
        } else {
            const { growTime, delay, fillTime } = props.animProps!;
            aircraft.childNodes.forEach(node => growAnimation(
                node as SVGPathElement, {
                growTime: growTime,
                delay: delay,
                fillTime: fillTime
            })
            );
        }

        setIsAnimated(true);

    }, [isAnimated, aircraftRef, props]);

    return (
        <svg
            className={`svg-aircraft--${direction}`}
            id={'id' in props ? props.id : ''}
            ref={aircraftRef}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 357.95 537.6"
        >
            <path className="f6bfffb2-0842-4624-9962-ecd809c8e0ea" d="M97,330.81a2.12,2.12,0,0,1-1.53-.64,2.15,2.15,0,0,1,0-3l112-109.9V110.36a2.18,2.18,0,0,1,.63-1.52L297.53,19.5a2.15,2.15,0,0,1,3,3l-88.72,88.71V218.13a2.17,2.17,0,0,1-.64,1.54L98.53,330.2A2.16,2.16,0,0,1,97,330.81Z" transform="translate(0 0)" />
            <path className="f6bfffb2-0842-4624-9962-ecd809c8e0ea" d="M196.08,145.29a2.15,2.15,0,0,1-2.15-2.15V113.78a2.15,2.15,0,1,1,4.3,0v29.36a2.15,2.15,0,0,1-2.15,2.15Z" transform="translate(0 0)" />
            <path className="f6bfffb2-0842-4624-9962-ecd809c8e0ea" d="M130.87,431.29H2.15A2.15,2.15,0,0,1,0,429.14v-15.2a2.16,2.16,0,0,1,.63-1.52L63,350a2.13,2.13,0,0,1,1.52-.63h63.1a2.13,2.13,0,0,1,1.52.63l48.12,48.12a2.15,2.15,0,0,1-3,3l-47.5-47.49H65.44L4.3,414.83V427H130.87a2.15,2.15,0,0,1,0,4.3Z" transform="translate(0 0)" />
            <path className="f6bfffb2-0842-4624-9962-ecd809c8e0ea" d="M273.41,332.38H244.47a2.15,2.15,0,1,1,0-4.29h28l23.3-23.3-71-71a2.12,2.12,0,0,1-.63-1.52V130.94a2.15,2.15,0,0,1,4.3,0V231.42l71.84,71.85a2.15,2.15,0,0,1,0,3l-25.44,25.45A2.15,2.15,0,0,1,273.41,332.38Z" transform="translate(0 0)" />
            {/* romboid of the aircraft */}
            <path className={"variant" in props ? "path-caption--orange" : "path-caption--purple"} d="M188.28,389.84a2.18,2.18,0,0,1-1.52-.63l-57.41-57.42a2.15,2.15,0,0,1,0-3l57.41-57.42a2.23,2.23,0,0,1,3,0l57.42,57.42a2.15,2.15,0,0,1,0,3L189.8,389.21A2.16,2.16,0,0,1,188.28,389.84ZM133.9,330.27l54.38,54.38,54.38-54.38-54.38-54.38Z" transform="translate(0 0)" />
            <path className="f6bfffb2-0842-4624-9962-ecd809c8e0ea" d="M320.77,435.89H280.86a2.12,2.12,0,0,1-1.52-.63l-66.63-66.62a2.15,2.15,0,0,1,3-3l66,66h39a2.15,2.15,0,0,1,0,4.3Z" transform="translate(0 0)" />
            <path className="f6bfffb2-0842-4624-9962-ecd809c8e0ea" d="M301.27,201.63a2.15,2.15,0,0,1-2.15-2.15V181.67a2.18,2.18,0,0,1,.63-1.52L315,165a2.15,2.15,0,1,1,3,3l-14.57,14.57v16.92a2.15,2.15,0,0,1-2.16,2.14Z" transform="translate(0 0)" />
            <path className="f6bfffb2-0842-4624-9962-ecd809c8e0ea" d="M295.52,279.77a2.14,2.14,0,0,1-1.52-.63l-38.69-38.69a2.15,2.15,0,0,1,3-3L297,276.1a2.15,2.15,0,0,1-1.52,3.67Z" transform="translate(0 0)" />
            <path className="f6bfffb2-0842-4624-9962-ecd809c8e0ea" d="M327.81,261.06H317.62a.67.67,0,0,1-.5-.21l-63.33-63.32a.74.74,0,0,1-.21-.51V174.37L240.9,161.84a.69.69,0,0,1-.21-.51V127.78l-9.47-9.46a.75.75,0,0,1-.21-.51V107.68a.75.75,0,0,1,.21-.51L291.44,47a.69.69,0,0,1,.51-.21H308.6a.69.69,0,0,1,.51.21l24,24a.75.75,0,0,1,.21.51v68a.71.71,0,0,1-.21.5l-39.29,39.28v23.7l34.51,34.51a.71.71,0,0,1,.21.5v22.4A.71.71,0,0,1,327.81,261.06Zm-9.89-1.43h9.18V238.25l-34.51-34.5a.75.75,0,0,1-.21-.51V179a.74.74,0,0,1,.21-.51l39.28-39.28V71.74L308.31,48.18H292.24L232.45,108v9.54l9.46,9.46a.75.75,0,0,1,.21.51V161l12.68,12.53a.71.71,0,0,1,.21.51V196.7Z" transform="translate(0 0)" />
            <path className={"variant" in props ? "path-caption--magent" : ""} d="M273.75,216.85a.71.71,0,0,1-.72-.7l0-.01V182.85a.67.67,0,0,1,.21-.5l33.05-33.06V116.82L284.7,95.23h-8.11l-23,23v20a.74.74,0,0,1-.21.51L241.14,151a.71.71,0,0,1-1-1l12-12V118a.71.71,0,0,1,.21-.5L275.78,94a.71.71,0,0,1,.51-.21H285a.71.71,0,0,1,.51.21l22,22a.71.71,0,0,1,.21.5v33.07a.71.71,0,0,1-.21.5l-33.06,33.06v33a.71.71,0,0,1-.7.72Z" transform="translate(0 0)" />
            <path className="f6bfffb2-0842-4624-9962-ecd809c8e0ea" d="M327.15,245.55a.73.73,0,0,1-.51-.21l-40-40a.75.75,0,0,1-.21-.51v-12.7a.75.75,0,0,1,.21-.51l4.67-4.66a.71.71,0,0,1,1,1l-4.45,4.46v12.13l39.77,39.77a.71.71,0,0,1,0,1A.73.73,0,0,1,327.15,245.55Z" transform="translate(0 0)" />
            <path className="f6bfffb2-0842-4624-9962-ecd809c8e0ea" d="M216.53,87.9a.7.7,0,0,1-.5-.21.71.71,0,0,1,0-1L302.49.21A.75.75,0,0,1,303,0h54.23a.72.72,0,0,1,.72.72h0a.72.72,0,0,1-.72.71H303.3L217,87.69A.71.71,0,0,1,216.53,87.9Z" transform="translate(0 0)" />
            <path className="f6bfffb2-0842-4624-9962-ecd809c8e0ea" d="M284.39,363.38H269.5a.72.72,0,1,1,0-1.43h14.59l58.62-58.62L244,204.59a.67.67,0,0,1-.21-.5v-25L230,165.29a.71.71,0,0,1,1-1l14,14a.72.72,0,0,1,.21.51v25l99,99a.71.71,0,0,1,.21.5.75.75,0,0,1-.21.51l-59.34,59.33A.67.67,0,0,1,284.39,363.38Z" transform="translate(0 0)" />
            <path className="f6bfffb2-0842-4624-9962-ecd809c8e0ea" d="M299.05,366.61a.71.71,0,0,1-.51-.21.72.72,0,0,1,0-1l51.54-51.54a.72.72,0,0,1,1,0,.71.71,0,0,1,0,1L299.55,366.4A.7.7,0,0,1,299.05,366.61Z" transform="translate(0 0)" />
            <path className="f6bfffb2-0842-4624-9962-ecd809c8e0ea" d="M318.62,452.11h-45.9a.71.71,0,0,1-.51-.21l-29.63-29.62a.72.72,0,0,1,1-1L273,450.68h45.6a.72.72,0,1,1,0,1.43Z" transform="translate(0 0)" />
            <path className="f6bfffb2-0842-4624-9962-ecd809c8e0ea" d="M42.45,420.34H21.11a.72.72,0,0,1-.72-.7l-.15-6a.72.72,0,0,1,.2-.52l48.67-49.74a.74.74,0,0,1,.51-.21H94a.71.71,0,0,1,.71.71v14.74a.71.71,0,0,1-.21.51L62.3,411.38a.75.75,0,0,1-.51.21H51.49L43,420.13A.71.71,0,0,1,42.45,420.34ZM21.81,418.9H42.15l8.54-8.54a.74.74,0,0,1,.51-.21H61.49l31.82-31.82V364.61H69.92L21.68,413.92Z" transform="translate(0 0)" />
            <path className="f6bfffb2-0842-4624-9962-ecd809c8e0ea" d="M167.6,414.43H153.67a.75.75,0,0,1-.51-.21L96.72,357.78H69.57L46.39,381v8.46a.72.72,0,0,1-1.44,0v-8.75a.72.72,0,0,1,.21-.51l23.61-23.6a.67.67,0,0,1,.5-.21H97a.71.71,0,0,1,.51.21L154,413h13.3l3.25-3.24a.71.71,0,0,1,1,1l-3.45,3.46A.75.75,0,0,1,167.6,414.43Z" transform="translate(0 0)" />
            <path className="f6bfffb2-0842-4624-9962-ecd809c8e0ea" d="M218.17,487.19a.71.71,0,0,1-.72-.7l0-.01V449.89a.74.74,0,0,1,.21-.51l13.1-13.09v-7.06l-5.39-5.38-12.21,12.2a.71.71,0,0,1-1,0l-26.31-26.31H162.19a.71.71,0,0,1-.51-.21L106,353.81a.72.72,0,0,1,0-1,.71.71,0,0,1,1,0l55.51,55.52h23.66a.71.71,0,0,1,.5.21l26,26,12.2-12.2a.72.72,0,0,1,1,0l6.1,6.1a.74.74,0,0,1,.21.51v7.64a.74.74,0,0,1-.21.51l-13.1,13.09v36.3A.71.71,0,0,1,218.17,487.19Z" transform="translate(0 0)" />
            <path className="f6bfffb2-0842-4624-9962-ecd809c8e0ea" d="M175.52,537.6H142.85a.71.71,0,0,1-.72-.7l0-.01V506.05a.71.71,0,0,1,.21-.51l4.55-4.55V447.41a.74.74,0,0,1,.21-.52H141a.72.72,0,0,1,0-1.43h21.2l17.6-17.6a.71.71,0,0,1,.5-.21h6.3a.71.71,0,0,1,.5.21l23.26,23.26a.71.71,0,0,1,.21.51v36.15a.71.71,0,0,1-.21.51l-28,28v14.47a.68.68,0,0,1-.21.51L176,537.39A.68.68,0,0,1,175.52,537.6Zm-32-1.43h31.67l5.7-5.7V516a.75.75,0,0,1,.21-.51l28-28V451.92l-22.8-22.83h-5.7L163,446.68a.67.67,0,0,1-.5.21H148.11a.74.74,0,0,1,.21.52v53.88a.71.71,0,0,1-.21.51l-4.55,4.55Z" transform="translate(0 0)" />
            <path className="f6bfffb2-0842-4624-9962-ecd809c8e0ea" d="M156,535.55a.72.72,0,0,1-.72-.72v-44a.75.75,0,0,1,.21-.51l32.95-32.94V445.26a.74.74,0,0,1,.21-.51l7.18-7.18a.71.71,0,0,1,1,0,.72.72,0,0,1,0,1l-7,7v12.14a.75.75,0,0,1-.21.51l-32.95,32.95v43.68A.72.72,0,0,1,156,535.55Z" transform="translate(0 0)" />
            <path className="f6bfffb2-0842-4624-9962-ecd809c8e0ea" d="M190.4,508a.71.71,0,0,1-.72-.7l0-.01V479a.75.75,0,0,1,.21-.51l13.3-13.29a.67.67,0,0,1,.5-.21h6.16a.72.72,0,1,1,0,1.43H204L191.12,479.3v27.91a.71.71,0,0,1-.72.79Z" transform="translate(0 0)" />
            <path className="f6bfffb2-0842-4624-9962-ecd809c8e0ea" d="M141,505.23a.71.71,0,0,1-.71-.71l0-.01V446.79a.72.72,0,0,1,1.43,0v57.72A.72.72,0,0,1,141,505.23Z" transform="translate(0 0)" />
        </svg>
    )
}

export default memo(AirCraft);