import React, { useContext, useRef } from 'react';

import AuthContext from '../../Auth/AuthContext';
import Tooltip from "../../Element/Tooltip";
import Dropdown from '../../Element/Dropdown';

import testImage from '../../../static/image/avatars/test.png';

export const UserPreview: React.FC = () => {
    const parentRef = useRef(null);
    const userData = useContext(AuthContext);
    // here assumes user is logged in
    return (
        <div className="user-preview" ref={parentRef}>
            <h3>{userData.username}</h3>
            <img src={testImage} alt={userData.username} />
            <Tooltip
                text="User options"
                target={parentRef}
                offset={60}
            />
            <Dropdown>

            </Dropdown>
        </div>
    );
}

export const UserProfile: React.FC = () => {
    return (
        <div>

        </div>
    )
}