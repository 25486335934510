import React from 'react';

import { HorizontalBars } from '../Rootrilla/RootRilla';

const closeModal = () => {
    const overlay = document.getElementsByClassName("overlay")[0] as HTMLDivElement;
    const modal = overlay.querySelector('.c-modal') as HTMLDivElement;

    modal.style.transform = "scale(0)";

    setTimeout(() => {
        overlay.style.opacity = "0";
        overlay.style.pointerEvents = "none";
    }, 300);
    overlay.click();
};

// toggle modal when overlay is clicked, this is implemented in parent component
export const closeOverlay = (e: MouseEvent): void => {
    e.preventDefault();
    const overlay = e.currentTarget as HTMLDivElement;

    if (e.target === overlay) {
        closeModal();
    }
}

// modal template contentless
export const Modal: React.FC<ModalProps & IntrinsicProps> = (props) => {

    const id = 'id' in props ? props.id : '';

    return (
        <div className="c-modal" id={id}>
            {/* can exit through clicking on this or overlay */}
            <div className="modal-close" onClick={
                "closeModal" in props ? props.closeModal :
                    closeModal
            }>
                &#10006;
            </div>
            {props.children}
        </div>
    )
}

// this is the content of the modal, varies depending on the clicked service
export const ServiceCaption: React.FC<ServiceCaptionProps> = (props) => {
    return (
        <div className="wrapper-service--caption">
            <HorizontalBars
                width="70%"
                alignment="flex-start"
                height="5%"
            />
            <div className="wrapper-text">
                <p className="typo-p">{props.text}</p>
            </div>
            <HorizontalBars
                width="70%"
                alignment="flex-end"
                height="5%"
            />
        </div>
    )
}