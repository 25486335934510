import React, { useMemo } from 'react';
import { capitalize } from '../../utils/functions';
import { Bar } from '../Rootrilla/RootRilla';

import { useTranslation } from 'react-i18next';

export const View: React.FC<ViewProps> = (props) => {
    const { t } = useTranslation();

    // level refers to the overline height respect to column boxes
    return (
        <section
            className="c-view"
            id={"id" in props ? props.id : ''}
            ref={"refProp" in props ? props.refProp : null}
        >
            <ViewHeader langKey={props.langKey!} level={props.level} t={t} />
            {props.children}
        </section>
    );
}

export const ViewHeader: React.FC<ViewHeaderProps> = ({ langKey, level, t }) => {
    const boxHeight: number = 18; // in pixels
    const headerTitle = t(`headers.${langKey}`);
    const style = useMemo(() => { return { marginBottom: `${boxHeight * (level - 1)}px` } }, [level]);

    return (
        <div className="c-header">
            <Bar />
            <div className="header" style={style}>
                <div></div>
                <h3 className="title">{capitalize(headerTitle)}</h3>
            </div>
        </div>
    )
}

export default View;
