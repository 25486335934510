import React, { useRef, useCallback } from 'react'

// button which can be on/off
export const SwitchButton: React.FC<ButtonTogglerProps> = (props) => {
    const toggleRef = useRef(null);
    var className = "c-toggle-switch";

    if ("className" in props) className = `${className} ${props.className}`;

    const toggle = useCallback(() => {
        const btnToggler = toggleRef.current! as HTMLDivElement;

        btnToggler.classList.toggle('active');

        if ('onClick' in props) {
            props.onClick!();
        }
    }, [toggleRef, props]);
    return (
        <div
            className={className}
            id={'id' in props ? props.id : ''}
            onClick={toggle}
        >
            <div className="toggle-indicator" ref={toggleRef}></div>
        </div>
    )
}

export default SwitchButton;