import React, { useRef, useCallback, memo } from 'react';

import { DropdownMenu, DropdownHeader, DropdownItem } from '../../Element/Dropdown';

import Logo from '../../Rootrilla/Logo';

import { ReactComponent as settingsIcon } from '../../../static/image/dashboard/settings.svg';
import { ReactComponent as secutiryIcon } from '../../../static/image/dashboard/security.svg';
import { ReactComponent as preferencesIcon } from '../../../static/image/dashboard/preferences.svg';

import { useTranslation } from 'react-i18next';


let isResizing: null | boolean = null;
let shouldToggleItemsClasses: boolean = true;

const MIN_WIDTH = 60;
const MAX_WIDTH = 180;

const RESIZE_BAR_COLOR = "rgb(3, 81, 165)";

const items: SideMenuItemProps[] = [
    {
        "langKey": "dashboard.sidemenu.settings",
        "Icon": settingsIcon,
        "link": "/dashboard/settings",
        "dropdown": [
            {
                "langKey": "",
                "Icon": preferencesIcon,
                "link": "#"
            }
        ]
    },
    {
        "langKey": "dashboard.sidemenu.settings",
        "Icon": secutiryIcon,
        "link": "/dashboard/settings"
    }
]

export const SideMenu: React.FC = () => {
    const sideMenuRef = useRef(null);
    const resizeBarRef = useRef(null);

    const onMouseMove = useCallback(
        (e: MouseEvent) => {
            if (!isResizing) return;


            // returns x coordinate of mouse in the screen
            let offset = e.pageX;
            // small adjustment
            const fit = 5;

            const sideMenu = sideMenuRef.current! as HTMLElement;

            if (offset < (MIN_WIDTH - fit) || offset > (MAX_WIDTH + fit)) {
                // prevent mouse fron continuing expanding the menu
                onMouseUp(e);

                if (offset < MAX_WIDTH) return;

                sideMenu.querySelectorAll('.sidemenu-item').forEach(item => {
                    const itemLink = item.querySelector('.sidemenu-link') as HTMLElement;
                    if (itemLink.classList.contains('compact')) itemLink.classList.remove('compact');

                    // if dropdown menu has no elements, doesn't show the dropdown indicator
                    const shouldCompressIn = item.querySelectorAll('.dropdown-menu li').length > 1;
                    if (!shouldCompressIn) itemLink.classList.add('no-drop');
                    item.querySelector('.dropdown-menu')!.classList.add('dropdown-in');
                });

                shouldToggleItemsClasses = true;

                return;
            }
            sideMenu.style.width = `${offset}px`;


            if (!shouldToggleItemsClasses) return;

            // here, the sidemenu is either max width or min width
            sideMenu.querySelectorAll('.sidemenu-item').forEach(item => {
                const itemLink = item.querySelector('.sidemenu-link') as HTMLElement;
                if (!itemLink.classList.contains('compact')) itemLink.classList.add('compact');
                // active class is added when the sidemenu reaches its max width, however, at this point,
                // this class should be remove because the sidemenu is reescaling
                if (itemLink.classList.contains('active')) itemLink.classList.remove('active');

                item.querySelector('.dropdown-menu')!.classList.remove('dropdown-in');
            });

            shouldToggleItemsClasses = false;
        },
        [sideMenuRef]
    );

    const onMouseUp = useCallback(
        (e: MouseEvent) => {
            if (!isResizing) return;

            isResizing = false;

            const sideMenuEdge = resizeBarRef.current! as HTMLSpanElement;

            sideMenuEdge.style.backgroundColor = '';
            document.body.style.cursor = "";

            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
        },
        [onMouseMove],
    )

    const onMouseDown = useCallback(
        (e: React.MouseEvent) => {
            e.stopPropagation();
            e.preventDefault();

            const sideMenuEdge = e.currentTarget as HTMLSpanElement;

            sideMenuEdge.style.backgroundColor = RESIZE_BAR_COLOR;
            document.body.style.cursor = "col-resize";

            document.addEventListener('mousemove', onMouseMove);
            document.addEventListener('mouseup', onMouseUp);

            isResizing = true;
        },
        [onMouseMove, onMouseUp],
    )

    return (
        <div
            className="c-sidemenu"
            ref={sideMenuRef}
        >
            <a className="sidemenu-logo" href="/dashboard">
                <Logo id="smRRLogo" />
            </a>
            <div className="top-items">
                {items.map(item => mapItem(item))}
            </div>
            <span className="resize-bar" ref={resizeBarRef} onMouseDown={onMouseDown}></span>
        </div>
    );
}

const mapItem = (item: SideMenuItemProps) => {
    return <SideMenuItem {...item} />
}

const mapDropdownItem = (item: DropdownItemProps) => {
    return <DropdownItem {...item} />
}

const onSideMenuLinkClick = (e: React.MouseEvent) => {
    const targetEl = e.target as HTMLElement;
    console.log("clicked menu link")
    console.log("tag name: ", targetEl.tagName);

    // logic for conditionally clicking doesn't apply when the element is "compacted"
    if (e.currentTarget.classList.contains("compact")) return;

    if (targetEl.tagName === 'SPAN') {
        e.currentTarget.classList.toggle("active");
    }


    // here, if a early return is performed, the 'a' tag does has a normal behaviour
    if (targetEl.tagName === 'H3' || targetEl.tagName === 'svg') return;

    e.preventDefault();
}

export const SideMenuItem: React.FC<SideMenuItemProps> = ({ langKey, Icon, link, dropdown }) => {
    const dropdownRef = useRef(null);
    const { t } = useTranslation();

    const hasManyDropdownItems = dropdown ? dropdown.length >= 1 : false;

    return (
        <div className="sidemenu-item">
            {/* sidemenu starts with its minimum width */}
            <a
                className={"sidemenu-link compact" + (!hasManyDropdownItems ? " no-drop" : "")}
                href={link}
                onClick={onSideMenuLinkClick}
            >
                <Icon />
                <h3>{t(langKey + ".title")}</h3>
                <span className="dropdown-caret"></span>
            </a>
            <DropdownMenu ref={dropdownRef} >
                <DropdownHeader
                    title={t(langKey + ".title")}
                    link={link}
                />
                {dropdown ? dropdown.map((item) => mapDropdownItem(item)) : ''}
            </DropdownMenu>
        </div>
    )
}

export default memo(SideMenu);