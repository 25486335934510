import React, { useEffect, useRef } from 'react';

import { tooltipFadeIn, tooltipFadeOut } from '../../utils/animation';

export const Tooltip: React.FC<TooltipProps> = ({ text, target, offset }) => {
    // target: react ref to element which the tooltip position will be based from
    // offset: margin (in %) of tooltip respect to parent
    const tooltipRef = useRef(null);

    useEffect(() => {
        if (target === undefined) return;

        const parent = target.current! as HTMLElement;
        const tooltip = tooltipRef.current! as HTMLElement;

        const onMouseEnter = () => tooltipFadeIn(tooltip, offset);
        const onMouseLeave = () => tooltipFadeOut(tooltip, offset);

        parent.addEventListener('mouseenter', onMouseEnter);

        parent.addEventListener('mouseleave', onMouseLeave);

        // clean up added listeners
        return () => {
            parent.removeEventListener('mouseenter', onMouseEnter);
            parent.removeEventListener('mouseleave', onMouseLeave);
        }
    }, [tooltipRef, target, offset]);

    return (
        <div
            className="tooltip"
            role="tooltip"
            ref={tooltipRef}
            style={{
                transform: `translate(-50%, ${offset ? (offset + 5) : 110}%)`
            }}
        >
            <p>{text}</p>
        </div>
    );
}

export default Tooltip;