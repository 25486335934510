import React, { memo } from 'react';

export const DiagAircrafts: React.FC<ElementLikeProps> = (props) => {
    return (
        <svg className="svg-aircraft--diag" id={"id" in props ? props.id : ""} data-name="Group 7618" viewBox="0 0 225.3 295.3"
            xmlns="http://www.w3.org/2000/svg">
            <path className="path-caption--magent" d="m68.87 134.5a0.29 0.29 0 0 0 0.5-0.208l-0.2-21.21 10.21-10.22a0.289 0.289 0 0 0 0.085-0.205v-17.08a0.2905 0.2905 0 0 0-0.581 0v16.96l-10.22 10.22a0.292 0.292 0 0 0-0.085 0.209l0.206 21.34a0.288 0.288 0 0 0 0.077 0.202z" data-name="Path 538" />
            <path className="path-caption--magent" d="m77.14 107.3a0.29 0.29 0 0 0 0.411 0l2.806-2.806a0.291 0.291 0 0 0-0.411-0.411l-2.806 2.807a0.29 0.29 0 0 0 0 0.41z" data-name="Path 539" />
            <path className="path-caption--magent" d="m56.03 140.9 12.3 12.3a0.29 0.29 0 0 0 0.411 0l1.452-1.453a0.289 0.289 0 0 0 0.085-0.205v-11.93a0.29 0.29 0 0 0-0.085-0.205l-6.031-6.031a0.289 0.289 0 0 0-0.205-0.085h-9.2a0.291 0.291 0 0 0 0 0.581h9.081l5.861 5.86v11.69l-1.162 1.162-12.1-12.1a0.2906 0.2906 0 1 0-0.411 0.411z" data-name="Path 540" />
            <path className="path-caption--magent" d="m51.86 117.8 2.766 2.766a0.291 0.291 0 0 0 0.411-0.411l-2.681-2.681v-4.453h13.57a0.29 0.29 0 0 0 0.205-0.085l9.69-9.69a0.291 0.291 0 0 0-0.411-0.411l-9.605 9.606h-13.74a0.289 0.289 0 0 0-0.291 0.291v4.864a0.292 0.292 0 0 0 0.086 0.204z" data-name="Path 541" />
            <path className="path-caption--magent" d="m54.42 131.3v-10.98a0.291 0.291 0 0 1 0.29-0.291h10.98a0.3 0.3 0 0 1 0.291 0.291v10.98a0.291 0.291 0 0 1-0.291 0.29h-10.98a0.29 0.29 0 0 1-0.29-0.29zm10.98-10.69h-10.4v10.4h10.4z" data-name="Path 542" />
            <path className="path-caption--magent" d="m37.44 123.2 3.816 3.815a0.289 0.289 0 0 0 0.205 0.085h12.74a0.2905 0.2905 0 1 0 0-0.581h-12.62l-3.73-3.73a0.291 0.291 0 0 0-0.411 0.411z" data-name="Path 543" />
            <path className="path-caption--magent" d="m61.7 102.7a0.29 0.29 0 0 0 0.411 0l1.7-1.7a0.29 0.29 0 0 0 0.085-0.206v-2.905a0.291 0.291 0 0 0-0.581 0v2.785l-1.617 1.617a0.291 0.291 0 0 0 0 0.409z" data-name="Path 544" />
            <path className="path-caption--magent" d="m54.78 110.7a0.289 0.289 0 0 0 0.205 0.085h7.4a0.2905 0.2905 0 1 0 0-0.581h-7.4a0.29 0.29 0 0 0-0.205 0.5z" data-name="Path 545" />
            <path className="path-caption--magent" d="m53.48 105.7 2.141-2.141a0.1 0.1 0 0 1 0.069-0.028h6.6l2.266-2.266v-7.509a0.1 0.1 0 0 1 0.028-0.069l6.5-6.5a0.1 0.1 0 0 1 0.068-0.028h4.586a0.094 0.094 0 0 1 0.068 0.028l1.592 1.592a0.1 0.1 0 0 1 0.028 0.068v11.51a0.1 0.1 0 0 1-0.028 0.068l-0.969 0.969a0.1 0.1 0 0 1-0.068 0.028h-1.808l-3.207 3.207a0.1 0.1 0 0 1-0.069 0.029l-2.41-0.015-2.165 2.165a0.1 0.1 0 0 1-0.068 0.028h-12.11a0.1 0.1 0 0 1-0.069-0.028l-0.974-0.974a0.1 0.1 0 0 1-0.01-0.136zm13.11 0.946 2.165-2.165a0.1 0.1 0 0 1 0.069-0.028l2.41 0.015 3.207-3.207a0.1 0.1 0 0 1 0.069-0.029h1.808l0.912-0.912v-11.43l-1.535-1.535h-4.506l-6.444 6.444v7.509a0.1 0.1 0 0 1-0.028 0.069l-2.323 2.322a0.1 0.1 0 0 1-0.068 0.028h-6.6l-2.044 2.044 0.877 0.877z" data-name="Path 546" />
            <path className="path-caption--magent" d="m62.87 106.8a0.1 0.1 0 0 0 0.137 0l3.182-3.181a0.1 0.1 0 0 0 0.028-0.069v-6.319l3.1-3.1h4.128l0.776 0.776v4.4l-1.916 1.916a0.1 0.1 0 0 0-0.028 0.069v2.333a0.1 0.1 0 0 0 0.194 0v-2.293l1.916-1.916a0.1 0.1 0 0 0 0.028-0.068v-4.483a0.1 0.1 0 0 0-0.028-0.069l-0.833-0.832a0.1 0.1 0 0 0-0.069-0.028h-4.208a0.1 0.1 0 0 0-0.069 0.028l-3.16 3.161a0.1 0.1 0 0 0-0.028 0.068v6.319l-3.153 3.153a0.1 0.1 0 0 0 0 0.135z" data-name="Path 547" />
            <path className="path-caption--magent" d="m55.02 104.4a0.1 0.1 0 0 0 0.069 0.028h7.643a0.094 0.094 0 0 0 0.068-0.028l1.217-1.216a0.1 0.1 0 0 0 0.028-0.068v-0.891a0.1 0.1 0 0 0-0.194 0v0.851l-1.159 1.16h-7.6a0.1 0.1 0 0 0-0.069 0.165z" data-name="Path 548" />
            <path className="path-caption--magent" d="m80.67 99.9a0.1 0.1 0 0 0 0.166-0.068v-16.53a0.1 0.1 0 0 0-0.029-0.068l-5.184-5.184a0.1 0.1 0 0 0-0.137 0.137l5.155 5.155v16.49a0.1 0.1 0 0 0 0.029 0.068z" data-name="Path 549" />
            <path className="path-caption--magent" d="m47.85 119.7 1.423 1.423a0.1 0.1 0 0 0 0.137 0 0.1 0.1 0 0 0 0-0.137l-1.4-1.395v-11.21h18.88a0.1 0.1 0 0 0 0.069-0.028l2.393-2.393h2.63a0.1 0.1 0 1 0 0-0.193h-2.671a0.1 0.1 0 0 0-0.069 0.028l-2.393 2.393h-18.93a0.1 0.1 0 0 0-0.1 0.1v11.34a0.1 0.1 0 0 0 0.037 0.065z" data-name="Path 550" />
            <path className="path-caption--magent" d="m46.14 118.7a0.1 0.1 0 0 0 0.069 0.028 0.1 0.1 0 0 0 0.1-0.1v-9.854a0.1 0.1 0 0 0-0.194 0v9.854a0.1 0.1 0 0 0 0.025 0.072z" data-name="Path 551" />
            <path className="path-caption--magent" d="m36.09 125 4.388 4.387a0.1 0.1 0 0 0 0.068 0.028h5.664a0.1 0.1 0 1 0 0-0.193h-5.624l-4.359-4.359a0.1 0.1 0 0 0-0.137 0.137z" data-name="Path 552" />
            <path className="path-caption--magent" d="m65.5 148.2v-1.632l-0.984-0.984a0.1 0.1 0 0 1-0.028-0.069v-6.162a0.094 0.094 0 0 1 0.028-0.069l1.408-1.409a0.1 0.1 0 0 1 0.137 0l2.333 2.333a0.1 0.1 0 0 1 0.028 0.07l-0.1 9.406a0.1 0.1 0 0 1-0.03 0.069l-0.587 0.558a0.1 0.1 0 0 1-0.135 0l-2.04-2.04a0.1 0.1 0 0 1-0.03-0.071zm2.627 1.44 0.1-9.324-2.236-2.236-1.312 1.312v6.083l0.984 0.984a0.1 0.1 0 0 1 0.028 0.069v1.632l1.945 1.945z" data-name="Path 553" />
            <path className="path-caption--magent" d="m54.13 135.8 1.331 1.332a0.1 0.1 0 0 0 0.069 0.028h10.79l2.6 2.6v4.432l-0.808 0.808a0.1 0.1 0 0 0 0.136 0.137l0.837-0.837a0.1 0.1 0 0 0 0.028-0.069v-4.512a0.1 0.1 0 0 0-0.028-0.069l-2.653-2.653a0.1 0.1 0 0 0-0.068-0.028h-10.79l-1.275-1.275v-0.62a0.1 0.1 0 1 0-0.193 0v0.66a0.1 0.1 0 0 0 0.024 0.066z" data-name="Path 554" />
            <path className="path-caption--magent" d="m42.34 137.9a0.1 0.1 0 0 0 0.137 0l3.5-3.5a0.1 0.1 0 0 0 0.029-0.068v-2.5l0.674-0.674h1.029v2.333a0.1 0.1 0 0 0 0.1 0.1h5.03l2.261 2.261a0.1 0.1 0 0 0 0.068 0.028h10.65a0.1 0.1 0 1 0 0-0.193h-10.62l-2.261-2.261a0.1 0.1 0 0 0-0.069-0.028h-4.973v-2.334a0.1 0.1 0 0 0-0.1-0.1h-1.166a0.1 0.1 0 0 0-0.068 0.028l-0.731 0.731a0.1 0.1 0 0 0-0.028 0.069v2.5l-3.469 3.469a0.1 0.1 0 0 0 0 0.139z" data-name="Path 555" />
            <path className="path-caption--magent" d="m41.57 146.7v-1.17a0.1 0.1 0 0 1 0.028-0.069l1.384-1.384v-5.352a0.1 0.1 0 0 1 0.028-0.069l3.456-3.456a0.1 0.1 0 0 1 0.068-0.029h4.446a0.1 0.1 0 0 1 0.069 0.029l0.6 0.6a0.1 0.1 0 0 1 0.028 0.068v3.365l2.026 2.026a0.1 0.1 0 0 1-0.137 0.136l-0.583-0.583a0.1 0.1 0 0 1-0.029 0.07l-5.122 5.122v0.87a0.1 0.1 0 0 1-0.028 0.069l-2.948 2.947a0.1 0.1 0 0 1-0.136 0l-3.124-3.124a0.1 0.1 0 0 1-0.026-0.066zm6.071 0.136v-0.87a0.1 0.1 0 0 1 0.028-0.069l5.151-5.151a0.1 0.1 0 0 1 0.069-0.028l-1.375-1.376a0.1 0.1 0 0 1-0.028-0.068v-3.365l-0.545-0.545h-4.366l-3.4 3.4v5.352a0.1 0.1 0 0 1-0.029 0.068l-1.384 1.384v1.09l3.027 3.027z" data-name="Path 556" />
            <path className="path-caption--magent" d="m43.66 148.5a0.1 0.1 0 0 0 0.137 0l4.2-4.2a0.1 0.1 0 0 0 0.028-0.068v-6.3l1.16-1.16a0.1 0.1 0 0 0 0.028-0.069v-1.372a0.1 0.1 0 1 0-0.194 0v1.332l-1.16 1.16a0.1 0.1 0 0 0-0.029 0.069v6.3l-4.176 4.176a0.1 0.1 0 0 0 0.01 0.132z" data-name="Path 557" />
            <path className="path-caption--magent" d="m43.01 142.5a0.1 0.1 0 0 0 0.137 0l2.7-2.7a0.1 0.1 0 0 0 0.028-0.069v-2.542a0.1 0.1 0 0 0-0.028-0.069l-0.589-0.589a0.1 0.1 0 0 0-0.137 0.137l0.561 0.56v2.462l-2.668 2.668a0.1 0.1 0 0 0 0 0.142z" data-name="Path 558" />
            <path className="path-caption--magent" d="m47.99 147a0.1 0.1 0 0 0 0.137 0l5.517-5.517a0.1 0.1 0 0 0-0.136-0.137l-5.518 5.518a0.1 0.1 0 0 0 0 0.136z" data-name="Path 559" />
            <path className="path-caption--magent" d="m18.76 84.43a0.291 0.291 0 0 1 0.208-0.5l21.21 0.2 10.22-10.22a0.289 0.289 0 0 1 0.205-0.085h17.08a0.2905 0.2905 0 1 1 0 0.581h-16.96l-10.22 10.22a0.293 0.293 0 0 1-0.208 0.085l-21.34-0.205a0.294 0.294 0 0 1-0.203-0.077z" data-name="Path 560" />
            <path className="path-caption--magent" d="m45.96 76.16a0.291 0.291 0 0 1 0-0.411l2.807-2.806a0.2906 0.2906 0 0 1 0.411 0.411l-2.806 2.806a0.291 0.291 0 0 1-0.412 0z" data-name="Path 561" />
            <path className="path-caption--magent" d="m12.39 97.27-12.3-12.3a0.291 0.291 0 0 1 0-0.411l1.453-1.453a0.291 0.291 0 0 1 0.206-0.085h11.93a0.289 0.289 0 0 1 0.205 0.085l6.031 6.031a0.292 0.292 0 0 1 0.085 0.206v9.2a0.2905 0.2905 0 1 1-0.581 0v-9.08l-5.861-5.861h-11.69l-1.162 1.162 12.1 12.1a0.2906 0.2906 0 0 1-0.411 0.411z" data-name="Path 562" />
            <path className="path-caption--magent" d="m35.47 101.4-2.767-2.766a0.291 0.291 0 0 1 0.411-0.411l2.681 2.68h4.453v-13.56a0.292 0.292 0 0 1 0.085-0.206l9.69-9.69a0.291 0.291 0 0 1 0.411 0.411l-9.6 9.606v13.74a0.291 0.291 0 0 1-0.291 0.29h-4.865a0.289 0.289 0 0 1-0.208-0.085z" data-name="Path 563" />
            <path className="path-caption--magent" d="m21.84 98.8a0.292 0.292 0 0 1-0.085-0.205v-10.98a0.29 0.29 0 0 1 0.29-0.291h10.98a0.3 0.3 0 0 1 0.291 0.291v10.98a0.291 0.291 0 0 1-0.291 0.29h-10.98a0.291 0.291 0 0 1-0.205-0.085zm0.5-10.89v10.4h10.4v-10.4z" data-name="Path 564" />
            <path className="path-caption--magent" d="m30.1 115.9-3.815-3.815a0.289 0.289 0 0 1-0.085-0.206v-12.74a0.291 0.291 0 0 1 0.581 0v12.62l3.73 3.73a0.291 0.291 0 0 1-0.411 0.411z" data-name="Path 565" />
            <path className="path-caption--magent" d="m50.63 91.6a0.291 0.291 0 0 1 0-0.411l1.7-1.7a0.291 0.291 0 0 1 0.206-0.085h2.905a0.291 0.291 0 1 1 0 0.581h-2.785l-1.617 1.617a0.29 0.29 0 0 1-0.409 0z" data-name="Path 566" />
            <path className="path-caption--magent" d="m42.61 98.52a0.29 0.29 0 0 1-0.085-0.206v-7.4a0.2905 0.2905 0 0 1 0.581 0v7.4a0.29 0.29 0 0 1-0.5 0.206z" data-name="Path 567" />
            <path className="path-caption--magent" d="m47.49 99.82-0.974-0.974a0.1 0.1 0 0 1-0.028-0.068v-12.11a0.1 0.1 0 0 1 0.028-0.069l2.165-2.165-0.014-2.41a0.1 0.1 0 0 1 0.028-0.069l3.207-3.207v-1.809a0.1 0.1 0 0 1 0.028-0.068l0.968-0.968a0.1 0.1 0 0 1 0.068-0.029h11.51a0.1 0.1 0 0 1 0.068 0.029l1.592 1.592a0.1 0.1 0 0 1 0.028 0.068v4.585a0.1 0.1 0 0 1-0.028 0.068l-6.5 6.5a0.1 0.1 0 0 1-0.069 0.028h-7.51l-2.265 2.266v6.6a0.1 0.1 0 0 1-0.028 0.068l-2.141 2.14a0.1 0.1 0 0 1-0.135 0zm-0.809-1.083 0.877 0.878 2.043-2.044v-6.6a0.1 0.1 0 0 1 0.028-0.068l2.323-2.322a0.1 0.1 0 0 1 0.069-0.028h7.509l6.444-6.444v-4.505l-1.536-1.535h-11.43l-0.911 0.912v1.809a0.1 0.1 0 0 1-0.028 0.069l-3.207 3.207 0.014 2.41a0.1 0.1 0 0 1-0.028 0.069l-2.165 2.165z" data-name="Path 568" />
            <path className="path-caption--magent" d="m46.54 90.43a0.1 0.1 0 0 1 0-0.137l3.182-3.182a0.1 0.1 0 0 1 0.068-0.028h6.319l3.1-3.1v-4.128l-0.776-0.776h-4.4l-1.916 1.916a0.1 0.1 0 0 1-0.068 0.028h-2.333a0.1 0.1 0 1 1 0-0.193h2.293l1.916-1.916a0.1 0.1 0 0 1 0.069-0.028h4.483a0.1 0.1 0 0 1 0.068 0.028l0.833 0.833a0.1 0.1 0 0 1 0.028 0.068v4.208a0.1 0.1 0 0 1-0.028 0.069l-3.161 3.16a0.1 0.1 0 0 1-0.068 0.028h-6.322l-3.153 3.153a0.1 0.1 0 0 1-0.134 0z" data-name="Path 569" />
            <path className="path-caption--magent" d="m48.9 98.28a0.1 0.1 0 0 1-0.028-0.069v-7.643a0.1 0.1 0 0 1 0.028-0.069l1.216-1.216a0.1 0.1 0 0 1 0.068-0.028h0.892a0.1 0.1 0 0 1 0 0.193h-0.852l-1.159 1.16v7.6a0.1 0.1 0 0 1-0.165 0.069z" data-name="Path 570" />
            <path className="path-caption--magent" d="m53.4 72.63a0.1 0.1 0 0 1-0.028-0.069 0.1 0.1 0 0 1 0.1-0.1h16.53a0.1 0.1 0 0 1 0.069 0.028l5.184 5.184a0.1 0.1 0 0 1-0.137 0.136l-5.155-5.155h-16.49a0.1 0.1 0 0 1-0.073-0.024z" data-name="Path 571" />
            <path className="path-caption--magent" d="m33.56 105.5-1.423-1.423a0.1 0.1 0 1 1 0.137-0.137l1.4 1.395h11.2v-18.88a0.1 0.1 0 0 1 0.028-0.068l2.393-2.393v-2.632a0.1 0.1 0 0 1 0.194 0v2.67a0.1 0.1 0 0 1-0.029 0.068l-2.393 2.393v18.93a0.1 0.1 0 0 1-0.028 0.069 0.1 0.1 0 0 1-0.069 0.028h-11.34a0.1 0.1 0 0 1-0.068-0.027z" data-name="Path 572" />
            <path className="path-caption--magent" d="m34.65 107.2a0.1 0.1 0 0 1-0.028-0.068 0.1 0.1 0 0 1 0.1-0.1h9.853a0.1 0.1 0 1 1 0 0.193h-9.853a0.1 0.1 0 0 1-0.072-0.025z" data-name="Path 573" />
            <path className="path-caption--magent" d="m28.34 117.2-4.387-4.387a0.1 0.1 0 0 1-0.028-0.069v-5.664a0.1 0.1 0 1 1 0.193 0v5.624l4.36 4.359a0.1 0.1 0 0 1 0 0.137 0.1 0.1 0 0 1-0.138 0z" data-name="Path 574" />
            <path className="path-caption--magent" d="m4.983 87.77-2.039-2.04a0.1 0.1 0 0 1 0-0.135l0.557-0.587a0.1 0.1 0 0 1 0.069-0.03l9.406-0.1a0.1 0.1 0 0 1 0.07 0.028l2.333 2.333a0.1 0.1 0 0 1 0 0.137l-1.409 1.409a0.1 0.1 0 0 1-0.069 0.028h-6.165a0.1 0.1 0 0 1-0.069-0.028l-0.984-0.984h-1.633a0.1 0.1 0 0 1-0.067-0.031zm-1.836-2.11 1.944 1.945h1.633a0.1 0.1 0 0 1 0.069 0.028l0.984 0.984h6.083l1.312-1.312-2.235-2.235-9.325 0.1z" data-name="Path 575" />
            <path className="path-caption--magent" d="m17.51 99.17-1.331-1.332a0.1 0.1 0 0 1-0.028-0.068v-10.79l-2.6-2.6h-4.432l-0.808 0.808a0.1 0.1 0 0 1-0.137 0 0.1 0.1 0 0 1 0-0.137l0.836-0.836a0.1 0.1 0 0 1 0.069-0.028h4.512a0.1 0.1 0 0 1 0.068 0.028l2.652 2.652a0.1 0.1 0 0 1 0.029 0.069v10.79l1.275 1.275h0.62a0.1 0.1 0 1 1 0 0.193h-0.66a0.1 0.1 0 0 1-0.065-0.024z" data-name="Path 576" />
            <path className="path-caption--magent" d="m15.39 111a0.1 0.1 0 0 1 0-0.137l3.5-3.5a0.1 0.1 0 0 1 0.068-0.028h2.5l0.675-0.674v-1.03h-2.334a0.1 0.1 0 0 1-0.1-0.1v-5.03l-2.26-2.261a0.1 0.1 0 0 1-0.028-0.069v-10.65a0.1 0.1 0 1 1 0.194 0v10.61l2.261 2.261a0.1 0.1 0 0 1 0.028 0.068v4.974h2.334a0.1 0.1 0 0 1 0.1 0.1v1.167a0.1 0.1 0 0 1-0.028 0.068l-0.731 0.731a0.1 0.1 0 0 1-0.069 0.028h-2.5l-3.469 3.469a0.1 0.1 0 0 1-0.141 0z" data-name="Path 577" />
            <path className="path-caption--magent" d="m6.497 111.7-3.124-3.124a0.1 0.1 0 0 1 0-0.137l2.948-2.948a0.1 0.1 0 0 1 0.068-0.028h0.87l5.122-5.122a0.1 0.1 0 0 1 0.07-0.028l-0.583-0.583a0.1 0.1 0 0 1 0.137-0.137l2.026 2.026h3.364a0.1 0.1 0 0 1 0.069 0.029l0.6 0.6a0.1 0.1 0 0 1 0.028 0.069v4.446a0.1 0.1 0 0 1-0.028 0.069l-3.455 3.456a0.1 0.1 0 0 1-0.069 0.028h-5.352l-1.384 1.384a0.1 0.1 0 0 1-0.069 0.029h-1.17a0.1 0.1 0 0 1-0.068-0.029zm-2.918-3.192 3.027 3.027h1.09l1.384-1.384a0.1 0.1 0 0 1 0.069-0.028h5.352l3.4-3.4v-4.364l-0.545-0.545h-3.364a0.1 0.1 0 0 1-0.068-0.029l-1.375-1.375a0.1 0.1 0 0 1-0.028 0.07l-5.151 5.151a0.1 0.1 0 0 1-0.068 0.028h-0.87z" data-name="Path 578" />
            <path className="path-caption--magent" d="m4.823 109.6a0.1 0.1 0 0 1 0-0.137l4.2-4.2a0.1 0.1 0 0 1 0.069-0.028h6.3l1.16-1.16a0.094 0.094 0 0 1 0.068-0.028h1.372a0.1 0.1 0 0 1 0 0.193h-1.332l-1.161 1.16a0.094 0.094 0 0 1-0.068 0.028h-6.3l-4.176 4.176a0.1 0.1 0 0 1-0.132 0z" data-name="Path 579" />
            <path className="path-caption--magent" d="m10.75 110.3a0.1 0.1 0 0 1 0-0.137l2.7-2.7a0.1 0.1 0 0 1 0.068-0.029h2.542a0.1 0.1 0 0 1 0.068 0.029l0.589 0.589a0.1 0.1 0 0 1-0.137 0.137l-0.561-0.56h-2.462l-2.668 2.668a0.1 0.1 0 0 1-0.139 0z" data-name="Path 580" />
            <path className="path-caption--magent" d="m6.29 105.3a0.1 0.1 0 0 1 0-0.137l5.517-5.518a0.1 0.1 0 0 1 0.136 0.137l-5.517 5.517a0.1 0.1 0 0 1-0.136 1e-3z" data-name="Path 581" />
            <path className="path-caption--magent" d="m84.44 88.18a0.287 0.287 0 0 0-0.208-0.085 0.29 0.29 0 0 0-0.284 0.293l0.2 21.21-10.22 10.22a0.288 0.288 0 0 0-0.085 0.205v17.08a0.2905 0.2905 0 1 0 0.581 0v-16.96l10.22-10.22a0.292 0.292 0 0 0 0.085-0.208l-0.208-21.34a0.289 0.289 0 0 0-0.085-0.203z" data-name="Path 626" />
            <path className="path-caption--magent" d="m76.17 115.4a0.29 0.29 0 0 0-0.411 0l-2.804 2.807a0.291 0.291 0 0 0 0.415 0.408l2.8-2.8a0.291 0.291 0 0 0 0-0.415z" data-name="Path 627" />
            <path className="path-caption--magent" d="m97.28 81.81-12.3-12.3a0.29 0.29 0 0 0-0.411 0l-1.453 1.453a0.291 0.291 0 0 0-0.085 0.205v11.93a0.29 0.29 0 0 0 0.084 0.2l6.032 6.036a0.291 0.291 0 0 0 0.206 0.09h9.2a0.291 0.291 0 0 0 0-0.581h-9.08l-5.86-5.866v-11.69l1.161-1.164 12.1 12.1a0.291 0.291 0 0 0 0.411-0.411z" data-name="Path 628" />
            <path className="path-caption--magent" d="m101.4 104.9-2.766-2.766a0.2917 0.2917 0 1 0-0.41 0.415l2.68 2.681v4.449h-13.56a0.292 0.292 0 0 0-0.206 0.085l-9.69 9.69a0.2906 0.2906 0 0 0 0.411 0.411l9.604-9.604h13.74a0.29 0.29 0 0 0 0.291-0.291v-4.864a0.292 0.292 0 0 0-0.086-0.206z" data-name="Path 629" />
            <path className="path-caption--magent" d="m98.89 91.47v10.98a0.291 0.291 0 0 1-0.29 0.29h-10.98a0.3 0.3 0 0 1-0.291-0.29v-10.98a0.291 0.291 0 0 1 0.291-0.29h10.98a0.291 0.291 0 0 1 0.29 0.29zm-10.98 10.69h10.4v-10.4h-10.4z" data-name="Path 630" />
            <path className="path-caption--magent" d="m115.9 99.52-3.815-3.815a0.29 0.29 0 0 0-0.205-0.085h-12.74a0.2905 0.2905 0 1 0 0 0.581h12.62l3.73 3.73a0.291 0.291 0 0 0 0.411-0.411z" data-name="Path 631" />
            <path className="path-caption--magent" d="m91.62 120.1a0.29 0.29 0 0 0-0.411 0l-1.7 1.7a0.29 0.29 0 0 0-0.089 0.208v2.907a0.291 0.291 0 0 0 0.581 0v-2.787l1.619-1.613a0.29 0.29 0 0 0 0-0.415z" data-name="Path 632" />
            <path className="path-caption--magent" d="m98.53 112a0.292 0.292 0 0 0-0.206-0.085h-7.396a0.2905 0.2905 0 0 0 0 0.581h7.4a0.291 0.291 0 0 0 0.206-0.5z" data-name="Path 633" />
            <path className="path-caption--magent" d="m99.83 117-2.143 2.14a0.1 0.1 0 0 1-0.068 0.028h-6.6l-2.26 2.266v7.509a0.1 0.1 0 0 1-0.028 0.069l-6.5 6.5a0.1 0.1 0 0 1-0.069 0.028h-4.586a0.1 0.1 0 0 1-0.069-0.029l-1.592-1.59a0.1 0.1 0 0 1-0.028-0.069v-11.51a0.1 0.1 0 0 1 0.028-0.069l0.972-0.968a0.1 0.1 0 0 1 0.068-0.029h1.807l3.207-3.206a0.1 0.1 0 0 1 0.069-0.028l2.41 0.015 2.165-2.167a0.1 0.1 0 0 1 0.068-0.028h12.11a0.1 0.1 0 0 1 0.069 0.028l0.974 0.972a0.1 0.1 0 0 1 0 0.14zm-13.11-0.946-2.165 2.165a0.1 0.1 0 0 1-0.069 0.028l-2.41-0.014-3.207 3.207a0.1 0.1 0 0 1-0.069 0.028h-1.809l-0.911 0.912v11.43l1.535 1.535h4.505l6.444-6.444v-7.509a0.1 0.1 0 0 1 0.028-0.069l2.322-2.322a0.1 0.1 0 0 1 0.068-0.028h6.6l2.044-2.044-0.88-0.877z" data-name="Path 634" />
            <path className="path-caption--magent" d="m90.44 116a0.1 0.1 0 0 0-0.137 0l-3.182 3.182a0.1 0.1 0 0 0-0.028 0.068v6.322l-3.101 3.1h-4.131l-0.776-0.776v-4.4l1.917-1.917a0.1 0.1 0 0 0 0.028-0.068v-2.334a0.1 0.1 0 1 0-0.194 0v2.295l-1.916 1.914a0.1 0.1 0 0 0-0.028 0.068v4.483a0.1 0.1 0 0 0 0.028 0.069l0.833 0.832a0.094 0.094 0 0 0 0.068 0.028h4.208a0.1 0.1 0 0 0 0.069-0.028l3.16-3.16a0.1 0.1 0 0 0 0.028-0.069v-6.319l3.154-3.153a0.1 0.1 0 0 0 0-0.137z" data-name="Path 635" />
            <path className="path-caption--magent" d="m98.29 118.3a0.1 0.1 0 0 0-0.069-0.029h-7.643a0.1 0.1 0 0 0-0.069 0.029l-1.216 1.216a0.1 0.1 0 0 0-0.028 0.068v0.887a0.1 0.1 0 1 0 0.193 0v-0.847l1.16-1.159h7.6a0.1 0.1 0 0 0 0.1-0.1 0.1 0.1 0 0 0-0.028-0.065z" data-name="Path 636" />
            <path className="path-caption--magent" d="m72.64 122.8a0.1 0.1 0 0 0-0.165 0.072v16.53a0.1 0.1 0 0 0 0.028 0.068l5.184 5.184a0.1 0.1 0 1 0 0.137-0.137l-5.156-5.158v-16.49a0.1 0.1 0 0 0-0.028-0.072z" data-name="Path 637" />
            <path className="path-caption--magent" d="m105.5 103-1.423-1.424a0.1 0.1 0 0 0-0.137 0.137l1.395 1.4v11.2h-18.88a0.1 0.1 0 0 0-0.069 0.028l-2.392 2.393h-2.628a0.1 0.1 0 1 0 0 0.193h2.668a0.1 0.1 0 0 0 0.068-0.028l2.393-2.393h18.93a0.1 0.1 0 0 0 0.1-0.1v-11.34a0.1 0.1 0 0 0-0.031-0.068z" data-name="Path 638" />
            <path className="path-caption--magent" d="m107.2 104.1a0.1 0.1 0 0 0-0.065-0.028 0.1 0.1 0 0 0-0.1 0.1v9.85a0.1 0.1 0 1 0 0.193 0v-9.85a0.1 0.1 0 0 0-0.028-0.072z" data-name="Path 639" />
            <path className="path-caption--magent" d="m117.2 97.77-4.387-4.387a0.1 0.1 0 0 0-0.069-0.028h-5.661a0.1 0.1 0 1 0 0 0.193h5.621l4.359 4.359a0.1 0.1 0 1 0 0.137-0.137z" data-name="Path 640" />
            <path className="path-caption--magent" d="m87.81 74.48v1.632l0.984 0.985a0.1 0.1 0 0 1 0.028 0.068v6.162a0.1 0.1 0 0 1-0.028 0.069l-1.407 1.409a0.1 0.1 0 0 1-0.137 0l-2.335-2.333a0.1 0.1 0 0 1-0.028-0.07l0.1-9.406a0.1 0.1 0 0 1 0.033-0.069l0.587-0.557a0.1 0.1 0 0 1 0.135 0l2.045 2.041a0.1 0.1 0 0 1 0.023 0.069zm-2.623-1.437-0.1 9.324 2.23 2.233 1.312-1.312v-6.083l-0.984-0.984a0.1 0.1 0 0 1-0.028-0.068v-1.633l-1.945-1.945z" data-name="Path 641" />
            <path className="path-caption--magent" d="m99.18 86.93-1.332-1.331a0.1 0.1 0 0 0-0.069-0.03h-10.79l-2.6-2.6v-4.426l0.811-0.809a0.1 0.1 0 0 0-0.135-0.137l-0.837 0.837a0.1 0.1 0 0 0-0.028 0.069v4.512a0.1 0.1 0 0 0 0.028 0.069l2.652 2.652a0.1 0.1 0 0 0 0.069 0.028h10.79l1.275 1.275v0.62a0.1 0.1 0 0 0 0.194 0v-0.66a0.1 0.1 0 0 0-0.028-0.069z" data-name="Path 642" />
            <path className="path-caption--magent" d="m111 84.81a0.1 0.1 0 0 0-0.137 0l-3.5 3.5a0.1 0.1 0 0 0-0.029 0.069v2.503l-0.674 0.674h-1.03v-2.336a0.1 0.1 0 0 0-0.1-0.1h-5.03l-2.26-2.26a0.1 0.1 0 0 0-0.068-0.028h-10.64a0.1 0.1 0 1 0 0 0.193h10.61l2.261 2.261a0.094 0.094 0 0 0 0.068 0.029h4.973v2.335a0.1 0.1 0 0 0 0.1 0.1h1.167a0.1 0.1 0 0 0 0.069-0.028l0.731-0.731a0.1 0.1 0 0 0 0.028-0.069v-2.5l3.466-3.474a0.1 0.1 0 0 0 0-0.138z" data-name="Path 643" />
            <path className="path-caption--magent" d="m111.7 75.99v1.17a0.1 0.1 0 0 1-0.028 0.069l-1.384 1.383v5.357a0.1 0.1 0 0 1-0.029 0.069l-3.456 3.451a0.1 0.1 0 0 1-0.068 0.028h-4.446a0.1 0.1 0 0 1-0.068-0.028l-0.6-0.6a0.1 0.1 0 0 1-0.028-0.069v-3.365l-2.029-2.026a0.1 0.1 0 1 1 0.136-0.136l0.583 0.583a0.1 0.1 0 0 1 0.028-0.07l5.125-5.123v-0.87a0.1 0.1 0 0 1 0.029-0.069l2.946-2.947a0.1 0.1 0 0 1 0.137 0l3.124 3.124a0.1 0.1 0 0 1 0.028 0.069zm-6.071-0.136v0.87a0.1 0.1 0 0 1-0.028 0.069l-5.152 5.15a0.1 0.1 0 0 1-0.069 0.026l1.375 1.376a0.094 0.094 0 0 1 0.028 0.068v3.366l0.545 0.545h4.367l3.4-3.4v-5.355a0.094 0.094 0 0 1 0.028-0.068l1.383-1.381v-1.09l-3.027-3.027z" data-name="Path 644" />
            <path className="path-caption--magent" d="m109.6 74.24a0.1 0.1 0 0 0-0.136 0l-4.2 4.2a0.1 0.1 0 0 0-0.029 0.069v6.3l-1.165 1.163a0.1 0.1 0 0 0-0.028 0.069v1.371a0.1 0.1 0 1 0 0.193 0v-1.331l1.16-1.16a0.1 0.1 0 0 0 0.028-0.069v-6.3l4.177-4.175a0.1 0.1 0 0 0 0-0.137z" data-name="Path 645" />
            <path className="path-caption--magent" d="m110.3 80.17a0.1 0.1 0 0 0-0.137 0l-2.7 2.7a0.1 0.1 0 0 0-0.025 0.066v2.542a0.1 0.1 0 0 0 0.028 0.064l0.589 0.589a0.1 0.1 0 0 0 0.136-0.137l-0.56-0.552v-2.466l2.669-2.668a0.1 0.1 0 0 0 0-0.138z" data-name="Path 646" />
            <path className="path-caption--magent" d="m105.3 75.71a0.1 0.1 0 0 0-0.137 0l-5.518 5.518a0.1 0.1 0 0 0 0.137 0.137l5.518-5.518a0.1 0.1 0 0 0 0-0.137z" data-name="Path 647" />
            <path className="path-caption--magent" d="m134.6 138.3a0.29 0.29 0 0 1-0.208 0.5l-21.22-0.208-10.22 10.22a0.289 0.289 0 0 1-0.205 0.085h-17.08a0.2905 0.2905 0 1 1 0-0.581h16.96l10.22-10.22a0.293 0.293 0 0 1 0.208-0.085l21.34 0.205a0.292 0.292 0 0 1 0.203 0.085z" data-name="Path 648" />
            <path className="path-caption--magent" d="m107.3 146.6a0.29 0.29 0 0 1 0 0.415l-2.8 2.8a0.291 0.291 0 0 1-0.411-0.411l2.802-2.804a0.291 0.291 0 0 1 0.409 0z" data-name="Path 649" />
            <path className="path-caption--magent" d="m140.9 125.5 12.3 12.3a0.29 0.29 0 0 1 0 0.411l-1.453 1.453a0.29 0.29 0 0 1-0.205 0.085h-11.93a0.29 0.29 0 0 1-0.206-0.085l-6.031-6.024a0.29 0.29 0 0 1-0.085-0.209v-9.201a0.291 0.291 0 0 1 0.582 0v9.08l5.86 5.861h11.69l1.162-1.162-12.09-12.1a0.291 0.291 0 0 1 0.411-0.411z" data-name="Path 650" />
            <path className="path-caption--magent" d="m117.8 121.3 2.766 2.766a0.2903 0.2903 0 0 1-0.411 0.41l-2.681-2.679h-4.454v13.56a0.29 0.29 0 0 1-0.085 0.206l-9.686 9.691a0.291 0.291 0 0 1-0.411-0.411l9.606-9.606v-13.74a0.29 0.29 0 0 1 0.286-0.291h4.864a0.291 0.291 0 0 1 0.206 0.085z" data-name="Path 651" />
            <path className="path-caption--magent" d="m131.5 123.9a0.29 0.29 0 0 1 0.085 0.205v10.98a0.291 0.291 0 0 1-0.29 0.29h-10.98a0.3 0.3 0 0 1-0.29-0.29v-10.98a0.291 0.291 0 0 1 0.29-0.291h10.98a0.289 0.289 0 0 1 0.205 0.085zm-0.5 10.89v-10.4h-10.39v10.4z" data-name="Path 652" />
            <path className="path-caption--magent" d="m123.2 106.9 3.811 3.815a0.289 0.289 0 0 1 0.085 0.206v12.74a0.2905 0.2905 0 1 1-0.581 0v-12.62l-3.73-3.727a0.2934 0.2934 0 0 1 0.415-0.415z" data-name="Path 653" />
            <path className="path-caption--magent" d="m102.7 131.1a0.29 0.29 0 0 1 0 0.415l-1.7 1.7a0.291 0.291 0 0 1-0.205 0.085h-2.905a0.291 0.291 0 0 1 0-0.581h2.786l1.616-1.619a0.29 0.29 0 0 1 0.408 0z" data-name="Path 654" />
            <path className="path-caption--magent" d="m110.7 124.2a0.292 0.292 0 0 1 0.082 0.206v7.4a0.291 0.291 0 0 1-0.582 0v-7.4a0.291 0.291 0 0 1 0.5-0.206z" data-name="Path 655" />
            <path className="path-caption--magent" d="m105.8 122.9 0.974 0.972a0.1 0.1 0 0 1 0.028 0.069v12.11a0.1 0.1 0 0 1-0.028 0.069l-2.165 2.165 0.015 2.41a0.1 0.1 0 0 1-0.028 0.069l-3.208 3.21v1.809a0.1 0.1 0 0 1-0.028 0.069l-0.968 0.969a0.1 0.1 0 0 1-0.068 0.028h-11.51a0.1 0.1 0 0 1-0.069-0.028l-1.592-1.596a0.1 0.1 0 0 1-0.028-0.069v-4.582a0.1 0.1 0 0 1 0.028-0.069l6.5-6.5a0.1 0.1 0 0 1 0.072-0.031h7.51l2.266-2.266v-6.6a0.1 0.1 0 0 1 0.028-0.068l2.14-2.14a0.1 0.1 0 0 1 0.134 2e-3zm0.808 1.083-0.876-0.877-2.044 2.043v6.6a0.1 0.1 0 0 1-0.028 0.069l-2.322 2.322a0.1 0.1 0 0 1-0.069 0.028h-7.509l-6.445 6.441v4.505l1.535 1.535h11.43l0.912-0.912v-1.808a0.1 0.1 0 0 1 0.028-0.069l3.207-3.207-0.015-2.41a0.1 0.1 0 0 1 0.029-0.069l2.165-2.165z" data-name="Path 656" />
            <path className="path-caption--magent" d="m106.8 132.3a0.1 0.1 0 0 1 0 0.137l-3.182 3.182a0.1 0.1 0 0 1-0.069 0.028h-6.316l-3.1 3.1v4.128l0.776 0.776h4.4l1.912-1.912a0.1 0.1 0 0 1 0.068-0.029h2.334a0.1 0.1 0 1 1 0 0.193h-2.29l-1.919 1.917a0.1 0.1 0 0 1-0.068 0.028h-4.484a0.1 0.1 0 0 1-0.068-0.028l-0.833-0.833a0.094 0.094 0 0 1-0.028-0.068v-4.208a0.1 0.1 0 0 1 0.028-0.069l3.161-3.16a0.1 0.1 0 0 1 0.069-0.028h6.319l3.153-3.154a0.1 0.1 0 0 1 0.137 0z" data-name="Path 657" />
            <path className="path-caption--magent" d="m104.4 124.4a0.1 0.1 0 0 1 0.027 0.072v7.64a0.1 0.1 0 0 1-0.028 0.068l-1.215 1.216a0.1 0.1 0 0 1-0.069 0.028h-0.888a0.1 0.1 0 1 1 0-0.193h0.848l1.16-1.159v-7.6a0.1 0.1 0 0 1 0.092-0.1 0.1 0.1 0 0 1 0.073 0.028z" data-name="Path 658" />
            <path className="path-caption--magent" d="m99.91 150.1a0.1 0.1 0 0 1-0.068 0.165h-16.53a0.1 0.1 0 0 1-0.068-0.028l-5.184-5.184a0.1 0.1 0 0 1 0.137-0.137l5.155 5.156h16.49a0.1 0.1 0 0 1 0.069 0.028z" data-name="Path 659" />
            <path className="path-caption--magent" d="m119.8 117.3 1.424 1.423a0.1 0.1 0 1 1-0.137 0.137l-1.396-1.394h-11.21v18.88a0.094 0.094 0 0 1-0.029 0.068l-2.393 2.393v2.631a0.1 0.1 0 1 1-0.193 0v-2.67a0.094 0.094 0 0 1 0.029-0.069l2.393-2.393v-18.93a0.1 0.1 0 0 1 0.027-0.072 0.1 0.1 0 0 1 0.069-0.028h11.34a0.1 0.1 0 0 1 0.069 0.028z" data-name="Path 660" />
            <path className="path-caption--magent" d="m118.7 115.6a0.1 0.1 0 0 1 0.028 0.072 0.1 0.1 0 0 1-0.1 0.1h-9.847a0.1 0.1 0 1 1 0-0.2h9.851a0.1 0.1 0 0 1 0.068 0.028z" data-name="Path 661" />
            <path className="path-caption--magent" d="m125 105.5 4.387 4.388a0.1 0.1 0 0 1 0.028 0.068v5.664a0.1 0.1 0 0 1-0.194 0v-5.623l-4.359-4.359a0.1 0.1 0 0 1 0.138-0.138z" data-name="Path 662" />
            <path className="path-caption--magent" d="m148.3 135 2.04 2.04a0.1 0.1 0 0 1 0 0.136l-0.557 0.587a0.1 0.1 0 0 1-0.069 0.03l-9.406 0.1a0.1 0.1 0 0 1-0.07-0.028l-2.332-2.332a0.1 0.1 0 0 1 0-0.137l1.409-1.409a0.1 0.1 0 0 1 0.069-0.028h6.162a0.1 0.1 0 0 1 0.069 0.028l0.984 0.985h1.633a0.1 0.1 0 0 1 0.068 0.028zm1.836 2.11-1.944-1.945h-1.633a0.1 0.1 0 0 1-0.069-0.028l-0.983-0.984h-6.083l-1.312 1.312 2.236 2.235 9.324-0.1z" data-name="Path 663" />
            <path className="path-caption--magent" d="m135.8 123.6 1.331 1.332a0.1 0.1 0 0 1 0.028 0.069v10.79l2.6 2.6h4.428l0.808-0.818a0.1 0.1 0 0 1 0.137 0.137l-0.837 0.836a0.094 0.094 0 0 1-0.069 0.028h-4.511a0.1 0.1 0 0 1-0.068-0.028l-2.653-2.647a0.1 0.1 0 0 1-0.028-0.069v-10.79l-1.275-1.276h-0.617a0.1 0.1 0 1 1 0-0.193h0.66a0.1 0.1 0 0 1 0.066 0.028z" data-name="Path 664" />
            <path className="path-caption--magent" d="m137.9 111.8a0.1 0.1 0 0 1 0 0.137l-3.5 3.5a0.1 0.1 0 0 1-0.069 0.028h-2.5l-0.674 0.674v1.033h2.333a0.1 0.1 0 0 1 0.1 0.1v5.03l2.261 2.261a0.1 0.1 0 0 1 0.028 0.069v10.64a0.1 0.1 0 1 1-0.194 0v-10.61l-2.263-2.261a0.1 0.1 0 0 1-0.028-0.068v-4.974h-2.331a0.1 0.1 0 0 1-0.1-0.1v-1.163a0.1 0.1 0 0 1 0.028-0.068l0.731-0.731a0.1 0.1 0 0 1 0.069-0.026h2.5l3.472-3.472a0.1 0.1 0 0 1 0.137 0z" data-name="Path 665" />
            <path className="path-caption--magent" d="m146.8 111 3.12 3.124a0.1 0.1 0 0 1 0 0.137l-2.948 2.948a0.1 0.1 0 0 1-0.068 0.028h-0.87l-5.122 5.123a0.1 0.1 0 0 1-0.07 0.028l0.583 0.584a0.1 0.1 0 0 1-0.137 0.137l-2.025-2.027h-3.363a0.1 0.1 0 0 1-0.068-0.028l-0.6-0.6a0.1 0.1 0 0 1-0.032-0.071v-4.446a0.1 0.1 0 0 1 0.028-0.065l3.456-3.459a0.1 0.1 0 0 1 0.069-0.028h5.352l1.384-1.385a0.1 0.1 0 0 1 0.069-0.028h1.17a0.1 0.1 0 0 1 0.072 0.028zm2.918 3.192-3.03-3.026h-1.088l-1.386 1.384a0.1 0.1 0 0 1-0.068 0.029h-5.353l-3.4 3.4v4.364l0.545 0.545h3.362a0.094 0.094 0 0 1 0.068 0.028l1.379 1.376a0.1 0.1 0 0 1 0.028-0.07l5.151-5.15a0.1 0.1 0 0 1 0.068-0.028h0.87z" data-name="Path 666" />
            <path className="path-caption--magent" d="m148.5 113.1a0.1 0.1 0 0 1 0 0.136l-4.2 4.2a0.1 0.1 0 0 1-0.069 0.036h-6.3l-1.16 1.16a0.1 0.1 0 0 1-0.068 0.028h-1.372a0.1 0.1 0 1 1 0-0.193h1.329l1.16-1.16a0.1 0.1 0 0 1 0.069-0.035h6.3l4.174-4.172a0.1 0.1 0 0 1 0.137 0z" data-name="Path 667" />
            <path className="path-caption--magent" d="m142.6 112.4a0.1 0.1 0 0 1 0 0.137l-2.7 2.7a0.1 0.1 0 0 1-0.068 0.028h-2.539a0.1 0.1 0 0 1-0.069-0.028l-0.589-0.593a0.1 0.1 0 0 1 0.137-0.137l0.56 0.56h2.463l2.668-2.667a0.1 0.1 0 0 1 0.137 0z" data-name="Path 668" />
            <path className="path-caption--magent" d="m147 117.4a0.1 0.1 0 0 1 0 0.136l-5.517 5.518a0.1 0.1 0 0 1-0.137-0.137l5.518-5.517a0.1 0.1 0 0 1 0.136 0z" data-name="Path 669" />
            <path className="path-caption--magent" d="m14.81 18.76a0.291 0.291 0 0 0-0.5 0.209l0.2 21.21-10.21 10.21a0.289 0.289 0 0 0-0.085 0.206v17.08a0.2905 0.2905 0 1 0 0.581 0v-16.96l10.22-10.22a0.289 0.289 0 0 0 0.085-0.208l-0.208-21.34a0.289 0.289 0 0 0-0.085-0.203z" data-name="Path 670" />
            <path className="path-caption--magent" d="m6.539 45.96a0.291 0.291 0 0 0-0.411 0l-2.804 2.806a0.2913 0.2913 0 0 0 0.415 0.409l2.8-2.8a0.29 0.29 0 0 0 0-0.415z" data-name="Path 671" />
            <path className="path-caption--magent" d="m27.65 12.39-12.3-12.3a0.29 0.29 0 0 0-0.411 0l-1.453 1.453a0.291 0.291 0 0 0-0.085 0.205v11.93a0.291 0.291 0 0 0 0.085 0.206l6.031 6.031a0.292 0.292 0 0 0 0.205 0.085h9.2a0.2905 0.2905 0 1 0 0-0.581h-9.079l-5.861-5.861v-11.69l1.162-1.164 12.1 12.1a0.2906 0.2906 0 0 0 0.411-0.411z" data-name="Path 672" />
            <path className="path-caption--magent" d="m31.82 35.47-2.767-2.766a0.2917 0.2917 0 1 0-0.41 0.415l2.681 2.681v4.45h-13.57a0.29 0.29 0 0 0-0.205 0.085l-9.69 9.69a0.291 0.291 0 0 0 0.411 0.411l9.605-9.6h13.74a0.29 0.29 0 0 0 0.291-0.29v-4.871a0.289 0.289 0 0 0-0.085-0.205z" data-name="Path 673" />
            <path className="path-caption--magent" d="m29.26 22.04v10.98a0.29 0.29 0 0 1-0.29 0.291h-10.98a0.3 0.3 0 0 1-0.291-0.291v-10.98a0.29 0.29 0 0 1 0.291-0.29h10.98a0.289 0.289 0 0 1 0.29 0.29zm-10.98 10.69h10.4v-10.4h-10.4z" data-name="Path 674" />
            <path className="path-caption--magent" d="m46.24 30.1-3.815-3.815a0.29 0.29 0 0 0-0.205-0.085h-12.74a0.291 0.291 0 0 0 0 0.581h12.62l3.73 3.73a0.291 0.291 0 0 0 0.411-0.411z" data-name="Path 675" />
            <path className="path-caption--magent" d="m21.98 50.63a0.29 0.29 0 0 0-0.411 0l-1.7 1.7a0.29 0.29 0 0 0-0.089 0.208v2.907a0.291 0.291 0 0 0 0.581 0v-2.787l1.619-1.613a0.29 0.29 0 0 0 0-0.415z" data-name="Path 676" />
            <path className="path-caption--magent" d="m28.9 42.61a0.287 0.287 0 0 0-0.205-0.085h-7.395a0.2905 0.2905 0 1 0 0 0.581h7.4a0.29 0.29 0 0 0 0.286-0.291 0.287 0.287 0 0 0-0.086-0.205z" data-name="Path 677" />
            <path className="path-caption--magent" d="m30.2 47.62-2.143 2.141a0.1 0.1 0 0 1-0.069 0.028h-6.6l-2.259 2.266v7.51a0.1 0.1 0 0 1-0.029 0.068l-6.5 6.5a0.1 0.1 0 0 1-0.068 0.028h-4.586a0.1 0.1 0 0 1-0.068-0.028l-1.592-1.592a0.1 0.1 0 0 1-0.029-0.068v-11.51a0.1 0.1 0 0 1 0.029-0.068l0.971-0.969a0.1 0.1 0 0 1 0.069-0.028h1.805l3.207-3.208a0.1 0.1 0 0 1 0.069-0.029l2.41 0.015 2.166-2.165a0.1 0.1 0 0 1 0.069-0.028h12.1a0.1 0.1 0 0 1 0.068 0.028l0.975 0.972a0.1 0.1 0 0 1 0 0.139zm-13.11-0.946-2.166 2.166a0.1 0.1 0 0 1-0.069 0.029l-2.41-0.015-3.207 3.207a0.1 0.1 0 0 1-0.068 0.028h-1.809l-0.912 0.912v11.43l1.535 1.535h4.506l6.444-6.444v-7.508a0.1 0.1 0 0 1 0.029-0.068l2.322-2.323a0.1 0.1 0 0 1 0.069-0.028h6.6l2.043-2.043-0.882-0.879z" data-name="Path 678" />
            <path className="path-caption--magent" d="m20.81 46.54a0.1 0.1 0 0 0-0.136 0l-3.182 3.182a0.1 0.1 0 0 0-0.029 0.069v6.321l-3.1 3.1h-4.131l-0.776-0.776v-4.4l1.916-1.917a0.1 0.1 0 0 0 0.029-0.068v-2.334a0.1 0.1 0 0 0-0.194 0v2.295l-1.915 1.914a0.1 0.1 0 0 0-0.029 0.069v4.483a0.1 0.1 0 0 0 0.029 0.069l0.832 0.833a0.1 0.1 0 0 0 0.069 0.028h4.207a0.1 0.1 0 0 0 0.068-0.028l3.161-3.16a0.1 0.1 0 0 0 0.028-0.069v-6.32l3.153-3.153a0.1 0.1 0 0 0 0-0.138z" data-name="Path 679" />
            <path className="path-caption--magent" d="m28.65 48.9a0.1 0.1 0 0 0-0.068-0.028h-7.644a0.1 0.1 0 0 0-0.068 0.028l-1.217 1.216a0.1 0.1 0 0 0-0.028 0.069v0.887a0.1 0.1 0 0 0 0.194 0v-0.847l1.159-1.159h7.6a0.1 0.1 0 0 0 0.1-0.1 0.1 0.1 0 0 0-0.028-0.066z" data-name="Path 680" />
            <path className="path-caption--magent" d="m3.012 53.4a0.1 0.1 0 0 0-0.066-0.028 0.1 0.1 0 0 0-0.1 0.1v16.53a0.1 0.1 0 0 0 0.028 0.069l5.184 5.183a0.1 0.1 0 1 0 0.137-0.137l-5.155-5.158v-16.49a0.1 0.1 0 0 0-0.028-0.072z" data-name="Path 681" />
            <path className="path-caption--magent" d="m35.83 33.55-1.423-1.424a0.1 0.1 0 0 0-0.137 0.137l1.395 1.395v11.21h-18.88a0.1 0.1 0 0 0-0.069 0.028l-2.393 2.392h-2.627a0.1 0.1 0 1 0 0 0.194h2.67a0.1 0.1 0 0 0 0.069-0.028l2.39-2.393h18.93a0.1 0.1 0 0 0 0.068-0.028 0.1 0.1 0 0 0 0.028-0.069v-11.34a0.1 0.1 0 0 0-0.028-0.068z" data-name="Path 682" />
            <path className="path-caption--magent" d="m37.54 34.65a0.1 0.1 0 0 0-0.066-0.028 0.1 0.1 0 0 0-0.1 0.1v9.851a0.1 0.1 0 0 0 0.194 0v-9.851a0.1 0.1 0 0 0-0.028-0.072z" data-name="Path 683" />
            <path className="path-caption--magent" d="m47.58 28.34-4.387-4.388a0.1 0.1 0 0 0-0.069-0.028h-5.661a0.1 0.1 0 1 0 0 0.193h5.621l4.359 4.36a0.1 0.1 0 0 0 0.137 0 0.1 0.1 0 0 0 0-0.137z" data-name="Path 684" />
            <path className="path-caption--magent" d="m18.18 5.052v1.633l0.984 0.984a0.1 0.1 0 0 1 0.028 0.068v6.163a0.1 0.1 0 0 1-0.028 0.068l-1.407 1.41a0.1 0.1 0 0 1-0.137 0l-2.335-2.333a0.1 0.1 0 0 1-0.028-0.07l0.1-9.406a0.1 0.1 0 0 1 0.033-0.069l0.587-0.557a0.1 0.1 0 0 1 0.135 0l2.039 2.041a0.1 0.1 0 0 1 0.029 0.068zm-2.623-1.436-0.1 9.324 2.23 2.232 1.312-1.312v-6.082l-0.984-0.984a0.1 0.1 0 0 1-0.029-0.069v-1.632l-1.944-1.945z" data-name="Path 685" />
            <path className="path-caption--magent" d="m29.55 17.51-1.331-1.331a0.1 0.1 0 0 0-0.069-0.03h-10.79l-2.6-2.6v-4.427l0.81-0.807a0.1 0.1 0 0 0-0.135-0.138l-0.837 0.837a0.1 0.1 0 0 0-0.028 0.068v4.512a0.1 0.1 0 0 0 0.028 0.069l2.653 2.652a0.1 0.1 0 0 0 0.068 0.028h10.79l1.275 1.275v0.621a0.1 0.1 0 0 0 0.194 0v-0.661a0.1 0.1 0 0 0-0.029-0.068z" data-name="Path 686" />
            <path className="path-caption--magent" d="m41.34 15.39a0.1 0.1 0 0 0-0.137 0l-3.5 3.5a0.1 0.1 0 0 0-0.029 0.068v2.504l-0.674 0.674h-1.03v-2.336a0.1 0.1 0 0 0-0.1-0.1h-5.03l-2.254-2.257a0.1 0.1 0 0 0-0.069-0.028h-10.65a0.1 0.1 0 0 0 0 0.194h10.61l2.26 2.261a0.1 0.1 0 0 0 0.069 0.028h4.973v2.333a0.1 0.1 0 0 0 0.1 0.1h1.167a0.1 0.1 0 0 0 0.068-0.028l0.731-0.731a0.1 0.1 0 0 0 0.028-0.068v-2.5l3.467-3.476a0.1 0.1 0 0 0 0-0.138z" data-name="Path 687" />
            <path className="path-caption--magent" d="m42.11 6.564v1.17a0.1 0.1 0 0 1-0.028 0.068l-1.384 1.385v5.356a0.1 0.1 0 0 1-0.028 0.069l-3.456 3.452a0.1 0.1 0 0 1-0.068 0.028h-4.448a0.094 0.094 0 0 1-0.068-0.028l-0.6-0.6a0.1 0.1 0 0 1-0.028-0.069v-3.366l-2.028-2.026a0.1 0.1 0 0 1 0-0.137 0.1 0.1 0 0 1 0.137 0l0.583 0.583a0.1 0.1 0 0 1 0.028-0.07l5.124-5.121v-0.87a0.1 0.1 0 0 1 0.029-0.068l2.946-2.949a0.1 0.1 0 0 1 0.137 0l3.124 3.124a0.1 0.1 0 0 1 0.028 0.069zm-6.071-0.136v0.87a0.094 0.094 0 0 1-0.029 0.068l-5.15 5.151a0.1 0.1 0 0 1-0.07 0.028l1.375 1.375a0.1 0.1 0 0 1 0.028 0.069v3.364l0.545 0.545h4.367l3.4-3.4v-5.355a0.1 0.1 0 0 1 0.028-0.069l1.383-1.38v-1.09l-3.026-3.027z" data-name="Path 688" />
            <path className="path-caption--magent" d="m40.01 4.822a0.1 0.1 0 0 0-0.137 0l-4.2 4.2a0.1 0.1 0 0 0-0.028 0.069v6.3l-1.165 1.163a0.1 0.1 0 0 0-0.028 0.069v1.371a0.1 0.1 0 0 0 0.194 0v-1.331l1.16-1.161a0.1 0.1 0 0 0 0.028-0.068v-6.3l4.176-4.174a0.1 0.1 0 0 0 0-0.138z" data-name="Path 689" />
            <path className="path-caption--magent" d="m40.66 10.75a0.1 0.1 0 0 0-0.137 0l-2.7 2.7a0.1 0.1 0 0 0-0.025 0.065v2.542a0.1 0.1 0 0 0 0.029 0.065l0.589 0.589a0.1 0.1 0 0 0 0.137-0.136l-0.561-0.553v-2.467l2.668-2.668a0.1 0.1 0 0 0 0-0.137z" data-name="Path 690" />
            <path className="path-caption--magent" d="m35.68 6.289a0.1 0.1 0 0 0-0.137 0l-5.517 5.518a0.1 0.1 0 0 0 0.137 0.137l5.517-5.517a0.1 0.1 0 0 0 0-0.138z" data-name="Path 691" />
            <path className="path-caption--magent" d="m64.92 68.87a0.291 0.291 0 0 1-0.208 0.5l-21.22-0.208-10.22 10.22a0.291 0.291 0 0 1-0.205 0.085h-17.08a0.2905 0.2905 0 1 1 0-0.581h16.96l10.22-10.22a0.291 0.291 0 0 1 0.208-0.085l21.34 0.206a0.289 0.289 0 0 1 0.203 0.085z" data-name="Path 692" />
            <path className="path-caption--magent" d="m37.72 77.14a0.291 0.291 0 0 1 0 0.415l-2.8 2.8a0.2906 0.2906 0 0 1-0.411-0.411l2.803-2.804a0.29 0.29 0 0 1 0.408 0z" data-name="Path 693" />
            <path className="path-caption--magent" d="m71.29 56.03 12.3 12.3a0.291 0.291 0 0 1 0 0.411l-1.453 1.453a0.292 0.292 0 0 1-0.206 0.085h-11.92a0.289 0.289 0 0 1-0.205-0.085l-6.031-6.024a0.289 0.289 0 0 1-0.085-0.209v-9.2a0.2905 0.2905 0 1 1 0.581 0v9.08l5.861 5.861h11.69l1.162-1.162-12.1-12.1a0.291 0.291 0 0 1 0.411-0.411z" data-name="Path 694" />
            <path className="path-caption--magent" d="m48.21 51.86 2.766 2.766a0.2906 0.2906 0 1 1-0.411 0.411l-2.681-2.681h-4.454v13.56a0.29 0.29 0 0 1-0.085 0.205l-9.686 9.691a0.291 0.291 0 0 1-0.411-0.411l9.601-9.605v-13.74a0.29 0.29 0 0 1 0.29-0.291h4.865a0.293 0.293 0 0 1 0.206 0.085z" data-name="Path 695" />
            <path className="path-caption--magent" d="m61.84 54.5a0.292 0.292 0 0 1 0.085 0.206v10.98a0.289 0.289 0 0 1-0.29 0.29h-10.98a0.3 0.3 0 0 1-0.29-0.29v-10.98a0.29 0.29 0 0 1 0.29-0.291h10.98a0.293 0.293 0 0 1 0.206 0.085zm-0.5 10.89v-10.4h-10.39v10.4z" data-name="Path 696" />
            <path className="path-caption--magent" d="m53.58 37.44 3.811 3.815a0.289 0.289 0 0 1 0.085 0.205v12.74a0.291 0.291 0 0 1-0.581 0v-12.62l-3.73-3.726a0.2934 0.2934 0 0 1 0.415-0.415z" data-name="Path 697" />
            <path className="path-caption--magent" d="m33.04 61.69a0.29 0.29 0 0 1 0 0.415l-1.7 1.7a0.291 0.291 0 0 1-0.206 0.085h-2.903a0.2905 0.2905 0 1 1 0-0.581h2.784l1.618-1.619a0.291 0.291 0 0 1 0.407 0z" data-name="Path 698" />
            <path className="path-caption--magent" d="m41.07 54.78a0.29 0.29 0 0 1 0.081 0.206v7.4a0.2905 0.2905 0 1 1-0.581 0v-7.4a0.291 0.291 0 0 1 0.5-0.206z" data-name="Path 699" />
            <path className="path-caption--magent" d="m36.19 53.48 0.975 0.972a0.1 0.1 0 0 1 0.028 0.068v12.11a0.1 0.1 0 0 1-0.028 0.068l-2.165 2.165 0.014 2.41a0.1 0.1 0 0 1-0.028 0.069l-3.208 3.211v1.809a0.1 0.1 0 0 1-0.028 0.069l-0.968 0.968a0.1 0.1 0 0 1-0.068 0.028h-11.51a0.1 0.1 0 0 1-0.069-0.028l-1.592-1.595a0.1 0.1 0 0 1-0.028-0.069v-4.582a0.1 0.1 0 0 1 0.028-0.069l6.5-6.5a0.093 0.093 0 0 1 0.072-0.031h7.51l2.265-2.265v-6.6a0.1 0.1 0 0 1 0.029-0.069l2.138-2.138a0.1 0.1 0 0 1 0.136 0zm0.809 1.083-0.877-0.877-2.043 2.044v6.6a0.094 0.094 0 0 1-0.029 0.069l-2.322 2.323a0.1 0.1 0 0 1-0.068 0.028h-7.511l-6.443 6.439v4.506l1.536 1.535h11.43l0.912-0.912v-1.809a0.1 0.1 0 0 1 0.028-0.068l3.207-3.207-0.014-2.41a0.1 0.1 0 0 1 0.028-0.069l2.165-2.166z" data-name="Path 700" />
            <path className="path-caption--magent" d="m37.13 62.87a0.1 0.1 0 0 1 0 0.136l-3.181 3.182a0.1 0.1 0 0 1-0.069 0.028h-6.316l-3.1 3.1v4.128l0.776 0.776h4.4l1.912-1.913a0.1 0.1 0 0 1 0.069-0.028h2.333a0.1 0.1 0 1 1 0 0.193h-2.29l-1.919 1.917a0.1 0.1 0 0 1-0.069 0.028h-4.483a0.1 0.1 0 0 1-0.068-0.028l-0.832-0.833a0.1 0.1 0 0 1-0.029-0.069v-4.207a0.1 0.1 0 0 1 0.029-0.068l3.159-3.161a0.1 0.1 0 0 1 0.069-0.029h6.319l3.153-3.152a0.1 0.1 0 0 1 0.137 0z" data-name="Path 701" />
            <path className="path-caption--magent" d="m34.77 55.02a0.1 0.1 0 0 1 0.027 0.072v7.64a0.1 0.1 0 0 1-0.028 0.068l-1.215 1.217a0.1 0.1 0 0 1-0.068 0.028h-0.889a0.1 0.1 0 1 1 0-0.193h0.848l1.159-1.16v-7.6a0.1 0.1 0 0 1 0.093-0.1 0.1 0.1 0 0 1 0.073 0.028z" data-name="Path 702" />
            <path className="path-caption--magent" d="m30.28 80.66a0.1 0.1 0 0 1-0.068 0.166h-16.53a0.1 0.1 0 0 1-0.068-0.028l-5.184-5.184a0.1 0.1 0 0 1 0.137-0.138l5.155 5.155h16.49a0.1 0.1 0 0 1 0.069 0.029z" data-name="Path 703" />
            <path className="path-caption--magent" d="m50.12 47.85 1.423 1.423a0.1 0.1 0 1 1-0.137 0.137l-1.4-1.4h-11.2v18.88a0.1 0.1 0 0 1-0.028 0.069l-2.393 2.393v2.63a0.1 0.1 0 1 1-0.194 0v-2.67a0.1 0.1 0 0 1 0.028-0.068l2.393-2.394v-18.93a0.1 0.1 0 0 1 0.1-0.1h11.34a0.1 0.1 0 0 1 0.069 0.028z" data-name="Path 704" />
            <path className="path-caption--magent" d="m49.03 46.14a0.1 0.1 0 0 1-0.069 0.166h-9.85a0.1 0.1 0 1 1 0-0.194h9.85a0.1 0.1 0 0 1 0.069 0.028z" data-name="Path 705" />
            <path className="path-caption--magent" d="m55.33 36.09 4.387 4.388a0.1 0.1 0 0 1 0.028 0.069v5.664a0.1 0.1 0 1 1-0.193 0v-5.625l-4.359-4.359a0.1 0.1 0 0 1 0-0.137 0.1 0.1 0 0 1 0.137 0z" data-name="Path 706" />
            <path className="path-caption--magent" d="m78.69 65.53 2.039 2.04a0.1 0.1 0 0 1 0 0.135l-0.558 0.587a0.1 0.1 0 0 1-0.069 0.03l-9.406 0.1a0.1 0.1 0 0 1-0.069-0.028l-2.331-2.331a0.1 0.1 0 0 1 0-0.137l1.409-1.409a0.1 0.1 0 0 1 0.068-0.028h6.163a0.1 0.1 0 0 1 0.068 0.028l0.984 0.984h1.633a0.1 0.1 0 0 1 0.069 0.029zm1.836 2.11-1.944-1.945h-1.633a0.1 0.1 0 0 1-0.068-0.028l-0.985-0.985h-6.083l-1.311 1.312 2.236 2.236 9.324-0.1z" data-name="Path 707" />
            <path className="path-caption--magent" d="m66.17 54.13 1.331 1.332a0.1 0.1 0 0 1 0.028 0.068v10.79l2.6 2.6h4.428l0.808-0.808a0.1 0.1 0 0 1 0.137 0.137l-0.836 0.836a0.1 0.1 0 0 1-0.069 0.028h-4.512a0.094 0.094 0 0 1-0.068-0.028l-2.652-2.657a0.094 0.094 0 0 1-0.029-0.068v-10.79l-1.275-1.276h-0.617a0.1 0.1 0 1 1 0-0.193h0.66a0.1 0.1 0 0 1 0.066 0.028z" data-name="Path 708" />
            <path className="path-caption--magent" d="m68.29 42.34a0.1 0.1 0 0 1 0 0.137l-3.5 3.5a0.1 0.1 0 0 1-0.068 0.028h-2.501l-0.674 0.675v1.026h2.333a0.1 0.1 0 0 1 0.1 0.1v5.03l2.261 2.261a0.1 0.1 0 0 1 0.028 0.069v10.64a0.1 0.1 0 0 1-0.194 0v-10.61l-2.263-2.261a0.1 0.1 0 0 1-0.028-0.068v-4.973h-2.331a0.1 0.1 0 0 1-0.1-0.1v-1.163a0.1 0.1 0 0 1 0.029-0.069l0.731-0.731a0.1 0.1 0 0 1 0.068-0.026h2.5l3.472-3.471a0.1 0.1 0 0 1 0.137 0z" data-name="Path 709" />
            <path className="path-caption--magent" d="m77.19 41.6 3.12 3.124a0.1 0.1 0 0 1 0 0.137l-2.947 2.948a0.1 0.1 0 0 1-0.068 0.028h-0.87l-5.123 5.122a0.1 0.1 0 0 1-0.07 0.028l0.583 0.585a0.1 0.1 0 0 1-0.137 0.137l-2.026-2.027h-3.362a0.1 0.1 0 0 1-0.069-0.029l-0.6-0.6a0.1 0.1 0 0 1-0.031-0.07v-4.446a0.094 0.094 0 0 1 0.028-0.065l3.456-3.459a0.1 0.1 0 0 1 0.069-0.028h5.347l1.389-1.385a0.1 0.1 0 0 1 0.069-0.028h1.17a0.1 0.1 0 0 1 0.072 0.028zm2.918 3.192-3.03-3.027h-1.088l-1.387 1.384a0.1 0.1 0 0 1-0.068 0.028h-5.353l-3.399 3.395v4.371l0.545 0.545h3.362a0.1 0.1 0 0 1 0.068 0.028l1.375 1.375a0.1 0.1 0 0 1 0.028-0.069l5.154-5.15a0.1 0.1 0 0 1 0.069-0.028h0.87z" data-name="Path 710" />
            <path className="path-caption--magent" d="m78.86 43.66a0.1 0.1 0 0 1 0 0.137l-4.2 4.2a0.1 0.1 0 0 1-0.069 0.035h-6.3l-1.163 1.158a0.1 0.1 0 0 1-0.068 0.028h-1.369a0.1 0.1 0 1 1 0-0.194h1.329l1.16-1.16a0.1 0.1 0 0 1 0.069-0.032h6.3l4.175-4.172a0.1 0.1 0 0 1 0.136 0z" data-name="Path 711" />
            <path className="path-caption--magent" d="m72.93 43.01a0.1 0.1 0 0 1 0 0.137l-2.7 2.7a0.1 0.1 0 0 1-0.069 0.028h-2.538a0.1 0.1 0 0 1-0.069-0.028l-0.589-0.592a0.1 0.1 0 0 1 0.137-0.137l0.56 0.561h2.463l2.668-2.669a0.1 0.1 0 0 1 0.137 0z" data-name="Path 712" />
            <path className="path-caption--magent" d="m77.39 47.99a0.1 0.1 0 0 1 0 0.137l-5.518 5.517a0.1 0.1 0 0 1-0.137-0.137l5.518-5.517a0.1 0.1 0 0 1 0.137 0z" data-name="Path 713" />
            <path className="path-caption--magent" d="m156.4 160.8a0.29 0.29 0 0 0-0.5 0.208l0.2 21.21-10.21 10.22a0.289 0.289 0 0 0-0.085 0.205v17.08a0.2905 0.2905 0 0 0 0.581 0v-16.96l10.22-10.22a0.292 0.292 0 0 0 0.085-0.209l-0.206-21.34a0.288 0.288 0 0 0-0.077-0.202z" data-name="Path 538" />
            <path className="path-caption--magent" d="m148.2 188a0.29 0.29 0 0 0-0.411 0l-2.806 2.806a0.291 0.291 0 0 0 0.411 0.411l2.806-2.807a0.29 0.29 0 0 0 0-0.41z" data-name="Path 539" />
            <path className="path-caption--magent" d="m169.3 154.4-12.3-12.3a0.29 0.29 0 0 0-0.411 0l-1.452 1.453a0.289 0.289 0 0 0-0.085 0.205v11.93a0.29 0.29 0 0 0 0.085 0.205l6.031 6.031a0.289 0.289 0 0 0 0.205 0.085h9.2a0.291 0.291 0 0 0 0-0.581h-9.081l-5.861-5.86v-11.69l1.162-1.162 12.1 12.1a0.2906 0.2906 0 0 0 0.411-0.411z" data-name="Path 540" />
            <path className="path-caption--magent" d="m173.4 177.5-2.766-2.766a0.291 0.291 0 0 0-0.411 0.411l2.681 2.681v4.453h-13.57a0.29 0.29 0 0 0-0.205 0.085l-9.69 9.69a0.291 0.291 0 0 0 0.411 0.411l9.605-9.606h13.74a0.289 0.289 0 0 0 0.291-0.291v-4.864a0.292 0.292 0 0 0-0.086-0.204z" data-name="Path 541" />
            <path className="path-caption--magent" d="m170.9 164v10.98a0.291 0.291 0 0 1-0.29 0.291h-10.98a0.3 0.3 0 0 1-0.291-0.291v-10.98a0.291 0.291 0 0 1 0.291-0.29h10.98a0.29 0.29 0 0 1 0.29 0.29zm-10.98 10.69h10.4v-10.4h-10.4z" data-name="Path 542" />
            <path className="path-caption--magent" d="m187.9 172.1-3.816-3.815a0.289 0.289 0 0 0-0.205-0.085h-12.74a0.2905 0.2905 0 1 0 0 0.581h12.62l3.73 3.73a0.291 0.291 0 1 0 0.411-0.411z" data-name="Path 543" />
            <path className="path-caption--magent" d="m163.6 192.6a0.29 0.29 0 0 0-0.411 0l-1.7 1.7a0.29 0.29 0 0 0-0.085 0.206v2.905a0.291 0.291 0 0 0 0.581 0v-2.785l1.617-1.617a0.291 0.291 0 0 0 0-0.409z" data-name="Path 544" />
            <path className="path-caption--magent" d="m170.5 184.6a0.289 0.289 0 0 0-0.205-0.085h-7.4a0.2905 0.2905 0 1 0 0 0.581h7.4a0.29 0.29 0 0 0 0.205-0.5z" data-name="Path 545" />
            <path className="path-caption--magent" d="m171.8 189.6-2.141 2.141a0.1 0.1 0 0 1-0.069 0.028h-6.6l-2.266 2.266v7.51a0.1 0.1 0 0 1-0.028 0.069l-6.5 6.5a0.1 0.1 0 0 1-0.068 0.028h-4.586a0.094 0.094 0 0 1-0.068-0.028l-1.592-1.592a0.1 0.1 0 0 1-0.028-0.068v-11.51a0.1 0.1 0 0 1 0.028-0.068l0.969-0.969a0.1 0.1 0 0 1 0.068-0.028h1.808l3.207-3.207a0.1 0.1 0 0 1 0.069-0.029l2.41 0.015 2.165-2.165a0.1 0.1 0 0 1 0.068-0.028h12.11a0.1 0.1 0 0 1 0.069 0.028l0.974 0.974a0.1 0.1 0 0 1 0 0.135zm-13.11-0.946-2.165 2.165a0.1 0.1 0 0 1-0.069 0.028l-2.41-0.015-3.207 3.207a0.1 0.1 0 0 1-0.069 0.029h-1.808l-0.912 0.912v11.43l1.535 1.535h4.506l6.444-6.444v-7.51a0.1 0.1 0 0 1 0.028-0.069l2.323-2.322a0.1 0.1 0 0 1 0.068-0.028h6.6l2.044-2.044-0.877-0.877z" data-name="Path 546" />
            <path className="path-caption--magent" d="m162.4 188.5a0.1 0.1 0 0 0-0.137 0l-3.182 3.181a0.1 0.1 0 0 0-0.028 0.069v6.319l-3.1 3.1h-4.128l-0.776-0.776v-4.4l1.916-1.916a0.1 0.1 0 0 0 0.028-0.069v-2.333a0.1 0.1 0 0 0-0.194 0v2.293l-1.916 1.916a0.1 0.1 0 0 0-0.028 0.068v4.483a0.1 0.1 0 0 0 0.028 0.069l0.833 0.832a0.1 0.1 0 0 0 0.069 0.028h4.208a0.1 0.1 0 0 0 0.069-0.028l3.16-3.161a0.1 0.1 0 0 0 0.028-0.068v-6.319l3.153-3.153a0.1 0.1 0 0 0 0-0.135z" data-name="Path 547" />
            <path className="path-caption--magent" d="m170.3 190.9a0.1 0.1 0 0 0-0.069-0.028h-7.643a0.094 0.094 0 0 0-0.068 0.028l-1.217 1.216a0.1 0.1 0 0 0-0.028 0.068v0.891a0.1 0.1 0 0 0 0.194 0v-0.851l1.159-1.16h7.6a0.1 0.1 0 0 0 0.069-0.165z" data-name="Path 548" />
            <path className="path-caption--magent" d="m144.6 195.4a0.1 0.1 0 0 0-0.166 0.068v16.53a0.1 0.1 0 0 0 0.029 0.068l5.184 5.184a0.1 0.1 0 0 0 0.137-0.137l-5.155-5.155v-16.49a0.1 0.1 0 0 0-0.029-0.068z" data-name="Path 549" />
            <path className="path-caption--magent" d="m177.5 175.6-1.423-1.423a0.1 0.1 0 0 0-0.137 0 0.1 0.1 0 0 0 0 0.137l1.4 1.395v11.21h-18.88a0.1 0.1 0 0 0-0.069 0.028l-2.393 2.393h-2.63a0.1 0.1 0 1 0 0 0.193h2.671a0.1 0.1 0 0 0 0.069-0.028l2.393-2.393h18.93a0.1 0.1 0 0 0 0.1-0.1v-11.34a0.1 0.1 0 0 0-0.037-0.065z" data-name="Path 550" />
            <path className="path-caption--magent" d="m179.2 176.6a0.1 0.1 0 0 0-0.069-0.028 0.1 0.1 0 0 0-0.1 0.1v9.854a0.1 0.1 0 1 0 0.194 0v-9.854a0.1 0.1 0 0 0-0.025-0.072z" data-name="Path 551" />
            <path className="path-caption--magent" d="m189.2 170.3-4.388-4.387a0.1 0.1 0 0 0-0.068-0.028h-5.664a0.1 0.1 0 1 0 0 0.193h5.624l4.359 4.359a0.1 0.1 0 0 0 0.137-0.137z" data-name="Path 552" />
            <path className="path-caption--magent" d="m159.8 147.1v1.632l0.984 0.984a0.1 0.1 0 0 1 0.028 0.069v6.162a0.094 0.094 0 0 1-0.028 0.069l-1.408 1.409a0.1 0.1 0 0 1-0.137 0l-2.333-2.333a0.1 0.1 0 0 1-0.028-0.07l0.1-9.406a0.1 0.1 0 0 1 0.03-0.069l0.587-0.558a0.1 0.1 0 0 1 0.135 0l2.04 2.04a0.1 0.1 0 0 1 0.03 0.071zm-2.627-1.44-0.1 9.324 2.236 2.236 1.312-1.312v-6.083l-0.984-0.984a0.1 0.1 0 0 1-0.028-0.069v-1.632l-1.945-1.945z" data-name="Path 553" />
            <path className="path-caption--magent" d="m171.2 159.5-1.331-1.332a0.1 0.1 0 0 0-0.069-0.028h-10.79l-2.6-2.6v-4.432l0.808-0.808a0.1 0.1 0 0 0-0.136-0.137l-0.837 0.837a0.1 0.1 0 0 0-0.028 0.069v4.512a0.1 0.1 0 0 0 0.028 0.069l2.653 2.653a0.1 0.1 0 0 0 0.068 0.028h10.79l1.275 1.275v0.62a0.1 0.1 0 1 0 0.193 0v-0.66a0.1 0.1 0 0 0-0.024-0.066z" data-name="Path 554" />
            <path className="path-caption--magent" d="m183 157.4a0.1 0.1 0 0 0-0.137 0l-3.5 3.5a0.1 0.1 0 0 0-0.029 0.068v2.5l-0.674 0.674h-1.029v-2.333a0.1 0.1 0 0 0-0.1-0.1h-5.03l-2.261-2.261a0.1 0.1 0 0 0-0.068-0.028h-10.65a0.1 0.1 0 1 0 0 0.193h10.61l2.261 2.261a0.1 0.1 0 0 0 0.069 0.028h4.973v2.334a0.1 0.1 0 0 0 0.1 0.1h1.166a0.1 0.1 0 0 0 0.068-0.028l0.731-0.731a0.1 0.1 0 0 0 0.028-0.069v-2.5l3.469-3.469a0.1 0.1 0 0 0 0-0.139z" data-name="Path 555" />
            <path className="path-caption--magent" d="m183.7 148.6v1.17a0.1 0.1 0 0 1-0.028 0.069l-1.384 1.384v5.352a0.1 0.1 0 0 1-0.028 0.069l-3.456 3.456a0.1 0.1 0 0 1-0.068 0.029h-4.447a0.1 0.1 0 0 1-0.069-0.029l-0.6-0.6a0.1 0.1 0 0 1-0.028-0.068v-3.365l-2.026-2.026a0.1 0.1 0 0 1 0.137-0.136l0.583 0.583a0.1 0.1 0 0 1 0.029-0.07l5.122-5.122v-0.87a0.1 0.1 0 0 1 0.028-0.069l2.948-2.947a0.1 0.1 0 0 1 0.136 0l3.124 3.124a0.1 0.1 0 0 1 0.027 0.066zm-6.071-0.136v0.87a0.1 0.1 0 0 1-0.028 0.069l-5.151 5.151a0.1 0.1 0 0 1-0.069 0.028l1.375 1.376a0.1 0.1 0 0 1 0.028 0.068v3.365l0.545 0.545h4.366l3.4-3.4v-5.352a0.1 0.1 0 0 1 0.029-0.068l1.384-1.384v-1.09l-3.027-3.027z" data-name="Path 556" />
            <path className="path-caption--magent" d="m181.6 146.8a0.1 0.1 0 0 0-0.137 0l-4.2 4.2a0.1 0.1 0 0 0-0.028 0.068v6.3l-1.16 1.16a0.1 0.1 0 0 0-0.028 0.069v1.372a0.1 0.1 0 1 0 0.194 0v-1.332l1.16-1.16a0.1 0.1 0 0 0 0.029-0.069v-6.3l4.176-4.176a0.1 0.1 0 0 0-0.01-0.132z" data-name="Path 557" />
            <path className="path-caption--magent" d="m182.3 152.8a0.1 0.1 0 0 0-0.137 0l-2.7 2.7a0.1 0.1 0 0 0-0.028 0.069v2.542a0.1 0.1 0 0 0 0.028 0.069l0.589 0.589a0.1 0.1 0 0 0 0.137-0.137l-0.561-0.56v-2.462l2.668-2.668a0.1 0.1 0 0 0 0-0.142z" data-name="Path 558" />
            <path className="path-caption--magent" d="m177.3 148.3a0.1 0.1 0 0 0-0.137 0l-5.517 5.517a0.1 0.1 0 0 0 0.136 0.137l5.518-5.518a0.1 0.1 0 0 0 0-0.136z" data-name="Path 559" />
            <path className="path-caption--magent" d="m206.6 210.9a0.291 0.291 0 0 1-0.208 0.5l-21.21-0.2-10.22 10.22a0.289 0.289 0 0 1-0.205 0.085h-17.08a0.2905 0.2905 0 1 1 0-0.581h16.96l10.22-10.22a0.293 0.293 0 0 1 0.208-0.085l21.34 0.205a0.294 0.294 0 0 1 0.203 0.077z" data-name="Path 560" />
            <path className="path-caption--magent" d="m179.3 219.1a0.291 0.291 0 0 1 0 0.411l-2.807 2.806a0.2906 0.2906 0 0 1-0.411-0.411l2.806-2.806a0.291 0.291 0 0 1 0.412 0z" data-name="Path 561" />
            <path className="path-caption--magent" d="m212.9 198 12.3 12.3a0.291 0.291 0 0 1 0 0.411l-1.453 1.453a0.291 0.291 0 0 1-0.206 0.085h-11.93a0.289 0.289 0 0 1-0.205-0.085l-6.031-6.031a0.292 0.292 0 0 1-0.085-0.206v-9.2a0.2905 0.2905 0 1 1 0.581 0v9.08l5.861 5.861h11.69l1.162-1.162-12.1-12.1a0.2906 0.2906 0 0 1 0.411-0.411z" data-name="Path 562" />
            <path className="path-caption--magent" d="m189.8 193.9 2.767 2.766a0.291 0.291 0 0 1-0.411 0.411l-2.681-2.68h-4.453v13.56a0.292 0.292 0 0 1-0.085 0.206l-9.69 9.69a0.291 0.291 0 0 1-0.411-0.411l9.6-9.606v-13.74a0.291 0.291 0 0 1 0.291-0.29h4.865a0.289 0.289 0 0 1 0.208 0.085z" data-name="Path 563" />
            <path className="path-caption--magent" d="m203.5 196.5a0.292 0.292 0 0 1 0.085 0.205v10.98a0.29 0.29 0 0 1-0.29 0.291h-10.98a0.3 0.3 0 0 1-0.291-0.291v-10.98a0.291 0.291 0 0 1 0.291-0.29h10.98a0.291 0.291 0 0 1 0.205 0.085zm-0.5 10.89v-10.4h-10.4v10.4z" data-name="Path 564" />
            <path className="path-caption--magent" d="m195.2 179.4 3.815 3.815a0.289 0.289 0 0 1 0.085 0.206v12.74a0.291 0.291 0 0 1-0.581 0v-12.62l-3.73-3.73a0.291 0.291 0 0 1 0.411-0.411z" data-name="Path 565" />
            <path className="path-caption--magent" d="m174.7 203.7a0.291 0.291 0 0 1 0 0.411l-1.7 1.7a0.291 0.291 0 0 1-0.206 0.085h-2.911a0.291 0.291 0 1 1 0-0.581h2.785l1.617-1.617a0.29 0.29 0 0 1 0.415 0z" data-name="Path 566" />
            <path className="path-caption--magent" d="m182.7 196.8a0.29 0.29 0 0 1 0.085 0.206v7.4a0.2905 0.2905 0 0 1-0.581 0v-7.4a0.29 0.29 0 0 1 0.5-0.206z" data-name="Path 567" />
            <path className="path-caption--magent" d="m177.8 195.5 0.974 0.974a0.1 0.1 0 0 1 0.028 0.068v12.11a0.1 0.1 0 0 1-0.028 0.069l-2.165 2.165 0.014 2.41a0.1 0.1 0 0 1-0.028 0.069l-3.207 3.207v1.809a0.1 0.1 0 0 1-0.028 0.068l-0.968 0.968a0.1 0.1 0 0 1-0.068 0.029h-11.51a0.1 0.1 0 0 1-0.068-0.029l-1.592-1.592a0.1 0.1 0 0 1-0.028-0.068v-4.585a0.1 0.1 0 0 1 0.028-0.068l6.5-6.5a0.1 0.1 0 0 1 0.069-0.028h7.51l2.265-2.266v-6.6a0.1 0.1 0 0 1 0.028-0.068l2.141-2.14a0.1 0.1 0 0 1 0.135 0zm0.809 1.083-0.877-0.878-2.043 2.044v6.6a0.1 0.1 0 0 1-0.028 0.068l-2.323 2.322a0.1 0.1 0 0 1-0.069 0.028h-7.509l-6.444 6.444v4.505l1.536 1.535h11.43l0.911-0.912v-1.809a0.1 0.1 0 0 1 0.028-0.069l3.207-3.207-0.014-2.41a0.1 0.1 0 0 1 0.028-0.069l2.165-2.165z" data-name="Path 568" />
            <path className="path-caption--magent" d="m178.8 204.9a0.1 0.1 0 0 1 0 0.137l-3.182 3.182a0.1 0.1 0 0 1-0.068 0.028h-6.319l-3.1 3.1v4.128l0.776 0.776h4.4l1.916-1.916a0.1 0.1 0 0 1 0.068-0.028h2.333a0.1 0.1 0 1 1 0 0.193h-2.293l-1.916 1.916a0.1 0.1 0 0 1-0.069 0.028h-4.483a0.1 0.1 0 0 1-0.068-0.028l-0.833-0.833a0.1 0.1 0 0 1-0.028-0.068v-4.208a0.1 0.1 0 0 1 0.028-0.069l3.161-3.16a0.1 0.1 0 0 1 0.068-0.028h6.319l3.153-3.153a0.1 0.1 0 0 1 0.137 0z" data-name="Path 569" />
            <path className="path-caption--magent" d="m176.4 197a0.1 0.1 0 0 1 0.028 0.069v7.643a0.1 0.1 0 0 1-0.028 0.069l-1.216 1.216a0.1 0.1 0 0 1-0.068 0.028h-0.892a0.1 0.1 0 0 1 0-0.193h0.852l1.159-1.16v-7.6a0.1 0.1 0 0 1 0.165-0.069z" data-name="Path 570" />
            <path className="path-caption--magent" d="m171.9 222.7a0.1 0.1 0 0 1 0.028 0.069 0.1 0.1 0 0 1-0.1 0.1h-16.53a0.1 0.1 0 0 1-0.069-0.028l-5.184-5.184a0.1 0.1 0 0 1 0.137-0.136l5.155 5.155h16.49a0.1 0.1 0 0 1 0.073 0.024z" data-name="Path 571" />
            <path className="path-caption--magent" d="m191.8 189.8 1.423 1.424a0.1 0.1 0 0 1-0.137 0.137l-1.4-1.395h-11.21v18.88a0.1 0.1 0 0 1-0.028 0.068l-2.393 2.393v2.631a0.1 0.1 0 0 1-0.194 0v-2.67a0.1 0.1 0 0 1 0.029-0.068l2.393-2.393v-18.94a0.1 0.1 0 0 1 0.028-0.069 0.1 0.1 0 0 1 0.069-0.028h11.34a0.1 0.1 0 0 1 0.073 0.029z" data-name="Path 572" />
            <path className="path-caption--magent" d="m190.7 188.1a0.1 0.1 0 0 1 0.028 0.068 0.1 0.1 0 0 1-0.1 0.1h-9.853a0.1 0.1 0 1 1 0-0.193h9.853a0.1 0.1 0 0 1 0.072 0.025z" data-name="Path 573" />
            <path className="path-caption--magent" d="m197 178.1 4.387 4.387a0.1 0.1 0 0 1 0.028 0.069v5.664a0.1 0.1 0 1 1-0.193 0v-5.624l-4.36-4.359a0.1 0.1 0 0 1 0-0.137 0.1 0.1 0 0 1 0.138 0z" data-name="Path 574" />
            <path className="path-caption--magent" d="m220.3 207.5 2.039 2.04a0.1 0.1 0 0 1 0 0.135l-0.557 0.587a0.1 0.1 0 0 1-0.069 0.03l-9.406 0.1a0.1 0.1 0 0 1-0.07-0.028l-2.333-2.333a0.1 0.1 0 0 1 0-0.137l1.409-1.409a0.1 0.1 0 0 1 0.069-0.028h6.163a0.1 0.1 0 0 1 0.069 0.028l0.984 0.984h1.633a0.1 0.1 0 0 1 0.069 0.031zm1.836 2.11-1.944-1.945h-1.633a0.1 0.1 0 0 1-0.069-0.028l-0.984-0.984h-6.083l-1.312 1.312 2.235 2.235 9.325-0.1z" data-name="Path 575" />
            <path className="path-caption--magent" d="m207.8 196.1 1.331 1.332a0.1 0.1 0 0 1 0.028 0.068v10.79l2.6 2.6h4.432l0.808-0.808a0.1 0.1 0 0 1 0.137 0 0.1 0.1 0 0 1 0 0.137l-0.836 0.836a0.1 0.1 0 0 1-0.069 0.028h-4.512a0.1 0.1 0 0 1-0.068-0.028l-2.652-2.652a0.1 0.1 0 0 1-0.029-0.069v-10.79l-1.275-1.275h-0.62a0.1 0.1 0 1 1 0-0.193h0.66a0.1 0.1 0 0 1 0.065 0.023z" data-name="Path 576" />
            <path className="path-caption--magent" d="m209.9 184.3a0.1 0.1 0 0 1 0 0.137l-3.5 3.5a0.1 0.1 0 0 1-0.068 0.028h-2.5l-0.675 0.674v1.03h2.334a0.1 0.1 0 0 1 0.1 0.1v5.03l2.26 2.261a0.1 0.1 0 0 1 0.028 0.069v10.65a0.1 0.1 0 1 1-0.194 0v-10.61l-2.261-2.261a0.1 0.1 0 0 1-0.028-0.068v-4.974h-2.337a0.1 0.1 0 0 1-0.1-0.1v-1.167a0.1 0.1 0 0 1 0.028-0.068l0.731-0.731a0.1 0.1 0 0 1 0.069-0.028h2.5l3.469-3.469a0.1 0.1 0 0 1 0.144 0z" data-name="Path 577" />
            <path className="path-caption--magent" d="m218.8 183.6 3.124 3.124a0.1 0.1 0 0 1 0 0.137l-2.948 2.948a0.1 0.1 0 0 1-0.068 0.028h-0.87l-5.122 5.122a0.1 0.1 0 0 1-0.07 0.028l0.583 0.583a0.1 0.1 0 0 1-0.137 0.137l-2.026-2.026h-3.364a0.1 0.1 0 0 1-0.069-0.029l-0.6-0.6a0.1 0.1 0 0 1-0.028-0.069v-4.446a0.1 0.1 0 0 1 0.028-0.069l3.455-3.456a0.1 0.1 0 0 1 0.069-0.028h5.352l1.384-1.384a0.1 0.1 0 0 1 0.069-0.029h1.17a0.1 0.1 0 0 1 0.068 0.029zm2.918 3.192-3.027-3.027h-1.09l-1.384 1.384a0.1 0.1 0 0 1-0.069 0.028h-5.352l-3.4 3.4v4.366l0.545 0.545h3.364a0.1 0.1 0 0 1 0.068 0.029l1.375 1.375a0.1 0.1 0 0 1 0.028-0.07l5.151-5.151a0.1 0.1 0 0 1 0.068-0.028h0.87z" data-name="Path 578" />
            <path className="path-caption--magent" d="m220.5 185.7a0.1 0.1 0 0 1 0 0.137l-4.2 4.2a0.1 0.1 0 0 1-0.069 0.028h-6.3l-1.16 1.16a0.094 0.094 0 0 1-0.068 0.028h-1.372a0.1 0.1 0 0 1 0-0.193h1.332l1.161-1.16a0.094 0.094 0 0 1 0.068-0.028h6.3l4.176-4.176a0.1 0.1 0 0 1 0.132 0z" data-name="Path 579" />
            <path className="path-caption--magent" d="m214.6 185a0.1 0.1 0 0 1 0 0.137l-2.7 2.7a0.1 0.1 0 0 1-0.068 0.029h-2.542a0.1 0.1 0 0 1-0.068-0.029l-0.589-0.589a0.1 0.1 0 0 1 0.137-0.137l0.561 0.56h2.462l2.668-2.668a0.1 0.1 0 0 1 0.139 0z" data-name="Path 580" />
            <path className="path-caption--magent" d="m219 190a0.1 0.1 0 0 1 0 0.137l-5.517 5.518a0.1 0.1 0 0 1-0.136-0.137l5.517-5.517a0.1 0.1 0 0 1 0.136-1e-3z" data-name="Path 581" />
            <path className="path-caption--magent" d="m140.9 207.1a0.287 0.287 0 0 0 0.209 0.087 0.29 0.29 0 0 0 0.288-0.293l-0.2-21.21 10.21-10.22a0.288 0.288 0 0 0 0.085-0.205v-17.08a0.2905 0.2905 0 1 0-0.581 0v16.96l-10.22 10.22a0.292 0.292 0 0 0-0.085 0.208l0.205 21.34a0.289 0.289 0 0 0 0.086 0.202z" data-name="Path 626" />
            <path className="path-caption--magent" d="m149.1 179.9a0.29 0.29 0 0 0 0.415 0l2.8-2.8a0.291 0.291 0 0 0-0.408-0.415l-2.807 2.806a0.291 0.291 0 0 0 0 0.409z" data-name="Path 627" />
            <path className="path-caption--magent" d="m128 213.5 12.3 12.3a0.29 0.29 0 0 0 0.411 0l1.453-1.453a0.291 0.291 0 0 0 0.085-0.205v-11.93a0.29 0.29 0 0 0-0.084-0.2l-6.025-6.036a0.291 0.291 0 0 0-0.21-0.085h-9.199a0.291 0.291 0 0 0 0 0.581h9.079l5.861 5.86v11.69l-1.161 1.162-12.1-12.09a0.291 0.291 0 0 0-0.411 0.411z" data-name="Path 628" />
            <path className="path-caption--magent" d="m123.9 190.4 2.766 2.766a0.2906 0.2906 0 0 0 0.411-0.411l-2.681-2.681v-4.454h13.56a0.292 0.292 0 0 0 0.206-0.085l9.69-9.686a0.2906 0.2906 0 0 0-0.411-0.411l-9.605 9.601h-13.74a0.29 0.29 0 0 0-0.29 0.291v4.864a0.292 0.292 0 0 0 0.085 0.206z" data-name="Path 629" />
            <path className="path-caption--magent" d="m126.4 203.8v-10.98a0.291 0.291 0 0 1 0.29-0.29h10.98a0.3 0.3 0 0 1 0.291 0.29v10.98a0.291 0.291 0 0 1-0.291 0.29h-10.98a0.291 0.291 0 0 1-0.29-0.29zm10.98-10.69h-10.4v10.4h10.4z" data-name="Path 630" />
            <path className="path-caption--magent" d="m109.4 195.8 3.815 3.811a0.29 0.29 0 0 0 0.206 0.085h12.74a0.2905 0.2905 0 1 0 0-0.581h-12.62l-3.727-3.73a0.2934 0.2934 0 0 0-0.415 0.415z" data-name="Path 631" />
            <path className="path-caption--magent" d="m133.7 175.2a0.29 0.29 0 0 0 0.415 0l1.7-1.7a0.29 0.29 0 0 0 0.083-0.2v-2.91a0.2915 0.2915 0 0 0-0.583 0v2.786l-1.615 1.617a0.29 0.29 0 0 0 0 0.407z" data-name="Path 632" />
            <path className="path-caption--magent" d="m126.8 183.3a0.292 0.292 0 0 0 0.205 0.081h7.4a0.2905 0.2905 0 0 0 0-0.581h-7.4a0.291 0.291 0 0 0-0.205 0.5z" data-name="Path 633" />
            <path className="path-caption--magent" d="m125.5 178.3 2.14-2.141a0.1 0.1 0 0 1 0.068-0.028h6.6l2.263-2.265v-7.507a0.1 0.1 0 0 1 0.028-0.069l6.5-6.5a0.1 0.1 0 0 1 0.072-0.031h4.585a0.1 0.1 0 0 1 0.069 0.029l1.589 1.591a0.1 0.1 0 0 1 0.028 0.069v11.51a0.1 0.1 0 0 1-0.028 0.069l-0.968 0.968a0.1 0.1 0 0 1-0.068 0.029h-1.807l-3.21 3.207a0.1 0.1 0 0 1-0.069 0.028l-2.41-0.015-2.165 2.165a0.1 0.1 0 0 1-0.069 0.028h-12.11a0.1 0.1 0 0 1-0.071-0.027l-0.974-0.974a0.1 0.1 0 0 1 3e-3 -0.137zm13.11 0.946 2.163-2.165a0.1 0.1 0 0 1 0.069-0.028l2.41 0.014 3.209-3.208a0.1 0.1 0 0 1 0.069-0.028h1.809l0.911-0.912v-11.43l-1.535-1.535h-4.505l-6.444 6.444v7.51a0.1 0.1 0 0 1-0.028 0.069l-2.323 2.321a0.1 0.1 0 0 1-0.068 0.028h-6.6l-2.04 2.044 0.878 0.877z" data-name="Path 634" />
            <path className="path-caption--magent" d="m134.9 179.3a0.1 0.1 0 0 0 0.137 0l3.182-3.181a0.1 0.1 0 0 0 0.028-0.068v-6.317l3.1-3.1h4.128l0.776 0.776v4.4l-1.912 1.912a0.1 0.1 0 0 0-0.028 0.068v2.334a0.1 0.1 0 1 0 0.194 0v-2.29l1.916-1.916a0.1 0.1 0 0 0 0.028-0.068v-4.487a0.1 0.1 0 0 0-0.028-0.069l-0.834-0.832a0.094 0.094 0 0 0-0.068-0.028h-4.208a0.1 0.1 0 0 0-0.069 0.028l-3.16 3.161a0.1 0.1 0 0 0-0.028 0.069v6.319l-3.154 3.153a0.1 0.1 0 0 0 0 0.136z" data-name="Path 635" />
            <path className="path-caption--magent" d="m127 177a0.1 0.1 0 0 0 0.072 0.027h7.64a0.1 0.1 0 0 0 0.069-0.029l1.216-1.214a0.1 0.1 0 0 0 0.028-0.069v-0.888a0.1 0.1 0 1 0-0.193 0v0.848l-1.16 1.16h-7.6a0.1 0.1 0 0 0-0.1 0.1 0.1 0.1 0 0 0 0.028 0.065z" data-name="Path 636" />
            <path className="path-caption--magent" d="m152.7 172.5a0.1 0.1 0 0 0 0.165-0.068v-16.53a0.1 0.1 0 0 0-0.028-0.068l-5.184-5.184a0.1 0.1 0 0 0-0.136 0.137l5.155 5.155v16.49a0.1 0.1 0 0 0 0.028 0.069z" data-name="Path 637" />
            <path className="path-caption--magent" d="m119.8 192.3 1.423 1.423a0.1 0.1 0 0 0 0.137-0.137l-1.395-1.4v-11.2h18.88a0.1 0.1 0 0 0 0.069-0.028l2.393-2.394h2.631a0.1 0.1 0 1 0 0-0.193h-2.672a0.1 0.1 0 0 0-0.068 0.028l-2.393 2.393h-18.93a0.1 0.1 0 0 0-0.068 0.028 0.1 0.1 0 0 0-0.032 0.069v11.34a0.1 0.1 0 0 0 0.028 0.069z" data-name="Path 638" />
            <path className="path-caption--magent" d="m118.1 191.2a0.1 0.1 0 0 0 0.069 0.028 0.1 0.1 0 0 0 0.1-0.1v-9.847a0.1 0.1 0 1 0-0.197 0v9.851a0.1 0.1 0 0 0 0.028 0.068z" data-name="Path 639" />
            <path className="path-caption--magent" d="m108.1 197.5 4.388 4.387a0.1 0.1 0 0 0 0.069 0.028h5.664a0.1 0.1 0 1 0 0-0.193h-5.624l-4.359-4.36a0.1 0.1 0 1 0-0.138 0.138z" data-name="Path 640" />
            <path className="path-caption--magent" d="m137.5 220.8v-1.632l-0.985-0.985a0.1 0.1 0 0 1-0.028-0.069v-6.162a0.1 0.1 0 0 1 0.028-0.069l1.409-1.408a0.1 0.1 0 0 1 0.137 0l2.333 2.333a0.1 0.1 0 0 1 0.028 0.07l-0.1 9.406a0.1 0.1 0 0 1-0.03 0.069l-0.586 0.557a0.1 0.1 0 0 1-0.135 0l-2.04-2.04a0.1 0.1 0 0 1-0.031-0.07zm2.627 1.44 0.1-9.324-2.234-2.236-1.312 1.312v6.082l0.984 0.984a0.1 0.1 0 0 1 0.028 0.068v1.632l1.945 1.945z" data-name="Path 641" />
            <path className="path-caption--magent" d="m126.1 208.4 1.332 1.331a0.1 0.1 0 0 0 0.068 0.028h10.79l2.6 2.6v4.428l-0.808 0.809a0.1 0.1 0 0 0 0.137 0.137l0.836-0.837a0.1 0.1 0 0 0 0.028-0.069v-4.512a0.1 0.1 0 0 0-0.028-0.069l-2.656-2.652a0.1 0.1 0 0 0-0.069-0.028h-10.79l-1.275-1.275v-0.617a0.1 0.1 0 0 0-0.194 0v0.66a0.1 0.1 0 0 0 0.028 0.066z" data-name="Path 642" />
            <path className="path-caption--magent" d="m114.3 210.5a0.1 0.1 0 0 0 0.137 0l3.5-3.5a0.1 0.1 0 0 0 0.029-0.069v-2.5l0.671-0.674h1.03v2.333a0.1 0.1 0 0 0 0.1 0.1h5.03l2.26 2.26a0.1 0.1 0 0 0 0.068 0.028h10.65a0.1 0.1 0 1 0 0-0.193h-10.61l-2.261-2.263a0.094 0.094 0 0 0-0.068-0.029h-4.974v-2.33a0.1 0.1 0 0 0-0.1-0.1h-1.163a0.1 0.1 0 0 0-0.069 0.028l-0.73 0.731a0.1 0.1 0 0 0-0.026 0.069v2.5l-3.472 3.472a0.1 0.1 0 0 0 0 0.137z" data-name="Path 643" />
            <path className="path-caption--magent" d="m113.6 219.3v-1.17a0.1 0.1 0 0 1 0.028-0.069l1.384-1.384v-5.352a0.1 0.1 0 0 1 0.029-0.069l3.459-3.455a0.1 0.1 0 0 1 0.065-0.029h4.446a0.1 0.1 0 0 1 0.069 0.029l0.6 0.6a0.1 0.1 0 0 1 0.03 0.071v3.363l2.026 2.026a0.1 0.1 0 0 1-0.136 0.137l-0.583-0.583a0.1 0.1 0 0 1-0.028 0.07l-5.124 5.121v0.87a0.1 0.1 0 0 1-0.029 0.069l-2.947 2.947a0.1 0.1 0 0 1-0.137 0l-3.124-3.12a0.1 0.1 0 0 1-0.028-0.072zm6.071 0.136v-0.87a0.1 0.1 0 0 1 0.028-0.069l5.151-5.15a0.1 0.1 0 0 1 0.069-0.028l-1.374-1.376a0.094 0.094 0 0 1-0.029-0.071v-3.361l-0.545-0.545h-4.366l-3.4 3.4v5.352a0.094 0.094 0 0 1-0.028 0.068l-1.384 1.386v1.09l3.027 3.027z" data-name="Path 644" />
            <path className="path-caption--magent" d="m115.7 221.1a0.1 0.1 0 0 0 0.136 0l4.2-4.2a0.1 0.1 0 0 0 0.036-0.07v-6.3l1.16-1.16a0.1 0.1 0 0 0 0.028-0.069v-1.371a0.1 0.1 0 1 0-0.193 0v1.329l-1.16 1.16a0.1 0.1 0 0 0-0.028 0.069v6.3l-4.179 4.175a0.1 0.1 0 0 0 0 0.137z" data-name="Path 645" />
            <path className="path-caption--magent" d="m115 215.1a0.1 0.1 0 0 0 0.137 0l2.7-2.7a0.1 0.1 0 0 0 0.028-0.069v-2.538a0.1 0.1 0 0 0-0.031-0.069l-0.59-0.589a0.1 0.1 0 0 0-0.136 0.137l0.56 0.56v2.463l-2.668 2.668a0.1 0.1 0 0 0 0 0.137z" data-name="Path 646" />
            <path className="path-caption--magent" d="m120 219.6a0.1 0.1 0 0 0 0.137 0l5.518-5.517a0.1 0.1 0 0 0-0.138-0.137l-5.517 5.517a0.1 0.1 0 0 0 0 0.137z" data-name="Path 647" />
            <path className="path-caption--magent" d="m90.76 157a0.29 0.29 0 0 1 0.208-0.5l21.21 0.2 10.22-10.21a0.289 0.289 0 0 1 0.205-0.085h17.08a0.2905 0.2905 0 1 1 0 0.581h-16.96l-10.22 10.22a0.293 0.293 0 0 1-0.209 0.085l-21.34-0.208a0.292 0.292 0 0 1-0.203-0.085z" data-name="Path 648" />
            <path className="path-caption--magent" d="m118 148.7a0.29 0.29 0 0 1 0-0.411l2.807-2.804a0.291 0.291 0 0 1 0.408 0.415l-2.8 2.8a0.291 0.291 0 0 1-0.415 0z" data-name="Path 649" />
            <path className="path-caption--magent" d="m84.39 169.8-12.3-12.3a0.29 0.29 0 0 1 0-0.411l1.452-1.453a0.29 0.29 0 0 1 0.205-0.085h11.93a0.29 0.29 0 0 1 0.206 0.085l6.031 6.031a0.29 0.29 0 0 1 0.09 0.206v9.2a0.291 0.291 0 0 1-0.582 0v-9.079l-5.865-5.861h-11.69l-1.164 1.161 12.1 12.1a0.291 0.291 0 0 1-0.411 0.411z" data-name="Path 650" />
            <path className="path-caption--magent" d="m107.5 174-2.766-2.766a0.2903 0.2903 0 0 1 0.411-0.41l2.681 2.68h4.453v-13.56a0.29 0.29 0 0 1 0.085-0.206l9.691-9.69a0.292 0.292 0 1 1 0.411 0.415l-9.606 9.6v13.74a0.29 0.29 0 0 1-0.291 0.291h-4.863a0.291 0.291 0 0 1-0.206-0.086z" data-name="Path 651" />
            <path className="path-caption--magent" d="m93.84 171.4a0.29 0.29 0 0 1-0.086-0.205v-10.98a0.291 0.291 0 0 1 0.291-0.29h10.98a0.3 0.3 0 0 1 0.29 0.29v10.98a0.291 0.291 0 0 1-0.29 0.291h-10.98a0.289 0.289 0 0 1-0.205-0.086zm0.495-10.89v10.4h10.4v-10.4z" data-name="Path 652" />
            <path className="path-caption--magent" d="m102.1 188.4-3.815-3.815a0.289 0.289 0 0 1-0.085-0.205v-12.74a0.2905 0.2905 0 1 1 0.581 0v12.62l3.73 3.73a0.291 0.291 0 1 1-0.411 0.411z" data-name="Path 653" />
            <path className="path-caption--magent" d="m122.6 164.2a0.29 0.29 0 0 1 0-0.411l1.7-1.7a0.291 0.291 0 0 1 0.208-0.089h2.907a0.291 0.291 0 0 1 0 0.581h-2.787l-1.613 1.619a0.29 0.29 0 0 1-0.415 0z" data-name="Path 654" />
            <path className="path-caption--magent" d="m114.6 171.1a0.292 0.292 0 0 1-0.085-0.206v-7.395a0.291 0.291 0 0 1 0.582 0v7.4a0.291 0.291 0 0 1-0.5 0.206z" data-name="Path 655" />
            <path className="path-caption--magent" d="m119.5 172.4-0.974-0.974a0.1 0.1 0 0 1-0.026-0.069v-12.11a0.1 0.1 0 0 1 0.028-0.069l2.166-2.164-0.015-2.41a0.1 0.1 0 0 1 0.028-0.069l3.208-3.208v-1.809a0.1 0.1 0 0 1 0.028-0.065l0.969-0.972a0.1 0.1 0 0 1 0.068-0.028h11.51a0.1 0.1 0 0 1 0.069 0.028l1.592 1.592a0.1 0.1 0 0 1 0.028 0.069v4.586a0.1 0.1 0 0 1-0.028 0.069l-6.5 6.5a0.1 0.1 0 0 1-0.068 0.028h-7.511l-2.266 2.266v6.6a0.1 0.1 0 0 1-0.028 0.068l-2.14 2.14a0.1 0.1 0 0 1-0.14-3e-3zm-0.806-1.083 0.877 0.878 2.044-2.044v-6.594a0.1 0.1 0 0 1 0.028-0.069l2.322-2.322a0.1 0.1 0 0 1 0.069-0.028h7.509l6.444-6.444v-4.508l-1.535-1.535h-11.43l-0.912 0.912v1.808a0.1 0.1 0 0 1-0.028 0.069l-3.207 3.207 0.015 2.41a0.1 0.1 0 0 1-0.029 0.069l-2.165 2.165z" data-name="Path 656" />
            <path className="path-caption--magent" d="m118.5 163a0.1 0.1 0 0 1 0-0.137l3.182-3.182a0.1 0.1 0 0 1 0.068-0.029h6.322l3.1-3.1v-4.131l-0.775-0.776h-4.4l-1.918 1.916a0.1 0.1 0 0 1-0.068 0.029h-2.334a0.1 0.1 0 1 1 0-0.193h2.295l1.914-1.917a0.1 0.1 0 0 1 0.068-0.028h4.483a0.1 0.1 0 0 1 0.068 0.028l0.833 0.833a0.094 0.094 0 0 1 0.028 0.068v4.208a0.1 0.1 0 0 1-0.028 0.069l-3.16 3.16a0.1 0.1 0 0 1-0.069 0.028h-6.319l-3.153 3.154a0.1 0.1 0 0 1-0.137 0z" data-name="Path 657" />
            <path className="path-caption--magent" d="m120.9 170.9a0.1 0.1 0 0 1-0.028-0.069v-7.643a0.1 0.1 0 0 1 0.028-0.068l1.216-1.217a0.1 0.1 0 0 1 0.069-0.028h0.887a0.1 0.1 0 1 1 0 0.193h-0.847l-1.16 1.16v7.6a0.1 0.1 0 0 1-0.1 0.1 0.1 0.1 0 0 1-0.065-0.028z" data-name="Path 658" />
            <path className="path-caption--magent" d="m125.4 145.2a0.1 0.1 0 0 1 0.071-0.165h16.53a0.1 0.1 0 0 1 0.07 0.028l5.183 5.184a0.1 0.1 0 1 1-0.136 0.137l-5.16-5.156h-16.49a0.1 0.1 0 0 1-0.071-0.028z" data-name="Path 659" />
            <path className="path-caption--magent" d="m105.6 178-1.423-1.423a0.1 0.1 0 0 1 0.137-0.137l1.4 1.395h11.2v-18.88a0.094 0.094 0 0 1 0.029-0.068l2.393-2.393v-2.628a0.1 0.1 0 1 1 0.193 0v2.67a0.094 0.094 0 0 1-0.029 0.069l-2.393 2.39v18.93a0.1 0.1 0 0 1-0.028 0.069 0.1 0.1 0 0 1-0.069 0.028h-11.34a0.1 0.1 0 0 1-0.068-0.029z" data-name="Path 660" />
            <path className="path-caption--magent" d="m106.6 179.7a0.1 0.1 0 0 1-0.028-0.065 0.1 0.1 0 0 1 0.1-0.1h9.85a0.1 0.1 0 1 1 0 0.193h-9.85a0.1 0.1 0 0 1-0.072-0.028z" data-name="Path 661" />
            <path className="path-caption--magent" d="m100.3 189.8-4.387-4.387a0.1 0.1 0 0 1-0.029-0.069v-5.661a0.1 0.1 0 0 1 0.194 0v5.621l4.359 4.359a0.1 0.1 0 0 1-0.137 0.137z" data-name="Path 662" />
            <path className="path-caption--magent" d="m76.98 160.4-2.04-2.045a0.1 0.1 0 0 1 0-0.136l0.556-0.586a0.1 0.1 0 0 1 0.069-0.033l9.406-0.1a0.1 0.1 0 0 1 0.07 0.028l2.333 2.333a0.1 0.1 0 0 1 0 0.137l-1.409 1.41a0.1 0.1 0 0 1-0.068 0.028h-6.163a0.1 0.1 0 0 1-0.069-0.028l-0.984-0.985h-1.632a0.1 0.1 0 0 1-0.069-0.023zm-1.836-2.115 1.945 1.945h1.632a0.1 0.1 0 0 1 0.069 0.028l0.984 0.984h6.083l1.312-1.312-2.236-2.235-9.321 0.105z" data-name="Path 663" />
            <path className="path-caption--magent" d="m89.51 171.7-1.332-1.332a0.1 0.1 0 0 1-0.03-0.069v-10.79l-2.6-2.6h-4.426l-0.808 0.811a0.1 0.1 0 0 1-0.137-0.135l0.836-0.837a0.094 0.094 0 0 1 0.069-0.028h4.512a0.1 0.1 0 0 1 0.068 0.028l2.653 2.652a0.1 0.1 0 0 1 0.028 0.069v10.79l1.275 1.275h0.62a0.1 0.1 0 1 1 0 0.193h-0.66a0.1 0.1 0 0 1-0.068-0.027z" data-name="Path 664" />
            <path className="path-caption--magent" d="m87.39 183.5a0.1 0.1 0 0 1 0-0.137l3.5-3.5a0.1 0.1 0 0 1 0.069-0.028h2.502l0.674-0.674v-1.03h-2.336a0.1 0.1 0 0 1-0.1-0.1v-5.024l-2.257-2.261a0.1 0.1 0 0 1-0.028-0.069v-10.65a0.1 0.1 0 1 1 0.194 0v10.61l2.26 2.261a0.1 0.1 0 0 1 0.028 0.068v4.974h2.333a0.1 0.1 0 0 1 0.1 0.1v1.167a0.1 0.1 0 0 1-0.028 0.068l-0.731 0.731a0.1 0.1 0 0 1-0.068 0.028h-2.5l-3.476 3.466a0.1 0.1 0 0 1-0.136 0z" data-name="Path 665" />
            <path className="path-caption--magent" d="m78.5 184.3-3.123-3.124a0.1 0.1 0 0 1 0-0.137l2.947-2.948a0.1 0.1 0 0 1 0.069-0.027h0.87l5.122-5.123a0.1 0.1 0 0 1 0.07-0.028l-0.584-0.584a0.1 0.1 0 0 1 0.137-0.137l2.026 2.026h3.364a0.1 0.1 0 0 1 0.068 0.028l0.6 0.6a0.1 0.1 0 0 1 0.028 0.068v4.45a0.1 0.1 0 0 1-0.028 0.068l-3.456 3.456a0.1 0.1 0 0 1-0.069 0.028h-5.35l-1.384 1.384a0.1 0.1 0 0 1-0.068 0.028h-1.17a0.1 0.1 0 0 1-0.069-0.028zm-2.918-3.193 3.027 3.027h1.09l1.384-1.384a0.1 0.1 0 0 1 0.068-0.029h5.352l3.4-3.4v-4.364l-0.545-0.545h-3.365a0.094 0.094 0 0 1-0.068-0.028l-1.377-1.376a0.1 0.1 0 0 1-0.028 0.07l-5.149 5.151a0.1 0.1 0 0 1-0.068 0.028h-0.87z" data-name="Path 666" />
            <path className="path-caption--magent" d="m76.82 182.2a0.1 0.1 0 0 1 0-0.136l4.2-4.2a0.1 0.1 0 0 1 0.071-0.034h6.3l1.16-1.16a0.1 0.1 0 0 1 0.069-0.028h1.371a0.1 0.1 0 1 1 0 0.193h-1.331l-1.16 1.16a0.1 0.1 0 0 1-0.068 0.028h-6.3l-4.175 4.177a0.1 0.1 0 0 1-0.137 0z" data-name="Path 667" />
            <path className="path-caption--magent" d="m82.75 182.9a0.1 0.1 0 0 1 0-0.137l2.7-2.7a0.1 0.1 0 0 1 0.065-0.025h2.542a0.1 0.1 0 0 1 0.064 0.028l0.589 0.589a0.1 0.1 0 1 1-0.137 0.137l-0.552-0.56h-2.467l-2.667 2.668a0.1 0.1 0 0 1-0.137 0z" data-name="Path 668" />
            <path className="path-caption--magent" d="m78.29 177.9a0.1 0.1 0 0 1 0-0.136l5.517-5.518a0.1 0.1 0 0 1 0.137 0.137l-5.518 5.517a0.1 0.1 0 0 1-0.136 0z" data-name="Path 669" />
            <path className="path-caption--magent" d="m210.5 276.5a0.291 0.291 0 0 0 0.5-0.209l-0.2-21.21 10.21-10.21a0.289 0.289 0 0 0 0.085-0.206v-17.08a0.2905 0.2905 0 1 0-0.581 0v16.96l-10.22 10.22a0.289 0.289 0 0 0-0.085 0.208l0.206 21.34a0.289 0.289 0 0 0 0.085 0.203z" data-name="Path 670" />
            <path className="path-caption--magent" d="m218.8 249.3a0.291 0.291 0 0 0 0.415 0l2.8-2.8a0.291 0.291 0 0 0-0.408-0.415l-2.807 2.807a0.29 0.29 0 0 0 0 0.408z" data-name="Path 671" />
            <path className="path-caption--magent" d="m197.7 282.9 12.3 12.3a0.29 0.29 0 0 0 0.411 0l1.453-1.452a0.291 0.291 0 0 0 0.085-0.206v-11.93a0.291 0.291 0 0 0-0.085-0.206l-6.024-6.03a0.292 0.292 0 0 0-0.209-0.085h-9.2a0.2905 0.2905 0 1 0 0 0.581h9.08l5.861 5.861v11.69l-1.162 1.162-12.1-12.1a0.2906 0.2906 0 1 0-0.411 0.411z" data-name="Path 672" />
            <path className="path-caption--magent" d="m193.5 259.8 2.766 2.766a0.2906 0.2906 0 0 0 0.411-0.411l-2.681-2.681v-4.454h13.56a0.29 0.29 0 0 0 0.205-0.085l9.691-9.686a0.291 0.291 0 0 0-0.411-0.411l-9.605 9.6h-13.74a0.29 0.29 0 0 0-0.291 0.292v4.865a0.289 0.289 0 0 0 0.085 0.205z" data-name="Path 673" />
            <path className="path-caption--magent" d="m196.1 273.3v-10.98a0.29 0.29 0 0 1 0.29-0.291h10.98a0.3 0.3 0 0 1 0.291 0.291v10.98a0.29 0.29 0 0 1-0.291 0.29h-10.98a0.289 0.289 0 0 1-0.29-0.29zm10.98-10.69h-10.4v10.4h10.4z" data-name="Path 674" />
            <path className="path-caption--magent" d="m179.1 265.2 3.815 3.811a0.29 0.29 0 0 0 0.206 0.085h12.74a0.291 0.291 0 0 0 0-0.581h-12.62l-3.727-3.73a0.2934 0.2934 0 0 0-0.415 0.415z" data-name="Path 675" />
            <path className="path-caption--magent" d="m203.3 244.7a0.29 0.29 0 0 0 0.415 0l1.7-1.7a0.29 0.29 0 0 0 0.084-0.2v-2.909a0.291 0.291 0 1 0-0.581 0v2.785l-1.618 1.617a0.29 0.29 0 0 0 0 0.407z" data-name="Path 676" />
            <path className="path-caption--magent" d="m196.4 252.7a0.287 0.287 0 0 0 0.205 0.081h7.4a0.2905 0.2905 0 1 0 0-0.581h-7.4a0.29 0.29 0 0 0-0.29 0.291 0.287 0.287 0 0 0 0.085 0.209z" data-name="Path 677" />
            <path className="path-caption--magent" d="m195.1 247.7 2.14-2.142a0.1 0.1 0 0 1 0.069-0.028h6.6l2.262-2.265v-7.507a0.1 0.1 0 0 1 0.029-0.068l6.5-6.5a0.1 0.1 0 0 1 0.071-0.032h4.586a0.1 0.1 0 0 1 0.068 0.028l1.592 1.592a0.1 0.1 0 0 1 0.029 0.068v11.51a0.1 0.1 0 0 1-0.029 0.068l-0.968 0.968a0.1 0.1 0 0 1-0.069 0.028h-1.809l-3.21 3.21a0.1 0.1 0 0 1-0.069 0.029l-2.41-0.015-2.166 2.165a0.1 0.1 0 0 1-0.069 0.028h-12.1a0.1 0.1 0 0 1-0.071-0.029l-0.974-0.974a0.1 0.1 0 0 1 3e-3 -0.136zm13.11 0.946 2.163-2.166a0.1 0.1 0 0 1 0.069-0.029l2.41 0.015 3.21-3.208a0.1 0.1 0 0 1 0.068-0.028h1.809l0.912-0.912v-11.43l-1.536-1.535h-4.505l-6.444 6.443v7.51a0.1 0.1 0 0 1-0.029 0.068l-2.322 2.323a0.1 0.1 0 0 1-0.069 0.028h-6.6l-2.039 2.044 0.877 0.878z" data-name="Path 678" />
            <path className="path-caption--magent" d="m204.5 248.8a0.1 0.1 0 0 0 0.136 0l3.182-3.182a0.1 0.1 0 0 0 0.029-0.069v-6.316l3.1-3.1h4.128l0.776 0.776v4.4l-1.913 1.913a0.1 0.1 0 0 0-0.029 0.068v2.333a0.1 0.1 0 0 0 0.194 0v-2.29l1.915-1.916a0.1 0.1 0 0 0 0.029-0.069v-4.485a0.1 0.1 0 0 0-0.029-0.069l-0.831-0.833a0.1 0.1 0 0 0-0.069-0.028h-4.208a0.1 0.1 0 0 0-0.068 0.028l-3.161 3.161a0.1 0.1 0 0 0-0.028 0.069v6.319l-3.153 3.153a0.1 0.1 0 0 0 0 0.137z" data-name="Path 679" />
            <path className="path-caption--magent" d="m196.7 246.4a0.1 0.1 0 0 0 0.072 0.027h7.64a0.1 0.1 0 0 0 0.068-0.028l1.217-1.216a0.1 0.1 0 0 0 0.028-0.068v-0.888a0.1 0.1 0 0 0-0.194 0v0.848l-1.159 1.159h-7.6a0.1 0.1 0 0 0-0.1 0.1 0.1 0.1 0 0 0 0.028 0.066z" data-name="Path 680" />
            <path className="path-caption--magent" d="m222.3 241.9a0.1 0.1 0 0 0 0.166-0.069v-16.53a0.1 0.1 0 0 0-0.028-0.069l-5.185-5.184a0.1 0.1 0 0 0-0.137 0.138l5.156 5.155v16.49a0.1 0.1 0 0 0 0.028 0.068z" data-name="Path 681" />
            <path className="path-caption--magent" d="m189.5 261.7 1.423 1.423a0.1 0.1 0 0 0 0.137-0.137l-1.395-1.395v-11.21h18.88a0.1 0.1 0 0 0 0.069-0.028l2.393-2.393h2.63a0.1 0.1 0 1 0 0-0.194h-2.67a0.1 0.1 0 0 0-0.069 0.028l-2.393 2.393h-18.93a0.1 0.1 0 0 0-0.068 0.028 0.1 0.1 0 0 0-0.032 0.069v11.34a0.1 0.1 0 0 0 0.028 0.069z" data-name="Path 682" />
            <path className="path-caption--magent" d="m187.8 260.7a0.1 0.1 0 0 0 0.068 0.028 0.1 0.1 0 0 0 0.1-0.1v-9.847a0.1 0.1 0 0 0-0.196 0v9.85a0.1 0.1 0 0 0 0.028 0.069z" data-name="Path 683" />
            <path className="path-caption--magent" d="m177.7 267 4.388 4.388a0.1 0.1 0 0 0 0.069 0.028h5.664a0.1 0.1 0 1 0 0-0.193h-5.624l-4.359-4.36a0.1 0.1 0 0 0-0.137 0 0.1 0.1 0 0 0-1e-3 0.137z" data-name="Path 684" />
            <path className="path-caption--magent" d="m207.1 290.2v-1.632l-0.985-0.985a0.1 0.1 0 0 1-0.028-0.068v-6.163a0.1 0.1 0 0 1 0.028-0.068l1.409-1.41a0.1 0.1 0 0 1 0.137 0l2.333 2.333a0.1 0.1 0 0 1 0.028 0.07l-0.1 9.406a0.1 0.1 0 0 1-0.03 0.069l-0.587 0.557a0.1 0.1 0 0 1-0.135 0l-2.042-2.04a0.1 0.1 0 0 1-0.028-0.069zm2.627 1.44 0.1-9.324-2.235-2.236-1.311 1.312v6.083l0.984 0.984a0.1 0.1 0 0 1 0.029 0.069v1.633l1.945 1.945z" data-name="Path 685" />
            <path className="path-caption--magent" d="m195.8 277.8 1.332 1.331a0.1 0.1 0 0 0 0.068 0.028h10.79l2.6 2.6v4.428l-0.808 0.808a0.1 0.1 0 0 0 0.137 0.137l0.837-0.836a0.1 0.1 0 0 0 0.028-0.069v-4.512a0.1 0.1 0 0 0-0.028-0.069l-2.658-2.652a0.1 0.1 0 0 0-0.068-0.028h-10.79l-1.275-1.275v-0.617a0.1 0.1 0 0 0-0.194 0v0.657a0.1 0.1 0 0 0 0.028 0.069z" data-name="Path 686" />
            <path className="path-caption--magent" d="m184 279.9a0.1 0.1 0 0 0 0.137 0l3.5-3.5a0.1 0.1 0 0 0 0.029-0.068v-2.502l0.671-0.674h1.03v2.334a0.1 0.1 0 0 0 0.1 0.1h5.03l2.261 2.261a0.1 0.1 0 0 0 0.069 0.028h10.64a0.1 0.1 0 0 0 0-0.194h-10.61l-2.261-2.263a0.1 0.1 0 0 0-0.069-0.028h-4.972v-2.331a0.1 0.1 0 0 0-0.1-0.1h-1.164a0.1 0.1 0 0 0-0.068 0.028l-0.731 0.731a0.1 0.1 0 0 0-0.026 0.068v2.5l-3.472 3.473a0.1 0.1 0 0 0 0 0.137z" data-name="Path 687" />
            <path className="path-caption--magent" d="m183.2 288.7v-1.17a0.1 0.1 0 0 1 0.028-0.068l1.385-1.39v-5.348a0.1 0.1 0 0 1 0.028-0.069l3.459-3.455a0.1 0.1 0 0 1 0.065-0.028h4.447a0.094 0.094 0 0 1 0.068 0.028l0.6 0.6a0.1 0.1 0 0 1 0.03 0.072v3.362l2.027 2.026a0.1 0.1 0 0 1 0 0.137 0.1 0.1 0 0 1-0.137 0l-0.583-0.583a0.1 0.1 0 0 1-0.028 0.07l-5.124 5.122v0.87a0.1 0.1 0 0 1-0.029 0.068l-2.947 2.948a0.1 0.1 0 0 1-0.137 0l-3.124-3.12a0.1 0.1 0 0 1-0.028-0.072zm6.072 0.136v-0.87a0.094 0.094 0 0 1 0.029-0.068l5.15-5.151a0.1 0.1 0 0 1 0.07-0.028l-1.376-1.376a0.1 0.1 0 0 1-0.029-0.071v-3.361l-0.545-0.546h-4.366l-3.4 3.4v5.352a0.1 0.1 0 0 1-0.028 0.069l-1.383 1.386v1.09l3.027 3.027z" data-name="Path 688" />
            <path className="path-caption--magent" d="m185.3 290.5a0.1 0.1 0 0 0 0.137 0l4.2-4.2a0.1 0.1 0 0 0 0.035-0.069v-6.3l1.161-1.16a0.1 0.1 0 0 0 0.028-0.068v-1.372a0.1 0.1 0 0 0-0.194 0v1.328l-1.163 1.161a0.1 0.1 0 0 0-0.028 0.068v6.3l-4.176 4.175a0.1 0.1 0 0 0 0 0.137z" data-name="Path 689" />
            <path className="path-caption--magent" d="m184.6 284.5a0.1 0.1 0 0 0 0.137 0l2.7-2.7a0.1 0.1 0 0 0 0.029-0.068v-2.538a0.1 0.1 0 0 0-0.032-0.069l-0.589-0.589a0.1 0.1 0 1 0-0.137 0.136l0.564 0.562v2.462l-2.672 2.668a0.1 0.1 0 0 0 0 0.136z" data-name="Path 690" />
            <path className="path-caption--magent" d="m189.6 289a0.1 0.1 0 0 0 0.137 0l5.517-5.518a0.1 0.1 0 0 0-0.136-0.137l-5.518 5.517a0.1 0.1 0 0 0 0 0.138z" data-name="Path 691" />
            <path className="path-caption--magent" d="m160.4 226.4a0.291 0.291 0 0 1 0.208-0.5l21.21 0.2 10.22-10.21a0.291 0.291 0 0 1 0.205-0.085h17.08a0.2905 0.2905 0 1 1 0 0.581h-16.96l-10.22 10.22a0.291 0.291 0 0 1-0.208 0.085l-21.34-0.208a0.289 0.289 0 0 1-0.203-0.085z" data-name="Path 692" />
            <path className="path-caption--magent" d="m187.6 218.2a0.291 0.291 0 0 1 0-0.411l2.806-2.804a0.2913 0.2913 0 0 1 0.409 0.415l-2.8 2.8a0.29 0.29 0 0 1-0.415 0z" data-name="Path 693" />
            <path className="path-caption--magent" d="m154 239.3-12.3-12.3a0.291 0.291 0 0 1 0-0.411l1.453-1.452a0.292 0.292 0 0 1 0.205-0.085h11.93a0.289 0.289 0 0 1 0.205 0.085l6.031 6.031a0.289 0.289 0 0 1 0.085 0.205v9.2a0.2905 0.2905 0 1 1-0.581 0v-9.079l-5.86-5.861h-11.69l-1.163 1.162 12.1 12.1a0.291 0.291 0 0 1-0.411 0.411z" data-name="Path 694" />
            <path className="path-caption--magent" d="m177.1 243.4-2.765-2.767a0.2917 0.2917 0 0 1 0.414-0.411l2.681 2.681h4.45v-13.56a0.29 0.29 0 0 1 0.085-0.205l9.69-9.69a0.2917 0.2917 0 1 1 0.411 0.414l-9.605 9.6v13.74a0.29 0.29 0 0 1-0.291 0.291h-4.864a0.293 0.293 0 0 1-0.206-0.085z" data-name="Path 695" />
            <path className="path-caption--magent" d="m163.5 240.8a0.292 0.292 0 0 1-0.085-0.206v-10.98a0.289 0.289 0 0 1 0.291-0.29h10.98a0.3 0.3 0 0 1 0.29 0.29v10.98a0.29 0.29 0 0 1-0.29 0.291h-10.98a0.293 0.293 0 0 1-0.206-0.085zm0.496-10.89v10.4h10.4v-10.4z" data-name="Path 696" />
            <path className="path-caption--magent" d="m171.7 257.9-3.815-3.815a0.289 0.289 0 0 1-0.085-0.205v-12.74a0.291 0.291 0 0 1 0.581 0v12.62l3.73 3.73a0.2906 0.2906 0 1 1-0.411 0.411z" data-name="Path 697" />
            <path className="path-caption--magent" d="m192.3 233.6a0.29 0.29 0 0 1 0-0.411l1.7-1.7a0.291 0.291 0 0 1 0.208-0.089h2.907a0.2905 0.2905 0 1 1 0 0.581h-2.787l-1.613 1.619a0.291 0.291 0 0 1-0.415 0z" data-name="Path 698" />
            <path className="path-caption--magent" d="m184.2 240.5a0.29 0.29 0 0 1-0.085-0.205v-7.395a0.2905 0.2905 0 1 1 0.581 0v7.4a0.291 0.291 0 0 1-0.5 0.205z" data-name="Path 699" />
            <path className="path-caption--magent" d="m189.1 241.8-0.974-0.974a0.1 0.1 0 0 1-0.026-0.069v-12.11a0.1 0.1 0 0 1 0.028-0.068l2.165-2.166-0.014-2.41a0.1 0.1 0 0 1 0.028-0.069l3.207-3.207v-1.809a0.1 0.1 0 0 1 0.029-0.065l0.968-0.971a0.1 0.1 0 0 1 0.069-0.029h11.51a0.1 0.1 0 0 1 0.069 0.028l1.592 1.592a0.1 0.1 0 0 1 0.028 0.068v4.587a0.1 0.1 0 0 1-0.028 0.069l-6.5 6.5a0.093 0.093 0 0 1-0.068 0.028h-7.512l-2.265 2.265v6.6a0.1 0.1 0 0 1-0.029 0.069l-2.14 2.137a0.1 0.1 0 0 1-0.139 0zm-0.807-1.083 0.877 0.877 2.044-2.044v-6.593a0.094 0.094 0 0 1 0.029-0.069l2.322-2.323a0.1 0.1 0 0 1 0.068-0.028h7.51l6.443-6.443v-4.508l-1.535-1.536h-11.43l-0.912 0.912v1.809a0.1 0.1 0 0 1-0.028 0.068l-3.207 3.208 0.014 2.41a0.1 0.1 0 0 1-0.028 0.069l-2.166 2.165z" data-name="Path 700" />
            <path className="path-caption--magent" d="m188.2 232.4a0.1 0.1 0 0 1 0-0.136l3.182-3.182a0.1 0.1 0 0 1 0.069-0.029h6.321l3.1-3.1v-4.131l-0.775-0.776h-4.4l-1.918 1.917a0.1 0.1 0 0 1-0.069 0.028h-2.333a0.1 0.1 0 1 1 0-0.193h2.295l1.914-1.917a0.1 0.1 0 0 1 0.069-0.028h4.483a0.1 0.1 0 0 1 0.068 0.028l0.832 0.833a0.1 0.1 0 0 1 0.029 0.069v4.207a0.1 0.1 0 0 1-0.029 0.068l-3.159 3.161a0.1 0.1 0 0 1-0.069 0.029h-6.319l-3.153 3.152a0.1 0.1 0 0 1-0.138 0z" data-name="Path 701" />
            <path className="path-caption--magent" d="m190.5 240.3a0.1 0.1 0 0 1-0.028-0.069v-7.643a0.1 0.1 0 0 1 0.028-0.068l1.216-1.217a0.1 0.1 0 0 1 0.069-0.028h0.887a0.1 0.1 0 1 1 0 0.193h-0.847l-1.159 1.16v7.6a0.1 0.1 0 0 1-0.1 0.1 0.1 0.1 0 0 1-0.066-0.028z" data-name="Path 702" />
            <path className="path-caption--magent" d="m195 214.6a0.1 0.1 0 0 1 0.072-0.165h16.53a0.1 0.1 0 0 1 0.073 0.028l5.184 5.184a0.1 0.1 0 0 1-0.137 0.137l-5.16-5.155h-16.49a0.1 0.1 0 0 1-0.072-0.029z" data-name="Path 703" />
            <path className="path-caption--magent" d="m175.2 247.5-1.423-1.423a0.1 0.1 0 0 1 0.137-0.137l1.4 1.4h11.2v-18.88a0.1 0.1 0 0 1 0.028-0.069l2.393-2.393v-2.627a0.1 0.1 0 1 1 0.194 0v2.67a0.1 0.1 0 0 1-0.028 0.068l-2.393 2.391v18.93a0.1 0.1 0 0 1-0.1 0.1h-11.34a0.1 0.1 0 0 1-0.069-0.032z" data-name="Path 704" />
            <path className="path-caption--magent" d="m176.3 249.2a0.1 0.1 0 0 1 0.071-0.166h9.85a0.1 0.1 0 1 1 0 0.194h-9.85a0.1 0.1 0 0 1-0.071-0.028z" data-name="Path 705" />
            <path className="path-caption--magent" d="m170 259.2-4.387-4.387a0.1 0.1 0 0 1-0.029-0.069v-5.661a0.1 0.1 0 1 1 0.194 0v5.621l4.359 4.359a0.1 0.1 0 1 1-0.137 0.137z" data-name="Path 706" />
            <path className="path-caption--magent" d="m146.6 229.8-2.039-2.04a0.1 0.1 0 0 1 0-0.135l0.555-0.587a0.1 0.1 0 0 1 0.07-0.033l9.406-0.1a0.1 0.1 0 0 1 0.069 0.028l2.333 2.333a0.1 0.1 0 0 1 0 0.137l-1.408 1.409a0.1 0.1 0 0 1-0.068 0.028h-6.164a0.1 0.1 0 0 1-0.068-0.028l-0.984-0.984h-1.634a0.1 0.1 0 0 1-0.068-0.028zm-1.836-2.111 1.945 1.946h1.633a0.1 0.1 0 0 1 0.068 0.028l0.984 0.985h6.083l1.312-1.313-2.236-2.236-9.321 0.106z" data-name="Path 707" />
            <path className="path-caption--magent" d="m159.1 241.2-1.332-1.332a0.1 0.1 0 0 1-0.029-0.068v-10.79l-2.6-2.6h-4.426l-0.809 0.81a0.1 0.1 0 0 1-0.136-0.135l0.836-0.837a0.1 0.1 0 0 1 0.068-0.028h4.513a0.094 0.094 0 0 1 0.068 0.028l2.652 2.653a0.094 0.094 0 0 1 0.029 0.068v10.79l1.275 1.275h0.62a0.1 0.1 0 1 1 0 0.193h-0.66a0.1 0.1 0 0 1-0.069-0.028z" data-name="Path 708" />
            <path className="path-caption--magent" d="m157 253a0.1 0.1 0 0 1 0-0.137l3.5-3.5a0.1 0.1 0 0 1 0.068-0.028h2.503l0.674-0.675v-1.027h-2.336a0.1 0.1 0 0 1-0.1-0.1v-5.03l-2.257-2.257a0.1 0.1 0 0 1-0.028-0.069v-10.65a0.1 0.1 0 0 1 0.194 0v10.61l2.261 2.261a0.1 0.1 0 0 1 0.028 0.068v4.973h2.333a0.1 0.1 0 0 1 0.1 0.1v1.166a0.1 0.1 0 0 1-0.029 0.069l-0.731 0.731a0.1 0.1 0 0 1-0.069 0.028h-2.5l-3.474 3.467a0.1 0.1 0 0 1-0.137 0z" data-name="Path 709" />
            <path className="path-caption--magent" d="m148.1 253.7-3.123-3.124a0.1 0.1 0 0 1 0-0.137l2.947-2.948a0.1 0.1 0 0 1 0.068-0.027h0.87l5.123-5.123a0.1 0.1 0 0 1 0.07-0.028l-0.584-0.583a0.1 0.1 0 0 1 0.137-0.138l2.026 2.026h3.364a0.1 0.1 0 0 1 0.069 0.029l0.6 0.6a0.1 0.1 0 0 1 0.028 0.069v4.448a0.094 0.094 0 0 1-0.028 0.068l-3.455 3.456a0.1 0.1 0 0 1-0.069 0.028h-5.351l-1.384 1.384a0.1 0.1 0 0 1-0.068 0.028h-1.171a0.1 0.1 0 0 1-0.069-0.028zm-2.918-3.193 3.027 3.027h1.09l1.384-1.384a0.1 0.1 0 0 1 0.068-0.028h5.353l3.4-3.4v-4.365l-0.545-0.545h-3.365a0.1 0.1 0 0 1-0.068-0.028l-1.378-1.375a0.1 0.1 0 0 1-0.028 0.069l-5.149 5.15a0.1 0.1 0 0 1-0.069 0.028h-0.87z" data-name="Path 710" />
            <path className="path-caption--magent" d="m146.5 251.6a0.1 0.1 0 0 1 0-0.137l4.2-4.2a0.1 0.1 0 0 1 0.071-0.033h6.3l1.161-1.16a0.1 0.1 0 0 1 0.068-0.028h1.371a0.1 0.1 0 1 1 0 0.194h-1.331l-1.16 1.16a0.1 0.1 0 0 1-0.068 0.029h-6.3l-4.176 4.175a0.1 0.1 0 0 1-0.136 0z" data-name="Path 711" />
            <path className="path-caption--magent" d="m152.4 252.3a0.1 0.1 0 0 1 0-0.137l2.7-2.7a0.1 0.1 0 0 1 0.065-0.025h2.542a0.1 0.1 0 0 1 0.065 0.028l0.589 0.589a0.1 0.1 0 0 1-0.137 0.137l-0.552-0.561h-2.467l-2.668 2.669a0.1 0.1 0 0 1-0.137 0z" data-name="Path 712" />
            <path className="path-caption--magent" d="m147.9 247.3a0.1 0.1 0 0 1 0-0.137l5.517-5.518a0.1 0.1 0 0 1 0.137 0.137l-5.518 5.518a0.1 0.1 0 0 1-0.136 0z" data-name="Path 713" />
        </svg>
    );
}


export default memo(DiagAircrafts);