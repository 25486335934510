import React from 'react';

import { useTranslation } from 'react-i18next';

import { Modal } from '../Element/Modal';

import SwitchButton from '../Element/SwitchButton';

import { cookieStorage } from '../../utils/storage';

import {
    COOKIE_CONSENT_PROPERTY_NAME
} from '../constants';

export const shouldShowConsent = () => !cookieStorage.getItem(COOKIE_CONSENT_PROPERTY_NAME);
const saveConsent = () => cookieStorage.setItem(COOKIE_CONSENT_PROPERTY_NAME, 'true', 365);

// cookie_consent true means this component won't render
export const Cookies: React.FC = () => {
    return (
        <div className="c-cookies" id="cookies">
            <CookiesPreferences />
            <CookiesConsent />
        </div>
    )
}

const mapPreferences = (index: number, t: any) => {
    return (
        <div>
            <p className="typo-p">{t(`cookies.preferences.${index}`)}</p>
            <SwitchButton />
        </div>
    );
}

// allows user modifie which cookies are active as well as info about them
export const CookiesPreferences: React.FC = () => {
    const { t } = useTranslation();
    return (
        <Modal id="cookiesPreferences" closeModal={closeModal}>
            <h3 className="typo-h3">Cookies preferences</h3>
            <div className="c-preferences">
                {[1, 2, 3].map(index => mapPreferences(index, t))}
            </div>
            <div id="cookiesPreferencesBtnContainer">
                <button onClick={savePreferences}>{t('cookies.btn.save')}</button>
                <button onClick={acceptAll}>{t('cookies.btn.all')}</button>
            </div>
        </Modal>
    )
}

// cookie polycy agreement, this only show up if user hasn't accept it
export const CookiesConsent: React.FC = () => {
    const { t } = useTranslation();
    return (
        <div className="c-cookies--consent" id="cookiesConsent">
            <div className="caption">
                <p className="typo-p">{t('cookies.consent.caption')}</p>
            </div>
            <div className="options">
                <button id="cookiesPreferencesBtn" onClick={showPreferences}>{t('cookies.btn.preferences')}</button>
                <button onClick={hideCookiesConsent}>{t('cookies.btn.accept')}</button>

            </div>
        </div>
    )
}

const closeModal = () => {
    const modal = document.getElementById('cookiesPreferences') as HTMLDivElement;
    modal.style.transform = 'translate(-50%, -50%) scale(0)';

    const cookiesConsent = document.querySelector('#cookies .c-cookies--consent') as HTMLDivElement;
    setTimeout(() => {
        cookiesConsent.style.animation = "fade-in-from-bottom 0.5s ease-in";
        cookiesConsent.style.animationFillMode = "forwards";
    }, 300);

}

const hideCookiesConsent = () => {
    // save users action
    saveConsent()

    // animates consent hid
    const cookiesContainer = document.getElementById("cookies") as HTMLDivElement;

    animateConsentHide();

    // wait for animation to end, and then hide the overlay
    setTimeout(() => {
        cookiesContainer.style.opacity = '0';
        cookiesContainer.style.visibility = 'hidden';
    }, 500);
}

const showPreferences = () => {
    const preferencesModal = document.getElementById('cookiesPreferences') as HTMLDivElement;
    animateConsentHide();

    preferencesModal.style.transform = 'translate(-50%, -50%) scale(1)';
}


const savePreferences = () => {
    saveConsent();
    animateCookiesHide();
}

// callback when user clicks 'accept all' button in preferences modal
const acceptAll = () => {
    saveConsent();
    animateCookiesHide();
}

// hide only the cookie consent
const animateConsentHide = () => {
    const cookiesConsent = document.querySelector('#cookies .c-cookies--consent') as HTMLDivElement;
    cookiesConsent.style.animation = "fade-out-bottom 0.5s ease-in";
    cookiesConsent.style.animationFillMode = 'forwards';
}


// hide cookies container and preferences
const animateCookiesHide = () => {
    const cookiesContainer = document.getElementById("cookies") as HTMLDivElement;
    const modal = document.getElementById('cookiesPreferences') as HTMLDivElement;

    modal.style.transform = 'translate(-50%, -50%) scale(0)';

    setTimeout(() => {
        cookiesContainer.style.opacity = '0';
        cookiesContainer.style.visibility = 'hidden';
    }, 400);
}

export default Cookies;