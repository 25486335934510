import React, { useEffect } from 'react';
// router components
import {
    BrowserRouter as Router,
    Routes,
    Route
} from 'react-router-dom';

// components
import SideMenu from './SideMenu/SideMenu';

import DashboardHome from './Views/DashboardHome';
import DashboardSettings from './Views/DashboardSettings';
import Profile from './Views/Profile';

export const Dashboard: React.FC<DashboardProps> = (props) => {
    useEffect(() => {
        document.title = "Rootrilla | Dashboard"
    }, []);

    return (
        <div className="wrapper" id="dshb">
            <SideMenu />
            <main>
                <Router>
                    <Routes>
                        <Route path='/dashboard' element={<DashboardHome />} />
                        <Route path='/dashboard/profile' element={<Profile></Profile>} />
                        <Route path='/dashboard/settings' element={<DashboardSettings></DashboardSettings>} />
                    </Routes>
                </Router>
                {/* simple and unsafe way to turn on/off dashboard app */}
                <div className={"overlay inner" + ("disabled" in props ? " active" : "")}></div>
            </main>
            <div className="overlay"></div>
        </div>
    );
}

export default Dashboard;