import React, { memo } from 'react';

export const QuadAirCraft: React.FC<ElementLikeProps> = (props) => {
    return (
        <svg className="svg-aircraft--quad" id={"id" in props ? props.id : ''} xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 700.2326 794.9352">
            <path className="path-caption--gray"
                d="M411.1028,303.8209a1.85,1.85,0,0,1,1.4533.6317,1.89,1.89,0,0,1,0,2.7374L303.0027,406.9987V504.07a1.66,1.66,0,0,1-.6707,1.3687l-87.4191,81.1736a2.2083,2.2083,0,0,1-2.9066-.4212,1.808,1.808,0,0,1,0-2.3162l86.7484-80.5419V406.1564a1.66,1.66,0,0,1,.6707-1.3686l110.1123-100.44A2.1718,2.1718,0,0,1,411.1028,303.8209Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M314.1816,472.2745a2.0647,2.0647,0,0,1,2.124,2v26.6367a2.1278,2.1278,0,0,1-4.248,0V474.2749a2.1378,2.1378,0,0,1,2.124-2Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M377.9014,212.54H503.776a2.0648,2.0648,0,0,1,2.124,2v13.7921a1.66,1.66,0,0,1-.6707,1.3687l-61.0369,56.7478a2.44,2.44,0,0,1-1.4533.5264H381.0315a2.1994,2.1994,0,0,1-1.4532-.5264L332.515,242.7564a1.92,1.92,0,0,1,.4472-2.7373,2.1125,2.1125,0,0,1,2.4594,0l46.3925,43.1662H441.733l59.8072-55.59V216.5408H377.9014a2.0647,2.0647,0,0,1-2.124-2,2.1383,2.1383,0,0,1,2.124-2Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M238.6122,302.3469h28.2826a2.0707,2.0707,0,0,1,2.2358,1.79,2.0217,2.0217,0,0,1-1.9,2.1057H239.5065l-22.805,21.162,69.421,64.5388a1.6594,1.6594,0,0,1,.6708,1.3687V485.33a2.1278,2.1278,0,0,1-4.248,0V394.0488l-70.2036-65.2757a1.89,1.89,0,0,1,0-2.7374l24.929-23.1624A1.7243,1.7243,0,0,1,238.6122,302.3469Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M321.7833,250.1263a2.2,2.2,0,0,1,1.4533.5264l56.1181,52.1153a1.8893,1.8893,0,0,1,0,2.7374l-56.1181,52.1154a2.1869,2.1869,0,0,1-2.9066,0l-56.1181-52.1154a1.8893,1.8893,0,0,1,0-2.7374L320.33,250.6527A2.2,2.2,0,0,1,321.7833,250.1263Zm53.2116,54.1157-53.2116-49.3779L268.5716,304.242l53.2117,49.378Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M192.22,208.3287h39.0144a2.1992,2.1992,0,0,1,1.4533.5265l65.173,60.538a1.92,1.92,0,0,1-.4471,2.7374,2.1125,2.1125,0,0,1-2.4594,0l-64.5024-59.9063h-38.12a2.0648,2.0648,0,0,1-2.124-2,2.0221,2.0221,0,0,1,2.0123-1.8952Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M211.3356,421.1067a2.0647,2.0647,0,0,1,2.124,2v16.2136a1.66,1.66,0,0,1-.6707,1.3687l-14.868,13.7921a2.208,2.208,0,0,1-2.9065.4212,1.92,1.92,0,0,1-.4472-2.7374c.1118-.2106.3354-.3158.4472-.4211l14.1972-13.2658V423.107a2.0648,2.0648,0,0,1,2.124-2Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M216.9251,350.1456a2.1994,2.1994,0,0,1,1.4532.5264l37.7848,35.1647a1.92,1.92,0,0,1-.4472,2.7374,2.1125,2.1125,0,0,1-2.4594,0l-37.7847-35.0594a1.89,1.89,0,0,1,0-2.7374,2.227,2.227,0,0,1,1.4533-.6317Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M185.4005,367.0963H195.35a.5929.5929,0,0,1,.4471.2106l61.9312,57.4847a.7812.7812,0,0,1,.2236.4212v20.53L270.36,457.1137a.7809.7809,0,0,1,.2236.4211v30.6375l9.2785,8.6333a.7806.7806,0,0,1,.2236.4211v9.16a.5376.5376,0,0,1-.2236.4211L220.95,561.5549a.869.869,0,0,1-.4472.2106H204.1811a.5928.5928,0,0,1-.4471-.2106l-23.4757-21.7937a.7806.7806,0,0,1-.2236-.4211V477.5386a.5375.5375,0,0,1,.2236-.4211l38.4554-35.6911V419.9486l-33.76-31.3745a.7809.7809,0,0,1-.2236-.4211v-20.32A.6541.6541,0,0,1,185.4005,367.0963Zm9.6139,1.3686h-8.9431v19.3722l33.76,31.3745a.7809.7809,0,0,1,.2236.4211v22.0042a.5377.5377,0,0,1-.2236.4212l-38.4555,35.6911v61.2749l23.0286,21.3726H220.167L278.6327,506.07v-8.6333l-9.2785-8.6332a.7812.7812,0,0,1-.2236-.4212V457.9558L256.722,446.6906a.7809.7809,0,0,1-.2236-.4211V425.6339Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M238.2768,407.3146a.7013.7013,0,0,1,.6708.6317h0v30.2164a.5376.5376,0,0,1-.2236.4211l-32.3071,30.111v29.4794l21.1281,19.5827h7.9371l22.47-20.8461v-18.214a.5373.5373,0,0,1,.2236-.4211l11.9614-11.16a.7368.7368,0,0,1,1.0061.1053.6125.6125,0,0,1,0,.8422l-11.7378,10.95v18.2141a.5376.5376,0,0,1-.2236.4211L236.2646,519.02a.869.869,0,0,1-.4472.2106h-8.4959a.5926.5926,0,0,1-.4472-.2106l-21.4635-20.0038a.7813.7813,0,0,1-.2236-.4211V468.59a.5377.5377,0,0,1,.2236-.4212l32.3071-30.0058V408.1568a.56.56,0,0,1,.5589-.8422Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M186.0713,381.2043a.5925.5925,0,0,1,.4471.2105l39.1262,36.3228a.7812.7812,0,0,1,.2236.4212V429.74a.5376.5376,0,0,1-.2236.4211l-4.5833,4.2113a.7368.7368,0,0,1-1.0061-.1053.6125.6125,0,0,1,0-.8422l4.36-4.0008V418.3693l-38.9026-36.1122a.75.75,0,0,1,0-.9475C185.7359,381.31,185.8477,381.2043,186.0713,381.2043Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M294.1714,524.39a.5928.5928,0,0,1,.4471.2106.75.75,0,0,1,0,.9476L210.1059,604.09a.8693.8693,0,0,1-.4471.21H156.6707a.7013.7013,0,0,1-.6707-.6316h0a.7013.7013,0,0,1,.6707-.6317h52.7645l84.4008-78.331A.3484.3484,0,0,1,294.1714,524.39Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M227.88,274.2362H242.413c.3354,0,.7826.2106.7826.5264a.7828.7828,0,0,1-.6708.737H228.104l-57.3478,53.2735,96.474,89.7015a.7809.7809,0,0,1,.2236.4211V441.637L280.98,454.1657a.65.65,0,0,1-.1118.9476.7122.7122,0,0,1-.8943,0l-13.6383-12.7394a.7806.7806,0,0,1-.2236-.4211V419.2116l-96.8094-90.0174a.7809.7809,0,0,1-.2236-.4211.5373.5373,0,0,1,.2236-.4211l58.0186-53.9052C227.4333,274.2362,227.6568,274.2362,227.88,274.2362Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M213.46,271.2883a.593.593,0,0,1,.4472.2106.7494.7494,0,0,1,0,.9475L163.49,319.2976a.8758.8758,0,0,1-1.0061,0,.75.75,0,0,1,0-.9476l50.4169-46.8511A.7488.7488,0,0,1,213.46,271.2883Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M194.3437,193.5891h44.8274a.5929.5929,0,0,1,.4472.21l28.9533,26.9526a.6623.6623,0,0,1-.2236.9475,1.0086,1.0086,0,0,1-.7825,0l-28.73-26.742H194.2318c-.3353,0-.7825-.21-.7825-.5264a.7829.7829,0,0,1,.6708-.737.3569.3569,0,0,1,.2236-.1052Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M464.4262,222.4367h20.9046a.7013.7013,0,0,1,.6707.6318l.1118,5.4747a.5375.5375,0,0,1-.2236.4211L438.2676,274.131a.87.87,0,0,1-.4472.2105H414.0093a.7013.7013,0,0,1-.6707-.6317v-13.371a.5375.5375,0,0,1,.2236-.4211l31.5245-29.2688a.869.869,0,0,1,.4472-.2106h10.061l8.2724-7.791A.8822.8822,0,0,1,464.4262,222.4367Zm20.1221,1.3687H464.65l-8.3842,7.791a.8687.8687,0,0,1-.4471.2106h-10.061L414.68,260.76v12.4235h22.9168l47.175-44.7455Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M342.0171,227.8062h13.6383a.593.593,0,0,1,.4472.2106l55.2238,51.273H437.82l22.6932-21.0567v-7.6856a.672.672,0,0,1,1.3415,0v7.8962a.5373.5373,0,0,1-.2236.4211l-23.14,21.4779a.8687.8687,0,0,1-.4471.2106H410.8792a.5928.5928,0,0,1-.4471-.2106L355.2083,229.07H342.2407l-3.13,2.948a.737.737,0,0,1-1.0061-.1053.6127.6127,0,0,1,0-.8423l3.3537-3.1585C341.6818,227.9115,341.7936,227.8062,342.0171,227.8062Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M292.6063,161.6882a.7013.7013,0,0,1,.6708.6317h0v33.27a.5376.5376,0,0,1-.2236.4211l-12.8558,11.897V214.33l5.2541,4.8431,11.9615-11.0548a.8755.8755,0,0,1,1.0061,0l25.7115,23.8994h23.14a.5929.5929,0,0,1,.4472.2105L402.16,282.87a.7494.7494,0,0,1,0,.9475.8755.8755,0,0,1-1.0061,0l-54.106-50.4308h-23.14a.5933.5933,0,0,1-.4472-.21L298.084,209.5922l-11.9615,11.0547a.8755.8755,0,0,1-1.0061,0l-5.9248-5.58a.7813.7813,0,0,1-.2236-.4211v-6.9487a.5377.5377,0,0,1,.2236-.4212l12.8558-11.897V162.4252A.5387.5387,0,0,1,292.6063,161.6882Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M334.3037,115.89h31.9717a.7014.7014,0,0,1,.6707.6317h0V144.527a.5376.5376,0,0,1-.2236.4211l-4.4715,4.106v48.641a.5373.5373,0,0,1-.2236.4211h5.9248a.7206.7206,0,0,1,.6707.737c0,.3159-.3353.5264-.6707.5264h-20.681l-17.2155,16.0031a.869.869,0,0,1-.4472.2106H323.46a.5929.5929,0,0,1-.4471-.2106L300.32,194.2207a.7809.7809,0,0,1-.2236-.4211V160.9512a.5377.5377,0,0,1,.2236-.4212l27.3884-25.4786v-13.16a.5372.5372,0,0,1,.2235-.4211l6.0367-5.58C333.9683,115.9951,334.08,115.89,334.3037,115.89Zm31.3009,1.3687H334.639l-5.5894,5.1589v13.16a.5377.5377,0,0,1-.2236.4212l-27.3883,25.4786V193.8l22.246,20.7409h5.5895l17.2155-16.0031a.8687.8687,0,0,1,.4471-.2106h14.0855a.7809.7809,0,0,1-.2236-.4211V148.9489a.5377.5377,0,0,1,.2236-.4212l4.4715-4.106Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M353.42,117.7849a.7014.7014,0,0,1,.6708.6317v40.0078a.5376.5376,0,0,1-.2236.4211L321.6715,188.746v11.0548a.5376.5376,0,0,1-.2236.4211l-7.0427,6.5276a.8758.8758,0,0,1-1.0061,0,.75.75,0,0,1,0-.9476l6.8192-6.317V188.43a.5377.5377,0,0,1,.2236-.4212l32.1952-29.9V118.4166A.823.823,0,0,1,353.42,117.7849Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M319.7711,142.8424a.7014.7014,0,0,1,.6707.6317h0v25.6892a.5375.5375,0,0,1-.2236.4211L307.2507,181.692a.869.869,0,0,1-.4472.2106h-6.0366c-.3353,0-.7825-.2106-.7825-.5264a.7828.7828,0,0,1,.6707-.737h5.8131L319.1,168.9527V143.5794c-.1117-.4211.1118-.737.6708-.737Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M368.064,145.3692a.7013.7013,0,0,1,.6707.6317h0v52.4312a.6526.6526,0,0,1-.7825.5264c-.3353,0-.5589-.3158-.6707-.5264V146.0009C367.2815,145.58,367.6169,145.3692,368.064,145.3692Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M573.74,782.925a1.14,1.14,0,0,1-1.0032-.4,1.2873,1.2873,0,0,1,0-1.9l77.1386-67.9v-66a.938.938,0,0,1,.4459-.9l61.644-55.2a1.7052,1.7052,0,0,1,2.118.3,1.2446,1.2446,0,0,1,0,1.5l-61.1982,54.9v66.1a1.2179,1.2179,0,0,1-.4459,1l-77.5844,68.2A2.0391,2.0391,0,0,1,573.74,782.925Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M642.0723,668.3251a1.406,1.406,0,0,1-1.4492-1.3v-18.1a1.4577,1.4577,0,0,1,2.8983,0v18.1a1.406,1.406,0,0,1-1.4491,1.3Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M597.1491,845.025h-88.62a1.406,1.406,0,0,1-1.4491-1.3v-9.4a.9382.9382,0,0,1,.4459-.9l42.9167-38.6a1.2751,1.2751,0,0,1,1.0032-.4H594.92a1.14,1.14,0,0,1,1.0033.4l33.1072,29.7a1.3206,1.3206,0,0,1-.3344,1.9,1.6624,1.6624,0,0,1-1.6721,0l-32.6613-29.3H552.1144l-42.1364,37.8v7.5h87.1711a1.3077,1.3077,0,1,1,0,2.6Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M695.3558,783.925H675.4023c-.78,0-1.5606-.5-1.5606-1.2a1.3892,1.3892,0,0,1,1.3377-1.4H694.687l16.052-14.4-48.9362-43.9a1.04,1.04,0,0,1-.4459-.9v-62.6a1.4577,1.4577,0,0,1,2.8982,0v62.1l49.4936,44.4a1.2873,1.2873,0,0,1,0,1.9l-17.5011,15.7A2.282,2.282,0,0,1,695.3558,783.925Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M636.61,819.425a1.14,1.14,0,0,1-1.0033-.4l-39.5726-35.5a1.2875,1.2875,0,0,1,0-1.9l39.5726-35.5a1.681,1.681,0,0,1,2.118,0l39.5725,35.5a1.2873,1.2873,0,0,1,0,1.9l-39.5725,35.5A1.5207,1.5207,0,0,1,636.61,819.425Zm-37.4547-36.8,37.4546,33.6,37.4546-33.6-37.4546-33.6Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M727.9057,847.8251H700.3721a1.14,1.14,0,0,1-1.0033-.4l-45.9265-41.2a1.3206,1.3206,0,0,1,.3344-1.9,1.6624,1.6624,0,0,1,1.6721,0l45.4806,40.8h26.8647a1.406,1.406,0,0,1,1.4491,1.3,1.1852,1.1852,0,0,1-1.3375,1.4Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M714.529,703.1251a1.406,1.406,0,0,1-1.4491-1.3v-11a.9383.9383,0,0,1,.4459-.9l10.4784-9.4a1.684,1.684,0,0,1,2.118-.3,1.3079,1.3079,0,0,1,.3344,1.9l-.3344.3-10.0325,9v10.5a1.6236,1.6236,0,0,1-1.5607,1.2Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M710.5161,751.425a1.14,1.14,0,0,1-1.0033-.4l-26.6418-23.9a1.3206,1.3206,0,0,1,.3344-1.9,1.6622,1.6622,0,0,1,1.672,0l26.6419,23.9a1.2886,1.2886,0,0,1,0,1.9,1.2748,1.2748,0,0,1-1.0032.4Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M732.8105,739.8251h-7.0228a.44.44,0,0,1-.3344-.1l-43.5855-39.1a.3441.3441,0,0,1-.1114-.3v-14l-8.6948-7.7a.3439.3439,0,0,1-.1115-.3v-20.7l-6.5768-5.8a.3438.3438,0,0,1-.1115-.3v-6.3a.3438.3438,0,0,1,.1115-.3l41.4676-37.2a.44.44,0,0,1,.3344-.1h11.4816a.44.44,0,0,1,.3344.1l16.4978,14.8a.3442.3442,0,0,1,.1114.3v42a.3441.3441,0,0,1-.1114.3l-27.0877,24.3v14.6l23.7435,21.3a.3438.3438,0,0,1,.1115.3v13.8C733.2563,739.725,733.0334,739.925,732.8105,739.8251Zm-6.8-.8h6.354v-13.2l-23.7436-21.3a.3441.3441,0,0,1-.1114-.3v-15a.3442.3442,0,0,1,.1114-.3l27.0877-24.3v-41.7l-16.2749-14.6H708.3981l-41.1332,37v5.9l6.4654,5.8a.3441.3441,0,0,1,.1114.3v20.7l8.6948,7.7a.3441.3441,0,0,1,.1114.3v14Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M695.5788,712.525a.457.457,0,0,1-.4459-.4h0v-20.6a.3441.3441,0,0,1,.1114-.3l22.74-20.4v-20.1l-14.9372-13.3h-5.5736l-15.829,14.2v12.4a.3441.3441,0,0,1-.1114.3l-8.4719,7.6a.4464.4464,0,0,1-.6688-.1.58.58,0,0,1,0-.6l8.249-7.4v-12.4a.3439.3439,0,0,1,.1114-.3l16.1634-14.5a.44.44,0,0,1,.3344-.1h6.02a.44.44,0,0,1,.3344.1l15.16,13.6a.3439.3439,0,0,1,.1115.3v20.4a.3438.3438,0,0,1-.1115.3l-22.74,20.4v20.4a.4751.4751,0,0,1-.4459.5Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M732.3646,730.3251a.4406.4406,0,0,1-.3345-.1l-27.5335-24.7a.3439.3439,0,0,1-.1115-.3v-7.8a.3439.3439,0,0,1,.1115-.3l3.2327-2.9a.4464.4464,0,0,1,.6688.1.58.58,0,0,1,0,.6l-3.01,2.8v7.5l27.4221,24.6a.3527.3527,0,0,1,0,.6C732.5875,730.225,732.4761,730.3251,732.3646,730.3251Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M656.1178,632.925a.4405.4405,0,0,1-.3344-.1.3527.3527,0,0,1,0-.6l59.526-53.4a.44.44,0,0,1,.3344-.1h37.3431a.457.457,0,0,1,.4459.4h0a.457.457,0,0,1-.4459.4h-37.12l-59.4146,53.3C656.3407,632.8251,656.2292,632.925,656.1178,632.925Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M702.8245,803.1251H692.569c-.2229,0-.5573-.1-.5573-.4a.4608.4608,0,0,1,.4459-.5h10.1439l40.3529-36.2-67.9979-61a.3439.3439,0,0,1-.1115-.3v-15.4l-9.4751-8.5a.3575.3575,0,0,1,.1115-.6.7837.7837,0,0,1,.6688,0l9.698,8.6a.3439.3439,0,0,1,.1115.3v15.4l68.2209,61.2a.46.46,0,0,1,0,.6l-41.0217,36.6C703.1589,803.025,703.0474,803.025,702.8245,803.1251Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M712.9685,805.025a.44.44,0,0,1-.3344-.1.3526.3526,0,0,1,0-.6l35.448-31.8a.4477.4477,0,0,1,.6688,0,.3527.3527,0,0,1,0,.6l-35.448,31.8A.44.44,0,0,1,712.9685,805.025Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M726.4565,857.925h-31.658a.4405.4405,0,0,1-.3344-.1l-20.3994-18.4c-.1114-.2-.1114-.5.1115-.6a.5551.5551,0,0,1,.5573,0l20.2879,18.2h31.4351c.223,0,.5574.1.5574.4,0,.2-.2229.4-.5574.5Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M536.1739,838.225H521.46a.4571.4571,0,0,1-.446-.4l-.1114-3.7a.3441.3441,0,0,1,.1114-.3l33.5531-30.7a.44.44,0,0,1,.3344-.1h16.8322a.457.457,0,0,1,.4459.4v9.1a.3441.3441,0,0,1-.1114.3l-22.183,19.9a.44.44,0,0,1-.3344.1h-7.1342l-5.7965,5.3A.3552.3552,0,0,1,536.1739,838.225Zm-14.157-.8h14.0455l5.908-5.3a.44.44,0,0,1,.3344-.1h7.1342l21.96-19.7v-8.5H555.2356l-33.2187,30.5Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M622.4532,834.6251h-9.5866a.44.44,0,0,1-.3344-.1l-38.9037-34.9H554.9012l-15.9405,14.3v5.2a.4486.4486,0,0,1-.8919,0v-5.4a.344.344,0,0,1,.1115-.3l16.2749-14.6a.44.44,0,0,1,.3344-.1h18.95a.44.44,0,0,1,.3344.1l38.9038,34.8h9.1407l2.2294-2a.4465.4465,0,0,1,.6689.1.58.58,0,0,1,0,.6l-2.3409,2.1A.2286.2286,0,0,1,622.4532,834.6251Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M657.2325,879.525a.457.457,0,0,1-.4459-.4h0v-22.6a.3441.3441,0,0,1,.1114-.3l9.0292-8.1v-4.4l-3.6785-3.3-8.36,7.5a.4477.4477,0,0,1-.6689,0l-18.17-16.3H618.7746a.44.44,0,0,1-.3344-.1l-38.3463-34.4a.3527.3527,0,0,1,0-.6.4477.4477,0,0,1,.6688,0l38.2348,34.3h16.2749a.4405.4405,0,0,1,.3344.1l17.947,16.1,8.36-7.5a.4477.4477,0,0,1,.6688,0l4.2359,3.8a.3439.3439,0,0,1,.1115.3v4.7a.3439.3439,0,0,1-.1115.3l-9.0292,8.1v22.4C657.6783,879.3251,657.5669,879.525,657.2325,879.525Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M627.9153,910.725H605.3979a.4571.4571,0,0,1-.4459-.4h0v-19.1a.344.344,0,0,1,.1115-.3l3.1212-2.8v-33.1a.3441.3441,0,0,1,.1114-.3H604.06c-.223,0-.4459-.3-.4459-.5a.457.457,0,0,1,.4459-.4H618.663l12.15-10.9a.4405.4405,0,0,1,.3344-.1h4.3474a.4405.4405,0,0,1,.3344.1l16.052,14.4a.344.344,0,0,1,.1114.3v22.3a.344.344,0,0,1-.1114.3l-19.2846,17.3v8.9a.3438.3438,0,0,1-.1115.3l-4.2359,3.8A.5994.5994,0,0,1,627.9153,910.725Zm-22.0714-.9h21.8484l3.9016-3.5v-8.9a.3439.3439,0,0,1,.1114-.3l19.2846-17.3v-22l-15.7175-14.1h-3.9015l-12.15,10.9a.44.44,0,0,1-.3344.1h-9.921a.3441.3441,0,0,1,.1114.3v33.3a.3442.3442,0,0,1-.1114.3l-3.1212,2.8Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M614.4272,909.425a.4571.4571,0,0,1-.4459-.4v-27.2a.3441.3441,0,0,1,.1114-.3l22.74-20.3v-7.5a.3442.3442,0,0,1,.1114-.3l4.9048-4.4a.4477.4477,0,0,1,.6688,0,.3527.3527,0,0,1,0,.6l-4.7933,4.3v7.5a.3441.3441,0,0,1-.1114.3l-22.74,20.4v27C614.8731,909.225,614.65,909.425,614.4272,909.425Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M638.1707,892.425a.4571.4571,0,0,1-.4459-.4h0v-17.5a.3439.3439,0,0,1,.1115-.3l9.1407-8.2a.44.44,0,0,1,.3344-.1h4.2359c.223,0,.5574.1.5574.4a.4609.4609,0,0,1-.446.5h-4.1244l-8.9177,8v17.1a.4608.4608,0,0,1-.4459.5Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M604.06,890.725a.4571.4571,0,0,1-.4459-.4h0v-35.7c0-.2.3344-.4.5574-.4a.4571.4571,0,0,1,.4459.4v35.7C604.6177,890.525,604.3947,890.725,604.06,890.725Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M438.6359,782.6251l-77.5845-68.3a1.2184,1.2184,0,0,1-.4459-1v-66.1l-61.0866-54.7a1.2446,1.2446,0,0,1,0-1.5,1.6838,1.6838,0,0,1,2.1179-.3l61.644,55.2a1.7115,1.7115,0,0,1,.4459.9v66l77.1386,67.9a1.3507,1.3507,0,0,1-1.0032,2.3A3.3725,3.3725,0,0,1,438.6359,782.6251Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M371.4183,668.3251a1.406,1.406,0,0,1-1.4491-1.3v-18.1a1.4577,1.4577,0,0,1,2.8982,0v18.1c.1115.7-.5573,1.3-1.4491,1.3Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M416.3415,845.025a1.3077,1.3077,0,1,1,0-2.6h87.1711v-7.5l-42.1364-37.8H419.1283l-32.6613,29.3a1.6624,1.6624,0,0,1-1.6721,0,1.3078,1.3078,0,0,1-.3344-1.9l33.1072-29.7a1.2755,1.2755,0,0,1,1.0033-.4h43.474a1.14,1.14,0,0,1,1.0033.4l42.9167,38.6a1.4227,1.4227,0,0,1,.4459.9v9.4a1.406,1.406,0,0,1-1.4491,1.3Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M317.2429,783.525l-17.5011-15.7a1.2875,1.2875,0,0,1,0-1.9l49.4936-44.4v-62.1a1.4577,1.4577,0,0,1,2.8982,0v62.6a.9382.9382,0,0,1-.4459.9l-48.9361,44,16.0519,14.4h19.5076a1.3179,1.3179,0,0,1,1.3377,1.4,1.37,1.37,0,0,1-1.5606,1.2H318.1347A1.4393,1.4393,0,0,1,317.2429,783.525Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M375.7657,819.025l-39.4611-35.5a1.2875,1.2875,0,0,1,0-1.9l39.5726-35.5a1.681,1.681,0,0,1,2.118,0l39.5725,35.5a1.2873,1.2873,0,0,1,0,1.9l-39.5725,35.5a2.1231,2.1231,0,0,1-1.0033.4A1.624,1.624,0,0,1,375.7657,819.025Zm1.0033-70-37.4546,33.6,37.4546,33.6,37.4545-33.6Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M285.5849,847.925a1.3076,1.3076,0,1,1,0-2.6H312.45l45.4806-40.8a1.6624,1.6624,0,0,1,1.6721,0,1.3078,1.3078,0,0,1,.3344,1.9l-45.9265,41.2a1.2751,1.2751,0,0,1-1.0032.4l-27.4221-.1Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M298.9615,703.1251a1.406,1.406,0,0,1-1.4491-1.3v-10.5l-10.0325-9-.3344-.3a1.3206,1.3206,0,0,1,.3344-1.9,1.705,1.705,0,0,1,2.118.3l10.4783,9.4a1.7112,1.7112,0,0,1,.4459.9v11a1.4517,1.4517,0,0,1-1.5606,1.4Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M302.9745,751.425a1.14,1.14,0,0,1-1.0032-.4,1.2884,1.2884,0,0,1,0-1.9l26.6418-23.9a1.6624,1.6624,0,0,1,1.6721,0,1.3078,1.3078,0,0,1,.3344,1.9l-26.6418,23.9a1.2751,1.2751,0,0,1-1.0033.4Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M280.2342,739.425v-13.8a.3438.3438,0,0,1,.1115-.3l23.7435-21.3v-14.6l-27.0876-24.3a.3439.3439,0,0,1-.1115-.3v-42a.344.344,0,0,1,.1115-.3l16.4978-14.8a.44.44,0,0,1,.3344-.1h11.4816a.44.44,0,0,1,.3344.1l41.4676,37.2a.3438.3438,0,0,1,.1115.3v6.3a.3438.3438,0,0,1-.1115.3l-6.5768,5.8v20.7a.3439.3439,0,0,1-.1115.3l-8.6948,7.7v14a.3439.3439,0,0,1-.1115.3l-43.5855,39.1a.44.44,0,0,1-.3344.1H280.68C280.4572,739.925,280.2342,739.725,280.2342,739.425Zm50.6083-39.3v-14a.3438.3438,0,0,1,.1115-.3l8.6948-7.7v-20.7a.3437.3437,0,0,1,.1115-.3l6.4653-5.8v-5.9l-41.1331-37H294.0568l-16.1635,14.5v41.7l27.0877,24.3a.344.344,0,0,1,.1115.3v15a.3439.3439,0,0,1-.1115.3l-23.7435,21.3v13.2h6.3539Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M317.9118,712.525a.457.457,0,0,1-.4459-.4v-20.4l-22.74-20.4a.344.344,0,0,1-.1115-.3v-20.4a.3437.3437,0,0,1,.1115-.3l15.16-13.6a.44.44,0,0,1,.3344-.1h6.02a.44.44,0,0,1,.3344.1l16.1634,14.5a.3439.3439,0,0,1,.1115.3v12.4l8.2489,7.4a.58.58,0,0,1,0,.6.5684.5684,0,0,1-.6688.1l-8.4719-7.6a.3439.3439,0,0,1-.1115-.3v-12.4l-15.829-14.2h-5.5736l-14.8258,13.3v20.1l22.74,20.4a.3437.3437,0,0,1,.1115.3v20.6h0c0,.1-.2229.3-.5573.3Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M280.7916,730.1251a.3528.3528,0,0,1,0-.6l27.4221-24.6v-7.5l-3.01-2.8a.58.58,0,0,1,0-.6.5682.5682,0,0,1,.6688-.1l3.2327,2.9a.3439.3439,0,0,1,.1115.3v7.8a.344.344,0,0,1-.1115.3l-27.5336,24.7a.4408.4408,0,0,1-.3344.1C281.0145,730.3251,280.9031,730.225,280.7916,730.1251Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M357.0384,632.725l-59.4145-53.3h-37.12a.457.457,0,0,1-.4459-.4h0a.4571.4571,0,0,1,.4459-.4h37.3431a.44.44,0,0,1,.3344.1l59.526,53.4a.3527.3527,0,0,1,0,.6.44.44,0,0,1-.3344.1C357.2614,632.925,357.15,632.8251,357.0384,632.725Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M310.3317,802.925l-40.91-36.6a.4593.4593,0,0,1,0-.6l68.2208-61.2v-15.4a.3438.3438,0,0,1,.1115-.3l9.6981-8.6a.783.783,0,0,1,.6688,0,.4376.4376,0,0,1,.1115.6l-9.4751,8.5v15.4a.3439.3439,0,0,1-.1115.3l-67.9979,61,40.3529,36.2h10.1439c.223,0,.4459.3.4459.5s-.3344.4-.5574.4H310.6661C310.4431,803.025,310.3317,803.025,310.3317,802.925Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M300.1877,804.925l-35.448-31.8a.3527.3527,0,0,1,0-.6.4477.4477,0,0,1,.6688,0l35.4481,31.8a.3527.3527,0,0,1,0,.6.6093.6093,0,0,1-.6689,0Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M287.034,857.925h-.1115c-.2229,0-.4458-.3-.4458-.5s.3344-.4.5573-.4h31.4351l20.2879-18.2a.5553.5553,0,0,1,.5574,0,.3574.3574,0,0,1,.1115.6l-20.3994,18.3a.44.44,0,0,1-.3344.1H287.034Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M476.8708,838.1251l-5.7966-5.3H463.94a.44.44,0,0,1-.3344-.1l-22.1829-19.9a.3441.3441,0,0,1-.1114-.3v-9.1a.457.457,0,0,1,.4458-.4h16.8323a.44.44,0,0,1,.3344.1l33.5531,30.7a.3438.3438,0,0,1,.1115.3l-.1115,3.7a.4571.4571,0,0,1-.4459.4H477.3167A.3556.3556,0,0,1,476.8708,838.1251Zm14.7143-3.8-33.2186-30.5H442.203v8.5l21.96,19.7h7.1342a.44.44,0,0,1,.3344.1l5.908,5.3h14.0455Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M390.7029,834.525l-2.3409-2.1a.58.58,0,0,1,0-.6.5685.5685,0,0,1,.6689-.1l2.2294,2h9.1407l38.9037-34.8a.44.44,0,0,1,.3344-.1h19.0618a.44.44,0,0,1,.3344.1l16.2749,14.6a.3441.3441,0,0,1,.1114.3v5.4a.4485.4485,0,0,1-.8917,0v-5.3l-15.94-14.3H439.8621l-38.9037,34.9a.44.44,0,0,1-.3344.1h-9.5866C390.9259,834.6251,390.8144,834.525,390.7029,834.525Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M355.7008,879.1251v-22.4l-9.0293-8.1a.344.344,0,0,1-.1115-.3v-4.7a.3438.3438,0,0,1,.1115-.3l4.2359-3.8a.4479.4479,0,0,1,.6689,0l8.36,7.5,17.947-16.1a.44.44,0,0,1,.3344-.1H394.493l38.2349-34.3a.4477.4477,0,0,1,.6688,0,.3527.3527,0,0,1,0,.6l-38.3464,34.4a.44.44,0,0,1-.3344.1H378.441l-18.0584,16.3a.4476.4476,0,0,1-.6688,0l-8.36-7.5-3.6786,3.3v4.4l9.0292,8.1a.3439.3439,0,0,1,.1115.3v22.6h0a.457.457,0,0,1-.4459.4A.6391.6391,0,0,1,355.7008,879.1251Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M385.2408,910.6251l-4.2359-3.8a.3439.3439,0,0,1-.1115-.3v-8.9l-19.2846-17.3a.3439.3439,0,0,1-.1115-.3v-22.3a.3439.3439,0,0,1,.1115-.3l16.0519-14.4a.4406.4406,0,0,1,.3345-.1h4.3474a.44.44,0,0,1,.3344.1l12.15,10.9H409.43a.45.45,0,0,1,0,.9h-4.2359a.3439.3439,0,0,1,.1115.3v33.1l3.1212,2.8a.3439.3439,0,0,1,.1115.3v19.1h0a.4571.4571,0,0,1-.4459.4H385.5752A.5994.5994,0,0,0,385.2408,910.6251Zm22.4059-19.2-3.1212-2.8a.344.344,0,0,1-.1115-.3v-33.3a.3439.3439,0,0,1,.1115-.3h-9.921a.44.44,0,0,1-.3344-.1l-12.15-10.9h-3.9016l-15.7175,14.1v22l19.2846,17.3a.3437.3437,0,0,1,.1115.3v8.9l3.9015,3.5h21.8485Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M398.6175,909.025v-27l-22.74-20.4a.3439.3439,0,0,1-.1115-.3v-7.5l-4.7933-4.3a.3528.3528,0,0,1,0-.6.4479.4479,0,0,1,.6689,0l4.9047,4.4a.3438.3438,0,0,1,.1115.3v7.5l22.74,20.3a.344.344,0,0,1,.1115.3v27.2a.4571.4571,0,0,1-.4459.4A.3279.3279,0,0,1,398.6175,909.025Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M375.32,892.425a.4753.4753,0,0,1-.4459-.5v-17.2l-8.9177-8h-4.1245c-.2229,0-.4459-.3-.4459-.5s.3344-.4.5574-.4h4.2359a.44.44,0,0,1,.3344.1l9.1407,8.2a.3439.3439,0,0,1,.1115.3v17.5h0c.1115.3-.1115.5-.4459.5Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M408.8729,890.225v-35.7a.4571.4571,0,0,1,.4459-.4c.2229,0,.5573.1.5573.4v35.7h0a.4571.4571,0,0,1-.4458.4A.399.399,0,0,1,408.8729,890.225Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M602.6949,304.45,712.8072,404.89a1.66,1.66,0,0,1,.6707,1.3687v97.1766l86.7484,80.5419a1.808,1.808,0,0,1,0,2.3162,2.208,2.208,0,0,1-2.9066.4212l-87.4191-81.1735a1.66,1.66,0,0,1-.6707-1.3687V407.1013L599.6765,307.2925a1.89,1.89,0,0,1,0-2.7374,1.85,1.85,0,0,1,1.4533-.6317A2.1718,2.1718,0,0,1,602.6949,304.45Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M698.051,472.377a2.1379,2.1379,0,0,1,2.124,2v26.6367a2.1278,2.1278,0,0,1-4.248,0V474.3774a2.0648,2.0648,0,0,1,2.124-2Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M634.3312,212.6426a2.1383,2.1383,0,0,1,2.124,2,2.0648,2.0648,0,0,1-2.124,2H510.6924v11.0548l59.8072,55.59h59.919l46.3924-43.1662a2.1125,2.1125,0,0,1,2.4594,0,1.92,1.92,0,0,1,.4472,2.7374l-47.0633,43.6926a2.199,2.199,0,0,1-1.4532.5265H569.4935a2.44,2.44,0,0,1-1.4533-.5265l-61.0368-56.7478a1.6594,1.6594,0,0,1-.6708-1.3687V214.643a2.0648,2.0648,0,0,1,2.124-2Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M774.9619,302.9758l24.929,23.1624a1.89,1.89,0,0,1,0,2.7374l-70.2036,65.2758v91.2808a2.1278,2.1278,0,0,1-4.248,0V393.4144a1.66,1.66,0,0,1,.6708-1.3687l69.421-64.5388-22.805-21.1619H745.0024a2.0218,2.0218,0,0,1-1.9-2.1057,2.0706,2.0706,0,0,1,2.2358-1.79H773.62A1.7243,1.7243,0,0,1,774.9619,302.9758Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M691.9026,250.7552l56.1181,52.1154a1.8893,1.8893,0,0,1,0,2.7374l-56.1181,52.1153a2.1868,2.1868,0,0,1-2.9065,0L632.8779,305.608a1.89,1.89,0,0,1,0-2.7374l56.1182-52.1154a2.2692,2.2692,0,0,1,2.9065,0Zm-1.4533,102.9673,53.2117-49.378-53.2117-49.3779-53.2116,49.3779Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M820.0129,208.4313a2.0221,2.0221,0,0,1,2.0123,1.8951,2.0648,2.0648,0,0,1-2.124,2h-38.12l-64.5024,59.9063a2.1125,2.1125,0,0,1-2.4594,0,1.92,1.92,0,0,1-.4471-2.7374l65.1731-60.538a2.1987,2.1987,0,0,1,1.4532-.5264Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M800.897,421.2092a2.0648,2.0648,0,0,1,2.124,2V438.581l14.1972,13.2657c.1118.1053.3354.2105.4472.4211a1.92,1.92,0,0,1-.4472,2.7374,2.2081,2.2081,0,0,1-2.9065-.4211l-14.868-13.7922a1.66,1.66,0,0,1-.6707-1.3686V423.21a2.0648,2.0648,0,0,1,2.124-2Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M795.3075,350.2482a2.2265,2.2265,0,0,1,1.4533.6317,1.8894,1.8894,0,0,1,0,2.7373l-37.7847,35.0594a2.1122,2.1122,0,0,1-2.4594,0,1.92,1.92,0,0,1-.4472-2.7373l37.7848-35.1647a2.1989,2.1989,0,0,1,1.4532-.5264Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M827.5028,367.9358v20.32a.7809.7809,0,0,1-.2236.4211l-33.76,31.3745v21.4778L831.9743,477.22a.5377.5377,0,0,1,.2236.4212v61.8014a.7806.7806,0,0,1-.2236.4211l-23.4757,21.7937a.5922.5922,0,0,1-.4471.2106H791.73a.869.869,0,0,1-.4472-.2106L732.37,506.91a.5375.5375,0,0,1-.2236-.4211v-9.16a.7809.7809,0,0,1,.2236-.4211l9.2785-8.6333V457.6373a.7809.7809,0,0,1,.2236-.4211l12.4086-11.3706v-20.53a.7809.7809,0,0,1,.2236-.4211l61.9312-57.4848a.5929.5929,0,0,1,.4471-.2106h9.9493A.6541.6541,0,0,1,827.5028,367.9358Zm-71.7686,57.8006V446.372a.7809.7809,0,0,1-.2236.4211L743.102,458.0584v30.4269a.7809.7809,0,0,1-.2236.4211L733.6,497.54v8.6332l58.4657,54.3263h15.7623l23.0286-21.3725v-61.275L792.401,442.1606a.5375.5375,0,0,1-.2236-.4211V419.7352a.7809.7809,0,0,1,.2236-.4211l33.76-31.3745V368.5675h-8.9431Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M773.9558,407.4171a.56.56,0,0,1,.5589.8423v30.0058l32.3071,30.0058a.5375.5375,0,0,1,.2236.4211v30.0058a.7809.7809,0,0,1-.2236.4211l-21.4635,20.0039a.593.593,0,0,1-.4472.2106h-8.4959a.87.87,0,0,1-.4472-.2106L753.0512,497.75a.5374.5374,0,0,1-.2235-.4211v-18.214L741.09,468.1657a.6127.6127,0,0,1,0-.8423.737.737,0,0,1,1.0061-.1053l11.9614,11.16a.5375.5375,0,0,1,.2236.4211v18.214l22.47,20.8462h7.9371l21.1281-19.5827V468.7974l-32.3071-30.1111a.5374.5374,0,0,1-.2235-.4211V408.0488h0a.7013.7013,0,0,1,.6707-.6317Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M826.72,381.4121a.75.75,0,0,1,0,.9476l-38.9026,36.1122v11.0548l4.36,4.0007a.6127.6127,0,0,1,0,.8423.737.737,0,0,1-1.0061.1053l-4.5833-4.2114a.5375.5375,0,0,1-.2236-.4211V418.2613a.7809.7809,0,0,1,.2236-.4211l39.1262-36.3228a.5926.5926,0,0,1,.4471-.2106C826.3849,381.3068,826.4967,381.4121,826.72,381.4121Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M718.3966,524.8083l84.4008,78.3309h52.7645a.7014.7014,0,0,1,.6707.6317h0a.7014.7014,0,0,1-.6707.6317H802.5738a.8687.8687,0,0,1-.4471-.2106l-84.5126-78.5415a.7494.7494,0,0,1,0-.9475.5924.5924,0,0,1,.4471-.2106A.3483.3483,0,0,1,718.3966,524.8083Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M784.9111,274.5493,842.93,328.4545a.5375.5375,0,0,1,.2236.4211.7806.7806,0,0,1-.2236.4211L746.12,419.3141v22.7412a.7812.7812,0,0,1-.2236.4212l-13.6383,12.7393a.7119.7119,0,0,1-.8943,0,.65.65,0,0,1-.1118-.9476L744.7788,441.74V418.9983a.7812.7812,0,0,1,.2236-.4212l96.474-89.7015-57.3478-53.2734H769.7078a.7829.7829,0,0,1-.6708-.737c0-.3159.4472-.5265.7826-.5265h14.5326C784.5758,274.3387,784.7994,274.3387,784.9111,274.5493Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M799.3319,271.6014l50.4169,46.8512a.7494.7494,0,0,1,0,.9475.8755.8755,0,0,1-1.0061,0l-50.4169-46.8512a.75.75,0,0,1,0-.9475.593.593,0,0,1,.4472-.2106A.7488.7488,0,0,1,799.3319,271.6014Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M817.8889,193.6916a.3571.3571,0,0,1,.2237.1053.7828.7828,0,0,1,.6707.737c0,.3158-.4472.5264-.7825.5264H773.3969l-28.73,26.742a1.0086,1.0086,0,0,1-.7825,0,.6625.6625,0,0,1-.2236-.9476l28.9533-26.9525a.593.593,0,0,1,.4472-.2106Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M548.3653,222.75l8.2724,7.7909h10.061a.869.869,0,0,1,.4472.2106L598.67,260.02a.5376.5376,0,0,1,.2236.4211v13.3711a.7014.7014,0,0,1-.6707.6317H574.4122a.869.869,0,0,1-.4472-.2106l-47.6221-45.1666a.5377.5377,0,0,1-.2236-.4212l.1118-5.4747a.7013.7013,0,0,1,.6707-.6317h20.9046A.8822.8822,0,0,1,548.3653,222.75ZM527.4607,228.54l47.175,44.7455h22.9168V260.8625L566.4752,231.91H556.4141a.8687.8687,0,0,1-.4471-.2106l-8.3842-7.7909H527.6843Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M670.7744,228.014l3.3537,3.1585a.6127.6127,0,0,1,0,.8423.7368.7368,0,0,1-1.0061.1053l-3.13-2.948H657.0244l-55.2239,51.2731a.5928.5928,0,0,1-.4471.2106H574.1886a.8687.8687,0,0,1-.4471-.2106l-23.14-21.4778a.5376.5376,0,0,1-.2236-.4211V250.65a.672.672,0,0,1,1.3415,0v7.6857l22.6932,21.0567h26.494L656.13,228.1193a.5926.5926,0,0,1,.4471-.2106h13.6383C670.439,227.9087,670.5508,228.014,670.7744,228.014Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M720.1852,162.5277v32.9537l12.8558,11.8971a.5373.5373,0,0,1,.2236.4211v6.9487a.7809.7809,0,0,1-.2236.4211l-5.9248,5.58a.8758.8758,0,0,1-1.0061,0l-11.9615-11.0548-25.3761,23.5835a.593.593,0,0,1-.4472.2106h-23.14L611.079,283.92a.8755.8755,0,0,1-1.0061,0,.7494.7494,0,0,1,0-.9475l54.4413-50.6413a.593.593,0,0,1,.4472-.2106h23.14l25.7115-23.8994a.8758.8758,0,0,1,1.0061,0l11.9615,11.0548,5.2541-4.843V208.01l-12.8558-11.8971a.5375.5375,0,0,1-.2236-.4211v-33.27h0a.7013.7013,0,0,1,.6708-.6317A.5387.5387,0,0,1,720.1852,162.5277Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M678.2643,115.9924l6.0366,5.58a.5376.5376,0,0,1,.2236.4211v13.16l27.3883,25.4786a.5373.5373,0,0,1,.2236.4211v32.8484a.7812.7812,0,0,1-.2236.4212L689.22,215.4853a.593.593,0,0,1-.4471.21h-6.1484a.87.87,0,0,1-.4472-.21l-17.2155-16.0031H644.28c-.3354,0-.6707-.2106-.6707-.5264a.7206.7206,0,0,1,.6707-.737h5.9248a.5377.5377,0,0,1-.2236-.4212V149.1567l-4.4715-4.1061a.5375.5375,0,0,1-.2236-.4211V116.6241h0a.7013.7013,0,0,1,.6707-.6317h31.9717C678.1525,115.9924,678.2643,116.0977,678.2643,115.9924ZM646.74,144.5242l4.4715,4.1061a.5373.5373,0,0,1,.2236.4211v48.9568a.7812.7812,0,0,1-.2236.4212h14.0855a.8685.8685,0,0,1,.4471.2105l17.2155,16.0031h5.59l22.246-20.7408V161.58l-27.3883-25.4786a.5376.5376,0,0,1-.2236-.4211V122.52l-5.5894-5.1588H646.628Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M659.5955,118.5192v39.6919l32.1952,29.9005a.5373.5373,0,0,1,.2236.4211v11.0548l6.8192,6.317a.7494.7494,0,0,1,0,.9475.8755.8755,0,0,1-1.0061,0l-7.0427-6.5276a.5373.5373,0,0,1-.2236-.4211V188.8485l-32.1953-29.9a.5373.5373,0,0,1-.2236-.4211V118.5192a.7013.7013,0,0,1,.6708-.6317A.8229.8229,0,0,1,659.5955,118.5192Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M692.4615,142.9449c.559,0,.7825.3159.6708.737v25.3733l12.6321,11.6865h5.8131a.7828.7828,0,0,1,.6707.737c0,.3159-.4472.5264-.7825.5264h-6.0366a.87.87,0,0,1-.4472-.21L692.0144,169.687a.5377.5377,0,0,1-.2236-.4212V143.5767h0a.7014.7014,0,0,1,.6707-.6318Z"
                transform="translate(-156 -115.8898)" />
            <path className="path-caption--gray"
                d="M644.9511,146.1035v52.4311c-.1118.2106-.3353.5265-.6707.5265a.6527.6527,0,0,1-.7825-.5265V146.1035h0a.7013.7013,0,0,1,.6707-.6318C644.6158,145.4717,644.9511,145.6823,644.9511,146.1035Z"
                transform="translate(-156 -115.8898)" />
        </svg>
    )
}

export default memo(QuadAirCraft);