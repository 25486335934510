import React from 'react'

export const Icon: React.FC<ElementLikeProps> = ({ id }) => {
    return (
        <svg id={id ? id : ""} viewBox="0 0 64.54 49.12"
            xmlns="http://www.w3.org/2000/svg">
            <polygon className="path-caption--white" transform="translate(-16.59,-25.92)" points="66.15 75.04 66.15 60.06 52.71 60.06 37.72 75.04 16.59 75.04 31.53 60.06 46.38 45.22 66.15 45.22 66.15 42.96 81.13 42.96 81.13 75.04" />
            <polygon className="path-caption--red_1" transform="translate(-16.59,-25.92)" points="43.43 45.07 29.28 59.23 16.83 46.74 16.83 25.92 80.89 25.92 80.89 40.86 31.98 40.76 36.29 45.07" />
            <rect className="path-caption--purple" x="48.28" y="7.629e-8" width="16.01" height="14.94" />
            <rect className="path-caption--magent" x="32.27" y="7.629e-8" width="16.01" height="14.94" />
            <rect className="path-caption--red_2" x="16.25" y="7.629e-8" width="16.01" height="14.94" />
            <rect className="path-caption--red_1" x=".24" y="7.629e-8" width="16.01" height="14.94" />
        </svg>
    )
}

export default Icon;
