import React, { memo } from 'react'

import View from './View';
import ContactForm from '../Form/ContactForm';

import { useTranslation } from 'react-i18next';

export const Contact: React.FC = () => {
    const { t } = useTranslation();

    return (
        <View
            langKey="contact"
            level={1}
            id="contact">
            <div className="c-contact">
                <ContactForm>
                    <p className="typo-p--gray">{t('contact.caption')}</p>
                </ContactForm>
            </div>
        </View>
    )
}

export default memo(Contact);