import React, {useEffect} from 'react';

import LogoNav from '../Rootrilla/LogoNav';

import { useTranslation } from 'react-i18next';

import LangSelector from '../Other/LangSelector';

import Aircraft from '../Rootrilla/Aircraft';

export const NotFound: React.FC = () => {
    const { t } = useTranslation();

    useEffect(() => {
        document.title = "Rootrilla | Page not found"
    }, []);
    return (
        <div className="wrapper" id="notFound">
            <header>
                <LogoNav id="rootrillaLogo" />
                <LangSelector />
            </header>
            <main>
                <h1>{t('notfound.title')}</h1>
                <p>{t('notfound.caption')}</p>
                <a className="arrow--back" href="/">{t('notfound.back')}</a>
            </main>
            <figure>
                <Aircraft direction="left" animate={false} />
                <Aircraft direction="right" animate={false} />
            </figure>
        </div>

    )
}

export default NotFound;