import React, { useEffect, useState } from 'react';

import { growAnimation } from "../../utils/animation";
import { openTab, getThemeFromCSS } from "../../utils/functions";

import { PWN2ME_LINK } from '../constants';

const lightFill = '949090';
const darkFill = 'fff';

export const onThemeToggle = (newTheme: string) => {
    const themeDependentPaths = document.querySelectorAll(`svg.svg-logo--pwn2me *[class='path-caption--pwn2me-theme'], svg.svg-logo--pwn2me > g > .path-caption--pwn2me-theme`) as NodeListOf<SVGPathElement>;

    themeDependentPaths.forEach(path => {
        if (window.getComputedStyle(path, null).getPropertyValue('animation-name') !== 'none') {
            // remove animation so element fill and stroke attrs can be manipulated without problems
            path.style.animation = "none";
        }
        var fill = newTheme === 'light' ? `#${lightFill}` : `#${darkFill}`;
        path.style.fill = fill;
    });
}

export const LogoPwn2me: React.FC<AnimableSVGProps> = ({ id, animate, animationProps }) => {
    const [isAnimated, setIsAnimated] = useState(false);

    const logoId = id || 'pwn2meLogo' as string;

    useEffect(() => {
        if (isAnimated || animate === false) return;

        const theme = getThemeFromCSS();

        // map CSS classes to animations name as: fill-<hex color>
        const colorsMap = {
            "path-caption--pwn2me-yellow": "ffe75e",
            "path-caption--pwn2me-orange": "feb72b",
            "path-caption--pwn2me-light_green": "899857",
            "path-caption--pwn2me-green": "527318",
            "path-caption--red_1": "e00615",
            "path-caption--pwn2me-theme": theme === 'light' ? lightFill : darkFill,
            "path-caption--pwn2me-theme-2": "527318"
        }

        const paths = document.querySelectorAll(`svg#${logoId} > :not(defs, g), svg#${logoId} > g > *`) as NodeListOf<SVGPathElement>;
        if (animationProps) {
            const { growTime, delay, fillTime } = animationProps!;
            paths.forEach(path => growAnimation(
                path, {
                colorMap: colorsMap,
                growTime: growTime,
                delay: delay,
                fillTime: fillTime
            })
            );
        } else {
            paths.forEach(path => growAnimation(path, { colorMap: colorsMap }));
        }
        setIsAnimated(true);

    }, [logoId, animate]);


    return (
        <svg
            className="svg-logo--pwn2me"
            id={logoId}
            onClick={() => openTab(PWN2ME_LINK)}
            xmlns="http://www.w3.org/2000/svg"
            xlinkHref="http://www.w3.org/1999/xlink"
            viewBox="0 0 86.3 14.1"
        >
            <defs>
                <clipPath id="f56089e7-7f00-4320-b390-28dd03a13ea1" transform="translate(-5.1 -7.2)">
                    <polygon className="b1a58058-c016-4cef-bd17-c840ef3c18e3"
                        points="77 12.1 72.7 16.4 72.1 17 72.9 17 72.9 17 72.9 17 91.3 17 91.3 21.3 78.9 21.3 78.9 21.3 67.8 21.3 67.8 21.3 67.7 21.3 66.1 21.3 66.1 16.9 70.9 12.1 77 12.1" />
                </clipPath>
            </defs>
            <circle className="path-caption--pwn2me-green" cx="61.3" cy="6.2" r="0.9" />
            <path className="path-caption--pwn2me-theme"
                d="M22.5,7.9v5.7L19,17.1H13.2l4-4h0l1.2-1.2H5.1v-4Zm-13.3,4v1.2h7.3l-4,4H9.2v4.1H5.1V11.9Z"
                transform="translate(-5.1 -7.2)" />
            <polygon className="path-caption--pwn2me-theme"
                points="23.6 0.7 23.6 7.5 19.5 5.8 19.5 0.7 23.6 0.7" />
            <polygon className="path-caption--pwn2me-theme"
                points="19.5 5.1 23.6 6.9 23.6 6.9 27.7 8.7 27.7 13 19.5 9.6 19.5 5.1" />
            <polygon className="path-caption--pwn2me-theme"
                points="31.7 0.7 31.7 10 35.7 10 35.7 5.3 39.7 5.3 39.7 14 30.1 14 27.6 12.9 27.6 0.7 31.7 0.7" />
            <rect className="path-caption--pwn2me-green" x="35.7" y="0.7" width="4" height="4" />
            <path className="path-caption--pwn2me-theme"
                d="M54,7.9l5.7,2.5v4.4L53.1,12H46.9V8Zm-7.1,3.6H51v9.7H46.9Zm13.3-.8,4,1.8v8.8H60.1Z"
                transform="translate(-5.1 -7.2)" />
            <polygon className="path-caption--red_1" points="61.8 0 71.9 4.3 60.7 4.3 50.8 0 61.8 0" />
            <polygon className="path-caption--pwn2me-yellow"
                points="71.9 4.9 67.6 9.2 67 9.8 67.8 9.8 67.8 9.8 67.8 9.8 86.2 9.8 86.2 14.1 73.8 14.1 73.8 14.1 62.7 14.1 62.7 14.1 62.6 14.1 61 14.1 61 9.7 65.8 4.9 71.9 4.9" />
            <g className="f500040a-51c0-42f6-af2f-c4ba7662d134">
                <rect className="path-caption--pwn2me-orange" x="72.5" y="9.6" width="4.6" height="4.5" />
                <rect className="path-caption--pwn2me-light_green" x="77.1" y="9.6" width="4.6" height="4.5" />
                <rect className="path-caption--pwn2me-green" x="81.7" y="9.6" width="4.6" height="4.5" />
            </g>
            <polygon className="path-caption--pwn2me-theme-2"
                points="75.8 4.9 76.7 5.3 76.7 9.5 75.2 9.5 75.2 6.3 72.5 6.3 72.5 4.9 75.8 4.9" />
            <rect className="path-caption--pwn2me-theme-2" x="72.5" y="6.2" width="1.4" height="3.3" />
            <polygon className="path-caption--pwn2me-theme-2"
                points="76.8 5.4 79.4 6.5 79.4 8 78 7.4 78 7.4 76.8 6.8 76.8 5.4" />
            <polygon className="path-caption--pwn2me-theme-2" points="78 7.1 79.4 7.7 79.4 9.5 78 9.5 78 7.1" />
            <rect className="path-caption--pwn2me-theme-2" x="80.2" y="4.9" width="6" height="1.4" />
            <polygon className="path-caption--pwn2me-theme-2"
                points="81.6 6.5 81.6 8.1 86.2 8.1 86.2 9.5 80.2 9.5 80.2 6.5 81.6 6.5" />
            <rect className="path-caption--pwn2me-theme-2" x="81.1" y="6.5" width="3.6" height="1.4" />
        </svg>
    )
}

export default LogoPwn2me;
