import React, { memo } from 'react';

export const MiniAirCrafts: React.FC<ElementLikeProps> = (props) => {
    return (
        <svg className="svg-aircraft--mini" id={"id" in props ? props.id : ''} xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 338.7916 317.9803">
            <path className="path-caption--magent"
                d="M227.6523,321.2217a.59.59,0,0,0,1-.42l-.42-43.44,20.91-20.92a.56.56,0,0,0,.18-.42v-35a.6.6,0,0,0-1.19,0v34.73l-20.9,20.9a.63.63,0,0,0-.17.43l.42,43.69A.59.59,0,0,0,227.6523,321.2217Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M244.5823,265.5317a.6.6,0,0,0,.84,0l5.74-5.75a.594.594,0,0,0-.84-.84l-5.74,5.71a.59.59,0,0,0-.047.8331A.5778.5778,0,0,0,244.5823,265.5317Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M201.3623,334.2617l25.2,25.19a.58.58,0,0,0,.82.02l.02-.02,3-3a.6.6,0,0,0,.18-.42v-24.38a.6.6,0,0,0-.18-.42l-12.35-12.35a.59.59,0,0,0-.42-.17h-18.87a.6.6,0,0,0,0,1.19h18.6l12,12v23.93l-2.38,2.38-24.78-24.77a.594.594,0,0,0-.84.84Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M192.8223,287.0117l5.67,5.66a.594.594,0,1,0,.84-.84l-5.49-5.49v-9.12h27.77a.59.59,0,0,0,.42-.17l19.84-19.84a.594.594,0,0,0-.84-.84l-19.66,19.66h-28.13a.58.58,0,0,0-.42.18.59.59,0,0,0-.17.42v10A.61.61,0,0,0,192.8223,287.0117Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M198.0623,314.4917v-22.47a.6.6,0,0,1,.6-.6h22.47a.62.62,0,0,1,.6.6v22.47a.6.6,0,0,1-.6.6h-22.42a.62.62,0,0,1-.6-.6Zm22.48-21.84h-21.29v21.29h21.29Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M163.3023,298.0017l7.81,7.81a.59.59,0,0,0,.42.17h26.08a.58.58,0,0,0,.59-.57c0-.0067,0-.0135,0-.02a.59.59,0,0,0-.58-.6l-.01,0h-25.84l-7.63-7.63a.594.594,0,0,0-.84.84Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M212.9623,255.9617a.58.58,0,0,0,.82.02l.02-.02,3.49-3.48a.59.59,0,0,0,.17-.42v-5.95a.6.6,0,1,0-1.19,0v5.7l-3.31,3.31A.6.6,0,0,0,212.9623,255.9617Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M198.7923,272.3817a.6.6,0,0,0,.42.18h15.15a.59.59,0,0,0,.59-.59v-.01a.58.58,0,0,0-.57-.59h-15.17a.59.59,0,0,0-.42,1Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M196.1323,262.1217l4.39-4.38a.17.17,0,0,1,.19-.09h13.5l4.64-4.64v-15.36a.17.17,0,0,1,.06-.14l13.31-13.31a.2.2,0,0,1,.14-.06h9.35a.2.2,0,0,1,.14.06l3.26,3.26a.17.17,0,0,1,.06.14v23.57a.2006.2006,0,0,1-.06.14l-2,2a.2.2,0,0,1-.14.06h-3.71l-6.55,6.56a.17.17,0,0,1-.14.06h-4.93l-4.43,4.44a.24.24,0,0,1-.14.05h-24.8a.24.24,0,0,1-.14-.05l-2-2a.21.21,0,0,1-.0133-.2967Zm26.84,1.94,4.44-4.41a.22.22,0,0,1,.14-.05h4.93l6.57-6.57a.2.2,0,0,1,.14-.06h3.7l1.87-1.87v-23.4l-3.14-3.15h-9.23l-13.19,13.2v15.37a.17.17,0,0,1-.06.14l-4.76,4.76a.17.17,0,0,1-.14.06h-13.53l-4.19,4.18,1.8,1.8Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M215.3723,264.3317a.19.19,0,0,0,.2684.0116l.0116-.0116,6.51-6.51a.2.2,0,0,0,.06-.14v-12.94l6.36-6.36h8.45l1.59,1.59v9l-3.91,3.94a.2494.2494,0,0,0-.06.14v4.78a.2.2,0,0,0,.4,0v-4.7l3.92-3.92a.2.2,0,0,0,.06-.14v-9.18a.2.2,0,0,0-.06-.14l-1.7-1.71a.24.24,0,0,0-.14,0h-8.62a.24.24,0,0,0-.14,0l-6.47,6.47a.2493.2493,0,0,0-.06.15v12.93l-6.45,6.46a.19.19,0,0,0-.0314.2668Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M199.3023,259.4917a.2.2,0,0,0,.14.06h15.65a.2.2,0,0,0,.14-.06l2.49-2.48a.2.2,0,0,0,.06-.15v-1.82a.2.2,0,0,0-.4,0v1.74l-2.37,2.38h-15.57a.2.2,0,0,0-.2.19A.17.17,0,0,0,199.3023,259.4917Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M251.8023,250.2917a.198.198,0,0,0,.34-.14v-33.85a.2006.2006,0,0,0-.06-.14l-10.61-10.61a.2.2,0,0,0-.28.28l10.52,10.56v33.76A.2394.2394,0,0,0,251.8023,250.2917Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M184.6123,290.9317l2.91,2.91a.2.2,0,0,0,.28-.28l-2.85-2.86v-22.94h38.65a.2.2,0,0,0,.14-.06l4.9-4.9h5.38a.2.2,0,0,0,.2-.2.19.19,0,0,0-.19-.19l-.01,0h-5.47a.2411.2411,0,0,0-.14.05l-4.9,4.9h-38.8a.18.18,0,0,0-.14.06.2.2,0,0,0-.06.14v23.23A.2.2,0,0,0,184.6123,290.9317Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M181.1123,288.6517a.198.198,0,0,0,.34-.14v-20.14a.2.2,0,0,0-.4,0v20.18A.1994.1994,0,0,0,181.1123,288.6517Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M160.5423,301.5917l9,9a.25.25,0,0,0,.15.05h11.59a.2.2,0,1,0,0-.39h-11.57l-8.93-8.93a.2.2,0,0,0-.28.28Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M220.7123,349.2817v-3.34l-2-2a.2.2,0,0,1-.06-.14v-12.63a.17.17,0,0,1,.06-.14l2.88-2.89a.19.19,0,0,1,.2684-.0116l.0116.0116,4.78,4.78a.2.2,0,0,1,.06.14l-.21,19.26a.2.2,0,0,1-.06.14l-1.2,1.14a.19.19,0,0,1-.2685.0116l-.0115-.0116-4.18-4.18A.17.17,0,0,1,220.7123,349.2817Zm5.38,2.95.21-19.09-4.58-4.58-2.68,2.69v12.45l2,2a.2.2,0,0,1,.06.14v3.34l4,4Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M197.4723,323.7717l2.73,2.73a.2.2,0,0,0,.14.06h22.09l5.32,5.31v9.08l-1.66,1.7a.2.2,0,0,0,.24.32.2021.2021,0,0,0,.04-.04l1.72-1.71a.2409.2409,0,0,0,.05-.14v-9.24a.2409.2409,0,0,0-.05-.14l-5.44-5.43a.17.17,0,0,0-.14-.06h-22.09l-2.61-2.61v-1.27a.2.2,0,1,0-.39,0v1.32A.2.2,0,0,0,197.4723,323.7717Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M173.3423,328.1217a.21.21,0,0,0,.28,0l7.16-7.16a.2508.2508,0,0,0,.06-.14v-5.17l1.38-1.38h2.1v4.78a.2.2,0,0,0,.2.2h10.3l4.63,4.63a.24.24,0,0,0,.14.05h21.81a.2.2,0,1,0,0-.39h-21.69l-4.63-4.63a.17.17,0,0,0-.14-.06h-10.23v-4.78a.2.2,0,0,0-.2-.2h-2.39a.2.2,0,0,0-.14.06l-1.49,1.5a.2.2,0,0,0-.06.14v5.12l-7.1,7.11a.19.19,0,0,0,.01.32Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M171.7623,346.1917v-2.4a.2.2,0,0,1,.06-.14l2.83-2.83v-11a.2.2,0,0,1,.06-.14l7.07-7.08a.2409.2409,0,0,1,.14-.05h9.11a.2409.2409,0,0,1,.14.05l1.23,1.24a.17.17,0,0,1,.06.14v6.88l4.15,4.15a.21.21,0,0,1,0,.28.19.19,0,0,1-.2685.0116l-.0115-.0116-1.2-1.19a.2.2,0,0,1-.06.14l-10.48,10.49v1.78a.2508.2508,0,0,1-.06.14l-6,6a.21.21,0,0,1-.28,0l-6.39-6.4A.1994.1994,0,0,1,171.7623,346.1917Zm12.43.28v-1.82a.17.17,0,0,1,.06-.14l10.54-10.55a.2.2,0,0,1,.15-.06l-2.82-2.81a.2.2,0,0,1-.06-.14v-6.89l-1.11-1.12h-8.94l-7,7v11a.2.2,0,0,1-.06.14l-2.84,2.84v2.23l6.2,6.19Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M176.0423,349.7517a.21.21,0,0,0,.29,0l8.6-8.6a.2.2,0,0,0,.06-.14v-12.9l2.38-2.38a.2.2,0,0,0,.05-.14v-2.81a.18.18,0,0,0-.1694-.19.177.177,0,0,0-.0206,0,.19.19,0,0,0-.2.18l0,.01v2.73l-2.38,2.38a.2.2,0,0,0-.06.14v12.89l-8.55,8.55A.21.21,0,0,0,176.0423,349.7517Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M174.7123,337.6517a.21.21,0,0,0,.28,0l5.52-5.52a.2.2,0,0,0,.06-.14v-5.24a.2.2,0,0,0-.06-.14l-1.21-1.21a.2.2,0,0,0-.32.24.1969.1969,0,0,0,.04.04l1.15,1.15v5l-5.46,5.51a.19.19,0,0,0-.0451.2649A.1875.1875,0,0,0,174.7123,337.6517Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M184.9123,346.7517a.19.19,0,0,0,.2684.0115l.0116-.0115,11.3-11.3a.21.21,0,0,0,0-.28.19.19,0,0,0-.2685-.0115l-.0115.0115-11.3,11.3a.19.19,0,0,0-.0116.2685Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M125.0523,218.6517a.59.59,0,0,1,.42-1l43.44.42,20.91-20.92a.63.63,0,0,1,.43-.17h35a.6.6,0,0,1,0,1.19h-34.76l-20.92,20.92a.59.59,0,0,1-.42.17l-43.69-.42A.59.59,0,0,1,125.0523,218.6517Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M180.7123,201.6517a.6.6,0,0,1,0-.84l5.75-5.74a.58.58,0,0,1,.82-.02l.02.02a.6.6,0,0,1,0,.84l-5.72,5.74a.58.58,0,0,1-.8187.0514A.5925.5925,0,0,1,180.7123,201.6517Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M112.0123,244.9117l-25.2-25.2a.6.6,0,0,1,0-.84l3-3a.6.6,0,0,1,.42-.18h24.41a.62.62,0,0,1,.42.18l12.35,12.38a.59.59,0,0,1,.17.42v18.83a.6.6,0,1,1-1.19,0v-18.59l-12-12h-23.93l-2.38,2.38,24.77,24.77a.61.61,0,0,1,0,.85A.6.6,0,0,1,112.0123,244.9117Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M159.2623,253.4517l-5.66-5.67a.594.594,0,1,1,.84-.84l5.49,5.49h9.12v-27.78a.63.63,0,0,1,.17-.43l19.84-19.84a.6.6,0,0,1,.84.85l-19.66,19.66v28.13a.64.64,0,0,1-.18.42.59.59,0,0,1-.42.17h-9.93A.59.59,0,0,1,159.2623,253.4517Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M131.3623,248.0317a.56.56,0,0,1-.18-.42v-22.48a.6.6,0,0,1,.6-.59h22.47a.61.61,0,0,1,.6.59v22.52a.59.59,0,0,1-.59.59l-.01,0h-22.47A.59.59,0,0,1,131.3623,248.0317Zm1-22.3v21.28h21.35v-21.28Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M148.2723,282.9717l-7.81-7.81a.59.59,0,0,1-.17-.42v-26.09a.58.58,0,0,1,.57-.59c.0067,0,.0134,0,.02,0a.59.59,0,0,1,.6.58l0,.01v25.83l7.63,7.64a.594.594,0,0,1-.84.84h0Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M190.3123,233.3117a.58.58,0,0,1-.02-.82l.02-.02,3.48-3.49a.59.59,0,0,1,.42-.17h5.95a.58.58,0,0,1,.59.57c0,.0068,0,.0135,0,.02a.59.59,0,0,1-.58.6l-.01,0h-5.7l-3.31,3.31A.6.6,0,0,1,190.3123,233.3117Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M173.8923,247.4717a.56.56,0,0,1-.18-.42v-15.14a.6.6,0,0,1,1.19,0v15.14a.6.6,0,0,1-.59.6A.56.56,0,0,1,173.8923,247.4717Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M183.8723,250.1317l-2-2a.2494.2494,0,0,1-.06-.14v-24.78a.2494.2494,0,0,1,.06-.14l4.44-4.42v-4.93a.25.25,0,0,1,.05-.15l6.57-6.56v-3.71a.2.2,0,0,1,.06-.14l2-2a.2.2,0,0,1,.14-.06h23.57a.17.17,0,0,1,.14.06l3.26,3.26a.2.2,0,0,1,.06.14v9.39a.2.2,0,0,1-.06.14l-13.39,13.32a.17.17,0,0,1-.14.06h-15.34l-4.64,4.64v13.54a.2.2,0,0,1-.06.14l-4.38,4.38a.19.19,0,0,1-.2677-.0235Zm-1.66-2.21,1.8,1.79,4.18-4.18v-13.51a.2.2,0,0,1,.06-.14l4.75-4.75a.2.2,0,0,1,.14-.06h15.38l13.19-13.19v-9.23l-3.14-3.14h-23.41l-1.86,1.87v3.7a.2.2,0,0,1-.06.14l-6.53,6.57v4.93a.2.2,0,0,1-.06.14l-4.43,4.44Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M181.9423,230.9017a.19.19,0,0,1-.0116-.2684l.0116-.0116,6.51-6.51a.2.2,0,0,1,.14-.06h12.94l6.36-6.36v-8.45l-1.59-1.59h-9l-3.92,3.93a.24.24,0,0,1-.14,0h-4.78a.19.19,0,0,1-.2-.18l0-.01a.2.2,0,0,1,.2-.2h4.7l3.92-3.93a.24.24,0,0,1,.14,0h9.18a.24.24,0,0,1,.14,0l1.7,1.71a.2.2,0,0,1,.06.14v8.62a.2.2,0,0,1-.06.14l-6.53,6.52a.2.2,0,0,1-.14,0h-12.86l-6.45,6.46a.19.19,0,0,1-.32.05Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M186.7723,246.9717a.24.24,0,0,1-.05-.14v-15.65a.24.24,0,0,1,.05-.14l2.49-2.49a.2.2,0,0,1,.14-.06h1.83a.2.2,0,0,1,0,.4h-1.74l-2.38,2.37v15.57a.2.2,0,0,1-.2.2A.2494.2494,0,0,1,186.7723,246.9717Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M195.9823,194.4717a.1979.1979,0,0,1,.14-.34h33.84a.2006.2006,0,0,1,.14.05l10.61,10.62a.2.2,0,1,1-.24.32.1969.1969,0,0,1-.04-.04l-10.56-10.56h-33.75A.2.2,0,0,1,195.9823,194.4717Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M155.3423,261.6517l-2.91-2.91a.2.2,0,0,1,.28-.28l2.86,2.85h22.94v-38.66a.2.2,0,0,1,.06-.14l4.9-4.9v-5.36a.2.2,0,0,1,.39-.0889.2021.2021,0,0,1,0,.0889v5.47a.2409.2409,0,0,1-.05.14l-4.9,4.89v38.77a.21.21,0,0,1-.2.2h-23.23A.2.2,0,0,1,155.3423,261.6517Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M157.5823,265.1617a.198.198,0,0,1,.14-.34h20.18a.2.2,0,0,1,0,.4h-20.19A.2.2,0,0,1,157.5823,265.1617Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M144.7123,285.7317l-9-9a.2.2,0,0,1,0-.14v-11.58a.19.19,0,0,1,.18-.2l.01,0a.2.2,0,0,1,.2.2v11.51l8.93,8.93a.2.2,0,0,1-.28.28Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M96.8423,225.4617l-4.13-4.18a.2.2,0,0,1,0-.28l1.15-1.2a.17.17,0,0,1,.14-.06l19.26-.21a.2.2,0,0,1,.14.06l4.78,4.78a.21.21,0,0,1,0,.28l-2.89,2.88a.18.18,0,0,1-.14.06h-12.67a.2.2,0,0,1-.14-.06l-2-2h-3.35A.25.25,0,0,1,96.8423,225.4617Zm-3.75-4.32,4,4h3.32a.2.2,0,0,1,.14.06l2,2h12.47l2.69-2.68-4.58-4.58-19.09.21Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M122.5023,248.7917l-2.73-2.72a.2.2,0,0,1-.06-.14v-22.09l-5.31-5.32h-9.08l-1.61,1.66a.21.21,0,0,1-.28,0,.19.19,0,0,1-.0116-.2684l.0116-.0116,1.71-1.72a.24.24,0,0,1,.14-.05h9.24a.24.24,0,0,1,.14.05l5.43,5.43a.2.2,0,0,1,.06.14v22.1l2.61,2.61h1.27a.19.19,0,0,1,.2.18l0,.01a.2.2,0,0,1-.2.2h-1.35A.17.17,0,0,1,122.5023,248.7917Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M118.1523,272.9317a.19.19,0,0,1-.0116-.2684l.0116-.0116,7.16-7.16a.2.2,0,0,1,.14-.06h5.13l1.38-1.38v-2.11h-4.78a.19.19,0,0,1-.2-.18l0-.01v-10.3l-4.63-4.63a.24.24,0,0,1,0-.14v-21.81a.19.19,0,0,1,.18-.2l.01,0a.2.2,0,0,1,.2.2v21.78l4.63,4.63a.17.17,0,0,1,.06.14v10.13h4.78a.19.19,0,0,1,.19.19l0,.01v2.38a.2007.2007,0,0,1,0,.14l-1.5,1.5a.2.2,0,0,1-.14.06h-5.13l-7.1,7.1a.19.19,0,1,1-.38,0Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M99.9423,274.4517l-6.4-6.39a.21.21,0,0,1,0-.28l6-6a.2.2,0,0,1,.17-.13h1.78l10.49-10.48a.2.2,0,0,1,.14-.06l-1.19-1.2a.19.19,0,0,1-.0116-.2684l.0116-.0116a.21.21,0,0,1,.28,0l4.14,4.15h6.89a.17.17,0,0,1,.14.06l1.23,1.23a.2006.2006,0,0,1,.06.14v9.11a.24.24,0,0,1,0,.14l-7.08,7.07a.2.2,0,0,1-.14.06h-11l-2.83,2.83a.2.2,0,0,1-.14.06h-2.4A.2.2,0,0,1,99.9423,274.4517Zm-6-6.53,6.2,6.19h2.23l2.84-2.83a.18.18,0,0,1,.14-.06h11l7-7v-8.94l-1.12-1.11h-6.95a.2.2,0,0,1-.14-.06l-2.81-2.82a.22.22,0,0,1-.06.15l-10.56,10.58a.18.18,0,0,1-.14.06h-1.77Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M96.5123,270.2217a.21.21,0,0,1,0-.28l8.61-8.6a.2.2,0,0,1,.14-.06h12.9l2.38-2.38a.17.17,0,0,1,.14-.06h2.8a.2.2,0,0,1,.0022.4h-2.7222l-2.38,2.41a.24.24,0,0,1-.14.05h-12.9l-8.55,8.55a.19.19,0,0,1-.2683-.0154Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M108.6523,271.5617a.21.21,0,0,1,0-.28l5.52-5.52a.2.2,0,0,1,.14-.06h5.21a.2.2,0,0,1,.14.06l1.2,1.21a.19.19,0,0,1,.0115.2685l-.0115.0115a.21.21,0,0,1-.28,0l-1.14-1.15h-5l-5.47,5.46a.19.19,0,0,1-.32,0Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M99.5223,261.3617a.19.19,0,0,1-.0116-.2684l.0116-.0116,11.3-11.3a.2.2,0,1,1,.32.24.2021.2021,0,0,1-.04.04l-11.3,11.3a.19.19,0,0,1-.2685.0116Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M331.0123,216.7017a.59.59,0,0,0,1-.42l-.42-43.44,20.92-20.92a.59.59,0,0,0,.17-.42v-35a.58.58,0,0,0-.57-.59h-.02a.59.59,0,0,0-.6.58l0,.01v34.73l-20.91,20.92a.56.56,0,0,0-.18.42l.42,43.69A.56.56,0,0,0,331.0123,216.7017Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M347.9323,161.0117a.6.6,0,0,0,.84,0l5.75-5.75a.594.594,0,1,0-.84-.84l-5.75,5.74A.61.61,0,0,0,347.9323,161.0117Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M304.7123,229.7417l25.19,25.19a.58.58,0,0,0,.82.02l.02-.02,3-3a.59.59,0,0,0,.17-.42v-24.4a.61.61,0,0,0-.2-.46l-12.35-12.35a.61.61,0,0,0-.42-.17h-18.82a.6.6,0,1,0,0,1.19h18.59l12,12v23.93l-2.38,2.4-24.77-24.77a.6.6,0,0,0-.84,0,.58.58,0,0,0-.05.8187C304.6847,229.7148,304.6981,229.7286,304.7123,229.7417Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M296.1823,182.4917l5.66,5.66a.594.594,0,0,0,.84-.84l-5.49-5.49v-9.12h27.78a.59.59,0,0,0,.42-.17l19.84-19.88a.6.6,0,0,0,0-.84.58.58,0,0,0-.82-.02l-.02.02-19.68,19.7h-28.11a.62.62,0,0,0-.6.6v10A.6.6,0,0,0,296.1823,182.4917Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M301.4223,209.9717v-22.47a.6.6,0,0,1,.59-.6h22.48a.61.61,0,0,1,.59.6v22.47a.59.59,0,0,1-.58.6l-.01,0h-22.48a.62.62,0,0,1-.42-.18A.59.59,0,0,1,301.4223,209.9717Zm22.47-21.88h-21.28v21.29h21.28Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M266.6523,193.4817l7.81,7.81a.59.59,0,0,0,.42.17h26.08a.59.59,0,0,0,.6-.58l0-.01a.6.6,0,0,0-.6-.6h-25.83l-7.64-7.62a.58.58,0,0,0-.82-.02l-.02.02A.6.6,0,0,0,266.6523,193.4817Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M316.3123,151.4417a.59.59,0,0,0,.8342.0158l.0158-.0158,3.48-3.48a.6.6,0,0,0,.18-.42v-6a.6.6,0,0,0-.6-.6h0a.59.59,0,0,0-.59.59l0,.01v5.7l-3.32,3.41A.6.6,0,0,0,316.3123,151.4417Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M302.1523,167.8617a.58.58,0,0,0,.42.18h15.14a.6.6,0,0,0,.6-.6h0a.59.59,0,0,0-.59-.59l-.01,0h-15.14a.59.59,0,0,0-.6.58l0,.01A.56.56,0,0,0,302.1523,167.8617Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M299.4923,157.6517l4.38-4.38a.2.2,0,0,1,.14-.06h13.51l4.64-4.64v-15.42a.2.2,0,0,1,.05-.14l13.31-13.31a.2.2,0,0,1,.19-.05h9.39a.17.17,0,0,1,.14.06l3.26,3.26a.17.17,0,0,1,.06.14v23.54a.2.2,0,0,1-.06.14l-2,2a.2.2,0,0,1-.14.06h-3.7l-6.57,6.57a.24.24,0,0,1-.14,0h-4.94l-4.43,4.44a.2406.2406,0,0,1-.14,0h-24.82a.24.24,0,0,1-.14,0l-2-2A.19.19,0,0,1,299.4923,157.6517Zm26.84,1.94,4.43-4.44a.24.24,0,0,1,.14,0h4.94l6.56-6.57a.2.2,0,0,1,.14-.06h3.71l1.86-1.87v-23.47l-3.14-3.14h-9.26l-13.2,13.2v15.41a.2007.2007,0,0,1-.06.15l-4.74,4.7a.2.2,0,0,1-.14.06h-13.48l-4.18,4.18,1.79,1.8Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M318.7123,159.8117a.19.19,0,0,0,.2684.0116l.0116-.0116,6.52-6.51a.25.25,0,0,0,.06-.14v-12.94l6.35-6.36h8.45l1.59,1.59v9l-3.92,3.92a.2.2,0,0,0-.06.14v4.78a.2.2,0,0,0,.4,0v-4.64l3.93-4a.2.2,0,0,0,.06-.14v-9.14a.2.2,0,0,0-.06-.14l-1.71-1.71a.2.2,0,0,0-.14-.05h-8.61a.22.22,0,0,0-.14.05l-6.47,6.48a.17.17,0,0,0-.06.14v12.93l-6.47,6.46A.21.21,0,0,0,318.7123,159.8117Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M302.6523,154.9817a.24.24,0,0,0,.14,0h15.65a.2.2,0,0,0,.14,0l2.49-2.49a.2.2,0,0,0,.06-.14v-1.83a.2.2,0,0,0-.2-.2h0a.19.19,0,0,0-.19.19l0,.01v1.74l-2.38,2.39h-15.57a.19.19,0,0,0-.19.19l0,.01A.24.24,0,0,0,302.6523,154.9817Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M355.1623,145.7717a.17.17,0,0,0,.14.06.2.2,0,0,0,.2-.2h0v-33.85a.2.2,0,0,0-.06-.14l-10.62-10.61a.19.19,0,0,0-.2684-.0115l-.0116.0115a.21.21,0,0,0,0,.28l10.56,10.56v33.78A.18.18,0,0,0,355.1623,145.7717Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M287.9623,186.4117l2.92,2.91a.19.19,0,0,0,.2684.0116l.0116-.0116a.21.21,0,0,0,0-.28l-2.86-2.86v-22.94h38.65a.2.2,0,0,0,.14-.06l4.9-4.9h5.39a.19.19,0,0,0,.19-.19l0-.01a.18.18,0,0,0-.1694-.19.177.177,0,0,0-.0206,0h-5.47a.24.24,0,0,0-.14.05l-4.9,4.9h-38.77a.21.21,0,0,0-.2.2v23.23A.2.2,0,0,0,287.9623,186.4117Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M284.4623,184.1717a.2494.2494,0,0,0,.14.06.2.2,0,0,0,.2-.2h0v-20.18a.2.2,0,1,0-.4-.0022v20.1822A.2494.2494,0,0,0,284.4623,184.1717Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M263.9023,197.0717l9,9a.24.24,0,0,0,.14.05h11.6a.19.19,0,0,0,.2-.18l0-.01a.2.2,0,0,0-.2-.2h-11.54l-8.92-8.93a.2.2,0,0,0-.32.24.1969.1969,0,0,0,.04.04Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M324.1123,244.7617v-3.34l-2-2a.24.24,0,0,1-.05-.14v-12.63a.1994.1994,0,0,1,.05-.14l2.89-2.89a.21.21,0,0,1,.28,0l4.78,4.78a.2409.2409,0,0,1,.05.14l-.21,19.26a.18.18,0,0,1-.06.14l-1.2,1.14a.19.19,0,0,1-.2685.0116l-.0115-.0116-4.17-4.18A.17.17,0,0,1,324.1123,244.7617Zm5.38,2.95.22-19.06-4.58-4.58-2.69,2.69v12.45l2,2a.2409.2409,0,0,1,.05.14v3.3l4,4Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M300.8323,219.2517l2.73,2.73a.17.17,0,0,0,.14.06h22.09l5.31,5.31v9.08l-1.65,1.65a.2.2,0,0,0,.28.28l1.71-1.71a.2.2,0,0,0,.06-.14v-9.24a.2.2,0,0,0-.06-.14l-5.43-5.43a.2.2,0,0,0-.14-.06h-22.09l-2.61-2.61v-1.27a.2.2,0,0,0-.4,0v1.35A.17.17,0,0,0,300.8323,219.2517Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M276.7123,223.6517a.19.19,0,0,0,.2684.0116l.0116-.0116,7.16-7.16a.2.2,0,0,0,.06-.14v-5.13l1.38-1.38h2.12v4.78a.2.2,0,0,0,.2.2h10.3l4.62,4.63a.2393.2393,0,0,0,.14.05h21.82a.19.19,0,0,0,.2-.18l0-.01a.2.2,0,0,0-.2-.2h-21.76l-4.63-4.63a.2.2,0,0,0-.14-.06h-10.18v-4.78a.2.2,0,0,0-.2-.2h-2.39a.2494.2494,0,0,0-.14.06l-1.5,1.5a.2.2,0,0,0-.06.14v5.12l-7.1,7.11a.19.19,0,0,0,.0069.2686Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M275.1123,241.6517v-2.4a.2.2,0,0,1,.06-.14l2.83-2.83v-11a.2.2,0,0,1,.06-.14l7.08-7.08a.24.24,0,0,1,.14-.05h9.1a.24.24,0,0,1,.14.05l1.23,1.24a.17.17,0,0,1,.06.14v6.88l4.15,4.15a.2.2,0,0,1-.28.28l-1.19-1.1a.2.2,0,0,1-.06.14l-10.49,10.46v1.78a.2.2,0,0,1-.06.14l-6,6a.21.21,0,0,1-.28,0l-6.4-6.4A.2.2,0,0,1,275.1123,241.6517Zm12.43.28v-1.78a.17.17,0,0,1,.06-.14l10.55-10.55a.2.2,0,0,1,.14-.06l-2.82-2.75a.24.24,0,0,1-.05-.14v-6.89l-1.12-1.12h-8.94l-7,7v11a.2.2,0,0,1-.06.14l-2.83,2.84v2.23l6.2,6.2Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M279.4023,245.2417a.21.21,0,0,0,.28,0l8.61-8.61a.2494.2494,0,0,0,.06-.14v-12.9l2.37-2.38a.17.17,0,0,0,.06-.14v-2.81a.2.2,0,0,0-.4,0v2.73l-2.37,2.38a.17.17,0,0,0-.06.14v12.89l-8.55,8.55A.21.21,0,0,0,279.4023,245.2417Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M278.0623,233.1017a.21.21,0,0,0,.28,0l5.52-5.52a.2.2,0,0,0,.06-.14v-5.21a.2.2,0,0,0-.06-.14l-1.2-1.21a.2016.2016,0,0,0-.28.29l1.14,1.14v5l-5.46,5.47a.19.19,0,0,0,0,.32Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M288.2623,242.2317a.19.19,0,0,0,.2684.0116l.0115-.0116,11.3-11.3a.19.19,0,0,0,.0116-.2684l-.0116-.0116a.21.21,0,0,0-.28,0l-11.3,11.3A.21.21,0,0,0,288.2623,242.2317Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M228.4023,114.1017a.6.6,0,0,1,.43-1l43.43.42,20.92-20.92a.59.59,0,0,1,.42-.17h35a.6.6,0,1,1,0,1.19h-34.75l-20.92,20.9a.61.61,0,0,1-.43.17l-43.68-.42A.62.62,0,0,1,228.4023,114.1017Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M284.1023,97.1717a.6.6,0,0,1,0-.84l5.74-5.74a.6.6,0,0,1,.85.84l-5.75,5.74a.58.58,0,0,1-.82.02Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M215.3623,140.3917l-25.19-25.2a.6.6,0,0,1,0-.84l3-3a.64.64,0,0,1,.42-.18h24.43a.6006.6006,0,0,1,.42.18l12.35,12.35a.59.59,0,0,1,.17.42v18.84a.59.59,0,0,1-.59.59.6.6,0,0,1-.6-.59v-18.57l-12-12h-23.96l-2.38,2.38,24.77,24.78a.58.58,0,0,1,.02.82l-.02.02A.6.6,0,0,1,215.3623,140.3917Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M262.6223,148.9317l-5.67-5.67a.594.594,0,0,1,.84-.84l5.49,5.49h9.12v-27.77a.56.56,0,0,1,.18-.42l19.84-19.85a.6.6,0,0,1,.84,0,.61.61,0,0,1,0,.85l-19.67,19.66v28.13a.58.58,0,0,1-.57.59q-.01,0-.02,0h-10A.59.59,0,0,1,262.6223,148.9317Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M234.7123,143.5117a.59.59,0,0,1-.17-.42v-22.44a.59.59,0,0,1,.58-.6l.01,0h22.48a.61.61,0,0,1,.59.6v22.47a.59.59,0,0,1-.59.59h-22.48A.59.59,0,0,1,234.7123,143.5117Zm1-22.3v21.29h21.3v-21.29Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M251.6323,178.4517l-7.82-7.8a.59.59,0,0,1-.17-.42v-26.09a.6.6,0,0,1,1.19,0v25.84l7.64,7.63a.6.6,0,0,1,0,.84.58.58,0,0,1-.82.02Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M293.7123,128.7917a.6.6,0,0,1,0-.84l3.48-3.49a.63.63,0,0,1,.43-.17h5.94a.59.59,0,0,1,.6.58l0,.01a.6.6,0,0,1-.6.6h-5.7l-3.31,3.31A.6.6,0,0,1,293.7123,128.7917Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M277.2423,142.9617a.6.6,0,0,1-.17-.43v-15.14a.58.58,0,0,1,.57-.59q.01,0,.02,0a.59.59,0,0,1,.6.58l0,.01v15.14a.6.6,0,0,1-.6.6h0A.63.63,0,0,1,277.2423,142.9617Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M287.2223,145.6517l-2-2a.2.2,0,0,1-.06-.14v-24.86a.2.2,0,0,1,.06-.14l4.49-4.39v-4.93a.2.2,0,0,1,.06-.15l6.57-6.56v-3.71a.24.24,0,0,1,.05-.14l2-2a.17.17,0,0,1,.14-.06h23.48a.2.2,0,0,1,.14.06l3.26,3.26a.25.25,0,0,1,.06.14v9.39a.25.25,0,0,1-.06.14l-13.31,13.33a.2.2,0,0,1-.14.06h-15.38l-4.64,4.64v13.5a.17.17,0,0,1-.06.14l-4.38,4.42A.21.21,0,0,1,287.2223,145.6517Zm-1.65-2.22,1.79,1.8,4.19-4.19v-13.54a.24.24,0,0,1,.05-.14l4.76-4.71a.2.2,0,0,1,.14-.06h15.37l13.2-13.19v-9.27l-3.15-3.14h-23.4l-1.87,1.87v3.7a.2.2,0,0,1-.06.14l-6.56,6.57v4.93a.2.2,0,0,1-.06.14l-4.43,4.44Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M285.2922,126.3817a.21.21,0,0,1,0-.28l6.52-6.51a.17.17,0,0,1,.14-.06h12.94l6.35-6.36v-8.45l-1.59-1.59h-9l-3.94,3.93a.2.2,0,0,1-.14,0h-4.77a.19.19,0,0,1-.2-.18l0-.01a.2.2,0,0,1,.2-.2h4.69l3.92-3.92a.2.2,0,0,1,.15-.06h9.15a.17.17,0,0,1,.14.06l1.71,1.7a.2.2,0,0,1,.06.14v8.62a.2.2,0,0,1-.06.14l-6.47,6.47a.25.25,0,0,1-.14.06h-12.92l-6.46,6.45a.19.19,0,0,1-.2612.0631Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M290.1323,142.4517a.2.2,0,0,1-.06-.14v-15.66a.2.2,0,0,1,.06-.14l2.49-2.49a.2.2,0,0,1,.14-.06h1.82a.2.2,0,0,1,0,.4h-1.74l-2.37,2.37v15.57a.2.2,0,0,1-.2.2h0A.17.17,0,0,1,290.1323,142.4517Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M299.3323,89.9517a.24.24,0,0,1-.05-.14.19.19,0,0,1,.18-.2l.01,0h33.85a.2.2,0,0,1,.14.06l10.61,10.61a.2.2,0,1,1-.28.28l-10.55-10.56h-33.77A.2393.2393,0,0,1,299.3323,89.9517Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M258.7123,157.1417l-2.92-2.91a.21.21,0,0,1,0-.28.19.19,0,0,1,.2684-.0115l.0116.0115,2.86,2.85h22.94v-38.65a.2.2,0,0,1,.06-.14l4.9-4.9v-5.38a.2.2,0,1,1,.4-.0022v5.4722a.2.2,0,0,1-.06.14l-4.9,4.9v38.76a.2.2,0,0,1-.06.14.18.18,0,0,1-.14.06h-23.23A.17.17,0,0,1,258.7123,157.1417Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M260.9423,160.6517a.17.17,0,0,1-.06-.14.2.2,0,0,1,.2-.2h20.17a.2.2,0,1,1,0,.4h-20.17A.17.17,0,0,1,260.9423,160.6517Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M248.0323,181.2117l-9-9a.17.17,0,0,1-.06-.14v-11.58a.2.2,0,0,1,.4,0v11.51l8.92,8.93a.2.2,0,0,1-.28.28Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M200.2023,120.9417l-4.18-4.18a.21.21,0,0,1,0-.28l1.14-1.2a.2.2,0,0,1,.14-.06l19.26-.21a.17.17,0,0,1,.14.06l4.78,4.78a.19.19,0,0,1,.0115.2685l-.0115.0115-2.88,2.88a.2.2,0,0,1-.14.06h-12.62a.18.18,0,0,1-.14-.06l-2-2h-3.34A.25.25,0,0,1,200.2023,120.9417Zm-3.76-4.29,4,4h3.34a.17.17,0,0,1,.14.06l2,2h12.45l2.69-2.68-4.58-4.58-19.09.2Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M225.8523,144.2717l-2.73-2.72a.24.24,0,0,1,0-.14v-22.09l-5.41-5.32h-9l-1.69,1.65a.21.21,0,0,1-.28,0,.19.19,0,0,1-.0116-.2684l.0116-.0116,1.71-1.72a.24.24,0,0,1,.14-.05h9.24a.2.2,0,0,1,.14.05l5.43,5.43a.2.2,0,0,1,.06.14v22.1l2.61,2.61h1.27a.2.2,0,1,1,0,.39h-1.35A.2.2,0,0,1,225.8523,144.2717Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M221.5123,168.4117a.19.19,0,0,1-.0116-.2684l.0116-.0116,7.16-7.16a.17.17,0,0,1,.14-.06h5.12l1.38-1.38v-2.1h-4.78a.2.2,0,0,1-.19-.2v-10.3l-4.63-4.63a.2.2,0,0,1-.06-.14v-21.81a.2.2,0,0,1,.4,0v21.73l4.62,4.63a.17.17,0,0,1,.06.14v10.18h4.78a.2.2,0,0,1,.2.2v2.42a.2.2,0,0,1-.06.14l-1.5,1.49a.17.17,0,0,1-.14.06h-5.12l-7.1,7.1a.19.19,0,0,1-.2682-.0154Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M203.2923,169.9317l-6.39-6.39a.19.19,0,0,1-.0116-.2684l.0116-.0116,6-6a.2.2,0,0,1,.14-.06h1.78l10.52-10.55a.2.2,0,0,1,.14-.06l-1.19-1.2a.19.19,0,0,1-.0116-.2684l.0116-.0116a.21.21,0,0,1,.28,0l4.15,4.15h6.89a.2.2,0,0,1,.14.06l1.23,1.23a.2.2,0,0,1,.06.14v9.11a.2.2,0,0,1-.06.14l-7.08,7.07a.17.17,0,0,1-.14.06h-11l-2.83,2.83a.2.2,0,0,1-.14.06h-2.4A.2.2,0,0,1,203.2923,169.9317Zm-6-6.53,6.2,6.19h2.22l2.83-2.84a.2.2,0,0,1,.14-.06h11l7-7v-8.94l-1.15-1.1h-6.89a.17.17,0,0,1-.14-.06l-2.79-2.78a.2.2,0,0,1-.06.15l-10.54,10.54a.2.2,0,0,1-.14.06h-1.78Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M199.8723,165.7017a.19.19,0,0,1-.0116-.2684l.0116-.0116,8.61-8.6a.17.17,0,0,1,.14-.06h12.89l2.38-2.38a.25.25,0,0,1,.14-.06h2.81a.2.2,0,0,1,0,.4h-2.73l-2.4,2.38a.24.24,0,0,1-.14,0h-12.86l-8.55,8.55a.19.19,0,0,1-.2589.072A.194.194,0,0,1,199.8723,165.7017Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M212.0123,167.0417a.19.19,0,0,1-.0116-.2684l.0116-.0116,5.52-5.52a.18.18,0,0,1,.14-.06h5.2a.17.17,0,0,1,.14.06l1.21,1.21a.19.19,0,0,1,.0115.2685l-.0115.0115a.21.21,0,0,1-.28,0l-1.15-1.15h-5l-5.46,5.46a.19.19,0,0,1-.32,0Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M202.8723,156.8417a.21.21,0,0,1,0-.28l11.3-11.3a.21.21,0,0,1,.28,0,.19.19,0,0,1,.0115.2685l-.0115.0115-11.3,11.3a.19.19,0,0,1-.2685.0116Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M157.7123,168.7417a.6.6,0,0,0,.43.18.59.59,0,0,0,.59-.59v-.01l-.42-43.44,20.91-20.91a.58.58,0,0,0,.18-.42v-35a.6.6,0,0,0-.6-.59.59.59,0,0,0-.59.59v34.75l-20.91,20.92a.59.59,0,0,0-.17.42l.42,43.69A.5893.5893,0,0,0,157.7123,168.7417Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M174.6423,113.0517a.61.61,0,0,0,.85,0l5.74-5.75a.594.594,0,0,0-.84-.84l-5.75,5.75A.5984.5984,0,0,0,174.6423,113.0517Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M131.4323,181.7817l25.2,25.2a.6.6,0,0,0,.84,0l3-3a.6.6,0,0,0,.18-.42v-24.4a.63.63,0,0,0-.17-.43l-12.35-12.34a.56.56,0,0,0-.42-.18h-18.88a.59.59,0,0,0-.59.59l0,.01a.58.58,0,0,0,.57.59c.0067,0,.0134,0,.02,0h18.6l12,12v23.94l-2.38,2.38-24.78-24.78a.594.594,0,0,0-.84.84Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M122.8923,134.5317l5.67,5.66a.58.58,0,0,0,.82.02l.02-.02a.6.6,0,0,0,0-.84l-5.49-5.49v-9.11h27.8a.6.6,0,0,0,.42-.18l19.84-19.84a.58.58,0,0,0,.02-.82l-.02-.02a.6.6,0,0,0-.84,0l-19.69,19.67h-28.13a.58.58,0,0,0-.59.57c0,.0068,0,.0135,0,.02v10A.59.59,0,0,0,122.8923,134.5317Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M128.1323,162.0217v-22.48a.59.59,0,0,1,.59-.59l.01,0h22.47a.61.61,0,0,1,.6.59v22.48a.6.6,0,0,1-.6.59h-22.49a.59.59,0,0,1-.42-.17A.64.64,0,0,1,128.1323,162.0217Zm22.48-21.89h-21.29v21.28h21.29Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M93.3723,145.5217l7.81,7.81a.5993.5993,0,0,0,.42.18h26.11a.59.59,0,0,0,.59-.59v-.01a.58.58,0,0,0-.57-.59q-.01,0-.02,0h-25.87l-7.63-7.67a.6.6,0,0,0-.84,0,.58.58,0,0,0-.0514.8186A.5667.5667,0,0,0,93.3723,145.5217Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M143.0323,103.4917a.6.6,0,0,0,.84,0l3.49-3.49a.59.59,0,0,0,.17-.42v-5.93a.6.6,0,0,0-1.19,0v5.7l-3.31,3.3A.6.6,0,0,0,143.0323,103.4917Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M128.8623,119.9117a.63.63,0,0,0,.42.17h15.15a.6.6,0,0,0,0-1.19h-15.15a.6.6,0,0,0-.59.6A.63.63,0,0,0,128.8623,119.9117Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M126.2023,109.6517l4.39-4.38a.17.17,0,0,1,.14-.06h13.5l4.64-4.63v-15.39a.2.2,0,0,1,.06-.14l13.31-13.31a.2.2,0,0,1,.14-.06h9.39a.2.2,0,0,1,.14.06l3.26,3.26a.2006.2006,0,0,1,.06.14v23.57a.2.2,0,0,1-.06.14l-2,2a.2.2,0,0,1-.15.06h-3.7l-6.57,6.57a.17.17,0,0,1-.14.06h-4.93l-4.43,4.43a.2.2,0,0,1-.15.06h-24.76a.18.18,0,0,1-.14-.06l-2-2A.23.23,0,0,1,126.2023,109.6517Zm26.84,1.94,4.44-4.43a.17.17,0,0,1,.14-.06h4.93l6.57-6.57a.25.25,0,0,1,.14-.06h3.7l1.87-1.82v-23.43l-3.12-3.14h-9.23l-13.21,13.19v15.38a.2.2,0,0,1-.06.14l-4.76,4.75a.17.17,0,0,1-.14.06h-13.5l-4.19,4.18,1.8,1.8Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M145.4423,111.8517a.19.19,0,0,0,.2684.0115l.0116-.0115,6.51-6.51a.2.2,0,0,0,.06-.14v-12.94l6.36-6.35h8.45l1.61,1.58v9l-3.93,3.92a.2494.2494,0,0,0-.06.14v4.8a.2.2,0,0,0,.4,0v-4.7l3.92-3.92a.2.2,0,0,0,.06-.14v-9.18a.2.2,0,0,0-.06-.14l-1.7-1.7a.2.2,0,0,0-.14-.06h-8.62a.2.2,0,0,0-.14.06l-6.47,6.47a.2.2,0,0,0-.06.14v12.94l-6.45,6.45a.19.19,0,0,0-.0314.2669Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M129.3723,107.0217a.22.22,0,0,0,.14.06h15.65a.2.2,0,0,0,.14-.06l2.49-2.49a.2.2,0,0,0,.06-.14v-1.83a.2.2,0,0,0-.4,0v1.75l-2.37,2.34h-15.57a.2193.2193,0,0,0-.14.37Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M181.8723,97.8117a.198.198,0,0,0,.34-.14v-33.84a.17.17,0,0,0-.06-.14l-10.61-10.62a.21.21,0,0,0-.28,0,.19.19,0,0,0-.0116.2685l.0116.0115,10.55,10.56v33.74A.2.2,0,0,0,181.8723,97.8117Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M114.7123,138.4517l2.91,2.91a.2.2,0,0,0,.28-.28l-2.85-2.85v-23h38.66a.2.2,0,0,0,.14-.06l4.9-4.89h5.38a.2.2,0,1,0,0-.4h-5.47a.2.2,0,0,0-.14.06l-4.9,4.9h-38.8a.19.19,0,0,0-.2.18l0,.01v23.28A.2.2,0,0,0,114.7123,138.4517Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M111.1823,136.2117a.17.17,0,0,0,.14.06.2.2,0,0,0,.2-.2v-20.17a.2.2,0,0,0-.4,0v20.17A.18.18,0,0,0,111.1823,136.2117Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M90.6123,149.1217l9,9a.2.2,0,0,0,.14.06h11.6a.2.2,0,1,0,0-.4h-11.53l-8.93-8.93a.1945.1945,0,1,0-.28.27Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M150.8223,196.8117v-3.35l-2-2a.2.2,0,0,1-.06-.14v-12.67a.2.2,0,0,1,.06-.14l2.89-2.86a.19.19,0,0,1,.2684-.0115l.0116.0115,4.78,4.77a.2006.2006,0,0,1,.06.14l-.21,19.26a.2.2,0,0,1-.06.15l-1.21,1.14a.19.19,0,0,1-.2686.0013l-.0014-.0013-4.18-4.17A.2.2,0,0,1,150.8223,196.8117Zm5.38,3,.21-19.1-4.58-4.57-2.68,2.68v12.46l2,2a.2.2,0,0,1,.06.14v3.34l4,4Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M127.5423,171.3017l2.73,2.72a.2.2,0,0,0,.14.06h22.09l5.32,5.32v9.07l-1.66,1.65a.2.2,0,0,0,.24.32.2021.2021,0,0,0,.04-.04l1.71-1.71a.2.2,0,0,0,.06-.14v-9.24a.2.2,0,0,0-.06-.14l-5.44-5.43a.17.17,0,0,0-.14-.06h-22.08l-2.61-2.61v-1.27a.19.19,0,0,0-.19-.19l-.01,0a.18.18,0,0,0-.19.1694.177.177,0,0,0,0,.0206v1.36A.24.24,0,0,0,127.5423,171.3017Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M103.4123,175.6517a.19.19,0,0,0,.2684.0116l.0116-.0116,7.16-7.16a.2508.2508,0,0,0,.06-.14v-5.13l1.38-1.38h2.1v4.81a.2.2,0,0,0,.2.2h10.3l4.63,4.63a.2.2,0,0,0,.14.06h21.81a.2.2,0,1,0,.0022-.4H129.7123l-4.63-4.63a.17.17,0,0,0-.14-.06h-10.15v-4.8a.2.2,0,0,0-.2-.2h-2.39a.2.2,0,0,0-.14.06l-1.49,1.49a.2.2,0,0,0-.06.14v5.13l-7.1,7.1a.1843.1843,0,0,0-.0269.2626A.0422.0422,0,0,0,103.4123,175.6517Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M101.8323,193.7117v-2.39a.17.17,0,0,1,.06-.14l2.82-2.84v-11a.2.2,0,0,1,.06-.14l7.07-7.07a.2.2,0,0,1,.14-.06h9.11a.2.2,0,0,1,.14.06l1.23,1.23a.2.2,0,0,1,.06.14v6.89l4.15,4.15a.21.21,0,0,1,0,.28.23.23,0,0,1-.29,0l-1.19-1.2a.18.18,0,0,1-.06.14l-10.42,10.53v1.78a.2.2,0,0,1-.06.14l-6,6a.19.19,0,0,1-.2685.0116l-.0115-.0116-6.39-6.4A.18.18,0,0,1,101.8323,193.7117Zm12.43.28v-1.78a.2.2,0,0,1,.06-.14l10.54-10.55a.19.19,0,0,1,.14-.05l-2.81-2.82a.2.2,0,0,1-.06-.14v-6.86l-1.12-1.11h-8.93l-7,6.95v11a.2.2,0,0,1-.06.14l-2.84,2.84v2.18l6.2,6.2Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M106.1123,197.2817a.19.19,0,0,0,.2684.0116l.0116-.0116,8.61-8.63a.2.2,0,0,0,.06-.14v-12.86l2.38-2.37a.24.24,0,0,0,0-.14v-2.81a.19.19,0,0,0-.18-.2l-.01,0a.2.2,0,0,0-.2.2v2.72l-2.34,2.36a.2.2,0,0,0-.06.14v12.9l-8.55,8.55A.21.21,0,0,0,106.1123,197.2817Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M104.7823,185.1417a.19.19,0,0,0,.2684.0116l.0116-.0116,5.52-5.49a.2.2,0,0,0,.06-.14v-5.21a.2006.2006,0,0,0-.06-.14l-1.21-1.2a.2.2,0,0,0-.28.28l1.15,1.15v5l-5.46,5.46a.2.2,0,0,0,.0031.2769C104.787,185.13,104.7886,185.1319,104.7823,185.1417Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M114.9823,194.2717a.19.19,0,0,0,.2684.0116l.0116-.0116,11.29-11.29a.2.2,0,1,0-.24-.32.1969.1969,0,0,0-.04.04l-11.29,11.29a.19.19,0,0,0-.0116.2685Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M55.1223,66.1417a.59.59,0,0,1,.42-1l43.44.42,20.91-20.91a.64.64,0,0,1,.42-.18h35a.59.59,0,0,1,.59.59l0,.01a.58.58,0,0,1-.57.59h-34.77l-20.92,20.9a.55.55,0,0,1-.42.17l-43.69-.42A.55.55,0,0,1,55.1223,66.1417Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M110.8123,49.2217a.6.6,0,0,1,0-.84l5.75-5.73a.594.594,0,0,1,.84.84l-5.75,5.73A.6.6,0,0,1,110.8123,49.2217Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M42.0823,92.4317l-25.2-25.19a.6.6,0,0,1,0-.84l3-3a.59.59,0,0,1,.42-.17h24.41a.61.61,0,0,1,.42.17l12.35,12.37a.59.59,0,0,1,.17.42v18.84a.6.6,0,0,1-1.19,0v-18.59l-12-12h-23.93l-2.38,2.38,24.77,24.77a.594.594,0,0,1-.84.84Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M89.3323,100.9717l-5.62-5.66a.6.6,0,0,1,.84-.85l5.49,5.49h9.12v-27.77a.59.59,0,0,1,.17-.42l19.84-19.84a.594.594,0,0,1,.84.84l-19.7,19.67v28.12a.6.6,0,0,1-.18.42.59.59,0,0,1-.42.17h-10A.59.59,0,0,1,89.3323,100.9717Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M61.4323,95.5517a.56.56,0,0,1-.18-.42v-22.48a.6.6,0,0,1,.6-.6h22.47a.62.62,0,0,1,.6.6v22.48a.6.6,0,0,1-.6.6h-22.47A.5993.5993,0,0,1,61.4323,95.5517Zm1-22.3v21.29h21.28v-21.29Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M78.3423,130.5017l-7.81-7.85a.59.59,0,0,1-.17-.42v-26.05a.59.59,0,0,1,.59-.59h0a.6.6,0,0,1,.6.59v25.84l7.63,7.63a.58.58,0,0,1,.02.82l-.02.02A.6.6,0,0,1,78.3423,130.5017Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M120.3723,80.8317a.6.6,0,0,1,0-.84l3.49-3.48a.56.56,0,0,1,.42-.18h6a.6.6,0,0,1,0,1.19h-5.7l-3.31,3.31a.61.61,0,0,1-.8619.0382C120.3972,80.8577,120.3844,80.845,120.3723,80.8317Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M103.9623,95.0017a.6.6,0,0,1-.18-.42v-15.15a.6.6,0,0,1,1.19,0v15.15a.59.59,0,0,1-.59.59A.56.56,0,0,1,103.9623,95.0017Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M113.9423,97.6517l-2-2a.17.17,0,0,1-.06-.14v-24.77a.17.17,0,0,1,.06-.14l4.44-4.44v-4.93a.2192.2192,0,0,1,0-.14l6.57-6.57v-3.7a.17.17,0,0,1,.06-.14l2-2a.24.24,0,0,1,.14,0h23.56a.2206.2206,0,0,1,.14,0l3.26,3.26a.2006.2006,0,0,1,.06.14v9.39a.17.17,0,0,1-.06.14l-13.3,13.32a.18.18,0,0,1-.14.06h-15.37l-4.59,4.66v13.49a.2.2,0,0,1-.06.14l-4.43,4.37a.19.19,0,0,1-.2685.0116Zm-1.66-2.22,1.8,1.8,4.18-4.19v-13.49a.17.17,0,0,1,.06-.14l4.75-4.76a.25.25,0,0,1,.14-.06h15.38l13.19-13.19v-9.22l-3.14-3.15h-23.41l-1.86,1.87v3.75a.18.18,0,0,1-.06.14l-6.6,6.52v4.93a.17.17,0,0,1-.06.14l-4.43,4.44Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M112.0123,78.4217a.19.19,0,0,1-.0116-.2684l.0116-.0116,6.51-6.49a.2.2,0,0,1,.14-.06h12.94l6.36-6.35v-8.47l-1.59-1.59h-9l-3.92,3.92a.2.2,0,0,1-.14.06h-4.8a.2.2,0,0,1,0-.4h4.7l3.92-3.92a.2.2,0,0,1,.14-.06h9.18a.2.2,0,0,1,.14.06l1.7,1.7a.2006.2006,0,0,1,.06.14v8.62a.2.2,0,0,1-.06.14l-6.47,6.47a.17.17,0,0,1-.14.06h-12.97l-6.45,6.45A.19.19,0,0,1,112.0123,78.4217Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M116.8423,94.4917a.2.2,0,0,1,0-.14v-15.65a.2386.2386,0,0,1,0-.14l2.49-2.49a.25.25,0,0,1,.14-.06h1.83a.2.2,0,0,1,0,.4h-1.74l-2.38,2.37v15.57a.198.198,0,0,1-.34.14Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M126.0523,41.9917a.198.198,0,0,1,.14-.34h33.84a.17.17,0,0,1,.14.06l10.62,10.61a.2.2,0,0,1-.28.28l-10.56-10.55h-33.76A.2.2,0,0,1,126.0523,41.9917Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M85.4123,109.1817l-2.91-2.91a.2.2,0,0,1,.28-.28l2.85,2.86h23v-38.65a.17.17,0,0,1,.06-.14l4.9-4.9v-5.39a.2.2,0,0,1,.39-.0889.2021.2021,0,0,1,0,.0889v5.47a.2.2,0,0,1-.06.14l-4.89,4.9v38.76a.2051.2051,0,0,1-.2.2h-23.28A.2.2,0,0,1,85.4123,109.1817Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M87.6523,112.6517a.17.17,0,0,1-.06-.14.19.19,0,0,1,.19-.19l.01,0h20.18a.18.18,0,0,1,.19.1694.177.177,0,0,1,0,.0206.19.19,0,0,1-.18.2l-.01,0h-20.18A.2.2,0,0,1,87.6523,112.6517Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M74.7123,133.2517l-9-9a.2494.2494,0,0,1-.06-.14v-11.58a.2.2,0,0,1,.4,0h0v11.51l8.93,8.93a.21.21,0,0,1,0,.28.19.19,0,0,1-.2687.0013Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M26.9123,72.9817l-4.2-4.18a.19.19,0,0,1-.0013-.2687l.0013-.0013,1.15-1.21a.2.2,0,0,1,.14-.06l19.26-.21a.2.2,0,0,1,.14.06l4.78,4.78a.21.21,0,0,1,0,.28l-2.87,2.88a.2.2,0,0,1-.14.06h-12.62a.2.2,0,0,1-.14-.06l-2-2h-3.36A.2.2,0,0,1,26.9123,72.9817Zm-3.75-4.33,4,4h3.34a.2.2,0,0,1,.14.06l2,2h12.45l2.69-2.68-4.58-4.58-19.09.2Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M52.5723,96.3217l-2.73-2.73a.2.2,0,0,1-.06-.14v-22.09l-5.31-5.32h-9.08l-1.68,1.66a.2.2,0,0,1-.28-.28l1.71-1.71a.2.2,0,0,1,.17-.06h9.24a.2.2,0,0,1,.14.06l5.43,5.43a.17.17,0,0,1,.06.14v22.09l2.61,2.61h1.27a.2.2,0,1,1,0,.4h-1.35A.2.2,0,0,1,52.5723,96.3217Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M48.2223,120.4617a.19.19,0,0,1-.0116-.2685l.0116-.0115,7.16-7.17a.24.24,0,0,1,.14,0h5.13l1.38-1.38v-2.16h-4.78a.2.2,0,0,1-.2-.2v-10.3l-4.63-4.63a.2494.2494,0,0,1-.06-.14v-21.81a.2.2,0,1,1,.4,0h0v21.73l4.63,4.63a.2006.2006,0,0,1,.06.14v10.18h4.78a.19.19,0,0,1,.19.19l0,.01v2.38a.2409.2409,0,0,1-.05.14l-1.5,1.49a.2.2,0,0,1-.14.06h-5.13l-7.1,7.11A.21.21,0,0,1,48.2223,120.4617Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M30.0123,121.9817l-6.4-6.4a.21.21,0,0,1,0-.28l6-6a.24.24,0,0,1,.14,0h1.78l10.53-10.58a.2.2,0,0,1,.14-.06l-1.19-1.19a.2.2,0,1,1,.24-.32.2021.2021,0,0,1,.04.04l4.14,4.14h6.89a.2.2,0,0,1,.14.06l1.25,1.26a.2006.2006,0,0,1,.06.14v9.11a.2.2,0,0,1-.06.14l-7.07,7.07a.2.2,0,0,1-.14.06h-11l-2.79,2.81a.24.24,0,0,1-.14,0h-2.4A.24.24,0,0,1,30.0123,121.9817Zm-6-6.54,6.2,6.2h2.23l2.84-2.84a.2.2,0,0,1,.14-.05h11l7-7v-8.9l-1.12-1.12h-6.95a.2.2,0,0,1-.14-.08l-2.81-2.81a.2.2,0,0,1-.06.14l-10.55,10.56a.2.2,0,0,1-.14.06h-1.78Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M26.5823,117.7517a.21.21,0,0,1,0-.28l8.61-8.61a.2.2,0,0,1,.14-.06h12.9l2.37-2.37a.2.2,0,0,1,.15-.06h2.8a.2.2,0,1,1,0,.39h-2.72l-2.38,2.38a.2.2,0,0,1-.14.06h-12.9l-8.55,8.55A.21.21,0,0,1,26.5823,117.7517Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M38.7123,119.0817a.19.19,0,0,1-.0116-.2684l.0116-.0116,5.52-5.52a.25.25,0,0,1,.14-.06h5.21a.25.25,0,0,1,.14.06l1.2,1.21a.2.2,0,0,1-.28.28l-1.13-1.12h-5l-5.47,5.46a.19.19,0,0,1-.33-.03Z"
                transform="translate(-16.7107 -41.6514)" />
            <path className="path-caption--magent"
                d="M29.5923,108.8817a.19.19,0,0,1-.0116-.2684l.0116-.0116,11.3-11.29a.19.19,0,0,1,.2684-.0115l.0116.0115a.21.21,0,0,1,0,.28l-11.3,11.29a.19.19,0,0,1-.2685.0116Z"
                transform="translate(-16.7107 -41.6514)" />
        </svg>
    );
}

export default memo(MiniAirCrafts);