import React, { useState, useCallback } from 'react';

import { render } from 'react-dom';

import { useTranslation } from 'react-i18next';
import { emailRegex } from "../constants";

import FlashMessage from "../Element/FlashMessage";

const SUBJECT_MAX_LENGTH = 256;
const NAME_MAX_LENGTH = 80;

export const ContactForm: React.FC<IntrinsicProps> = (props) => {
    const { t } = useTranslation();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');

    const validateForm = useCallback((): boolean => {
        return name.length > 0 && email.length > 0 && subject.length > 0;
    }, [name, email, subject]);

    const sendForm = useCallback((e: React.MouseEvent): void => {
        e.preventDefault();
        let errorMessages = [];
        if (name.length === 0) {
            errorMessages.push(t('messages.flash.error.empty.name'));
        } else if (name.length > NAME_MAX_LENGTH) {
            errorMessages.push(t('messages.flash.error.exceeded.name'));
        }
        if (subject.length === 0) {
            errorMessages.push(t('messages.flash.error.empty.subject'));
        } else if (subject.length > SUBJECT_MAX_LENGTH) {
            errorMessages.push(t('messages.flash.error.exceeded.subject'));
        }


        if (!email.match(emailRegex)) {
            errorMessages.push(t('messages.flash.error.email'))
        }

        if (errorMessages.length > 0) {
            render(
                <>
                    {errorMessages.map((msg, index) => {
                        return (
                            <FlashMessage
                                message={t(msg)}
                                type="error"
                                timeoutMs={4000}
                                // shows only the first message, the incoming are hidden
                                classes={index > 0 ? "hide" : ""}
                            />
                        );
                    })}
                </>,
                document.getElementById('messages')
            );
            return
        }
        // at this point the form is valid
        render(
            <FlashMessage
                message={t('messages.flash.success.contact')}
                type="success"
                timeoutMs={3000}
            />,
            document.getElementById('messages')
        );
    }, [name, email, subject, t]);

    return (
        <form className="form-contact" id="contactForm">
            <div className="form-wrapper">  {/* give inputs a trapezoidal shape */}
                <div>
                    <input
                        type="text"
                        placeholder={t('contact.placeholder.name')}
                        name="name"
                        className="tunneled"
                        onChange={(e: React.FormEvent<HTMLInputElement>) => setName(e.currentTarget.value)}
                        maxLength={NAME_MAX_LENGTH}
                    />
                </div>
                <div>
                    <input
                        type="text"
                        placeholder={t('contact.placeholder.email')}
                        name="email"
                        className="tunneled"
                        onChange={(e: React.FormEvent<HTMLInputElement>) => setEmail(e.currentTarget.value)}
                    />
                </div>
                <div>
                    <textarea
                        placeholder={t('contact.placeholder.subject')}
                        name="subject"
                        className="tunneled"
                        onChange={(e: React.FormEvent<HTMLTextAreaElement>) => setSubject(e.currentTarget.value)}
                        maxLength={SUBJECT_MAX_LENGTH}
                    />
                </div>
            </div>
            {props.children}
            <input type="submit" disabled={!validateForm()} onClick={sendForm} value={t('contact.submit_button') as string} />
        </form>
    )
}

export default ContactForm;
