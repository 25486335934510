import React, {useRef} from 'react';

import { openTab } from '../../utils/functions';

export const LinkedIn: React.FC<SocialMediaProps> = ({ link }) => {
    return (
        <svg className="svg--social-media" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" onClick={() => openTab(link)}>
            <path
                d="M19,0H5A5.0005,5.0005,0,0,0,0,5V19a5.0005,5.0005,0,0,0,5,5H19a5,5,0,0,0,5-5V5A5,5,0,0,0,19,0ZM8,19H5V8H8ZM6.5,6.732A1.7641,1.7641,0,1,1,8.25,4.968,1.7575,1.7575,0,0,1,6.5,6.732ZM20,19H17V13.396c0-3.368-4-3.113-4,0V19H10V8h3V9.765c1.396-2.586,7-2.777,7,2.476Z"
                transform="translate(0 0)" />
        </svg>
    )
}

export const GitHub: React.FC<SocialMediaProps> = ({ link }) => {
    return (
        <svg className="svg--social-media" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 438.546 427.8185" onClick={() => openTab(link)}>
            <path
                d="M409.132,114.573a218.3183,218.3183,0,0,0-79.798-79.8Q278.937,5.3625,219.271,5.365q-59.6715,0-110.063,29.408a218.2935,218.2935,0,0,0-79.8,79.8Q.0005,164.9655,0,224.63,0,296.3,41.827,353.536q41.826,57.246,108.063,79.227,7.71,1.431,11.419-1.996a11.1655,11.1655,0,0,0,3.711-8.562q0-.8565-.144-15.417-.147-14.5635-.144-25.406l-6.567,1.136a83.77,83.77,0,0,1-15.846,1,120.73,120.73,0,0,1-19.842-1.999,44.3389,44.3389,0,0,1-19.13-8.559,36.2259,36.2259,0,0,1-12.56-17.556l-2.855-6.57a71.3417,71.3417,0,0,0-8.992-14.559q-6.1395-7.9965-12.419-10.848l-1.999-1.431a20.96,20.96,0,0,1-3.711-3.429,15.6628,15.6628,0,0,1-2.568-3.997q-.858-2.0025,1.427-3.289c1.525-.859,4.281-1.276,8.28-1.276l5.708.853q5.7105,1.1445,14.133,6.851a46.081,46.081,0,0,1,13.846,14.842q6.57,11.709,15.846,17.847t18.699,6.136a81.1912,81.1912,0,0,0,16.274-1.423,56.7853,56.7853,0,0,0,12.847-4.285q2.57-19.137,13.988-29.41a195.497,195.497,0,0,1-29.264-5.14,116.5207,116.5207,0,0,1-26.835-11.14A76.8614,76.8614,0,0,1,100.207,286.01q-9.135-11.421-14.987-29.979-5.8515-18.5608-5.852-42.826,0-34.5525,22.557-58.817-10.566-25.977,1.997-58.24,8.28-2.5725,24.554,3.853t23.84,10.994q7.569,4.5615,12.135,7.708a206.1958,206.1958,0,0,1,109.641,0l10.849-6.849a153.6534,153.6534,0,0,1,26.262-12.565q15.132-5.7075,23.134-3.138,12.843,32.2635,2.279,58.24,22.554,24.27,22.559,58.817,0,24.267-5.853,42.966-5.85,18.7065-15.125,29.979a79.8179,79.8179,0,0,1-23.131,18.986,116.7439,116.7439,0,0,1-26.84,11.136,195.2934,195.2934,0,0,1-29.263,5.146q14.841,12.843,14.842,40.539v60.237a11.3731,11.3731,0,0,0,3.572,8.562q3.5685,3.4185,11.276,1.995,66.2445-21.9795,108.068-79.226,41.82-57.2415,41.825-128.906Q438.531,164.9656,409.132,114.573Z"
                transform="translate(0 -5.365)" />
        </svg>
    );
}

export const Google: React.FC<SocialMediaProps> = ({ link, ref, ...props }) => {
    const localRef = useRef(null);
    const componentRef = ref || localRef;
    return (
        <svg
            ref={componentRef}
            className="svg--social-media"
            onClick={() => openTab(link)}
            viewBox="0 0 24 24" width="24" height="24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
                <path fill={'colorful' in props ? "#4285F4" : ""} d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z" />
                <path fill={'colorful' in props ? "#34A853" : ""} d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z" />
                <path fill={'colorful' in props ? "#FBBC05" : ""} d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z" />
                <path fill={'colorful' in props ? "#EA4335" : ""} d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z" />
            </g>
        </svg>
    );
}

export const Twitter: React.FC<SocialMediaProps> = ({ link }) => {
    return (
        <svg className="svg--social-media"
            onClick={() => openTab(link)}
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 19.504">
            <path
                d="M24,4.557a9.8306,9.8306,0,0,1-2.828.775,4.9319,4.9319,0,0,0,2.165-2.724A9.8636,9.8636,0,0,1,20.21,3.803a4.9271,4.9271,0,0,0-8.391,4.49A13.9782,13.9782,0,0,1,1.671,3.149,4.93,4.93,0,0,0,3.194,9.723,4.903,4.903,0,0,1,.965,9.107a4.9275,4.9275,0,0,0,3.949,4.89,4.9353,4.9353,0,0,1-2.224.084A4.9275,4.9275,0,0,0,7.29,17.5,9.9,9.9,0,0,1,0,19.54a13.9391,13.9391,0,0,0,7.548,2.212A13.9253,13.9253,0,0,0,21.543,7.106,10.0247,10.0247,0,0,0,24,4.557Z"
                transform="translate(0 -2.248)" />
        </svg>
    )
}
