import React, { useRef, useLayoutEffect } from 'react';

import { render } from 'react-dom';

import { capitalize } from '../../utils/functions';

const mapSubPath = (curPath: string, index: number, subPaths: string[]) => {
    const relPath = subPaths.slice(0, index).join("/");
    const showName = capitalize(curPath);
    return (
        <li>
            <a href={relPath}>{showName}</a>
        </li>
    );
}

export const Navigation: React.FC = () => {
    const listRef = useRef(null);

    useLayoutEffect(() => {
        const subPaths: string[] = window.location.pathname.split("/").slice(1);
        render(
            <>
                <li>
                    <a href={window.location.origin}>Home</a>
                </li>
                {subPaths.map((path, index) => mapSubPath(path, index, subPaths))}
            </>,
            listRef.current
        )
    });

    return (
        <div className="dshb-path">
            <ol ref={listRef}>

            </ol>
        </div>
    );
}

export default Navigation;