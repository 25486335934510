import React from 'react'

import Navigation from '../Navigation';

export const DashboardView: React.FC<DashboardViewProps> = ({ header, Icon, children }) => {
    return (
        <div className="v-dshb">
            <header className="c-page-dshb dshb-page-header">
                <div className="dshb-page-header--icon">
                    <Icon />
                </div>
                <div className="dshb-page-header--title">
                    <h1>{header}</h1>
                </div>
                <Navigation />
            </header>
            {children}
        </div>
    );
}

export default DashboardView;