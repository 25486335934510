import React, { useState, useRef, memo } from 'react';

import { useTranslation } from 'react-i18next';
import { GitHub, Google } from '../Other/Icons';
import Icon from '../Rootrilla/Icon';
import Tooltip from '../Element/Tooltip';

const redirectOAuth = (brand: string) => window.location.replace("/login/" + brand);

const submitForm = (e: React.MouseEvent): void => {
    e.preventDefault();

    const form = document.querySelector('.v-login form.form-login') as HTMLFormElement;

    form.submit();

}

export const LoginForm: React.FC = () => {
    const { t } = useTranslation();

    const googleBtnRef = useRef(null);
    const rootrillaBtnRef = useRef(null);

    const [username, setUsername] = useState('');
    const [pw, setPw] = useState('');

    const validateForm = (): boolean => {
        return username.length > 0 && pw.length > 0;
    }

    const isFormValid = validateForm();
    
    return (
        <form className="form-login" method="POST">
            <input
                type="text"
                name="username"
                placeholder={t('login.placeholder.username')}
                onChange={(e: React.FormEvent<HTMLInputElement>) => setUsername(e.currentTarget.value)}
            />
            <input
                type="password"
                name="pw"
                placeholder={t('login.placeholder.password')}
                onChange={(e: React.FormEvent<HTMLInputElement>) => setPw(e.currentTarget.value)}
            />
            <div

                className="btn-app"
                aria-disabled={!isFormValid}
                ref={rootrillaBtnRef}
                id="rootrillaBtn"
                onClick={isFormValid ? submitForm : () => { }}
            >
                <Icon />
                <p>{t('login.btn.submit')}</p>
                {!isFormValid ?
                    <Tooltip text="Fill in the  blanks" target={rootrillaBtnRef} />
                    : <></>
                }
            </div>
            <div className="hor-line--or">or</div>
            <div className="form-login--btns">
                <div
                    className="btn-app"
                    id="githubBtn"
                    onClick={() => redirectOAuth("github")}
                >
                    <GitHub link="/login/git" />
                    <p>{t('login.btn.submit')}</p>
                </div>
                <div
                    className="btn-app"
                    id="googleBtn"
                    ref={googleBtnRef}
                    aria-disabled="true"
                >
                    <Google link="#" colorful />
                    <p>{t('login.btn.submit')}</p>
                </div>
            </div>
        </form>
    )
}

export default memo(LoginForm);