import React, { useMemo } from 'react';

// make easier get diagonal alignment in text
export const Polygon: React.FC<PolygonProps> = ({ direction, shape, height, width, ...props }) => {
    const polygonStyle = useMemo(() => ({
        float: direction,
        shapeOutside: shape,
        height: height,
        width: width
    }), [direction, shape, height, width]);

    return (
        <div
            className="polygon"
            id={"id" in props ? props.id : ""}
            style={polygonStyle}
        >
        </div>
    )
}
