import React, { useEffect } from 'react';

import { getTheme } from '../../utils/storage';

import Logo from '../Rootrilla/Logo';
import { Spinner } from '../Element/LoadingSpinner';


export const Loading: React.FC = () => {
    const theme = getTheme();
    useEffect(() => {
        document.title = "Loading ...";
    })
    return (
        <div className={"v-load" + (theme === "dark" ? " dark" : "")}>
            <main>
                <Logo />
                <Spinner />
            </main>
        </div>
    );
}

export default Loading;