import React, { useEffect } from 'react';

import { SCROLL_UP_MIN_SCROLL } from '../constants';

const scrollTop = () => {
    const appContainer = document.getElementsByClassName('App')[0] as HTMLDivElement;
    const btn = document.getElementById('scrollUp') as HTMLDivElement;
    btn.style.transform = 'translateY(-15%)';
    btn.style.opacity = '0';

    setTimeout(() => appContainer.scrollTo(0, 0), 200);
}

const restore = (e: React.MouseEvent) => {
    const btn = e.currentTarget as HTMLDivElement;
    btn.style.transform = 'translateY(0%)';
};

const shiftDown = (e: React.MouseEvent) => {
    const btn = e.currentTarget as HTMLDivElement;
    btn.style.transform = 'translateY(8%)';
}

export const ScrollUp: React.FC = () => {

    useEffect(() => {
        const btn = document.getElementById('scrollUp') as HTMLDivElement;

        const appContainer = document.getElementsByClassName('App')[0] as HTMLDivElement;

        appContainer.onscroll = () => {
            if (appContainer.scrollTop > SCROLL_UP_MIN_SCROLL) {
                btn.style.opacity = '1';
                btn.style.pointerEvents = 'all';
                btn.style.transform = 'translateY(0)';
                return;
            }
            btn.style.opacity = '0';
            btn.style.pointerEvents = 'none';
            btn.style.transform = 'translateY(10%)';
        };
    }, []);

    return (
        <div id="scrollUp" onClick={scrollTop} onMouseEnter={shiftDown} onMouseLeave={restore}>
            <svg
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 117.856 181.4512">
                <polygon className="path-caption--white" points="45.496 99.243 45.496 36.035 18.773 63.124 0.714 46.015 45.496 0.5 72.951 0.5 117.149 46.015 99.09 63.599 72.298 35.767 72.418 72.628 45.496 99.243" />
                <polygon className="path-caption--red_2" points="45.496 108.569 72.654 81.48 72.654 99.243 45.496 126.926 45.496 108.569" />
                <polygon className="path-caption--magent" points="45.496 135.658 72.654 108.569 72.654 126.926 45.496 154.015 45.496 135.658" />
                <polygon className="path-caption--purple" points="45.496 162.926 72.654 135.599 72.654 154.015 45.496 180.272 45.496 162.926" />
                <path className="path-caption--white" d="M349.1386,539.1782" transform="translate(-260.504 -395.5)" />
            </svg>
        </div>
    )
}

export default ScrollUp;
