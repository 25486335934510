import React from 'react'

export const Logo: React.FC<ElementLikeProps> = (props) => {
    return (
        <svg
            id={'id' in props ? props.id : ''}
            onClick={'onClick' in props ? props.onClick : () => { }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100.96 100.96"
        >
            <title>Rootrilla</title>
            <rect width="100.96" height="100.96" rx="20.11" />
            <polygon className="path-caption--white" style={{ fill: "#fff" }}
                points="81.13 42.96 81.13 75.04 66.15 75.04 66.15 60.06 52.71 60.06 37.72 75.04 16.59 75.04 31.53 60.06 46.38 45.22 66.15 45.22 66.15 42.96 81.13 42.96"
            />
            <polygon className="path-caption--red_1"
                points="31.98 40.76 36.29 45.07 43.43 45.07 29.28 59.23 16.83 46.74 16.83 25.92 80.89 25.92 80.89 40.86 31.98 40.76" />
            <rect className="path-caption--purple" x="64.87" y="25.92" width="16.01" height="14.94" />
            <rect className="path-caption--magent" x="48.86" y="25.92" width="16.01" height="14.94" />
            <rect className="path-caption--red_2" x="32.84" y="25.92" width="16.01" height="14.94" />
            <rect className="path-caption--red_1" x="16.83" y="25.92" width="16.01" height="14.94" />
        </svg>
    );
}

export default Logo;
