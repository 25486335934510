import React, { useState, memo, useCallback, useRef } from 'react';
import { render } from 'react-dom';
import { useTranslation } from 'react-i18next';

import { emailRegex } from '../constants';

import FlashMessage from '../Element/FlashMessage';
import Tooltip from '../Element/Tooltip';

export const RegisterForm: React.FC = () => {
    const { t } = useTranslation();
    const submitBtnRef = useRef(null);

    const formRef = useRef(null);

    const [username, setUsername] = useState('');
    const [pw, setPw] = useState('');
    const [email, setEmail] = useState('');

    const validateForm = useCallback((): boolean => {
        return username.length > 0 && pw.length > 0 && email.length > 0;
    }, [username, pw, email]);

    const submitForm = useCallback((e: React.MouseEvent): void => {
        e.preventDefault();
        let errorMessages: string[] = [];
        const form = formRef.current! as HTMLFormElement;

        // select all inputs in the form that are no type submit
        form.querySelectorAll('input:not([type=submit])').forEach(input => {
            let message = "messages.flash.error.";
            let regex;
            let text;
            // fill message, regex and text, this is done like so since all the inputs
            // follows the same validation pattern
            switch (input.getAttribute("name")) {
                case "username":
                    message = message + "username";
                    regex = /^[a-zA-Z0-9]{3,15}$/;
                    text = username;
                    break;
                case "email":
                    message = message + "email";
                    regex = emailRegex;
                    text = email;
                    break;
                case "pw":
                    message = message + "password";
                    regex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,24}$/;
                    text = pw;
                    break;
                default:
                    console.error("not expected input name");
                    return;
            }

            if (!text.match(regex)) {
                errorMessages.push(t(message));
                // add class if doesn't have
                if (!input.classList.contains('error')) input.classList.add('error');
            } else if (input.classList.contains('error')) {
                // remove class if input is correct but has error class
                input.classList.remove('error');
            }
        });

        if (errorMessages.length > 0) {
            render(
                <>
                    {errorMessages.map((msg, index) => {
                        return (
                            <FlashMessage
                                message={msg}
                                type="error"
                                timeoutMs={4000}
                                classes={index > 0 ? "hide" : ""}
                            />
                        );
                    })}
                </>,
                document.getElementById('messages')
            );
            return
        }
        form.submit();
    }, [email, username, pw, t]);

    const isFormValid = validateForm();

    return (
        <form className="form-register" method="POST" ref={formRef}>
            <input
                type="text"
                name="username"
                placeholder={t('login.placeholder.username')}
                value={username}
                onChange={(e: React.FormEvent<HTMLInputElement>) => setUsername(e.currentTarget.value)}
            />
            <input
                type="text"
                name="email"
                value={email}
                placeholder={t('register.placeholder.email')}
                onChange={(e: React.FormEvent<HTMLInputElement>) => setEmail(e.currentTarget.value)}
            />
            <input
                type="password"
                name="pw"
                placeholder={t('login.placeholder.password')}
                onChange={(e: React.FormEvent<HTMLInputElement>) => setPw(e.currentTarget.value)}
            />
            <div className="form-register--btns">
                <input
                    ref={submitBtnRef}
                    type="submit"
                    value={t('register.btn.submit') as string}
                    disabled={!validateForm()}
                    onClick={submitForm}
                />
                {!isFormValid ?
                    <Tooltip text="Fill in the  blanks" target={submitBtnRef} />
                    : <></>
                }
            </div>
        </form>
    )
}

export default memo(RegisterForm);