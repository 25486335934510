import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';

import ThemeContext from './component/Theme/ThemeContext';
import AuthContext from './component/Auth/AuthContext';

import { getTheme } from './utils/storage';

import Home from './component/Page/Home';
import Login from './component/Page/Login';
import Register from './component/Page/Register';
import Maintenance from './component/Page/Maintenance';
import NotFound from './component/Page/NotFound';

import Cookies, { shouldShowConsent } from './component/Cookies/Cookies';
import Dashboard from './component/Dashboard/Dashboard';

import Loading from './component/Views/Loading';

import useGetUser from './hooks/user/useGetUser';


const App: React.FC<AppProps> = ({ maintenance }) => {
  const [theme, setTheme] = useState(getTheme());

  const { data, loading } = useGetUser();

  const isAuth = data !== undefined && "user" in data;

  if (loading) return <Loading />;
  if (maintenance) return <Maintenance type={maintenance} />

  return (
    <AuthContext.Provider value={{
      isAuth: isAuth,
      username: isAuth ? data.user.username : '',
      email: isAuth ? data.user.email : '',
    }}>
      <ThemeContext.Provider value={{ theme, setTheme }}>
        <div className={theme === "dark" ? "App dark" : "App"}>
          <Router>
            <Routes>
              <Route path='/' element={<Home></Home>} />
              <Route path='/login' element={isAuth ? <Navigate replace to="/" /> : <Login />}>
              </Route>

              <Route path='/register' element={isAuth ? <Navigate replace to="/"/> : <Register />}>
              </Route>

              {/* dashboard manages its own routes  */}
              <Route path='/dashboard/' element={isAuth ? <Dashboard /> : <Navigate replace to="/" />}>
              </Route>
              {/* routes related to OAuth implementation and Backend API*/}
              <Route path='/api' />
              <Route path='/login/' element={<Loading></Loading>} />
              {/* this route is returned when none of the above matches */}
              <Route element={<NotFound></NotFound>} />
            </Routes>
          </Router>
          {shouldShowConsent() ? <Cookies /> : ''}
        </div>
      </ThemeContext.Provider>
    </AuthContext.Provider>
  );
}

export default App;