import React, { useContext, useEffect, useState, useLayoutEffect, memo } from 'react';

import ThemeContext from '../Theme/ThemeContext';

import { growAnimation } from '../../utils/animation';

import {
    MIN_SCREEN_WIDTH_STARTUP_ANIM,
    MIN_SCREEN_HEIGHT_STARTUP_ANIM
} from '../constants';

export const LogoNav: React.FC<AnimableSVGProps> = (props) => {
    const [isAnimated, setIsAnimated] = useState(false);

    const { theme } = useContext(ThemeContext);

    const id = 'id' in props ? props.id : 'navLogo';

    useLayoutEffect(() => {
        if (isAnimated || ('animate' in props && !props.animate)) return;

        const shouldAnimate = window.innerWidth < MIN_SCREEN_WIDTH_STARTUP_ANIM && window.innerHeight < MIN_SCREEN_HEIGHT_STARTUP_ANIM

        if (!shouldAnimate) return;

        const paths = document.getElementById(id!)?.querySelectorAll('*') as NodeListOf<SVGPathElement>;
        paths?.forEach(path => {
            path.style.fill = 'transparent';
        });

    });

    // add 'draw' animation to svg children elements
    useEffect(() => {
        if (isAnimated || ('animate' in props && !props.animate)) return;

        const shouldAnimate = window.innerWidth < MIN_SCREEN_WIDTH_STARTUP_ANIM && window.innerHeight < MIN_SCREEN_HEIGHT_STARTUP_ANIM;

        if (!shouldAnimate) {
            setIsAnimated(true);
            return;
        }

        const colorsMap = {
            "path-caption--gray": "949090",
            "path-caption--red_1": "e00615",
            "path-caption--red_2": "d20525",
            "path-caption--purple": "83007d",
            "path-caption--orange": "feb72b",
            "path-caption--magent": "ac034f",
            "path-caption--nav-logo": theme === 'light' ? '949090' : 'fff'
        }


        const paths = document.querySelectorAll(`svg#${id} > *`) as NodeListOf<SVGPathElement>;

        if ('animationProps' in props) {
            const { growTime, delay, fillTime } = props.animationProps!;

            paths.forEach(path => growAnimation(
                path, {
                colorMap: colorsMap,
                growTime: growTime,
                delay: delay,
                fillTime: fillTime
            })
            );

        } else {
            paths.forEach(path => growAnimation(path, { colorMap: colorsMap }));
        }
        setIsAnimated(true);

        return;
    }, [isAnimated, theme, props, setIsAnimated, id]);

    // vary fill of svg children elements who depend on the theme
    useEffect(() => {
        if (!isAnimated) return;

        const themeDependentPaths = document.querySelectorAll(`svg#${id} .path-caption--nav-logo`) as NodeListOf<SVGPathElement>;

        themeDependentPaths.forEach(path => {
            if (window.getComputedStyle(path, null).getPropertyValue('animation-name') !== 'none') {
                path.style.animationName = "none";
            }
            var fill = "#" + (theme === 'light' ? '949090' : 'fff');
            path.style.fill = fill;
        });

    }, [theme, id, isAnimated]);

    return (
        <svg id={id} className="svg-nav--logo" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 179.45 72">
            <path className="path-caption--nav-logo"
                d="M.37,82.48H32.15v7.43H.37Zm12.16,8.37h7.42v15.86H12.53Z"
                transform="translate(-0.37 -34.71)" />
            <path className="path-caption--nav-logo"
                d="M76.88,82.48h7.39v7.39H76.88Zm0,16.84h7.39v7.39H76.88Z"
                transform="translate(-0.37 -34.71)" />
            <path className="path-caption--nav-logo"
                d="M126.55,82.48h0l-25.94,24.23H90.15l26-24.23Z" transform="translate(-0.37 -34.71)" />
            <path className="path-caption--nav-logo"
                d="M150.56,82.48h0l-25.94,24.23H114.15l26-24.23Z" transform="translate(-0.37 -34.71)" />
            <path className="path-caption--nav-logo"
                d="M179.79,82.56v3.26h0V89.9h-9.33l-14.89,6.55v1.9h-7.42v-6.7l20.73-9.09Zm-8.33,16.76v7.39H148.15V99.32Zm8.33-8.45v15.84H172.4V90.87Z"
                transform="translate(-0.37 -34.71)" />
            <path className="path-caption--nav-logo"
                d="M52.73,34.87v17L42.56,62.09,30.98,50.52h5.84L40.35,47H12.62v.08H.37V34.87ZM12.62,48.71v1.85H28.77L40.91,62.65,53.15,74.9H35.85L23.6,62.65h-11V74.9H.37V48.71Z"
                transform="translate(-0.37 -34.71)" />
            <path className="path-caption--nav-logo"
                d="M116.15,34.71V47.03H75.9v14H63.62V34.71Zm0,13.84V74.9H63.62V62.62h40.26v-14Z"
                transform="translate(-0.37 -34.71)" />
            <path className="path-caption--nav-logo"
                d="M179.82,34.71V47.03H139.57v14H127.29V34.71Zm0,13.84V74.9H127.29V62.62h40.26v-14Z"
                transform="translate(-0.37 -34.71)" />
            <polygon className="path-caption--nav-logo"
                points="70.18 56.18 70.18 72 62.79 72 62.79 64.61 56.16 64.61 48.77 72 38.35 72 45.72 64.61 53.04 57.29 62.79 57.29 62.79 56.18 70.18 56.18" />
            <polygon className="path-caption--red_1"
                points="45.94 55.09 48.06 57.22 51.59 57.22 44.61 64.2 38.47 58.04 38.47 47.77 70.06 47.77 70.06 55.14 45.94 55.09" />
            <rect className="path-caption--purple" x="62.16" y="47.77" width="7.9"
                height="7.37" />
            <rect className="path-caption--magent" x="54.26" y="47.77" width="7.9"
                height="7.37" />
            <rect className="path-caption--red_2" x="46.37" y="47.77" width="7.9"
                height="7.37" />
            <rect className="path-caption--red_1" x="38.47" y="47.77" width="7.9"
                height="7.37" />
            <circle className="path-caption--red_1" cx="51.38" cy="27.38" r="1.83" />
        </svg>
    );
}

export default memo(LogoNav);