import React, { useRef } from 'react';

import DashboardContent from '../DashboardContent';
import DashboardHeader from '../Header/DashboardHeader';

import Navigation from '../Navigation';


export const DashboardHome: React.FC = () => {
    const contentRef = useRef(null);

    // in case theme is undefined
    return (
        <>
            <DashboardHeader />
            <Navigation />
            <DashboardContent ref={contentRef}>
            </DashboardContent>
        </>
    );
}

export default DashboardHome;