import React, { useRef } from 'react';

// callback is called once the width reaches max value
// elem is expected to be the first child of 'progress-bar'
// max 
export const progress = (elem: HTMLElement, max: number = 100, callback: () => void): void => {
    var width = 0;
    var interval = setInterval(frame, 10);
    function frame() {
        if (width >= max) {
            clearInterval(interval);
            callback();
            return;
        }
        width++;
        elem.style.width = `${width}%`;

    }
}

export const ProgressBar: React.FC<ProgressBarProps> = ({ barRef, ...props }) => {
    const localRef = useRef(null);
    const ref = barRef || localRef;

    return (
        <div
            className="progress-bar"
            id={"id" in props ? props.id : ""}
        >
            <div className="bar" ref={ref}>
            </div>
        </div>
    );
}

export default ProgressBar;