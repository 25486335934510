import React, { memo, useLayoutEffect, useEffect } from 'react';
import { render } from 'react-dom';
import { useTranslation } from 'react-i18next';

import { cookieStorage } from '../../utils/storage';

import LoginForm from '../Form/LoginForm';

import Logo from '../Rootrilla/Logo';
import { LangSelector } from '../Other/LangSelector';

import Aircraft from '../Rootrilla/Aircraft';
import FlashMessage from '../Element/FlashMessage';

import { redirectHome } from "../../utils/functions";

export const Login: React.FC = () => {
    const { t } = useTranslation();

    useEffect(() => {
        document.title = "Rootrilla | Login"
    }, []);

    useLayoutEffect(() => {
        const loginState = cookieStorage.getItem('invalid_post');
        if (loginState === undefined) return;

        render(
            <FlashMessage
                message={t('messages.flash.error.login') as string}
                type="error"
                timeoutMs={4000}
            />,
            document.getElementById('messages')
        );

        cookieStorage.deleteItem("invalid_post");
    });

    return (
        <div className="v-login">
            <div className="c-login">
                <figure>
                    <Logo onClick={redirectHome} />
                </figure>
                <LoginForm />
                <a className="typo-a" href="/register">{t('login.link.register')}</a>
                <Aircraft id="loginAircraftBL" direction="left" animate={false} />
                <Aircraft id="loginAircraftTR" direction="left" animate={false} />
                <a className="arrow--back" href="/">{t('login.btn.back')}</a>
                <LangSelector />
            </div>
            <div id="messages"></div>
        </div>
    )
}

export default memo(Login);