import React, { memo, useMemo } from 'react'

import LogoNav from '../Rootrilla/LogoNav';

import { Google, GitHub, LinkedIn, Twitter } from '../Other/Icons';

import Logo from '../Rootrilla/Logo';
import { useTranslation } from 'react-i18next';


const links: SocialMedia = {
    github: "http://github.com",
    google: "http://google.com",
    linkedin: "http://linkedin.com",
    twitter: "http://twitter.com"
}

const mapSection = (section: IFooterSection, index: number) => {
    // fs: footer section
    // fsh: "" "" "" header
    return (
        <div key={"fs" + index}>
            <h5 key={"fsh" + index}>{section.name}</h5>
            {section.links.map((linkContent, aIndex) => {
                return <a href={linkContent[1]} key={"fsa" + aIndex}>{linkContent[0]}</a>
            })}
        </div>
    )
}

export const Footer: React.FC = () => {
    const { t } = useTranslation();
    const sections: IFooterSections = useMemo(() => ({
        services: {
            name: t('footer.services'),
            links: [
                ["Lorem", "#labs"],
                ["Lorem", "#labs"],
                ["Lorem", "#labs"]
            ]
        },
        company: {
            name: t('footer.company'),
            links: [
                ["Lorem", "#labs"],
                ["Lorem", "#labs"],
                ["Lorem", "#labs"]
            ]
        },
        other: {
            name: t('footer.other'),
            links: [
                ["Lorem", "#labs"],
                ["Lorem", "#labs"],
                ["Lorem", "#labs"]
            ]
        },
        policies: {
            name: t('footer.policies'),
            links: [
                ["Lorem", "#labs"],
                ["Lorem", "#labs"],
                ["Lorem", "#labs"],
                ["Lorem", "#labs"],
                ["Lorem", "#labs"]
            ]
        },
    }), [t]);


    return (
        <footer>
            <div className="c-footer--content">
                <div>
                    <Logo id="footerLogo" />
                    <div>
                        <GitHub link={links.github} />
                        <LinkedIn link={links.linkedin} />
                        <Google link={links.google} />
                        <Twitter link={links.twitter} />
                    </div>
                </div>
                <div className="c-footer--info">
                    {/* populate footers info with elements created in mapSection */}
                    {Object.keys(sections).map((key, index) => mapSection(sections[key], index))}
                </div>
            </div>
            <div className="c-footer--end">
                <LogoNav id="footerLogoV2" animate={false} />

            </div>


        </footer>
    )
}

export default memo(Footer);
