import React, { useRef, useEffect } from 'react';

const showMessage = (flash: HTMLElement) => {
    flash.style.transition = 'transform 0.3s ease-in, opacity 0.3s ease-out';
    flash.style.opacity = '1';
    flash.style.pointerEvents = 'all';
}

const closeMessage = (flash: HTMLElement) => {
    flash.style.transition = 'transform 0.2s ease-in, opacity 0.2s ease-out';
    flash.style.opacity = '0';
    flash.style.pointerEvents = 'none';
    setTimeout(() => flash.classList.add('hide'), 200);
    // if there are no more messages, return
    if (!flash.nextSibling) return;

    const nextFlash = flash.nextSibling as HTMLElement;

    // if flash is not hidden, don't show it
    if (!nextFlash.classList.contains("hide")) return;
    nextFlash.classList.remove('hide');
    showMessage(nextFlash);
}

// temporal message pop up
export const FlashMessage: React.FC<FlashMessageProps> = ({ message, type, timeoutMs, ...props }) => {
    const timeout: number | "never" = timeoutMs || 1000;
    const flashRef = useRef(null);

    useEffect(() => {
        if ("classes" in props && props.classes!.includes("hide")) return;

        if (flashRef === null || !flashRef.current) {
            console.error("expecting flashRef not to be null");
            return;
        }

        showMessage(flashRef.current!);

        // message remains till user click on the cross
        if (timeout === "never") return;

        setTimeout(() => {

            // message hasn't been closed yet
            if (window.getComputedStyle(flashRef.current!).getPropertyValue('opacity') === '1') {
                closeMessage(flashRef.current!);
            }
        }, timeout + 300);
    });

    return (
        <div
            className={`flash--${type} ` + ("classes" in props ? props.classes : "")}
            id={'id' in props ? props.id : ''}
            ref={flashRef}
        >
            <p>{message}</p>
            <span onClick={() => closeMessage(flashRef.current!)}>
                &#10006;
            </span>
        </div>
    );
}

export default FlashMessage;