import React, { useEffect, memo } from 'react';

import { Bar } from '../Rootrilla/RootRilla';

import LogoNav from '../Rootrilla/LogoNav';

import { useTranslation } from 'react-i18next';

import { useWindowSize } from '../../hooks/other/useWindowSize';

const toggleHamburgerBtn = (e: React.MouseEvent<HTMLElement> | null) => {
    const toggler = document.getElementsByClassName("nav-toggle")[0] as HTMLDivElement;

    if (toggler && toggler.classList.contains("active")) {
        foldInItems();
    }

    if (!e) return;

    const aTag = e.currentTarget.querySelector('a') as HTMLAnchorElement;

    if (aTag !== e.target) {
        aTag.click();
    }
}

// remove 'active' class from hamburger btn
const mapSection = (section: ISection, index: number, name: string) => {
    return (
        <li className="nav-section" onClick={toggleHamburgerBtn} key={"navLi" + index}>
            <div key={"navLiB" + index}></div>
            <div key={"navLiS" + index}>
                <a key={"navLiSa" + index} href={section.id}>{name}</a>
            </div>
        </li>
    );
}

const redirectHome = () => window.location.replace("/");

const sections: ISection[] = [
    {
        langKey: 'headers.rtm',
        id: '#rtm'
    },
    {
        langKey: 'headers.services',
        id: '#services'
    }, {
        langKey: 'headers.labs',
        id: '#labs'
    },
    {
        langKey: 'headers.contact',
        id: '#contact'
    }
];

export const Navbar: React.FC<NavbarProps> = (props) => {
    const { t } = useTranslation();
    const screenSize = useWindowSize();

    useEffect(() => {
        // this fix the issue of restoring elements such as #sections and #langSelector
        // on screen resize since they are modifided directly with TS

        const navSections = document.getElementById('sections') as HTMLDivElement;
        const langSelector = document.getElementById('langSelector') as HTMLDivElement;

        if (!screenSize || !screenSize.width) return;

        if (screenSize.width! >= 980) {
            navSections.style.transition = 'none';
            navSections.style.transform = 'translateY(0)';

            // waits till langSelector animation is done
            if (window.getComputedStyle(langSelector).getPropertyValue('animation-name') === 'none') {
                langSelector.style.opacity = '1';
                langSelector.style.pointerEvents = 'all';

            }
        } else {
            navSections.style.transform = 'translateY(-100%)';
            langSelector.style.animationName = "none";
            langSelector.style.pointerEvents = 'none';
            langSelector.style.opacity = '0';
            toggleHamburgerBtn(null);
        }
    }, [screenSize]);

    return (
        <nav className="smooth" id="navbar" role="navigation">
            <div className="nav-logo" onClick={redirectHome}>
                <LogoNav
                    id="navbarLogo"
                    animationProps={{
                        growTime: 2,
                        delay: 0.5,
                        fillTime: 0.6
                    }}
                    animate={'animateLogo' in props ? props.animateLogo : true}
                />
            </div>
            <Hamburger />
            <div className="nav-sections" id="sections">
                <Bar />
                <NavSections sections={sections} t={t} />
            </div>
        </nav>
    )
}

const NavSections: React.FC<NavSectionsProps> = memo(({ sections, t }) => {
    return (
        <ul>
            {sections.map((section, index) => mapSection(section, index, t(section.langKey)))}
        </ul>
    )
});

const Hamburger: React.FC = () => {
    return (
        <div className="nav-toggle" onClick={foldOutItems}>
            <span></span>
            <span></span>
            <span></span>
        </div>
    )
}

const foldOutItems = () => {
    const hamburgerBtn = document.getElementsByClassName('nav-toggle')[0] as HTMLDivElement;

    if (hamburgerBtn && hamburgerBtn.classList.contains('active')) {
        foldInItems();
        return;
    }

    const langSelector = document.getElementById('langSelector') as HTMLDivElement;
    const navSections = document.getElementById('sections') as HTMLDivElement;

    navSections.style.transition = "transform 0.3s ease-in"
    navSections.style.transform = 'translateY(0)';
    hamburgerBtn.classList.add('active');
    langSelector.style.opacity = '1';
    langSelector.style.pointerEvents = 'all';
}

const foldInItems = () => {
    const navSections = document.getElementById('sections') as HTMLDivElement;
    const hamburgerBtn = document.getElementsByClassName('nav-toggle')[0] as HTMLDivElement;
    const langSelector = document.getElementById('langSelector') as HTMLDivElement;

    if (hamburgerBtn && hamburgerBtn.classList.contains("active")) {
        navSections.style.transform = 'translateY(-100%)';
        hamburgerBtn.classList.remove("active");
        langSelector.style.opacity = '0';
        langSelector.style.pointerEvents = 'none';
    }
}

export default memo(Navbar);
