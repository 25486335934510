import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import './static/scss/App.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider
} from "@apollo/client";

import { cookieStorage } from './utils/storage';
import { COOKIE_FIRST_TIME_PROPERTY_NAME } from './component/constants';

document.addEventListener('DOMContentLoaded', () => {
  // non-existence of this cookie mean the user hasn't been in the page yet
  if (!cookieStorage.getItem(COOKIE_FIRST_TIME_PROPERTY_NAME)) {

    // avoid browser from caching previous scroll on page re load
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual'
    }

    // disable auto scrolling
    if (window.location.href.includes('#')) {
      const indexToRemove: number = window.location.href.indexOf('#');
      window.location.href = window.location.href.slice(0, indexToRemove - 1);
    }
  }

});

const client = new ApolloClient({
  uri: 'https://localhost:8000/api/auth/user',
  cache: new InMemoryCache()
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ApolloProvider>,
  document.getElementById('root')
);

reportWebVitals();
