import { getTheme } from "./storage";
import {
    ANIMATION_FADE_OUT_SHIFT_VERTICAL
} from '../component/constants';
import { shouldShowConsent } from "../component/Cookies/Cookies";

// this is related to how SCSS classes for 'path' tags are managed
// basically map the class with its respective color

const initialTheme = getTheme();

const colorsMap: Imap = {
    "path-caption--gray": "949090",
    "path-caption--red_1": "e00615",
    "path-caption--red_2": "d20525",
    "path-caption--purple": "83007d",
    "path-caption--orange": "feb72b",
    "path-caption--magent": "ac034f",
    "path-caption--pwn2me-green": "527318",
    "path-caption--pwn2me-yellow": "ffe75e",
    "path-caption--pwn2me-light_green": "899857",
    "path-caption--pwn2me-orange": "feb72b",
    "path-caption--pwn2me-theme": initialTheme === 'light' ? "949090" : "fff",
    "path-caption--pwn2me-theme-2": "527318",

    "path-caption--net2me-blue": "00a8cc",
    "path-caption--net2me-dark_blue": "27496d",
    "path-caption--net2me-cyan": "0c7b93",
    "path-caption--net2me-red": "red",
    "path-caption--net2me-theme": initialTheme === 'light' ? "27496d" : "fff",

    "path-caption--ctf-magent": "940a37",
    "path-caption--ctf-yellow": "ffd369",
    "path-caption--ctf-orange": "e26241",
    "path-caption--nav-logo": initialTheme === 'light' ? "949090" : "fff"

}

// this animation ´draws´ each path in an SVG
export const growAnimation = (
    svgPath: SVGPathElement,
    {
        colorMap = colorsMap,
        growTime = 3,
        delay = 0,
        fillTime = 0.6,
    } = {}
) => {
    // draw path tag and then fill it
    let pathLength: string = svgPath.getTotalLength().toString();

    svgPath.style.transition = "transition: all 0.3s ease";

    svgPath.setAttribute('stroke-dasharray', pathLength);
    svgPath.setAttribute('stroke-dashoffset', pathLength);

    var fillColor = '949090'; // rootrilla gray

    if (svgPath.classList) {
        // path tags are expected to have as first class one in colorsMap
        const firstClass = svgPath.classList[0];
        if (firstClass in colorMap) {
            fillColor = colorMap[firstClass as keyof typeof colorsMap];
        }
    }


    const fillDelay = growTime + delay;

    svgPath.style.animation = `draw ${growTime}s ease ${delay}s, fill-${fillColor} ${fillTime}s ease-in ${fillDelay}s`;
    svgPath.style.animationFillMode = "forwards";

}

export const startUpAnimation = ({
    logoRevealTime = 1.5,
    logoScaleTime = 0.6,
    logoStaticTime = 0,
    slideBarsTime = 0.6,
    delay = 600
} = {}) => {
    const screenWidth = window.innerWidth;

    const logo = document.getElementById('navbarLogo') as HTMLElement;

    // this bar goes from left to right in the animation
    const slideBarTop = document.getElementById('slideBarTop') as HTMLElement;
    const slideBarBottom = document.getElementById('slideBarBottom') as HTMLElement;

    const rotatedBarContainer = document.getElementById('rotatedBarContainer') as HTMLDivElement;

    const tippedContainer = document.getElementsByClassName('c-tipped')[0] as HTMLDivElement;

    // bezier curves are obtained from here: https://cubic-bezier.com/

    logo.style.transition = `transform ${logoRevealTime}s cubic-bezier(.3,.96,.77,.97)`;
    tippedContainer.style.transition = `opacity 0.3s ease-in`;
    rotatedBarContainer.style.transition = 'opacity 0.3s ease-in';

    slideBarTop.style.transition = slideBarBottom.style.transition = `transform ${slideBarsTime}s cubic-bezier(.3,.96,.77,.97)  , opacity ${slideBarsTime}s cubic-bezier(.82,.29,.94,.85) 0.07s`;

    // const slideBarsDelay = delay / 1000 + logoRevealTime;
    const slideBarsDelay = logoRevealTime;

    tippedContainer.style.display = 'block';
    logo.style.width = '15vw';

    const logoTransform = 'translate(calc(-25vw))';

    setTimeout(() => {
        rotatedBarContainer.querySelectorAll('div').forEach(bar => bar.style.transform = 'scale(1)');
        setTimeout(() => logo.style.transform = logoTransform, 100);

        // bottom and top bars slides around the logo
        setTimeout(() => {
            slideBarTop.style.transform = `translate(calc(${screenWidth <= 1400 ? '38' : '42'}vw), -50%) rotate(90deg)`;
            slideBarBottom.style.transform = `translate(calc(-${screenWidth <= 1400 ? '38' : '42'}vw), ${screenWidth <= 1600 ? 'calc(-50% - 0.75vw)' : '-50%'}) rotate(90deg)`;

            slideBarTop.style.opacity = '1';
            slideBarBottom.style.opacity = '1';
            rotatedBarContainer.style.opacity = '0';
        }, 500);

        // this set a transition for the logo to have a smooth opacity
        setTimeout(() => {
            logo.style.transition = `all ${logoScaleTime}s ease-in-out`;
        }, slideBarsDelay * 1000);


        // logo fades out
        setTimeout(() => {
            logo.style.opacity = '0'
            tippedContainer.style.display = 'none';

        }, (slideBarsDelay + logoScaleTime - 0.3) * 1000 + logoStaticTime);


        // bottom and top bars fade out
        setTimeout(() => {
            slideBarTop.style.opacity = '0';
            slideBarBottom.style.opacity = '0';

        }, (slideBarsDelay + logoScaleTime - 0.4) * 1000 + logoStaticTime);


        // here the logo is already faded out, and behind scenes position the logo in the navbar
        setTimeout(() => {
            logo.style.transition = `none`;
            logo.style.position = 'initial';
            logo.style.transform = `translateX(-20%)`;

            slideBarTop.style.display = 'none';
            tippedContainer.style.display = 'none';
        }, (slideBarsDelay + logoScaleTime + 0.1) * 1000 + logoStaticTime);


        // scale and fade in the logo from left to right in the navbar
        setTimeout(() => {
            logo.style.transition = `transform ${logoScaleTime}s cubic-bezier(.25,.11,.19,.69), opacity ${logoScaleTime + 0.2}s cubic-bezier(.61,.21,.86,.34)`;

            logo.style.opacity = '1';
            logo.style.transform = 'translateX(0%)';
            logo.style.width = '100%';

            const aircraft = document.getElementById('aircraftLand') as HTMLElement;
            aircraft.style.zIndex = '0';
            logo.style.zIndex = '2';

            const overlay = document.getElementById('startupOverlay') as HTMLDivElement;

            // here the scroll is enabled
            overlay.style.pointerEvents = 'none';


        }, (slideBarsDelay + logoScaleTime + 0.3) * 1000 + logoStaticTime);


    }, delay)

    if (shouldShowConsent()) {
        showCookies(5);
    }

}

export const startUpTransition = ({
    delay = 0.5
} = {}) => {
    const logo = document.getElementById('navbarLogo') as HTMLElement;

    const paths = logo.querySelectorAll('*') as NodeListOf<SVGPathElement>;

    paths.forEach(path => {
        path.style.fill = 'transparent';
        growAnimation(path, { growTime: 1, fillTime: 0.4 });
    });

    logo.style.position = 'initial';
    logo.style.animationName = 'none';
    logo.style.transform = 'translateX(0%)';
    logo.style.transition = 'all 0.5s ease';

    const langSelector = document.getElementById('langSelector') as HTMLElement

    langSelector.style.animation = 'none';
    langSelector.style.opacity = '1';

    const navbar = document.getElementById('navbar') as HTMLElement;

    const barBodyParts = navbar.querySelectorAll('.rr-bar--body div') as NodeListOf<HTMLElement>;
    barBodyParts.forEach(div => {
        div.style.animationDelay = `${delay + 0.1}s`;
    });


    const barHead = navbar.querySelector('.rr-bar--head') as HTMLElement;
    barHead.style.animationDelay = `${delay + 0.4}s`;

    const linkSections = navbar.querySelectorAll('ul li') as NodeListOf<HTMLElement>;
    linkSections.forEach(section => {
        let navTick = section.querySelector('div:first-child') as HTMLElement;
        navTick.style.animationDelay = `${delay + 0.6}s`;

        let navBorderBottom = section.querySelector('div:nth-child(2) a') as HTMLElement;
        navBorderBottom.style.animationDelay = `${delay + 0.9}s`;
    });

    const topLinks = document.querySelectorAll('.c-top .c-links div > p') as NodeListOf<HTMLElement>;
    topLinks.forEach(link => {
        link.style.animation = 'none';
        link.style.transform = 'scale(1)';
    });

    document.getElementById('landCaption')!.style.animationDelay = `${delay + 0.7}s`;

    document.getElementById('themeSwitcher')!.style.animationDelay = `${delay + 0.3}s`;

    if (shouldShowConsent()) {
        showCookies(1.8);
    }
}

export const fadeInFromBottom = (element: HTMLElement) => {
    element.style.opacity = '1';
    element.style.pointerEvents = 'all';
    element.style.transform = `translateY(0%)`;
}

export const fadeOutToBottom = (element: HTMLElement) => {
    element.style.opacity = '0';
    element.style.pointerEvents = 'all';
    element.style.transform = `translateY(${ANIMATION_FADE_OUT_SHIFT_VERTICAL})`;
}

const showCookies = (delaySeconds: number) => {
    const cookiesC = document.getElementById('cookies') as HTMLElement;
    const cookiesConsent = document.getElementById('cookiesConsent') as HTMLDivElement;

    setTimeout(() => {
        cookiesC.style.transition = 'background-color 0.3s ease-in-out';
        cookiesConsent.style.transition = 'transform 0.4s ease-out, opacity 0.4s ease-out';

        cookiesC.tabIndex = 10;
        cookiesC.focus();

        cookiesC.style.backgroundColor = 'rgba(0, 0, 0, 0.6)';
        cookiesC.style.pointerEvents = 'all';

        cookiesConsent.style.transform = 'translateY(0)';
        cookiesConsent.style.opacity = '1';

    }, delaySeconds * 1000);
}

export const tooltipFadeIn = (element: HTMLElement, offset: number = 105, direction: "down" | "left" | "right" | "up" = "down") => {
    switch (direction) {
        default:
            element.style.transition = 'transform 0.3s ease-out, opacity 0.3s ease-in';
            element.style.transform = `translate(-50%, ${offset + 5}%)`;
            element.style.opacity = '1';
            element.style.pointerEvents = 'all';
    }
}

// element is guaranteed to exist, look at ../component/Element/FloatCaption
export const tooltipFadeOut = (element: HTMLElement, offset: number = 105, direction: "down" | "left" | "right" | "up" = "down") => {
    switch (direction) {
        default:
            element.style.transition = 'transform 0.2s ease-out, opacity 0.3s ease-in';
            element.style.transform = `translate(-50%, ${offset}%)`;
            element.style.opacity = '0';
            element.style.pointerEvents = 'none';
    }
}