import React, { memo } from 'react';
import { EdgeBars } from '../Rootrilla/RootRilla';
import { Paragraph } from '../Element/Paragraph';
import { Polygon } from '../Other/Polygon';

import AirCraft from '../Rootrilla/Aircraft';

import { useTranslation } from 'react-i18next';

import { shouldAnimateStartup } from '../Page/Home';


export const Land: React.FC<SectionProps> = (props) => {
    const { t } = useTranslation();
    const shouldAnimate: boolean = shouldAnimateStartup();

    return (
        <section
            id="land"
            className="c-view"
        >
            {shouldAnimate ? <EdgeBars direction="left" id="slideBarTop" /> : ''}
            {shouldAnimate ? <EdgeBars direction="right" id="slideBarBottom" /> : ''}
            {shouldAnimate ?
                <div className="c-tipped">
                    <EdgeBars direction="left" id="rotatedBarContainer" />
                </div>
                : ''
            }

            <Paragraph className="art--land" id="landCaption">
                <Polygon
                    direction="right"
                    shape="polygon(100% 0px, 300px 0px, 300px 45%, 0px 100%, 100% 100%)"
                    height="500px"
                    width="520px"
                />

                <div className="wrapper-text">
                    {t('land').split('\n').map((line, index) => <p key={"landP" + index}>{line}</p>)}
                    {t('caption').split('\n').map((line, index) => <p key={"landP" + index}>{line}</p>)}
                    <EdgeBars direction="left" />
                </div>
            </Paragraph>
            <AirCraft
                direction="right"
                id="aircraftLand"
                animate={'animateSVG' in props ? props.animateSVG : true}
            />
            {/* disable scroll till startup animation ends */}
            {shouldAnimate ? <div id="startupOverlay"></div> : ''}
        </section>
    );
}


export default memo(Land);