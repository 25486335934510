import React, { useRef } from 'react';

import { useTranslation } from 'react-i18next';

// this appears when some element is hovered, it appears based on the given direction
const Dropdown: React.FC<DropdownProps> = ({ children, ref, ...props }) => {
    const localRef = useRef(null);
    const targetRef = ref || localRef;

    return (
        <div
            className={"dropdown " + ("direction" in props ? props.direction : "")}
            ref={targetRef}
        >
            {children}
        </div>
    );
}

export const DropdownMenu: React.FC<DropdownMenuProps> = ({ children, classes }) => {
    return (
        <ul
            className={"dropdown-menu " + (classes ? classes : "")}
        >
            {children}
        </ul>
    );
}

export const DropdownHeader: React.FC<DropdownHeaderProps> = ({ title, link }) => {
    return (
        <li className="dropdown-menu--header">
            <a href={link}>{title}</a>
        </li>
    );
}

export const DropdownItem: React.FC<DropdownItemProps> = ({ Icon, link }) => {
    return (
        <li className="dropdown-menu-item">
            <a href={link}>
                <Icon />
                <h4>lorem</h4>
            </a>
        </li>
    );
}

export default Dropdown;