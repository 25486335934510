import React, { useRef } from 'react';

export const DashboardContent = React.forwardRef((props: DashboardContentProps, ref: React.LegacyRef<HTMLDivElement>) => {
    const localRef = useRef(null) as React.LegacyRef<HTMLDivElement>;
    const componentRef = ref || localRef;

    return (
        <div
            className="dshb-content"
            id="content"
            ref={componentRef}
        >
            {props.children}
        </div>
    );
});

export default DashboardContent;