import React, { memo, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Tooltip from '../Element/Tooltip';

import AuthContext from '../Auth/AuthContext';

import { tooltipFadeIn, tooltipFadeOut } from '../../utils/animation';


export const TopLinks: React.FC = () => {
    const { isAuth } = useContext(AuthContext);
    const { t } = useTranslation();

    const links: TopLinkProps[] = useMemo(() => {
        return ([
            {
                name: isAuth ? t('redirect.profile.name') : t('redirect.login'),
                link: isAuth ? '' : '/login',
                // when user is authentication the profile feature will be disabled (WIP)
                isEnabled: !isAuth,
                caption: isAuth ? t('redirect.profile.caption') : ''
            },
            {
                name: isAuth ? t('redirect.logout') : t('redirect.register'),
                link: isAuth ? '/logout' : '/register'
            }, {
                name: 'Blog',
                link: 'https://blog.rootrilla.io'
            }, {
                name: t('redirect.home'),
                link: 'https://rootrilla.io'
            }
        ]);
    }, [t, isAuth]);

    return (
        <div className="c-links">
            {links.map((link, index) => <Link {...link} key={"l" + index} />)}
        </div>
    )
}

const onLinkHover = (e: React.MouseEvent) => {
    tooltipFadeIn(e.currentTarget.querySelector('div') as HTMLElement);
}

const onLinkUnhover = (e: React.MouseEvent) => {
    tooltipFadeOut(e.currentTarget.querySelector('div') as HTMLElement);
}

const Link: React.FC<TopLinkProps> = ({ name, link, ...props }) => {
    const hasCaption = 'caption' in props && props.caption !== '';

    return (
        <div
            className={"link " + (('isEnabled' in props && !props.isEnabled) ? 'disabled' : '')}
            onClick={link !== '' ? () => window.location.href = link : () => { }}
            onMouseEnter={hasCaption ? onLinkHover : () => { }
            }
            onMouseLeave={hasCaption ? onLinkUnhover : () => { }
            }
        >
            <p>{name}</p>
            {hasCaption ? (
                <Tooltip text={props.caption!} />
            ) : ''}
        </div>
    )
}


export default memo(TopLinks);
