import React, { memo } from 'react';

import LogoPwn2me from './LogoPwn2me';
import LogoNet2me from './LogoNet2me';
import LogoCTF from './LogoCTF';


export const Cow: React.FC<CowProps> = ({ animate, id, ...props }) => {
    return (
        <figure className="rr-cow" id={id ? id : ''}>
            <svg className="svg-cow" id="cowLeft" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 357.9001 537.5414">
                <path className="path-caption--net2me-cyan"
                    d="M98.55,207.99l112.6,110.5a2.2049,2.2049,0,0,1,.6,1.5v106.9l88.7,88.7a2.2708,2.2708,0,0,1,0,2.5,2.11,2.11,0,0,1-3,.5l-89.4-89.3a2.205,2.205,0,0,1-.6-1.5V320.89l-112-109.9a2.175,2.175,0,0,1,0-3,2.205,2.205,0,0,1,1.5-.6A2.271,2.271,0,0,1,98.55,207.99Z"
                    transform="translate(-0.05 -0.6484)" />
                <path className="path-caption--net2me-blue"
                    d="M196.05,392.89a2.1354,2.1354,0,0,1,2.1,2.2v29.4a2.1123,2.1123,0,0,1-2.1,2.1,2.1816,2.1816,0,0,1-2.1-2.1v-29.4a2.1354,2.1354,0,0,1,2.1-2.2Z"
                    transform="translate(-0.05 -0.6484)" />
                <path className="path-caption--net2me-dark_blue"
                    d="M130.85,106.89a2.1123,2.1123,0,0,1,2.1,2.1,2.2019,2.2019,0,0,1-2.1,2.2H4.35v12.2l61.1,61.2h61.3l47.5-47.5a2.2708,2.2708,0,0,1,2.5,0,2.11,2.11,0,0,1,.5,3l-48.1,48.1a2.0352,2.0352,0,0,1-1.5.6H64.55a2.0352,2.0352,0,0,1-1.5-.6L.65,125.79a2.2049,2.2049,0,0,1-.6-1.5v-15.2a2.1354,2.1354,0,0,1,2.2-2.1l128.6-.1Z"
                    transform="translate(-0.05 -0.6484)" />
                <path className="path-caption--net2me-blue"
                    d="M274.85,206.49l25.4,25.5a2.175,2.175,0,0,1,0,3l-71.8,71.8v100.5a2.22,2.22,0,0,1-2.2,2.2,2.1354,2.1354,0,0,1-2.1-2.2V305.89a2.0355,2.0355,0,0,1,.6-1.5l71-71-23.3-23.3h-28.3a2.1552,2.1552,0,1,1,.3-4.3h28.9A2.627,2.627,0,0,1,274.85,206.49Z"
                    transform="translate(-0.05 -0.6484)" />
                <path className="path-caption--net2me-dark_blue"
                    d="M189.85,148.99l57.4,57.4a2.175,2.175,0,0,1,0,3l-57.4,57.4a2.175,2.175,0,0,1-3,0l-57.4-57.4a2.175,2.175,0,0,1,0-3l57.4-57.4a2.175,2.175,0,0,1,3,0Zm-1.6,113.3,54.4-54.4-54.4-54.4-54.3,54.4Z"
                    transform="translate(-0.05 -0.6484)" />
                <path className="path-caption--net2me-blue"
                    d="M320.75,102.29a2.1122,2.1122,0,0,1,2.1,2.1,2.2019,2.2019,0,0,1-2.1,2.2h-39l-66,66a2.2708,2.2708,0,0,1-2.5,0,2.11,2.11,0,0,1-.5-3l66.6-66.6a2.0352,2.0352,0,0,1,1.5-.6l39.9-.1Z"
                    transform="translate(-0.05 -0.6484)" />
                <path className="path-caption--ctf-yellow"
                    d="M301.25,336.59a2.2018,2.2018,0,0,1,2.2,2.1v16.9l14.6,14.6a1.205,1.205,0,0,1,.5.5,2.1506,2.1506,0,1,1-3.5,2.5l-15.3-15.1a2.205,2.205,0,0,1-.6-1.5v-17.9a2.0505,2.0505,0,0,1,2.1-2.1Z"
                    transform="translate(-0.05 -0.6484)" />
                <path className="path-caption--net2me-cyan"
                    d="M295.45,258.39a2.0352,2.0352,0,0,1,1.5.6,2.175,2.175,0,0,1,0,3l-38.6,38.7a2.2708,2.2708,0,0,1-2.5,0,2.11,2.11,0,0,1-.5-3l38.7-38.7a2.5127,2.5127,0,0,1,1.4-.6Z"
                    transform="translate(-0.05 -0.6484)" />
                <path className="path-caption--ctf-orange"
                    d="M328.55,277.79v22.4a.7632.7632,0,0,1-.2.5l-34.5,34.5v23.7l39.3,39.3a.7638.7638,0,0,1,.2.5v68a.7631.7631,0,0,1-.2.5l-24,24a.7638.7638,0,0,1-.5.2h-16.7a.7633.7633,0,0,1-.5-.2l-60.2-60.2a.7638.7638,0,0,1-.2-.5v-10.1a.7638.7638,0,0,1,.2-.5l9.5-9.5v-33.5a.7638.7638,0,0,1,.2-.5l12.7-12.5v-22.6a.7636.7636,0,0,1,.2-.5l63.3-63.3a.7638.7638,0,0,1,.5-.2h10.2C328.15,277.09,328.55,277.39,328.55,277.79Zm-73.5,63.7v22.7a.7636.7636,0,0,1-.2.5l-12.7,12.5v33.5a.7636.7636,0,0,1-.2.5l-9.5,9.5v9.5l59.8,59.8h16.1l23.6-23.6v-67.5l-39.4-39.2a.7636.7636,0,0,1-.2-.5v-24.2a.7638.7638,0,0,1,.2-.5l34.5-34.5v-21.4h-9.1Z"
                    transform="translate(-0.05 -0.6484)" />
                <path className="path-caption--ctf-magent"
                    d="M273.75,321.29a.6835.6835,0,0,1,.7.7v33l33.1,33.1a.7638.7638,0,0,1,.2.5v33.1a.7636.7636,0,0,1-.2.5l-22,22a.7638.7638,0,0,1-.5.2h-8.7a.7633.7633,0,0,1-.5-.2l-23.5-23.5a.7636.7636,0,0,1-.2-.5v-20l-12-12a.75.75,0,0,1,0-.9.7693.7693,0,0,1,1-.1l12.2,12.3a.7638.7638,0,0,1,.2.5v20l23,23h8.1l21.6-21.6v-32.5l-33-33.1a.7638.7638,0,0,1-.2-.5v-33.3h0a.7514.7514,0,0,1,.7-.7Z"
                    transform="translate(-0.05 -0.6484)" />
                <path className="path-caption--gray"
                    d="M327.65,292.89a.6681.6681,0,0,1,0,1l-39.8,39.8v12.1l4.5,4.5a.75.75,0,0,1,0,.9.7693.7693,0,0,1-1,.1l-4.7-4.7a.7638.7638,0,0,1-.2-.5v-12.7a.7638.7638,0,0,1,.2-.5l40-40a.7638.7638,0,0,1,.5-.2C327.35,292.69,327.55,292.69,327.65,292.89Z"
                    transform="translate(-0.05 -0.6484)" />
                <path className="path-caption--ctf-yellow"
                    d="M217.05,450.49l86.3,86.3h53.9a.6835.6835,0,0,1,.7.7h0a.6835.6835,0,0,1-.7.7h-54.2a.7633.7633,0,0,1-.5-.2l-86.5-86.5a.6681.6681,0,0,1,0-1,.7638.7638,0,0,1,.5-.2C216.75,450.29,216.85,450.39,217.05,450.49Z"
                    transform="translate(-0.05 -0.6484)" />
                <path className="path-caption--white"
                    d="M284.85,175.09l59.3,59.3a.725.725,0,0,1,0,1l-99,99v25a.7638.7638,0,0,1-.2.5l-14,14a.75.75,0,0,1-.9,0,.7693.7693,0,0,1-.1-1l13.8-13.8v-25a.7638.7638,0,0,1,.2-.5l98.7-98.7-58.6-58.6h-14.8a.7071.7071,0,0,1,.2-1.4h14.9A.3653.3653,0,0,1,284.85,175.09Z"
                    transform="translate(-0.05 -0.6484)" />
                <path className="path-caption--net2me-dark_blue"
                    d="M299.55,171.79l51.5,51.5a.7071.7071,0,0,1-1,1l-51.5-51.5a.6681.6681,0,0,1,0-1,.725.725,0,0,1,1,0Z"
                    transform="translate(-0.05 -0.6484)" />
                <path className="path-caption--gray"
                    d="M318.65,86.09h.2a.7071.7071,0,1,1-.2,1.4h-45.6l-29.4,29.4a.6083.6083,0,0,1-.8,0,.7755.7755,0,0,1-.2-1l29.6-29.6a.7638.7638,0,0,1,.5-.2Z"
                    transform="translate(-0.05 -0.6484)" />
                <path className="path-caption--red_1"
                    d="M43.05,118.09l8.5,8.5h10.3a.7638.7638,0,0,1,.5.2l32.2,32.3a.7638.7638,0,0,1,.2.5v14.7a.6836.6836,0,0,1-.7.7H69.65a.7639.7639,0,0,1-.5-.2l-48.7-49.7a.7638.7638,0,0,1-.2-.5l.1-6a.6835.6835,0,0,1,.7-.7h21.3A.6183.6183,0,0,1,43.05,118.09Zm-21.4,6.2,48.2,49.3h23.4v-13.7l-31.8-31.8H51.15a.7638.7638,0,0,1-.5-.2l-8.5-8.5H21.85Z"
                    transform="translate(-0.05 -0.6484)" />
                <path className="path-caption--net2me-blue"
                    d="M168.15,123.99l3.5,3.5a.75.75,0,0,1,0,.9.7693.7693,0,0,1-1,.1l-3.3-3.2h-13.3l-56.5,56.4a.7638.7638,0,0,1-.5.2H69.35a.7638.7638,0,0,1-.5-.2l-23.6-23.6a.7638.7638,0,0,1-.2-.5v-8.9a.7.7,0,1,1,1.4,0v8.5l23.2,23.2h27.1l56.4-56.4a.7634.7634,0,0,1,.5-.2h13.9A.7347.7347,0,0,1,168.15,123.99Z"
                    transform="translate(-0.05 -0.6484)" />
                <path className="path-caption--net2me-blue"
                    d="M218.85,51.69v36.3l13.1,13.1a.7638.7638,0,0,1,.2.5v7.6a.7638.7638,0,0,1-.2.5l-6.1,6.1a.6681.6681,0,0,1-1,0l-12.2-12.1-26,26a.7638.7638,0,0,1-.5.2h-23.7l-55.5,55.5a.7071.7071,0,0,1-1-1l55.7-55.7a.7638.7638,0,0,1,.5-.2h23.7l26.3-26.3a.6681.6681,0,0,1,1,0l12.2,12.2,5.4-5.4v-7.1l-13.1-13.1a.7636.7636,0,0,1-.2-.5V51.69h0a.7.7,0,1,1,1.4,0Z"
                    transform="translate(-0.05 -0.6484)" />
                <path className="path-caption--white"
                    d="M176.05.79l6.1,6.1a.7641.7641,0,0,1,.2.5v14.5l28,28a.7641.7641,0,0,1,.2.5v36.1a.7641.7641,0,0,1-.2.5l-23.3,23.3a.7639.7639,0,0,1-.5.2h-6.3a.7639.7639,0,0,1-.5-.2l-17.5-17.6h-21.2a.6452.6452,0,0,1-.6-.6c0-.4.2-.8.6-.8h6.1a.7636.7636,0,0,1-.2-.5V37.19l-4.5-4.5a.7634.7634,0,0,1-.2-.5V1.39h0a.6836.6836,0,0,1,.7-.7h32.7C175.75.59,175.85.69,176.05.79Zm-32.5,31,4.6,4.5a.7638.7638,0,0,1,.2.5v53.9a.7638.7638,0,0,1-.2.5h14.4a.7638.7638,0,0,1,.5.2l17.6,17.6h5.7l22.8-22.8V50.69l-28-28a.7638.7638,0,0,1-.2-.5V7.69l-5.7-5.7h-31.7Z"
                    transform="translate(-0.05 -0.6484)" />
                <path className="path-caption--net2me-blue"
                    d="M156.65,3.29v43.7l32.9,33a.7636.7636,0,0,1,.2.5v12.1l7,7a.7071.7071,0,0,1-1,1l-7.2-7.2a.7636.7636,0,0,1-.2-.5V80.79l-32.9-32.9a.7636.7636,0,0,1-.2-.5v-44a.6836.6836,0,0,1,.7-.7A.6687.6687,0,0,1,156.65,3.29Z"
                    transform="translate(-0.05 -0.6484)" />
                <path className="path-caption--net2me-cyan"
                    d="M190.45,30.19a.6408.6408,0,0,1,.6.8v27.9l12.9,12.9h6.1a.7071.7071,0,0,1-.2,1.4h-6.2a.7638.7638,0,0,1-.5-.2l-13.3-13.3a.7638.7638,0,0,1-.2-.5V30.89h0a.7728.7728,0,0,1,.8-.7Z"
                    transform="translate(-0.05 -0.6484)" />
                <path className="path-caption--gray"
                    d="M141.75,33.69v57.7a.6453.6453,0,0,1-.6.6c-.4,0-.8-.2-.8-.6V33.69h0a.7.7,0,1,1,1.4,0Z"
                    transform="translate(-0.05 -0.6484)" />
            </svg>

            <svg className="svg-cow" id="cowRight" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 358.0001 537.6">
                <path className="path-caption--pwn2me-green"
                    d="M260.9,207.39a2.0352,2.0352,0,0,1,1.5.6,2.175,2.175,0,0,1,0,3l-112,109.9v106.9a2.0355,2.0355,0,0,1-.6,1.5l-89.4,89.4a2.11,2.11,0,0,1-3-.5,2.2708,2.2708,0,0,1,0-2.5l88.7-88.7V320.09a2.0352,2.0352,0,0,1,.6-1.5l112.6-110.5A2.13,2.13,0,0,1,260.9,207.39Z"
                    transform="translate(0 -0.5898)" />
                <path className="path-caption--gray"
                    d="M161.8,392.99a2.1354,2.1354,0,0,1,2.1,2.2v29.4a2.1506,2.1506,0,1,1-4.3,0v-29.4a2.218,2.218,0,0,1,2.2-2.2Z"
                    transform="translate(0 -0.5898)" />
                <path className="path-caption--pwn2me-green"
                    d="M227,106.99H355.8a2.2019,2.2019,0,0,1,2.2,2.1v15.2a2.0355,2.0355,0,0,1-.6,1.5L295,188.19a2.0352,2.0352,0,0,1-1.5.6H230.4a2.0352,2.0352,0,0,1-1.5-.6l-48.1-48.1a2.11,2.11,0,0,1,.5-3,2.2708,2.2708,0,0,1,2.5,0l47.5,47.5h61.3l61.1-61.2v-12.2H227a2.1353,2.1353,0,0,1-2.1-2.2,2.0273,2.0273,0,0,1,2.1-2Z"
                    transform="translate(0 -0.5898)" />
                <path className="path-caption--pwn2me-light_green"
                    d="M84.5,205.89h28.9a2.1552,2.1552,0,0,1,.3,4.3H85.4l-23.3,23.3,71,71a2.0351,2.0351,0,0,1,.6,1.5v101.3a2.1505,2.1505,0,1,1-4.3,0V306.79l-71.8-71.8a2.175,2.175,0,0,1,0-3L83,206.49A2.2044,2.2044,0,0,1,84.5,205.89Z"
                    transform="translate(0 -0.5898)" />
                <path className="path-caption--pwn2me-green"
                    d="M169.6,148.39a2.0352,2.0352,0,0,1,1.5.6l57.4,57.4a2.175,2.175,0,0,1,0,3l-57.4,57.4a2.175,2.175,0,0,1-3,0l-57.4-57.4a2.175,2.175,0,0,1,0-3l57.4-57.4A2.2045,2.2045,0,0,1,169.6,148.39Zm54.4,59.6-54.4-54.4-54.4,54.4,54.4,54.4Z"
                    transform="translate(0 -0.5898)" />
                <path className="path-caption--pwn2me-light_green"
                    d="M37.1,102.39H77a2.0352,2.0352,0,0,1,1.5.6l66.6,66.6a2.11,2.11,0,0,1-.5,3,2.2708,2.2708,0,0,1-2.5,0l-66-66h-39a2.1354,2.1354,0,0,1-2.1-2.2,2.09,2.09,0,0,1,2.1-2Z"
                    transform="translate(0 -0.5898)" />
                <path className="path-caption--pwn2me-yellow"
                    d="M56.6,336.59a2.22,2.22,0,0,1,2.2,2.2v17.8a2.0355,2.0355,0,0,1-.6,1.5l-15.3,15.1a2.1506,2.1506,0,0,1-3.5-2.5,1.2039,1.2039,0,0,1,.5-.5l14.6-14.6v-16.9a2.1122,2.1122,0,0,1,2.1-2.1Z"
                    transform="translate(0 -0.5898)" />
                <path className="path-caption--pwn2me-green"
                    d="M62.4,258.49a2.0352,2.0352,0,0,1,1.5.6l38.7,38.7a2.11,2.11,0,0,1-.5,3,2.2708,2.2708,0,0,1-2.5,0l-38.7-38.7a2.175,2.175,0,0,1,0-3,2.2049,2.2049,0,0,1,1.5-.6Z"
                    transform="translate(0 -0.5898)" />
                <path className="path-caption--pwn2me-yellow"
                    d="M30.1,277.19H40.3a.7638.7638,0,0,1,.5.2l63.3,63.3a.7638.7638,0,0,1,.2.5v22.6l12.7,12.5a.7638.7638,0,0,1,.2.5v33.5l9.5,9.5a.7639.7639,0,0,1,.2.5v10.1a.7634.7634,0,0,1-.2.5l-60.2,60.2a.7636.7636,0,0,1-.5.2H49.3a.7638.7638,0,0,1-.5-.2l-24-24a.7638.7638,0,0,1-.2-.5v-68a.7638.7638,0,0,1,.2-.5l39.3-39.3v-23.7l-34.5-34.4a.7636.7636,0,0,1-.2-.5v-22.4A.6687.6687,0,0,1,30.1,277.19Zm9.9,1.4H30.8v21.4l34.5,34.5a.7638.7638,0,0,1,.2.5v24.2a.7631.7631,0,0,1-.2.5L26,398.99v67.5l23.6,23.6H65.7l59.8-59.8v-9.6l-9.5-9.5a.7636.7636,0,0,1-.2-.5v-33.5l-12.7-12.5a.7636.7636,0,0,1-.2-.5v-22.7Z"
                    transform="translate(0 -0.5898)" />
                <path className="path-caption--ctf-magent"
                    d="M84.2,321.39a.6836.6836,0,0,1,.7.7h0v33.3a.763.763,0,0,1-.2.5l-33,33.1v32.5l21.6,21.6h8.1l23-23v-20a.7638.7638,0,0,1,.2-.5l12.2-12.3a.6751.6751,0,0,1,1,.1.75.75,0,0,1,0,.9l-12,12v20a.7638.7638,0,0,1-.2.5l-23.4,23.5a.7638.7638,0,0,1-.5.2H73a.7636.7636,0,0,1-.5-.2l-22-22a.7638.7638,0,0,1-.2-.5v-33.1a.7633.7633,0,0,1,.2-.5l33.1-33.1v-33c-.2-.4.2-.7.6-.7Z"
                    transform="translate(0 -0.5898)" />
                <path className="path-caption--gray"
                    d="M30.8,292.69a.7638.7638,0,0,1,.5.2l40,40a.7638.7638,0,0,1,.2.5v12.7a.7633.7633,0,0,1-.2.5l-4.7,4.7a.6751.6751,0,0,1-1-.1.75.75,0,0,1,0-.9l4.5-4.5v-12.1l-39.8-39.8a.6681.6681,0,0,1,0-1A.7638.7638,0,0,1,30.8,292.69Z"
                    transform="translate(0 -0.5898)" />
                <path className="path-caption--ctf-orange"
                    d="M141.4,450.29a.7638.7638,0,0,1,.5.2.6681.6681,0,0,1,0,1l-86.5,86.5a.7638.7638,0,0,1-.5.2H.7a.6835.6835,0,0,1-.7-.7H0a.6835.6835,0,0,1,.7-.7H54.6l86.3-86.3C141,450.39,141.2,450.39,141.4,450.29Z"
                    transform="translate(0 -0.5898)" />
                <path className="path-caption--white"
                    d="M73.5,174.89H88.4c.4,0,.8.2.8.6s-.2.8-.6.8H73.8l-58.6,58.6,98.7,98.7a.7639.7639,0,0,1,.2.5v25l13.8,13.8a.6752.6752,0,0,1-.1,1,.75.75,0,0,1-.9,0l-14-14a.7635.7635,0,0,1-.2-.5v-25l-99-99a.725.725,0,0,1,0-1L73,175.09C73.2,174.99,73.3,174.89,73.5,174.89Z"
                    transform="translate(0 -0.5898)" />
                <path className="path-caption--pwn2me-green"
                    d="M58.9,171.59a.7638.7638,0,0,1,.5.2.6681.6681,0,0,1,0,1L7.8,224.39a.7071.7071,0,0,1-1-1l51.5-51.5A.8643.8643,0,0,1,58.9,171.59Z"
                    transform="translate(0 -0.5898)" />
                <path className="path-caption--gray"
                    d="M39.3,86.09H85.2a.7634.7634,0,0,1,.5.2l29.6,29.6a.7755.7755,0,0,1-.2,1,.6081.6081,0,0,1-.8,0L84.9,87.49H39.3c-.4,0-.8-.2-.8-.6s.2-.8.6-.8l.2,0Z"
                    transform="translate(0 -0.5898)" />
                <path className="path-caption--red_1"
                    d="M315.5,117.89h21.3a.6836.6836,0,0,1,.7.7l.1,6a.7638.7638,0,0,1-.2.5l-48.6,49.8a.7634.7634,0,0,1-.5.2H263.9a.6836.6836,0,0,1-.7-.7v-14.7a.7638.7638,0,0,1,.2-.5l32.2-32.3a.7638.7638,0,0,1,.5-.2h10.3l8.5-8.5A.6047.6047,0,0,1,315.5,117.89Zm20.6,1.4H315.8l-8.5,8.5a.7639.7639,0,0,1-.5.2H296.5l-31.8,31.8v13.7h23.4l48.2-49.3Z"
                    transform="translate(0 -0.5898)" />
                <path className="path-caption--pwn2me-green"
                    d="M190.3,123.79h13.9a.763.763,0,0,1,.5.2l56.4,56.4h27.2l23.2-23.2v-8.5a.7.7,0,0,1,1.4,0v8.8a.7638.7638,0,0,1-.2.5l-23.6,23.6a.7636.7636,0,0,1-.5.2H260.9a.7638.7638,0,0,1-.5-.2l-56.5-56.4H190.6l-3.3,3.2a.675.675,0,0,1-1-.1.75.75,0,0,1,0-.9l3.5-3.5A4.324,4.324,0,0,1,190.3,123.79Z"
                    transform="translate(0 -0.5898)" />
                <path className="path-caption--pwn2me-light_green"
                    d="M139.7,51.09a.6836.6836,0,0,1,.7.7h0v36.6a.7632.7632,0,0,1-.2.5l-13.1,13.1v7.1l5.4,5.4,12.2-12.2a.6682.6682,0,0,1,1,0l26.3,26.3h23.7a.7638.7638,0,0,1,.5.2l55.7,55.7a.7071.7071,0,0,1-1,1l-55.5-55.5H171.7a.7639.7639,0,0,1-.5-.2l-26-26L133,115.99a.6682.6682,0,0,1-1,0l-6.1-6.1a.7636.7636,0,0,1-.2-.5v-7.7a.7638.7638,0,0,1,.2-.5L139,88.09V51.79A.7512.7512,0,0,1,139.7,51.09Z"
                    transform="translate(0 -0.5898)" />
                <path className="path-caption--white"
                    d="M182.4.59h32.7a.6835.6835,0,0,1,.7.7h0v30.8a.7638.7638,0,0,1-.2.5l-4.5,4.5v53.6a.7638.7638,0,0,1-.2.5H217a.7071.7071,0,0,1,0,1.4H195.8l-17.6,17.6a.7638.7638,0,0,1-.5.2h-6.3a.7638.7638,0,0,1-.5-.2l-23.3-23.3a.7636.7636,0,0,1-.2-.5V50.29a.7638.7638,0,0,1,.2-.5l28-28V7.29a.7638.7638,0,0,1,.2-.5L182,.69A1.0343,1.0343,0,0,1,182.4.59Zm32,1.5H182.7L177,7.79v14.5a.7638.7638,0,0,1-.2.5l-28,28v35.6l22.8,22.8h5.7l17.6-17.6a.7638.7638,0,0,1,.5-.2h14.4a.7638.7638,0,0,1-.2-.5V36.99a.7638.7638,0,0,1,.2-.5l4.6-4.5Z"
                    transform="translate(0 -0.5898)" />
                <path className="path-caption--pwn2me-light_green"
                    d="M201.9,2.69a.6835.6835,0,0,1,.7.7v44a.7636.7636,0,0,1-.2.5l-32.9,32.9v12.1a.7636.7636,0,0,1-.2.5l-7.2,7.2a.7071.7071,0,0,1-1-1l7-7V80.49a.7632.7632,0,0,1,.2-.5l32.9-33V3.29A.6687.6687,0,0,1,201.9,2.69Z"
                    transform="translate(0 -0.5898)" />
                <path className="path-caption--pwn2me-light_green"
                    d="M167.5,30.19a.6835.6835,0,0,1,.7.7h0v28.3a.7633.7633,0,0,1-.2.5l-13.3,13.3a.7634.7634,0,0,1-.5.2H148c-.4,0-.8-.2-.8-.6s.2-.8.6-.8h6.1l12.9-12.9V30.99c-.1-.4.2-.7.7-.8Z"
                    transform="translate(0 -0.5898)" />
                <path className="path-caption--gray"
                    d="M216.9,32.99a.6835.6835,0,0,1,.7.7h0v57.7a.7071.7071,0,0,1-1.4,0V33.69A.6835.6835,0,0,1,216.9,32.99Z"
                    transform="translate(0 -0.5898)" />
            </svg>

            {'nologos' in props ? '' :
                <LogoPwn2me id="cowPwn2me" animate={animate ? true : false} />
            }
            {'nologos' in props ? '' :
                <LogoNet2me id="cowNet2me" animate={animate ? true : false} />
            }
            {'nologos' in props ? '' :
                <LogoCTF id="cowCTF" animate={animate ? true : false} />
            }

        </figure>
    )
}

export default memo(Cow);
