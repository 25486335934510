import React, { useState, useCallback } from 'react';

import { useTranslation } from 'react-i18next';

const focusSibling = (elem: HTMLElement) => {
    const sibling = elem.nextSibling as HTMLElement;
    sibling.focus();
}

const filterPanels = () => { };


const onInputFocus = (e: React.FocusEvent) => {
    e.currentTarget.classList.add("active");
}

export const Search: React.FC = () => {
    const [input, setInput] = useState('');

    const onMouseLeave = useCallback(
        (e: React.MouseEvent) => {
            if (input.length > 0) return;
            e.currentTarget!.classList.remove("active");
        },
        [input],
    );

    return (
        <div className="search">
            <input
                type="text"
                placeholder="Search dashboards by name"
                onChange={(e: React.FormEvent<HTMLInputElement>) => setInput(e.currentTarget.value)}
                onMouseEnter={(e: React.MouseEvent) => e.currentTarget!.classList.add("active")}
                onMouseLeave={onMouseLeave}
                onFocus={onInputFocus}
                value={input}
                spellCheck={false}
            />
            <svg
                onClick={(e: React.MouseEvent) => focusSibling(e.currentTarget as HTMLElement)}
                version="1.1" viewBox="0 0 612 612"
                xmlns="http://www.w3.org/2000/svg">
                <g id="_x34__4_">
                    <path d="m606.2 578.7-158-155.5c41.38-44.96 66.8-104.4 66.8-169.8-0.02-140-115.3-253.4-257.5-253.4s-257.5 113.4-257.5 253.4 115.3 253.4 257.5 253.4c61.44 0 117.8-21.25 162.1-56.59l158.6 156.1c7.729 7.614 20.28 7.614 28.01 0 7.747-7.613 7.747-19.97 0.018-27.58zm-348.7-110.9c-120.3 0-217.9-95.99-217.9-214.4s97.54-214.4 217.9-214.4c120.3 0 217.9 95.99 217.9 214.4s-97.54 214.4-217.9 214.4z" />
                </g>
            </svg>
            <button onClick={filterPanels}>Search</button>
        </div>
    );
}

export default Search;