import React, { memo } from 'react';

export const Paragraph: React.FC<ParagraphProps> = memo((props) => {
    // this component allows diagonally alignment on text
    const { className } = props;

    return (
        <div
            className={className}
            id={"id" in props ? props.id : ''}
            onMouseEnter={"onMouseEnter" in props ? props.onMouseEnter : () => { }}
            onMouseLeave={"onMouseLeave" in props ? props.onMouseLeave : () => { }}
        >
            {props.children}
        </div>
    )
});

export const ParagraphHeader: React.FC<IntrinsicProps> =  ({children }) => {
    return (
        <figure className="logo">
            {children}
        </figure>
    )
}

export const LeftDiagonal: React.FC<UniDiagonalProps> = (props) => {
    // creates floating diagonal on the left
    const { direction, polWidth, polMinWidth, diagStart, slope, contentHeight } = props;

    return (
        <div className="polygon" style={{
            shapeOutside: `polygon(0 0, ${polMinWidth} 0, ${polMinWidth} ${diagStart}, calc(${polMinWidth} +  ${slope}) ${contentHeight}, 0 ${contentHeight})`,
            float: direction,
            width: polWidth,
            height: contentHeight
        }}></div>
    )
}

export const RightDiagonal: React.FC<UniDiagonalProps> = (props) => {
    // creates floating diagonal on the right
    const { direction, polWidth, polMinWidth, diagStart, slope, contentHeight } = props;
    return (
        <div className="polygon" style={{
            shapeOutside: `polygon(100% 0, calc(100% - ${polMinWidth}) 0, calc(100% - ${polMinWidth}) ${diagStart}, calc(${polMinWidth} - ${slope}) ${contentHeight}, 100% ${contentHeight})`,
            float: direction,
            width: polWidth,
            height: contentHeight
        }}></div>
    )
}

export const Diagonal: React.FC<DiagonalProps> = (props) => {
    // makes text to be diagonally justified
    switch (props.direction) {
        case "left":
            return <LeftDiagonal {...props} direction="left" />;
        case "right":
            return <RightDiagonal {...props} direction="right" />;
        default:
            return (
                <>
                    <LeftDiagonal {...props} direction="left" />
                    <RightDiagonal {...props} direction="right" />
                </>
            );
    }
}