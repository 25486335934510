import React, { useEffect, memo, useMemo } from 'react';

import View from './View';

import { Paragraph, ParagraphHeader } from '../Element/Paragraph';

import { EdgeBars, DiagonalEdgeBar } from '../Rootrilla/RootRilla';

import MiniAirCrafts from '../Rootrilla/MiniAircrafts';

import LogoLDA from '../Rootrilla/LogoLDA';

import LogoPwn2me from '../Rootrilla/LogoPwn2me';
import LogoNet2me from '../Rootrilla/LogoNet2me';
import LogoCTF from '../Rootrilla/LogoCTF';

import Cow from '../Rootrilla/Cow';
import { useTranslation } from 'react-i18next';

import { Polygon } from '../Other/Polygon';

import { growAnimation, fadeInFromBottom, fadeOutToBottom } from '../../utils/animation';

import { MIN_SCREEN_WIDTH_NAV_TOGGLE } from '../constants';

const showBadge = (e: React.MouseEvent) => {
    if (!e.currentTarget.getElementsByClassName('c-badge')) return;

    const badge = e.currentTarget.getElementsByClassName('c-badge')[0] as HTMLDivElement;
    badge.style.transition = 'all 0.4s ease-out';
    fadeInFromBottom(badge);

}

const hideBadge = (e: React.MouseEvent) => {
    if (!e.currentTarget.getElementsByClassName('c-badge')) return;

    const badge = e.currentTarget.getElementsByClassName('c-badge')[0] as HTMLDivElement;
    badge.style.transition = 'all 0.2s ease-in';

    fadeOutToBottom(badge);

}

const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

const SVGAnimation = {
    growTime: 1.5,
    delay: isFirefox ? 3 : 6,
    delayIncrement: 0.2,
    fillTime: 0.8
}

export const Labs: React.FC = () => {

    const { t } = useTranslation();

    const brands = useMemo(() => ({
        pwn2me: {
            badge: t('labs.pwn2me.badge'),
            content: t('labs.pwn2me.caption'),
            link: "https://pwn2.me"
        },
        net2me: {
            badge: t('labs.net2me.badge'),
            content: t('labs.net2me.caption'),
            link: "https://net2.me"
        },
        ctf: {
            badge: t('labs.ctf.badge'),
            content: t('labs.ctf.caption'),
            link: "https://ctf.me"
        }
    }), [t]);

    // animate labs view once get into the user's view
    useEffect(() => {
        const isMobileScreen = window.innerWidth <= MIN_SCREEN_WIDTH_NAV_TOGGLE;
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('animate-labs');

                    const pwn2meSVGPaths = document.querySelectorAll('#pwn2me svg.svg-logo--pwn2me *:not(defs, g, clipPath)');

                    const net2meSVGPaths = document.querySelectorAll('#net2me svg.svg-logo--net2me *:not(defs, g, clipPath)');

                    const CTFSVGPaths = document.querySelectorAll('#ctf svg *:not(defs, g, clipPath)');

                    setTimeout(() => {
                        // this fix cow animation on window resize
                        const cowContainer = document.getElementById('RRCow') as HTMLElement;
                        cowContainer.style.display = "none";
                    }, 4000);

                    [pwn2meSVGPaths, net2meSVGPaths, CTFSVGPaths].map(
                        (svgPaths, index) => svgPaths.forEach(path => growAnimation(
                            path as SVGPathElement,
                            {
                                growTime: 1.5,
                                delay: (isMobileScreen ? 0.3 : 2) + (index * 0.2),
                                fillTime: 0.8
                            }
                        ))
                    )

                    const miniAircraftPaths = document.querySelectorAll('svg#miniAicraftServices path');
                    miniAircraftPaths.forEach(path => growAnimation(
                        path as SVGPathElement,
                        { growTime: 2, delay: 2.5, fillTime: 1 }
                    ));



                }
            })
        });
        observer.observe(document.querySelector('#labsMain')!);
    }, []);

    const polygonHeight = "15em";

    return (
        <View langKey="labs" level={2} id="labs">
            <div className="c-labs" id="labsMain">
                <section id="pwn2me">
                    <Paragraph
                        className="art"
                        onMouseEnter={showBadge}
                        onMouseLeave={hideBadge}
                    >
                        <ParagraphHeader>
                            <LogoPwn2me
                                animate={false}
                                animationProps={{
                                    growTime: SVGAnimation.growTime,
                                    delay: SVGAnimation.delay,
                                    fillTime: SVGAnimation.fillTime
                                }} />
                            <div className="c-badge" id="badgeLearn">
                                <h4>{brands.pwn2me.badge}</h4>
                            </div>
                        </ParagraphHeader>
                        <Polygon
                            direction="left"
                            shape="polygon(0px 0px, 28px 1px, 29px 60%, 130px 100%, 0px 100%)"
                            height={polygonHeight}
                            width="150px"
                        />

                        <div className="wrapper-text">
                            {brands.pwn2me.content.split('\n').map(line => <p>{line}</p>)}

                            <EdgeBars direction="right" />,
                            <DiagonalEdgeBar
                                direction="left"
                                shape="polygon(2px 0px, 2px 50%, 75px 80%, 75px calc(80% - 3px), 4px calc(50% - 2px), 4px  0px)"
                                // height="350px"
                                height={polygonHeight}
                            />
                            <a className="typo-a--brand" href={brands.pwn2me.link}>{brands.pwn2me.link}</a>
                        </div>

                    </Paragraph>
                    <MiniAirCrafts id="miniAicraftServices" />
                </section>

                <section id="net2me">
                    <LogoLDA />
                    <Paragraph
                        className="art"
                        onMouseEnter={showBadge}
                        onMouseLeave={hideBadge}
                    >
                        <ParagraphHeader>
                            <LogoNet2me
                                animate={false}
                                animationProps={{
                                    growTime: SVGAnimation.growTime,
                                    delay: SVGAnimation.delay,
                                    fillTime: SVGAnimation.fillTime
                                }} />
                            <div className="c-badge" id="badgeDefend">
                                <h4>{brands.net2me.badge}</h4>
                            </div>
                        </ParagraphHeader>
                        {/* makes text diagonally aligned */}
                        <Polygon
                            direction="right"
                            shape="polygon(100% 0px, 130px 0px, 130px 55%, 35px 90.00%, 100% 90%)"
                            // height="300px"
                            height="15em"
                            width="150px"
                        />

                        <div className="wrapper-text">
                            {/* <p>{brands.net2me.content.split('\n').map(line => <>{line}<br /></>)}</p> */}
                            {brands.net2me.content.split('\n').map(line => <p>{line}</p>)}
                            <EdgeBars direction="left" />,
                            <DiagonalEdgeBar
                                direction="right"
                                shape="polygon(calc(95% - 2px) 0px, calc(95% - 2px) calc(66% - 2px), 0px calc(100% - 3px), 0px 100%, 95% 66%, 95% 0px)"
                                width="80px"
                                height="auto"
                            />
                            <a
                                className="typo-a--brand"
                                href={brands.net2me.link}
                                style={{ paddingRight: "20%" }}
                            >
                                {brands.net2me.link}
                            </a>
                        </div>

                    </Paragraph>
                </section>
                <section id="ctf">
                    <Paragraph
                        className="art"
                        onMouseEnter={showBadge}
                        onMouseLeave={hideBadge}
                    >
                        <ParagraphHeader>
                            <LogoCTF
                                animate={false}
                                animationProps={{
                                    growTime: SVGAnimation.growTime,
                                    delay: SVGAnimation.delay + SVGAnimation.delayIncrement * 2,
                                    fillTime: SVGAnimation.fillTime
                                }} />
                            <div className="c-badge" id="badgeAttack">
                                <h4>{brands.ctf.badge}</h4>
                            </div>
                        </ParagraphHeader>
                        <Polygon
                            direction="left"
                            shape="polygon(0px 0px, 28px 0px, 28px 5em, 100px 85%, 0px 85%)"
                            height="12em"
                            width="150px"
                        />
                        <Polygon
                            direction="right"
                            shape="polygon(122px 5rem, 122px 0px, 100% 0px, 100% 85%, 40px 85%)"
                            height="12em"
                            width="150px"
                        />

                        <div className="wrapper-text">
                            {brands.ctf.content.split('\n').map(line => <p>{line}</p>)}
                            <DiagonalEdgeBar
                                direction="left"
                                shape="polygon(0px 0px, 0px 60%, 90px 100%, 90px calc(100% - 3px), 2px calc(60% - 2px), 2px 0px)"
                                height="auto"
                            />,
                            <DiagonalEdgeBar
                                direction="right"
                                shape="polygon(75px 0px, 75px calc(60% - 3px), 0px calc(90% - 3px), 0px 90%, 77px 60% , 77px 0px)"
                                height="auto"
                            />
                            <a
                                className="typo-a--brand"
                                href={brands.ctf.link}
                                style={{ paddingRight: "20%" }}
                            >
                                {brands.ctf.link}
                            </a>
                        </div>
                    </Paragraph>
                </section>

                <Cow id="RRCow" />
            </div>
        </View>
    )
}

export default memo(Labs);