import React, { memo, useState, useLayoutEffect, useContext, useEffect } from 'react';

import { render } from 'react-dom';

import {
    MIN_SCREEN_WIDTH_STARTUP_ANIM,
    MIN_SCREEN_HEIGHT_STARTUP_ANIM,
    COOKIE_FIRST_TIME_PROPERTY_NAME,
    MAX_SCREEN_WIDTH_STARTUP_ANIM,
    MAX_SCREEN_HEIGHT_STARTUP_ANIM
} from '../constants';

import Land from '../Views/Land';
import RTM from '../Views/RTM';
import Services from '../Views/Services';
import CurrentServices from '../Views/CurrentServices';
import Labs from '../Views/Labs';
import Contact from '../Views/Contact';
import Navbar from '../Structure/Navbar';
import TopLinks from '../Other/TopLinks';
import ThemeSwitcher from '../Theme/ThemeSwitcher';
import Header from '../Structure/Header';
import Footer from '../Structure/Footer';
import ScrollUp from '../Other/ScrollUp';
import { LangSelector } from '../Other/LangSelector';

import AuthContext from '../Auth/AuthContext';

import FlashMessage from '../Element/FlashMessage';

import { startUpAnimation, startUpTransition } from '../../utils/animation';

import { cookieStorage } from '../../utils/storage';


const animConfig = {
    logoRevealTime: 1.5, // seconds
    logoScaleTime: 0.6, // seconds
    logoStaticTime: 0,  //miliseconds
    slideBarsTime: 0.4,
    delay: 500, // miliseconds
}

export const shouldAnimateStartup = () => {
    const isFirstTime = !cookieStorage.getItem(COOKIE_FIRST_TIME_PROPERTY_NAME);
    const isSmallDevice = window.innerWidth < MIN_SCREEN_WIDTH_STARTUP_ANIM || window.innerHeight < MIN_SCREEN_HEIGHT_STARTUP_ANIM;

    const isBigDevice = window.innerWidth > MAX_SCREEN_WIDTH_STARTUP_ANIM || window.innerHeight > MAX_SCREEN_HEIGHT_STARTUP_ANIM;

    return isFirstTime && !isSmallDevice && !isBigDevice;
}


export const Home: React.FC = () => {
    const { username } = useContext(AuthContext);
    const [isAnimated, setIsAnimated] = useState(false);


    // remove startup animation on small-screen devices
    useLayoutEffect(() => {
        if (isAnimated) return;

        shouldAnimateStartup() ? startUpAnimation(animConfig) : startUpTransition();

        setIsAnimated(true);

        // disabled ´long´ startup animation after first visit
        return () => {
            if (cookieStorage.getItem(COOKIE_FIRST_TIME_PROPERTY_NAME)) return;

            cookieStorage.setItem(COOKIE_FIRST_TIME_PROPERTY_NAME, 'false');
        }

    }, [isAnimated]);

    // show up greeting message if logged user
    useEffect(() => {
        if (!username) return;

        setTimeout(() => {
            render(
                <FlashMessage
                    message={`User ${username} is logged in!`}
                    type="success"
                    timeoutMs={2500}
                />,
                document.getElementById('messages')
            )
        }, 1500)
    }, [username]);

    // change title of document
    useEffect(() => {
        document.title = "Rootrilla | Cybersecurity Services"
    });

    return (
        <div className="wrapper home-page" id="home">
            <Header>
                <div className="c-top">
                    <LangSelector />
                    <TopLinks />
                </div>
                <Navbar />
            </Header>
            <main id="main">
                <Land />
                <RTM />
                <Services />
                <CurrentServices />
                <Labs />
                <Contact />
                <ThemeSwitcher />
            </main>
            <Footer />
            <ScrollUp />
            {/* flash messaging */}
            <div id="messages"></div>
            <div className="overlay"></div>
        </div>
    )
}

export default memo(Home);