import React, { memo } from 'react';

import { useTranslation } from 'react-i18next';
import { redirectPaymentGateway } from '../../utils/functions';

const langKeyPrefix = "rtm.pricing.plans";

const featuresMap: IFeature = {
    0: langKeyPrefix + ".features.0",
    1: langKeyPrefix + ".features.1",
    2: langKeyPrefix + ".features.2",
    3: langKeyPrefix + ".features.3",
    4: langKeyPrefix + ".features.4"
}

// LKey: Language key, relates to internationalization
const plans: IPlan[] = [
    {
        langKey: "researcher",
        features: [0, 1],
        price: "$12.00"
    },
    {
        langKey: "enterprise",
        features: [0, 1, 2, 3],
        price: "$24.00",
        highlight: true
    },
    {
        langKey: "ultimate",
        features: [0, 1, 2, 3, 4, 5],
        price: "Contact us."
    },
]

const mapCard = (data: IPlan) => {
    return <Card {...data} />
}

const mapFeature = (key: number, active: boolean, t: any) => {
    return <div className={active ? "active" : ""}>
        <h5>{t(featuresMap[key])}</h5>
    </div>
}

export const Pricing: React.FC = () => {
    const { t } = useTranslation();
    return (
        <section className="c-view" id="pricing">
            <header>
                <h1>{t('rtm.pricing.header')}</h1>
            </header>
            <div className="c-pcards">
                {plans.map(plan => mapCard(plan))}
            </div>
        </section>
    );
}

const Card: React.FC<CardProps> = memo(({ langKey, features, price, ...props }) => {
    const { t } = useTranslation();
    const relLangKey = `${langKeyPrefix}.${langKey}`;
    return (
        <div className={"c-card" + ("highlight" in props ? " active" : "")}>
            <header>
                <h3>{t(relLangKey + ".header")}</h3>
                <p>{t(relLangKey + ".desc")}</p>
            </header>
            <div className="hor-line"></div>
            <div className="features">
                {/* map all the keys in featuresMap and activate 'feature' if key is in given features */}
                {Object.keys(featuresMap).map(key => mapFeature(parseInt(key), parseInt(key) in features, t))}
            </div>
            <div className="hor-line"></div>
            <div className="choose">
                <h4>{price}<em className="postfix">{t(langKeyPrefix + ".frequency")}</em></h4>
                <button onClick={redirectPaymentGateway}>{t(`${langKeyPrefix}.btn`)}</button>
            </div>
        </div>
    )
});

export default Pricing;
