import React from 'react';

export const LoadingSpinner: React.FC = () => {
    return (
        <div className="wrapper-spinner">
            <Spinner />
        </div>
    )
}

export const Spinner: React.FC = () => {
    return (
        <div className="loader-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
}

export default LoadingSpinner;