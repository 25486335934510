import React, { memo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const foldInLanguages = () => {
    const dropdown = document.getElementById('dropdownLanguages') as HTMLElement;
    if (!dropdown.classList.contains('show')) return;

    dropdown.classList.remove('show');

    dropdown.style.transform = "scaleY(0)";

}

const foldOutLanguages = () => {
    const dropdown = document.getElementById('dropdownLanguages') as HTMLElement;

    // dropdown was active
    if (dropdown.classList.contains('show')) {
        foldInLanguages();
        return;
    }

    dropdown.classList.add('show');

    dropdown.style.transform = "scaleY(1)";
}

export const LangSelector: React.FC = memo(() => {

    const { i18n } = useTranslation();

    const currLang = i18n.language;

    // by default uses EN
    const [activeLanguage, setActiveLanguage] = useState(currLang in languages ? languages[currLang] : languages.en);

    const changeLanguage = useCallback((langKey: string) => {
        // avoid re-rendering
        if (activeLanguage.langKey === langKey) return;

        i18n.changeLanguage(langKey);

        // hide the dropdown menu
        foldInLanguages();
        setActiveLanguage(languages[langKey]);

    }, [i18n, activeLanguage]);


    const mapLanguage = useCallback((language: ILanguage, index: number) => {
        return (
            <Language {...language}
                index={index}
                onClick={() => changeLanguage(language.langKey)}
            />
        );
    }, [changeLanguage]);

    return (
        <div key="langSelector" className="c-lang-selector" id="langSelector">
            <div className="lang-selected" onClick={foldOutLanguages}>
                <activeLanguage.Flag />
                <div>{activeLanguage.displayName}</div>
                <svg
                    className="dropdown-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 306 188.7"
                >
                    <g key="lsG1" id="ac0b2e72-4c1f-41d7-9412-3f326ae00fc7" data-name="expand-more">
                        <polygon key="lsG1P" points="270.3 0 153 117.3 35.7 0 0 35.7 153 188.7 306 35.7 270.3 0" />
                    </g>
                </svg>
            </div>
            <div key={"dropdownLangs"} className="dropdown-languages" id="dropdownLanguages">
                {Object.keys(languages).map((key, index) => mapLanguage(languages[key], index))}
            </div>
        </div>
    )
});



const Spain: React.FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 335.45">
            <rect fill="#f8d12c" width="512" height="335.45" rx="11.8312" />
            <path fill="#b41a28" d="M11.7693,0h488.444A11.7867,11.7867,0,0,1,512,11.7867V69.5238a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V11.7693A11.7693,11.7693,0,0,1,11.7693,0Z" />
            <path fill="#b41a28" d="M0,265.9262H512a0,0,0,0,1,0,0v57.9865A11.5374,11.5374,0,0,1,500.4626,335.45H11.3492A11.3492,11.3492,0,0,1,0,324.1008V265.9262a0,0,0,0,1,0,0Z" />
        </svg>
    )
}

const USA: React.FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 335.4565">

            <rect fill="#da2a40" y="0.0065" width="512" height="335.45" rx="11.8312" />
            <path fill="#41479b" d="M12.5077,0H238.346a0,0,0,0,1,0,0V180.627a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V12.5077A12.5077,12.5077,0,0,1,12.5077,0Z" />
            <rect fill="#fff" x="238.346" y="25.8025" width="273.654" height="25.804" />
            <rect fill="#fff" x="238.346" y="77.4115" width="273.654" height="25.804" />
            <rect fill="#fff" x="238.346" y="129.0225" width="273.654" height="25.804" />
            <rect fill="#fff" y="180.6335" width="512" height="25.804" />
            <rect fill="#fff" y="233.4105" width="512" height="25.804" />
            <rect fill="#fff" y="285.3115" width="512" height="25.804" />
        </svg>
    )
}

const languages: ILanguages = {
    en: {
        'displayName': 'English',
        'Flag': USA,
        'langKey': 'en'
    },
    es: {
        'displayName': 'Español',
        'Flag': Spain,
        'langKey': 'es'
    }
}


const Language: React.FC<LanguageProps> = ({ displayName, Flag, index, onClick, children }) => {
    return (
        <div
            key={"lang" + index}
            className="lang"
            onClick={onClick}
        >
            <Flag key={"langF" + index} />
            <div key={"langD" + index}>{displayName}</div>
            {children}
        </div>
    )
}

export default LangSelector;