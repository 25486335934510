import React from 'react'

interface HeaderProps {
    children: React.ReactElement[];
}

export const Header: React.FC<HeaderProps> = ({ children }) => {
    return (
        <header key="header" className="c-nav">
            {children}
        </header>
    )
}

export default Header;