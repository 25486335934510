import React, { useEffect, useRef } from 'react';

import Cow from '../Rootrilla/Cow';

// import LogoNav from '../Rootrilla/LogoNav';

import { useTranslation } from 'react-i18next';
import ProgressBar, { progress } from '../Element/ProgressBar';
import { getTheme } from '../../utils/storage';

const cowBlinking = (maintenanceType: "frontend" | "backend" | "all") => {
    const cow = document.getElementById('cowMaintenance') as HTMLElement;
    switch (maintenanceType) {
        case "frontend":
            cow.querySelector('.svg-cow:first-child')?.classList.add('blink');
            break;
        case "backend":
            cow.querySelector('.svg-cow:nth-child(2)')?.classList.add('blink');
            break;
        default:
            cow.classList.add("blink");
    }
}

export const Maintenance: React.FC<MaintenanceProps> = ({ type }) => {
    const { t } = useTranslation();
    const barRef = useRef(null);
    const theme = getTheme();

    useEffect(() => {
        document.title = "Rootrilla | Maintenance"
    }, []);

    useEffect(() => {
        // @todo: keep growing bar based on expected maintenance time
        progress(barRef.current! as HTMLElement, 60, () => cowBlinking(type!));

    }, [barRef, type]);
    return (
        <div className={"wrapper" + (theme === "dark" ? " dark" : "")} id="maintenance">
            <header>
                <h1>{t('maintenance.caption')}</h1>
                <p>{t('maintenance.content')}</p>
            </header>
            <Cow animate={false} nologos id="cowMaintenance" />
            <ProgressBar barRef={barRef} />
        </div>
    )
}

export default Maintenance;
