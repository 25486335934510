import React, { useState, useEffect, memo, useCallback } from 'react';
import QuadAirCraft from '../Rootrilla/QuadAirCraft';

import { growAnimation } from '../../utils/animation';

import { useTranslation } from 'react-i18next';

const services: Array<RRServices> = [
    {
        id: "softwareDev",
        langKey: 'services.software'
    },
    {
        id: "devOpsSec",
        langKey: 'services.devOps'
    },
    {
        id: "MalwareAnalysis",
        langKey: 'services.malwareAnalysis'
    }
];

export const CurrentServices: React.FC = () => {
    const { t } = useTranslation();

    const [currentService, setCurrentService] = useState(services[0]);

    const updateCurrentService = useCallback((e: React.MouseEvent) => {
        // avoid rendering when user clicks the same service
        if (e.currentTarget.id === currentService.id) return;

        const prevService = document.getElementById(currentService.id)
        if (!prevService) {
            console.debug("Error, couldn't retrieve previous service element");
            return;
        }

        const container = document.getElementById("servicesFlag") as HTMLDivElement;

        // this avoids animating the flag more than one time
        if (container.classList.contains("animate-flag")) {
            const divService = e.currentTarget as HTMLDivElement;
            divService.style.transform = "scaleX(1)";
            divService.style.transformOrigin = "center left";
            container.classList.remove("animate-flag");
        }

        prevService.classList.toggle("active");
        e.currentTarget.classList.toggle("active"); // asummes that element isn't active

        const filteredServices = services.filter((service) => service.id === e.currentTarget.id);

        if (!filteredServices) {
            console.debug("Error on service filtration");
            return;
        }

        setCurrentService(filteredServices[0]);

    }, [currentService]);

    const mapService = useCallback((service: RRServices) => {
        const { id, langKey } = service;
        return (
            <>
                <div className={id === currentService.id ? "service active" : "service"} key={id} id={id} onClick={updateCurrentService}>
                    <h3>{t(`${langKey}.title`)}</h3>
                </div>
                <WrapperText langKey={langKey} t={t} key={"w" + id} />
            </>
        )
    }, [currentService, updateCurrentService, t]);

    useEffect(() => {
        const divService = document.querySelector('div.service.active') as HTMLDivElement;
        divService.style.transform = "scaleX(0)";
        divService.style.transformOrigin = "center left";

        let isAnimated = false;
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    document.getElementById('servicesFlag')!.classList.add('animate-flag');

                    if (!isAnimated) {
                        const quadAircraft = document.querySelectorAll('svg#quadAircraft path');
                        quadAircraft.forEach(path => growAnimation(path as SVGPathElement, { growTime: 2 }));

                        // transition is include later since bugs the flag animation
                        const services = document.querySelectorAll('#servicesFlag div.service') as NodeListOf<HTMLElement>;
                        services.forEach(service => {
                            service.style.transition = "all 0.3s ease";
                        });

                        divService.style.transform = "";

                        isAnimated = true;
                    }
                }
            })
        });
        observer.observe(document.getElementById('currentServices')!);
    }, [])

    return (
        <section className="c-view" id="currentServices">
            <div className="c-services--main" id="servicesFlag">
                {services.map(service => mapService(service))}
            </div>
            <ServiceCaption service={currentService} t={t} />
        </section >
    )
}

const ServiceCaption: React.FC<CServiceCaptionProps> = React.memo(({ service, t }) => {
    return (
        <div className="c-service--about">
            <PolygonShape height={"100%"} width={"50%"} verticalPadding={{ top: "20%", bottom: "14%" }}>
                <p className="typo-p--red">{t(`${service.langKey}.content`)}</p>
            </PolygonShape>
            <QuadAirCraft id="quadAircraft" />
        </div>
    )
});

const WrapperText: React.FC<CurrentServicesWrapperTextProps> = ({ langKey, t }) => {
    return (
        <div className="wrapper-text">
            <p>{t(`${langKey}.content`)}</p>
        </div>
    )
}

const PolygonShape: React.FC<PolygonShapeProps & IntrinsicProps> = ({ height, width, verticalPadding, children }) => {
    return (
        <>
            <div
                className="polygon"
                style={{
                    shapeOutside: `polygon(0 0, 100% 0, 100% ${verticalPadding.top}, calc(100% - 17vh) 38%, calc(100% - 17vh) 60%, calc(100% - 10vh) 66%, calc(100% - 10vh) 75%, 100% calc(100% - ${verticalPadding.bottom}), 100% 100%,  0 100%)`,
                    float: "left",
                    height: height,
                    width: width,
                }}
            >
            </div>
            <div
                className="polygon"
                style={{
                    shapeOutside: `polygon(100% 0, 0 0, 0 ${verticalPadding.top}, 17vh 38%, 17vh 60%, 10vh 66%, 10vh 75%, 0 calc(100% - ${verticalPadding.bottom}), 0 100%,  100% 100%)`,
                    float: "right",
                    height: height,
                    width: width,
                }}
            >
            </div>
            {children}
        </>
    )
}

export default memo(CurrentServices);