import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import RegisterForm from '../Form/RegisterForm';

import Logo from '../Rootrilla/Logo';
import { LangSelector } from '../Other/LangSelector';

import Aircraft from '../Rootrilla/Aircraft';

import { redirectHome } from "../../utils/functions";

export const Register: React.FC = () => {
    const { t } = useTranslation();
    useEffect(() => {
        document.title = "Rootrilla | Register"
    }, []);
    return (
        <div className="v-register">
            <div className="c-register">
                <figure>
                    <Logo onClick={redirectHome} />
                </figure>
                <RegisterForm />
                <a className="typo-a" href="/login">{t('register.link.login')}</a>
                <Aircraft id="loginAircraftBL" direction="left" animate={false} />
                <Aircraft id="loginAircraftTR" direction="left" animate={false} />
                <a className="arrow--back" href="/">{t('login.btn.back')}</a>
                <LangSelector />
            </div>
            <div id="messages"></div>
        </div>
    )
}

export default memo(Register);