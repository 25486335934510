import React, { memo } from 'react';

// Learn Defend and Attack logo
export const LogoLDA: React.FC<ElementLikeProps> = memo((props) => {
    return (
        <svg id={'id' in props ? props.id : 'learnDefendAttack'} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="219.862" height="219.862"
            viewBox="0 0 219.862 219.862">
            <defs>
                <linearGradient id="linear-gradient" x1="0.715" y1="-0.01" x2="0.166" y2="0.925"
                    gradientUnits="objectBoundingBox">
                    <stop offset="0" stop-color="#527318" />
                    <stop offset="1" stop-color="#feb72b" />
                </linearGradient>
                <linearGradient id="linear-gradient-2" x1="0.409" y1="0.116" x2="0.66" y2="0.859"
                    gradientUnits="objectBoundingBox">
                    <stop offset="0" stop-color="#009fe3" />
                    <stop offset="1" stop-color="#312783" />
                </linearGradient>
                <linearGradient id="linear-gradient-3" x1="0.89" y1="-0.037" x2="0.151" y2="0.726"
                    gradientUnits="objectBoundingBox">
                    <stop offset="0" stop-color="#940a37" />
                    <stop offset="1" stop-color="#e26241" />
                </linearGradient>
                <linearGradient id="linear-gradient-4" x1="0.512" y1="0.002" x2="0.489" y2="0.971"
                    xlinkHref="#linear-gradient-2" />
                <linearGradient id="linear-gradient-5" x1="0.014" y1="0.161" x2="0.962" y2="0.794"
                    xlinkHref="#linear-gradient-3" />
                <linearGradient id="linear-gradient-6" x1="0.957" y1="0.22" x2="-0.01" y2="0.783"
                    xlinkHref="#linear-gradient" />
            </defs>
            <g id="Group_958" data-name="Group 958" transform="translate(-1479.198 -1949.911)">
                <path id="Path_837" data-name="Path 837"
                    d="M1586.474,1956.34c-69.407-2.156-121.217,76.09-92.283,139.157a101.958,101.958,0,0,0,5.614,11.5l21.208-12.387c-23.816-44.574-2-112.834,65.461-113.977.092,0,.18.008.272.009v-24.3C1586.656,1956.339,1586.566,1956.337,1586.474,1956.34Z"
                    transform="translate(3.49 3.255)" fill="url(#linear-gradient)" />
                <path id="Path_838" data-name="Path 838"
                    d="M1616.475,2093.855l21.4,12.07a102.627,102.627,0,0,0,5.035-10.477c28.876-62.975-22.732-141.112-92.014-139.158v24.3C1617.795,1981.918,1639.647,2049.334,1616.475,2093.855Z"
                    transform="translate(39.377 3.232)" fill="url(#linear-gradient-2)" />
                <path id="Path_839" data-name="Path 839"
                    d="M1668.811,2056.97l-21.4-12.071c-11.281,21.674-33.229,37.924-65.851,38.475-32.261-.55-54.069-16.454-65.458-37.769l-21.208,12.387C1532.336,2123.894,1632.019,2123.56,1668.811,2056.97Z"
                    transform="translate(8.403 52.256)" fill="url(#linear-gradient-3)" />
                <g id="Group_895" data-name="Group 895" transform="translate(1479.698 1950.411)">
                    <path id="Path_840" data-name="Path 840"
                        d="M1589.128,1950.411a109.146,109.146,0,1,0,42.6,8.607,108.766,108.766,0,0,0-42.6-8.607Z"
                        transform="translate(-1479.698 -1950.411)" fill="none" stroke="#606060" stroke-miterlimit="10"
                        stroke-width="1" />
                </g>
                <g id="Group_902" data-name="Group 902" transform="translate(1530.602 1990.083)">
                    <g id="Group_897" data-name="Group 897" transform="translate(29.039)">
                        <g id="Group_896" data-name="Group 896">
                            <path id="Path_841" data-name="Path 841"
                                d="M1592,2007.74a9.265,9.265,0,0,0-9.265-9.265h-.05a9.666,9.666,0,0,0-12.033-14.54,8.391,8.391,0,0,0-16.771.412c0,.193.008.384.021.574a9.664,9.664,0,0,0-13.573,13.555,9.264,9.264,0,0,0-.463,18.509,6.518,6.518,0,0,0-1.424,4.237c.1,3.566,7.374,10.118,12.639,14.4a16.1,16.1,0,0,0,5.274,4.091l.067.05v-.017a11.868,11.868,0,0,0,10.452-.174h0a16.032,16.032,0,0,0,4.641-3.575c5.294-4.252,13.24-11.241,13.341-14.968a6.48,6.48,0,0,0-1.3-4.064A9.266,9.266,0,0,0,1592,2007.74Z"
                                transform="translate(-1531.173 -1975.956)" fill="url(#linear-gradient-4)" />
                        </g>
                    </g>
                    <g id="Group_899" data-name="Group 899" transform="translate(59.883 57.125)">
                        <g id="Group_898" data-name="Group 898">
                            <path id="Path_842" data-name="Path 842"
                                d="M1566.551,2066.994a9.266,9.266,0,0,0,12.612-3.558l.023-.044a9.665,9.665,0,0,0,18.565-3.4,8.391,8.391,0,0,0,7.835-14.834c-.169-.095-.34-.18-.513-.262a9.664,9.664,0,0,0-5.2-18.466,9.263,9.263,0,0,0-15.924-9.446,6.525,6.525,0,0,0-3-3.314c-3.159-1.657-12.43,1.491-18.742,3.993a16.077,16.077,0,0,0-6.147,2.6l-.076.034.014.009a11.871,11.871,0,0,0-4.953,9.2h0a16.008,16.008,0,0,0,.851,5.8c1.124,6.7,3.341,17.043,6.543,18.952a6.475,6.475,0,0,0,4.181.856A9.263,9.263,0,0,0,1566.551,2066.994Z"
                                transform="translate(-1551.033 -2012.739)" fill="url(#linear-gradient-5)" />
                        </g>
                    </g>
                    <g id="Group_901" data-name="Group 901" transform="translate(0 57.125)">
                        <g id="Group_900" data-name="Group 900">
                            <path id="Path_843" data-name="Path 843"
                                d="M1560.851,2055.123a6.476,6.476,0,0,0,4.181-.856c3.2-1.909,5.418-12.255,6.543-18.952a16.008,16.008,0,0,0,.851-5.8h0a11.869,11.869,0,0,0-4.953-9.2l.014-.009-.076-.034a16.077,16.077,0,0,0-6.147-2.6c-6.312-2.5-15.583-5.65-18.742-3.993a6.527,6.527,0,0,0-3,3.314,9.263,9.263,0,0,0-15.923,9.446,9.664,9.664,0,0,0-5.2,18.466c-.172.082-.343.168-.512.262a8.391,8.391,0,0,0,7.835,14.834,9.665,9.665,0,0,0,18.565,3.4l.023.044a9.264,9.264,0,0,0,16.538-8.313Z"
                                transform="translate(-1512.475 -2012.739)" fill="url(#linear-gradient-6)" />
                        </g>
                    </g>
                </g>
                <g id="Group_904" data-name="Group 904" transform="translate(1589.77 2000.781)">
                    <g id="Group_903" data-name="Group 903">
                        <path id="Path_844" data-name="Path 844"
                            d="M1551.281,2031.586a.709.709,0,0,1-.708-.71v-47.324a.708.708,0,1,1,1.416,0v47.324A.709.709,0,0,1,1551.281,2031.586Z"
                            transform="translate(-1550.573 -1982.844)" fill="#fff" />
                    </g>
                </g>
                <g id="Group_906" data-name="Group 906" transform="translate(1578.147 2008.428)">
                    <g id="Group_905" data-name="Group 905">
                        <path id="Path_845" data-name="Path 845"
                            d="M1555.43,2014.26c-.157,0-.314,0-.471-.016-11.371-.724-11.854-24.735-11.87-25.757a.71.71,0,0,1,.7-.719h.011a.708.708,0,0,1,.708.7c0,.238.471,23.724,10.542,24.366a5.6,5.6,0,0,0,4.283-1.589c6.015-5.464,6.639-22.617,6.644-22.79a.708.708,0,0,1,.708-.685h.023a.71.71,0,0,1,.687.731c-.025.729-.654,17.93-7.108,23.792A7.07,7.07,0,0,1,1555.43,2014.26Z"
                            transform="translate(-1543.089 -1987.768)" fill="#fff" />
                    </g>
                </g>
                <g id="Group_908" data-name="Group 908" transform="translate(1574.368 2008.427)">
                    <g id="Group_907" data-name="Group 907">
                        <path id="Path_846" data-name="Path 846"
                            d="M1556.8,2020.628q-.3,0-.606-.019c-3.511-.211-6.518-2.087-8.936-5.578a33.672,33.672,0,0,1-4.543-11,79.232,79.232,0,0,1-2.058-15.547.709.709,0,0,1,.7-.719h.011a.708.708,0,0,1,.708.7c0,.3.627,29.912,14.2,30.729a8.109,8.109,0,0,0,6.106-2.255c7.821-7.116,8.638-28.273,8.646-28.488a.709.709,0,0,1,1.417.05c-.031.893-.846,21.968-9.109,29.485A9.5,9.5,0,0,1,1556.8,2020.628Z"
                            transform="translate(-1540.656 -1987.768)" fill="#fff" />
                    </g>
                </g>
                <g id="Group_910" data-name="Group 910" transform="translate(1569.45 2008.427)">
                    <g id="Group_909" data-name="Group 909">
                        <path id="Path_847" data-name="Path 847"
                            d="M1558.736,2027.472c-.26,0-.522-.006-.786-.022-4.588-.251-8.526-2.508-11.7-6.7a39.055,39.055,0,0,1-6.024-13.349,88.4,88.4,0,0,1-2.735-18.91.707.707,0,0,1,.7-.721h.012a.707.707,0,0,1,.708.7,86.925,86.925,0,0,0,2.693,18.589c3.3,12.177,8.827,18.563,16.429,18.983a11.769,11.769,0,0,0,8.834-3.249c9.991-9.107,11.044-34.085,11.053-34.336a.709.709,0,1,1,1.416.053,97.87,97.87,0,0,1-1.781,14c-2.06,10.145-5.335,17.321-9.732,21.331A13.165,13.165,0,0,1,1558.736,2027.472Z"
                            transform="translate(-1537.489 -1987.768)" fill="#fff" />
                    </g>
                </g>
                <g id="Group_912" data-name="Group 912" transform="translate(1595.987 2057.594)">
                    <g id="Group_911" data-name="Group 911">
                        <path id="Path_848" data-name="Path 848"
                            d="M1594.689,2047.053a.708.708,0,0,1-.393-.12l-39.4-26.208a.709.709,0,1,1,.786-1.18l39.4,26.208a.71.71,0,0,1-.393,1.3Z"
                            transform="translate(-1554.576 -2019.426)" fill="#fff" />
                    </g>
                </g>
                <g id="Group_914" data-name="Group 914" transform="translate(1613.174 2067.532)">
                    <g id="Group_913" data-name="Group 913">
                        <path id="Path_849" data-name="Path 849"
                            d="M1578.234,2044.6a.7.7,0,0,1-.408-.13c-.46-.323-11.285-8.008-12.128-13.717a3.97,3.97,0,0,1,.769-3.146c1.882-2.407,5.83-2.379,11.73.085a56.618,56.618,0,0,1,8,4.206.709.709,0,1,1-.77,1.189c-3.96-2.564-14.887-8.391-17.846-4.606a2.566,2.566,0,0,0-.483,2.064c.753,5.109,11.433,12.69,11.542,12.766a.709.709,0,0,1-.408,1.289Z"
                            transform="translate(-1565.643 -2025.825)" fill="#fff" />
                    </g>
                </g>
                <g id="Group_916" data-name="Group 916" transform="translate(1607.11 2062.401)">
                    <g id="Group_915" data-name="Group 915">
                        <path id="Path_850" data-name="Path 850"
                            d="M1578.122,2050.485a.7.7,0,0,1-.41-.13c-.595-.424-14.567-10.474-15.875-19.1a7.043,7.043,0,0,1,1.323-5.516c6.9-9.067,27.161,3.83,28.021,4.383a.709.709,0,1,1-.769,1.191c-.2-.127-20.012-12.746-26.124-4.715a5.6,5.6,0,0,0-1.051,4.445c1.218,8.031,15.156,18.054,15.3,18.155a.707.707,0,0,1-.412,1.284Z"
                            transform="translate(-1561.738 -2022.521)" fill="#fff" />
                    </g>
                </g>
                <g id="Group_918" data-name="Group 918" transform="translate(1601.389 2057.65)">
                    <g id="Group_917" data-name="Group 917">
                        <path id="Path_851" data-name="Path 851"
                            d="M1578.106,2055.264a.7.7,0,0,1-.411-.132c-.727-.52-17.824-12.87-19.508-23.912a9.472,9.472,0,0,1,1.774-7.4c2.121-2.806,5.349-4.271,9.594-4.352a33.7,33.7,0,0,1,11.671,2.308,79.133,79.133,0,0,1,14.084,6.9.709.709,0,0,1-.767,1.193c-.252-.163-25.254-16.043-33.452-5.192a8.111,8.111,0,0,0-1.5,6.335c1.595,10.458,18.759,22.85,18.932,22.974a.709.709,0,0,1-.413,1.284Z"
                            transform="translate(-1558.054 -2019.462)" fill="#fff" />
                    </g>
                </g>
                <g id="Group_920" data-name="Group 920" transform="translate(1594.961 2052.146)">
                    <g id="Group_919" data-name="Group 919">
                        <path id="Path_852" data-name="Path 852"
                            d="M1577.552,2061.5a.7.7,0,0,1-.413-.135,97.726,97.726,0,0,1-10.669-9.236c-7.307-7.334-11.469-14.033-12.372-19.915a13.147,13.147,0,0,1,2.452-10.221c2.752-3.681,6.81-5.709,12.063-6.03a39.018,39.018,0,0,1,14.449,2.375,88.472,88.472,0,0,1,17.262,8.2.709.709,0,1,1-.766,1.193,86.949,86.949,0,0,0-16.967-8.051c-11.968-4-20.348-2.931-24.906,3.167A11.779,11.779,0,0,0,1555.5,2032c2.05,13.363,22.264,28.071,22.469,28.219a.71.71,0,0,1-.416,1.284Z"
                            transform="translate(-1553.915 -2015.918)" fill="#fff" />
                    </g>
                </g>
                <g id="Group_922" data-name="Group 922" transform="translate(1543.415 2057.806)">
                    <g id="Group_921" data-name="Group 921">
                        <path id="Path_853" data-name="Path 853"
                            d="M1521.435,2047.187a.708.708,0,0,1-.393-1.3l39.4-26.208a.709.709,0,1,1,.786,1.18l-39.405,26.208A.7.7,0,0,1,1521.435,2047.187Z"
                            transform="translate(-1520.725 -2019.562)" fill="#fff" />
                    </g>
                </g>
                <g id="Group_924" data-name="Group 924" transform="translate(1544.38 2069.489)">
                    <g id="Group_923" data-name="Group 923">
                        <path id="Path_854" data-name="Path 854"
                            d="M1529.637,2045.863a.709.709,0,0,1-.408-1.289c.107-.076,10.787-7.655,11.542-12.766a2.572,2.572,0,0,0-.483-2.064c-2.962-3.786-13.887,2.042-17.847,4.606a.709.709,0,1,1-.77-1.19,56.744,56.744,0,0,1,8-4.206c5.9-2.465,9.846-2.493,11.73-.085a3.974,3.974,0,0,1,.769,3.146c-.843,5.709-11.668,13.394-12.129,13.717A.693.693,0,0,1,1529.637,2045.863Z"
                            transform="translate(-1521.347 -2027.085)" fill="#fff" />
                    </g>
                </g>
                <g id="Group_926" data-name="Group 926" transform="translate(1541.557 2064.358)">
                    <g id="Group_925" data-name="Group 925">
                        <path id="Path_855" data-name="Path 855"
                            d="M1532.912,2051.747a.709.709,0,0,1-.412-1.286c.141-.1,14.08-10.124,15.3-18.155a5.6,5.6,0,0,0-1.05-4.445c-6.111-8.031-25.926,4.588-26.125,4.715a.708.708,0,1,1-.767-1.191c.859-.553,21.121-13.449,28.02-4.383a7.036,7.036,0,0,1,1.325,5.516c-1.308,8.623-15.281,18.672-15.875,19.1A.708.708,0,0,1,1532.912,2051.747Z"
                            transform="translate(-1519.528 -2023.781)" fill="#fff" />
                    </g>
                </g>
                <g id="Group_928" data-name="Group 928" transform="translate(1539.932 2059.139)">
                    <g id="Group_927" data-name="Group 927">
                        <path id="Path_856" data-name="Path 856"
                            d="M1536.013,2056.222a.708.708,0,0,1-.413-1.284c.174-.124,17.34-12.522,18.932-22.974a8.1,8.1,0,0,0-1.5-6.333c-8.2-10.853-33.2,5.029-33.452,5.19a.709.709,0,1,1-.766-1.193,79.021,79.021,0,0,1,14.084-6.9,33.625,33.625,0,0,1,11.669-2.306c4.246.079,7.475,1.544,9.595,4.35a9.471,9.471,0,0,1,1.773,7.4c-1.683,11.042-18.779,23.392-19.506,23.912A.706.706,0,0,1,1536.013,2056.222Z"
                            transform="translate(-1518.483 -2020.421)" fill="#fff" />
                    </g>
                </g>
                <g id="Group_930" data-name="Group 930" transform="translate(1537.409 2053.484)">
                    <g id="Group_929" data-name="Group 929">
                        <path id="Path_857" data-name="Path 857"
                            d="M1539.955,2062.362a.709.709,0,0,1-.415-1.284c.2-.146,20.419-14.856,22.469-28.217a11.776,11.776,0,0,0-2.187-9.155c-4.558-6.1-12.938-7.164-24.906-3.168a86.9,86.9,0,0,0-16.967,8.053.709.709,0,1,1-.766-1.194,88.567,88.567,0,0,1,17.262-8.2,39.009,39.009,0,0,1,14.45-2.375c5.252.322,9.31,2.35,12.062,6.031a13.151,13.151,0,0,1,2.452,10.22c-.9,5.882-5.064,12.583-12.372,19.916a97.82,97.82,0,0,1-10.669,9.236A.707.707,0,0,1,1539.955,2062.362Z"
                            transform="translate(-1516.858 -2016.78)" fill="#fff" />
                    </g>
                </g>
                <g id="Group_931" data-name="Group 931" transform="translate(1659.306 2098.595)">
                    <path id="Path_858" data-name="Path 858"
                        d="M1610.253,2055.251a.571.571,0,0,1-.287-.078l-14.332-8.275a.575.575,0,0,1,.575-1l14.332,8.275a.575.575,0,0,1-.288,1.073Z"
                        transform="translate(-1595.347 -2045.826)" fill="#fff" />
                </g>
                <g id="Group_932" data-name="Group 932" transform="translate(1506.226 2099.288)">
                    <path id="Path_859" data-name="Path 859"
                        d="M1497.355,2055.7a.575.575,0,0,1-.289-1.073l14.333-8.273a.575.575,0,0,1,.575,1l-14.333,8.273A.566.566,0,0,1,1497.355,2055.7Z"
                        transform="translate(-1496.779 -2046.272)" fill="#fff" />
                </g>
                <g id="Group_933" data-name="Group 933" transform="translate(1589.871 1962.947)">
                    <path id="Path_860" data-name="Path 860"
                        d="M1550.713,1958.773a.575.575,0,0,1,1.075.281l.094,16.549a.575.575,0,1,1-1.149.006l-.095-16.549A.563.563,0,0,1,1550.713,1958.773Z"
                        transform="translate(-1550.638 -1958.483)" fill="#fff" />
                </g>
                <g id="Group_939" data-name="Group 939" transform="translate(1495.082 1981.256)">
                    <g id="Group_934" data-name="Group 934" transform="translate(0 62.289)">
                        <path id="Path_861" data-name="Path 861"
                            d="M1504.008,2010.38l3.179.332-1.148,10.98-16.436-1.716.387-3.7,13.258,1.384Z"
                            transform="translate(-1489.604 -2010.38)" fill="#fff" />
                    </g>
                    <g id="Group_935" data-name="Group 935" transform="translate(2.089 45.706)">
                        <path id="Path_862" data-name="Path 862"
                            d="M1506.846,2014.954l-15.9-4.516L1494,1999.7l3,.854-2.033,7.158,3.12.885,1.8-6.354,2.937.834-1.806,6.354,3.832,1.089,2.184-7.685,3,.854Z"
                            transform="translate(-1490.949 -1999.702)" fill="#fff" />
                    </g>
                    <g id="Group_936" data-name="Group 936" transform="translate(9.421 32.772)">
                        <path id="Path_863" data-name="Path 863"
                            d="M1513.185,1996.557q-.892-.145-1.845-.277l-1.9-.26-2.938,5.746q.664.69,1.328,1.384t1.3,1.325l-1.758,3.44q-1.952-2.046-3.589-3.824t-3.055-3.4q-1.419-1.626-2.668-3.137t-2.4-3.039l1.6-3.14q1.9.058,3.862.172t4.113.314q2.148.2,4.547.481t5.2.668Zm-13.415-1.839q.263.275.707.772t1.027,1.137q.582.643,1.28,1.424t1.474,1.617l2.151-4.207q-1.13-.152-2.163-.281t-1.9-.214c-.579-.056-1.09-.107-1.53-.151S1500.023,1994.738,1499.77,1994.718Z"
                            transform="translate(-1495.67 -1991.374)" fill="#fff" />
                    </g>
                    <g id="Group_937" data-name="Group 937" transform="translate(16.998 15.339)">
                        <path id="Path_864" data-name="Path 864"
                            d="M1503.253,1983.962a10.242,10.242,0,0,1,4.606-3.608,4.6,4.6,0,0,1,4.445,1.041,4.647,4.647,0,0,1,1.7,2.395,5.248,5.248,0,0,1-.117,2.862q.8,0,1.764.073t1.98.2q1.016.128,2.063.329t2.022.436l-2.609,3.238q-.918-.153-1.861-.315t-1.862-.272q-.92-.112-1.769-.18c-.565-.047-1.1-.064-1.595-.056l-1.153,1.43,4.7,3.786-2.334,2.9L1500.549,1988a20.843,20.843,0,0,1,1.32-2.168Q1502.61,1984.76,1503.253,1983.962Zm2.6,1.823q-.254.315-.438.581t-.345.5l3.491,2.813.658-.817a6.342,6.342,0,0,0,1.477-2.668,1.894,1.894,0,0,0-.825-1.828,1.815,1.815,0,0,0-1.921-.37A5.681,5.681,0,0,0,1505.857,1985.785Z"
                            transform="translate(-1500.549 -1980.149)" fill="#fff" />
                    </g>
                    <g id="Group_938" data-name="Group 938" transform="translate(28.921)">
                        <path id="Path_865" data-name="Path 865"
                            d="M1527.24,1985.087q-3.029-1.2-6.212-2.184a57.261,57.261,0,0,0-6.363-1.572l6.807,8.419-2.854,2.308-10.392-12.848,2.354-1.905q1.107.118,2.569.408t3.049.677q1.586.389,3.224.859t3.114.932l-6.133-7.585,2.875-2.323,10.391,12.85Z"
                            transform="translate(-1508.226 -1970.272)" fill="#fff" />
                    </g>
                </g>
                <g id="Group_946" data-name="Group 946" transform="translate(1638.153 1985.202)">
                    <g id="Group_940" data-name="Group 940">
                        <path id="Path_866" data-name="Path 866"
                            d="M1597.834,1988.531a9.663,9.663,0,0,1-3.029,2.3,6.921,6.921,0,0,1-3.188.663,8.08,8.08,0,0,1-3.268-.871,13.924,13.924,0,0,1-3.266-2.3q-.736-.68-1.662-1.646a19.05,19.05,0,0,1-1.694-2.031l10.907-11.837a21.975,21.975,0,0,1,2.19,1.581q1.083.885,1.82,1.562a14.452,14.452,0,0,1,2.508,2.96,7.881,7.881,0,0,1,1.165,3.117,6.683,6.683,0,0,1-.373,3.222A10,10,0,0,1,1597.834,1988.531Zm-11.465-3.429c.118.131.258.277.423.438s.364.351.6.565a5.49,5.49,0,0,0,4,1.771,5.392,5.392,0,0,0,3.625-1.946,4.664,4.664,0,0,0-.375-7.512c-.186-.172-.382-.346-.587-.525a7.33,7.33,0,0,0-.6-.472Z"
                            transform="translate(-1581.727 -1972.813)" fill="#fff" />
                    </g>
                    <g id="Group_941" data-name="Group 941" transform="translate(11.885 13.996)">
                        <path id="Path_867" data-name="Path 867"
                            d="M1589.38,1991.533l13.375-9.708,6.556,9.032-2.529,1.834-4.37-6.02-2.625,1.9,3.879,5.346-2.469,1.794-3.881-5.345-3.223,2.339,4.693,6.465-2.529,1.834Z"
                            transform="translate(-1589.38 -1981.825)" fill="#fff" />
                    </g>
                    <g id="Group_942" data-name="Group 942" transform="translate(19.719 27.77)">
                        <path id="Path_868" data-name="Path 868"
                            d="M1594.424,1997.939l14.852-7.245,4.852,9.944-2.808,1.37-3.219-6.6-3.108,1.517,2.864,5.872-2.808,1.37-2.864-5.874-6.13,2.991Z"
                            transform="translate(-1594.424 -1990.694)" fill="#fff" />
                    </g>
                    <g id="Group_943" data-name="Group 943" transform="translate(25.2 42.249)">
                        <path id="Path_869" data-name="Path 869"
                            d="M1597.953,2004.566l15.887-4.549,3.072,10.73-3,.859-2.047-7.152-3.119.893,1.819,6.349-2.935.84-1.817-6.35-3.828,1.1,2.2,7.68-3,.86Z"
                            transform="translate(-1597.953 -2000.017)" fill="#fff" />
                    </g>
                    <g id="Group_944" data-name="Group 944" transform="translate(28.841 58.73)">
                        <path id="Path_870" data-name="Path 870"
                            d="M1601.137,2022.934q2.707-1.808,5.325-3.872a57.157,57.157,0,0,0,4.909-4.341l-10.8.817-.278-3.662,16.479-1.247.228,3.019q-.724.846-1.8,1.882t-2.289,2.123q-1.223,1.083-2.539,2.165t-2.536,2.033l9.725-.736.28,3.685-16.479,1.249Z"
                            transform="translate(-1600.298 -2010.629)" fill="#fff" />
                    </g>
                    <g id="Group_945" data-name="Group 945" transform="translate(28.671 77.157)">
                        <path id="Path_871" data-name="Path 871"
                            d="M1607.38,2037.987a9.666,9.666,0,0,1-3.608-1.205,6.943,6.943,0,0,1-2.344-2.26,8.079,8.079,0,0,1-1.121-3.19,13.846,13.846,0,0,1,.056-3.993q.145-.99.422-2.3a19.133,19.133,0,0,1,.722-2.545l15.927,2.339a22.369,22.369,0,0,1-.072,2.7q-.121,1.393-.265,2.384a14.563,14.563,0,0,1-1.031,3.741,7.9,7.9,0,0,1-1.918,2.719,6.691,6.691,0,0,1-2.873,1.5A9.953,9.953,0,0,1,1607.38,2037.987Zm-3.628-11.4c-.042.171-.082.37-.124.6s-.085.5-.13.812a5.485,5.485,0,0,0,.791,4.3,5.386,5.386,0,0,0,3.65,1.9,4.664,4.664,0,0,0,5.993-4.543c.037-.253.072-.512.1-.781a7.541,7.541,0,0,0,.051-.762Z"
                            transform="translate(-1600.188 -2022.494)" fill="#fff" />
                    </g>
                </g>
                <g id="Group_953" data-name="Group 953" transform="translate(1537.948 2129.01)">
                    <g id="Group_947" data-name="Group 947">
                        <path id="Path_872" data-name="Path 872"
                            d="M1528.451,2082.885q.163-.89.312-1.841c.1-.632.2-1.264.3-1.892l-5.69-3.047-1.409,1.3q-.708.653-1.35,1.28l-3.406-1.823q2.083-1.915,3.89-3.517t3.46-2.988q1.652-1.388,3.187-2.609t3.084-2.338l3.108,1.665q-.091,1.9-.246,3.858t-.39,4.1q-.24,2.145-.568,4.538t-.769,5.189Zm2.094-13.378c-.187.172-.447.4-.784.691s-.72.625-1.157,1-.918.8-1.447,1.254-1.078.937-1.645,1.443l4.165,2.23q.175-1.125.323-2.157t.25-1.9c.067-.578.127-1.085.18-1.527S1530.521,2069.76,1530.544,2069.507Z"
                            transform="translate(-1517.205 -2065.411)" fill="#fee" />
                    </g>
                    <g id="Group_948" data-name="Group 948" transform="translate(19.573 3.844)">
                        <path id="Path_873" data-name="Path 873"
                            d="M1543.751,2072.6l-1.094,2.977-4.679-1.718-4.6,12.536-3.493-1.282,4.6-12.535-4.678-1.718,1.093-2.977Z"
                            transform="translate(-1529.808 -2067.886)" fill="#fee" />
                    </g>
                    <g id="Group_949" data-name="Group 949" transform="translate(33.431 8.724)">
                        <path id="Path_874" data-name="Path 874"
                            d="M1552.76,2073.387l-.547,3.123-4.909-.859-2.3,13.154-3.664-.64,2.3-13.156-4.909-.858.547-3.124Z"
                            transform="translate(-1538.731 -2071.028)" fill="#fee" />
                    </g>
                    <g id="Group_950" data-name="Group 950" transform="translate(45.319 10.916)">
                        <path id="Path_875" data-name="Path 875"
                            d="M1559.14,2088.927q-.287-.857-.617-1.761l-.658-1.8-6.453.09q-.3.908-.6,1.822t-.561,1.772l-3.863.053q.895-2.683,1.7-4.96t1.58-4.291q.773-2.012,1.525-3.825t1.567-3.54l3.525-.048q.839,1.705,1.654,3.494t1.645,3.782q.831,1.991,1.7,4.244t1.842,4.912Zm-4.645-12.718c-.081.241-.2.568-.351.985s-.329.894-.526,1.438-.416,1.145-.66,1.8-.489,1.34-.741,2.058l4.726-.065q-.393-1.069-.764-2.044t-.7-1.781c-.222-.539-.415-1.013-.583-1.424S1554.6,2076.442,1554.494,2076.209Z"
                            transform="translate(-1546.386 -2072.44)" fill="#fee" />
                    </g>
                    <g id="Group_951" data-name="Group 951" transform="translate(62.906 8.023)">
                        <path id="Path_876" data-name="Path 876"
                            d="M1568.056,2087.706a8.028,8.028,0,0,1-6.493-.747,8.935,8.935,0,0,1-3.539-5.7,9.823,9.823,0,0,1-.228-3.71,7.892,7.892,0,0,1,1.083-3.054,7.353,7.353,0,0,1,2.2-2.258,9.674,9.674,0,0,1,3.136-1.342,10.724,10.724,0,0,1,1.892-.293,12.5,12.5,0,0,1,1.545,0,7.367,7.367,0,0,1,1.14.155c.312.072.539.132.682.18l-.344,3.172a9.036,9.036,0,0,0-1.888-.258,8.461,8.461,0,0,0-2.3.259,5.068,5.068,0,0,0-1.567.671,3.791,3.791,0,0,0-1.2,1.231,4.576,4.576,0,0,0-.6,1.825,6.873,6.873,0,0,0,.2,2.456,8.717,8.717,0,0,0,.742,2.016,4.538,4.538,0,0,0,1.187,1.469,3.868,3.868,0,0,0,1.69.759,5.238,5.238,0,0,0,2.232-.107,10.027,10.027,0,0,0,1.391-.432,10.759,10.759,0,0,0,1.061-.488,5.9,5.9,0,0,0,.787-.5q.333-.251.6-.463l1.693,2.659a8.765,8.765,0,0,1-1.957,1.352A12.98,12.98,0,0,1,1568.056,2087.706Z"
                            transform="translate(-1557.71 -2070.576)" fill="#fee" />
                    </g>
                    <g id="Group_952" data-name="Group 952" transform="translate(75.712 1.045)">
                        <path id="Path_877" data-name="Path 877"
                            d="M1581.845,2082.745q-.8-.513-1.819-1.067t-2.137-1.076q-1.118-.521-2.271-.941a17.646,17.646,0,0,0-2.216-.655l3,6.323-3.361,1.6L1565.956,2072l3.361-1.6,2.664,5.611q.65-1.978,1.281-4.1t1.157-3.935l3.988-1.893q-.662,2.537-1.409,4.945t-1.645,4.912a28.478,28.478,0,0,1,5.139,1.9,48.83,48.83,0,0,1,5.347,3Z"
                            transform="translate(-1565.956 -2066.084)" fill="#fee" />
                    </g>
                </g>
                <g id="Group_955" data-name="Group 955" transform="translate(1582.711 2009.412)">
                    <g id="Group_954" data-name="Group 954">
                        <path id="Path_878" data-name="Path 878"
                            d="M1553.7,2007.939c-.1,0-.207,0-.312-.012-3.047-.235-5.21-3.536-6.425-9.814a56.682,56.682,0,0,1-.93-8.994.708.708,0,0,1,.7-.718h.009a.709.709,0,0,1,.708.7c.059,4.718,1.26,17.043,6.048,17.411a2.573,2.573,0,0,0,1.988-.741c3.839-3.46,4.234-16.549,4.237-16.681a.708.708,0,1,1,1.416.039c-.014.562-.418,13.832-4.7,17.7A4.012,4.012,0,0,1,1553.7,2007.939Z"
                            transform="translate(-1546.028 -1988.402)" fill="#fff" />
                    </g>
                </g>
                <g id="Group_957" data-name="Group 957" transform="translate(1580.746 2047.208)">
                    <g id="Group_956" data-name="Group 956">
                        <circle id="Ellipse_8" data-name="Ellipse 8" cx="9.309" cy="9.309" r="9.309" fill="#fff" />
                    </g>
                </g>
            </g>
        </svg>
    )
})

export default LogoLDA;