export const GRAFANA_API_KEY = 'eyJrIjoiSjhEcHhsUm93UHpydDdFM3lKN01uUlpHYkVFSkNhMzUiLCJuIjoiRGV2ZWxvcGVyIiwiaWQiOjJ9';

export const GRAFANA_DOMAIN = 'http://localhost:80';
export const GRAFANA_ORGANIZATION_ID = '2';

export const MIN_SCREEN_WIDTH_STARTUP_ANIM = 1200;
export const MAX_SCREEN_WIDTH_STARTUP_ANIM = 2200;
export const MIN_SCREEN_HEIGHT_STARTUP_ANIM = 620;
export const MAX_SCREEN_HEIGHT_STARTUP_ANIM = 1200;


export const MIN_SCREEN_WIDTH_NAV_TOGGLE = 980;

export const SCROLL_UP_MIN_SCROLL = 20;

export const ANIMATION_FADE_OUT_SHIFT_VERTICAL = '10%';

export const COOKIE_SESSION_TYPE_PROPERTY_NAME = "sessionlt";
export const COOKIE_CONSENT_PROPERTY_NAME = "cookie_consent";
export const COOKIE_FIRST_TIME_PROPERTY_NAME = "first_time";
export const COOKIE_AUTH_TOKEN_PROPERTY_NAME = "uinfo";

export const NET2ME_LINK = "https://net2.me";
export const PWN2ME_LINK = "https://pwn2.me";
export const CTF_LINK = "https://ctf.me";

export const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
