import React from 'react';

import DashboardView from './DashboardView';
import { ReactComponent as SettingsIcon } from '../../../static/image/dashboard/settings.svg';
import LoadingSpinner from '../../Element/LoadingSpinner';

export const DashboardSettings: React.FC = () => {
    return (
        <DashboardView header="Settings" Icon={SettingsIcon}>
            <div className="c-page-dshb dshb-page-body">
                <LoadingSpinner />
            </div>
        </DashboardView>
    );
}

export default DashboardSettings;