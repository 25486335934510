import React, { useMemo } from 'react';


export const Bar: React.FC = () => {
    return (
        <div className="rr-bar">
            <div className="rr-bar--head">
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div className="rr-bar--body">
                <div></div>
                <div>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
    )
}

export const EdgeBars: React.FC<EdgeBarsProps> = (props) => {
    const { direction } = props;
    return (
        <div
            className={`rr-bar-edge--${direction}`}
            id={'id' in props ? props.id : ''}
        >
            <div></div>
            <div></div>
        </div>
    )
}

export const HorizontalBars: React.FC<HorizontalBarsProps> = ({ width, height, alignment }) => {
    return (
        <div
            className="rr-bars--hor"
            style={{
                width: width,
                alignSelf: alignment,
                height: height
            }}
        >
            <span></span>
            <span></span>
        </div>
    )
}

export const DiagonalEdgeBar: React.FC<DiagonalEdgeBarProps> = ({ direction, shape, ...props }) => {
    const style = useMemo(() => {
        return {
            clipPath: shape,
            width: "width" in props ? props.width : "80px",
            height: "height" in props ? props.height : "100%"
        }
    }, [shape, props]);
    return (
        <div className={`rr-bar-diag--${direction}`}>
            <div></div>
            <div
                style={style}
            >
            </div>
        </div>
    )
}

export const Diamond: React.FC<DiamondProps> = (props) => {
    // a use case of passing a onClick as a prop is to show a modal (which can be in children)
    return (
        <div
            className="rr-diamond"
            onClick={'onClick' in props ? props.onClick : () => { }}
            onMouseEnter={'onMouseEnter' in props ? props.onMouseEnter : () => { }}
            onMouseLeave={'onMouseLeave' in props ? props.onMouseLeave : () => { }}
        >
            {props.children}
        </div>
    )
}

