import React, { useEffect, useState, memo } from 'react';

import { growAnimation } from '../../utils/animation';
import { openTab } from '../../utils/functions';

import { CTF_LINK } from '../constants';

const colorsMap = {
    "path-caption--ctf-magent": "940a37",
    "path-caption--ctf-yellow": "ffd369",
    "path-caption--ctf-orange": "e26241",
}

export const LogoCTF: React.FC<AnimableSVGProps> = ({ animate, ...props }) => {

    const [isAnimated, setIsAnimated] = useState(false);

    const id = 'id' in props ? props.id : 'CTFLogo' as string;

    useEffect(() => {
        if (isAnimated || animate === false) return;
        const paths = document.querySelectorAll(`svg#${id} > *`) as NodeListOf<SVGPathElement>;

        if (!('animationProps' in props)) {
            paths.forEach(path => growAnimation(path, { colorMap: colorsMap }));
        } else {
            const { growTime, delay, fillTime } = props.animationProps!;
            paths.forEach(path => growAnimation(
                path, {
                growTime: growTime,
                delay: delay,
                fillTime: fillTime
            })
            );
        }
        setIsAnimated(true);
    }, [animate, id, isAnimated, props])
    return (
        <svg
            id={id}
            onClick={() => openTab(CTF_LINK)}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 69.68 17.83">
            <path className="path-caption--ctf-magent"
                d="M34.46,4.42V9.86H11.15V4.42ZM16.59,10.56v2l3.67,3.67,5.95,6H18.52l-7.37-7.37v-4.3Zm17.87,6.2v5.49H27.14L21.7,16.76Z"
                transform="translate(-11.15 -4.42)" />
            <rect className="path-caption--ctf-magent" x="33" y="6.13" width="5.48" height="11.7" />
            <polygon className="path-caption--ctf-magent"
                points="53.7 6.14 53.7 6.19 53.69 17.83 48.25 17.83 48.26 6.19 48.26 6.14 53.7 6.14" />
            <rect className="path-caption--ctf-magent" x="54.4" y="6.19" width="11.73" height="5.45" />
            <circle className="path-caption--ctf-yellow" cx="6.95" cy="7.16" r="0.69" />
            <polygon className="path-caption--ctf-orange"
                points="23.94 0.03 23.94 5.39 58.56 5.39 46.27 0.03 23.94 0.03" />
            <polygon className="path-caption--ctf-yellow"
                points="69.68 5.39 69.68 0.03 48.38 0.03 60.66 5.39 69.68 5.39" />
            <rect className="path-caption--ctf-yellow" x="12.73" y="0.03" width="5.33" height="5.33" />
            <rect className="path-caption--ctf-orange" x="17.95" y="0.03" width="5.33" height="5.33" />
            <rect className="path-caption--ctf-magent" x="23.84" y="0.02" width="5.33" height="5.33" />
        </svg>
    )
}

export default memo(LogoCTF);
