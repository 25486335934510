import React, { useEffect, useState } from "react";

import { growAnimation } from "../../utils/animation";
import { openTab, getThemeFromCSS } from '../../utils/functions';
import { NET2ME_LINK } from '../constants';


const lightFill = '#27496d';
const darkFill = '#fff';

// this method relies on the use of svg-logo--net2me class only in instances of this component
export const onThemeToggle = (newTheme: string) => {
    const themeDependentPaths = document.querySelectorAll(`svg.svg-logo--net2me *[class*='path-caption--net2me-theme'], svg.svg-logo--net2me > g > .path-caption--net2me-theme`) as NodeListOf<SVGPathElement>;

    themeDependentPaths.forEach(path => {
        if (window.getComputedStyle(path, null).getPropertyValue('animation-name') !== 'none') {
            // remove animation so element fill and stroke attrs can be manipulated without problems
            path.style.animation = "none";
        }
        var fill = newTheme === 'light' ? lightFill : darkFill;
        path.style.fill = fill;
    });
}

export const LogoNet2me: React.FC<AnimableSVGProps> = ({ id, animate, animationProps }) => {
    const [isAnimated, setIsAnimated] = useState(false);

    const logoId = id || 'net2meLogo' as string;


    useEffect(() => {
        if (isAnimated || animate === false) return;

        const theme = getThemeFromCSS();

        const colorsMap = {
            "path-caption--net2me-blue": "00a8cc",
            "path-caption--net2me-dark_blue": "27496d",
            "path-caption--net2me-cyan": "0c7b93",
            "path-caption--net2me-red": "red",
            "path-caption--net2me-theme": theme === 'light' ? "27496d" : "fff",
        }

        const paths = document.querySelectorAll(`svg#${logoId} > :not(defs, g), svg#${logoId} > g > *`) as NodeListOf<SVGPathElement>;
        if (animationProps) {
            const { growTime, delay, fillTime } = animationProps!;
            paths.forEach(path => growAnimation(
                path, {
                growTime: growTime,
                delay: delay,
                fillTime: fillTime
            })
            );
        } else {
            paths.forEach(path => growAnimation(path, { colorMap: colorsMap }));
        }
        setIsAnimated(true);

    }, [logoId, animate, animationProps, isAnimated]);

    return (
        <svg
            className="svg-logo--net2me"
            id={logoId}
            onClick={() => openTab(NET2ME_LINK)}
            xmlns="http://www.w3.org/2000/svg"
            xlinkHref="http://www.w3.org/1999/xlink" viewBox="0 0 77.3 13.5"
        >
            <defs>
                <clipPath id="f64f9030-d360-4254-8a39-56486e2acbb7" transform="translate(-7.3 -6.9)">
                    <polygon className="bf06dbed-c05d-4131-ae4f-6a52907e616a"
                        points="71.1 11.5 67.1 15.5 66.5 16.1 67.2 16.1 67.2 16.1 67.2 16.1 84.5 16.1 84.5 20.1 72.9 20.1 72.9 20.1 62.5 20.1 62.4 20.1 62.4 20.1 60.9 20.1 60.9 15.9 65.4 11.5 71.1 11.5" />
                </clipPath>
            </defs>
            <polygon className="path-caption--net2me-blue"
                points="63.8 4.6 59.8 8.6 59.2 9.2 59.9 9.2 59.9 9.2 59.9 9.2 77.2 9.2 77.2 13.2 65.6 13.2 65.6 13.2 55.2 13.2 55.1 13.2 55.1 13.2 53.6 13.2 53.6 9 58.1 4.6 63.8 4.6" />
            <g className="f961be5a-60ee-4710-b450-2df229159e2b">
                <rect className="path-caption--net2me-dark_blue" x="64.4" y="9" width="4.3" height="4.3" />
                <rect className="path-caption--net2me-cyan" x="68.7" y="9" width="4.3" height="4.3" />
                <rect className="path-caption--net2me-blue" x="73" y="9" width="4.3" height="4.3" />
            </g>
            <path className="path-caption--net2me-blue"
                d="M14.3,7.2,20,9.7v4.4l-6.5-2.8H7.3v-4Zm-7,4.5h4v8.6h-4ZM20.4,9.9l4,1.8v8.7h-4Z"
                transform="translate(-7.3 -6.9)" />
            <rect className="path-caption--net2me-blue" x="18.2" y="0.3" width="17.2" height="4" />
            <polygon className="path-caption--net2me-blue"
                points="22.3 5 22.3 9.5 35.2 9.5 35.2 13.4 18.4 13.4 18.4 5 22.3 5" />
            <rect className="path-caption--net2me-blue" x="23.1" y="5" width="8.4" height="3.9" />
            <circle className="path-caption--net2me-red" cx="53.9" cy="5.8" r="0.8" />
            <polygon className="path-caption--net2me-dark_blue" points="54.4 0 63.9 4 53.3 4 44 0 54.4 0" />
            <rect className="path-caption--net2me-blue" x="42.8" y="4.7" width="4" height="8.6" />
            <polygon className="path-caption--net2me-blue"
                points="36.2 0.2 36.2 4.2 52.2 4.2 43 0.2 36.2 0.2" />
            <polygon className="path-caption--net2me-theme"
                points="67.5 4.6 68.3 4.9 68.3 8.9 67 8.9 67 5.9 64.4 5.9 64.4 4.6 67.5 4.6" />
            <rect className="path-caption--net2me-theme" x="64.4" y="5.8" width="1.3" height="3.1" />
            <polygon className="path-caption--net2me-theme"
                points="68.5 5 70.9 6 70.9 7.5 69.6 6.9 69.6 6.9 68.5 6.4 68.5 5" />
            <polygon className="path-caption--net2me-theme"
                points="69.6 6.6 70.9 7.2 70.9 8.9 69.6 8.9 69.6 6.6" />
            <rect className="path-caption--net2me-theme" x="71.6" y="4.6" width="5.6" height="1.3" />
            <polygon className="path-caption--net2me-theme"
                points="72.9 6.1 72.9 7.6 77.2 7.6 77.2 8.9 71.6 8.9 71.6 6.1 72.9 6.1" />
            <rect className="path-caption--net2me-theme" x="72.5" y="6.1" width="3.4" height="1.3" />
        </svg>
    )
}

export default LogoNet2me;
