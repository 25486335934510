import React from 'react';

import Notifications from '../Header/Notifications';
import Search from './Search';

import { DashboardThemeSwitcher } from '../../Theme/ThemeSwitcher';
import { UserPreview } from './User';

export const DashboardHeader: React.FC = () => {
    return (
        <header className="dshb-header">
            <Search />
            <DashboardThemeSwitcher id="dshbThemeSwitcher" />
            <Notifications />
            <UserPreview />
        </header>
    );
}

export default DashboardHeader;