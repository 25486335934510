import { gql, useQuery } from '@apollo/client';

const GET_USER = gql`
    query getUser {
        user {
            username
            email
        }
    }
`
export const useGetUser = (): IQueryHook<IGraphQLUser> => {
    const { data, loading, error } = useQuery(GET_USER);
    return { data, loading, error };
}

export default useGetUser;
